import React, { useEffect, useState } from 'react';
import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import { FlightButton, FlightRadioButton, FlightModal, getIcon } from '@flybits/design-system';
import { ReactComponent as ConnectorDefaultImage } from 'src/assets/connectors-icon.svg';
import imageErrorConnector from 'src/assets/connectors-icon.svg';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import './Connector.scss';
import { getStoredOrganizationId, setStoredOrganizationId } from 'src/helpers/auth.helper';
import { useHistory } from 'react-router';
import useNotification from 'src/hooks/useNotification';
import { Connector, ConnectorV1 } from 'src/model/connectors/connectors';
import SaveToolbar from 'src/components/SaveToolbar/SaveToolbar';
import { capitalize, timeFromNowTo, removeProperties } from 'src/helpers/general.helper';
import { GENERIC_INTERNAL_ERROR } from 'src/constants/errors';
import ConnectorService from 'src/services/connectors.service';

// V1
import DisplayInformation from 'src/pages/Connectors/DisplayInformation';
import DeploymentV1 from 'src/pages/Connectors/DeploymentV1';
import Configurations from 'src/pages/Connectors/Configurations';
import PreDefinedAttributes from 'src/pages/Connectors/PreDefinedAttributes';
interface Props {
  location?: any;
  match?: any;
}
// Translation
import { useTranslation } from 'react-i18next';
import DeleteConnectorModal from 'src/components/Shared/DeleteConnectorModal/DeleteConnectorModal';
import UserDefinedAttributes from 'src/pages/Connectors/UserDefinedAttributes';
const ConnectorPage: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation(['translation', 'errors', 'integrations', 'components']);
  const { match, location: { state: { importFile = {}, isImport = false } = {} } = {} } = props;
  if (match.params.id) setStoredOrganizationId(match.params.id);
  const APIVersion = match.params.version ? match.params.version + '/' : '';
  const { organization } = useControlTowerContext();

  const orgID = match.params.id || organization?.id || getStoredOrganizationId();
  const connectorID = match.params.connector;
  const commonConnectorData = !APIVersion
    ? {
        id: 'new',
        status: 'draft',
        name: '', // Required
        description: '', // Optional
        documentationUrl: '', // Optional
        imageUrl: '', // Optional
        supportEmail: '', // Optional
        deployments: {}, // Optional
        createdAt: Date.now() / 1000, //unix timestamp
        updatedAt: Date.now() / 1000,
        ttl: 0,
      }
    : {
        id: 'new',
        configsVersion: 'v1',
        display: {
          name: '', // Required
          description: '', // Optional
          documentationUrl: '', // Optional
          imageUrl: '', // Optional
          supportEmail: '', // Optional
        },
        deployments: {}, // Optional
        metadata: {
          status: 'draft',
          createdAt: Date.now() / 1000, //unix timestamp
          updatedAt: Date.now() / 1000,
          ttl: 0,
        },
      };
  const connectorDataWithVersion = !APIVersion
    ? (commonConnectorData as Connector)
    : (commonConnectorData as ConnectorV1);
  const newConnector: Connector | ConnectorV1 = !APIVersion
    ? {
        ...connectorDataWithVersion,
        configTemplate: {}, // optional
      }
    : {
        ...connectorDataWithVersion,
        configs: {
          configSchema: {},
          uiSchema: {},
          additionalAttributes: {
            allowAdditional: false,
            allowedScopes: [],
            configSchema: {},
            uiSchema: {},
          },
          pluginAttributes: [],
        },
      };

  const history = useHistory();
  const { addNotification, addNotificationError } = useNotification();
  if (!orgID) {
    history.push('/organizations');
  }
  if (!connectorID) {
    history.goBack();
  }

  const MAIN_CLASS = 'Connector';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;
  const tabsArrayV0 = [
    { slug: 'basic', name: t('integrations:connectors.details.sections.basic.title', 'Basic info') },
    { slug: 'contact', name: t('integrations:connectors.details.sections.contact.title', 'Contact & documentation') },
    {
      slug: 'deployment',
      name: t('integrations:connectors.details.sections.deployment.title', 'Deployment configuration'),
    },
    {
      slug: 'config-template',
      name: t('integrations:connectors.details.sections.config-template.title', 'Configuration template'),
    },
    { slug: 'sharing', name: t('integrations:connectors.details.sections.sharing.title', 'Sharing settings') },
  ];
  const tabsArrayV1 = [
    {
      slug: 'basic',
      name: t('integrations:connectors.details.sections.display-information.title', 'Display information'),
    },
    {
      slug: 'deployment',
      name: t('integrations:connectors.details.sections.deployment.title', 'Deployment configuration'),
    },
    {
      slug: 'configuration',
      name: t('integrations:connectors.details.sections.configuration.title', 'Configuration'),
    },
    {
      slug: 'pre-defined-attributes',
      name: t('integrations:connectors.details.sections.pre-defined-attributes.title', 'Pre-defined attributes'),
    },
    {
      slug: 'user-defined-attributes',
      name: t('integrations:connectors.details.sections.user-defined-attributes.title', 'User-defined attributes'),
    },
  ];
  const tabsArray = !APIVersion ? tabsArrayV0 : tabsArrayV1;
  const [selectedTab, setTab] = useState(window.location.hash.replace('#', '') || 'basic');
  const [loading, setLoading] = useState(false);
  const [isModified, setModified] = useState(false);
  const [isCodeModified, setCodeModified] = useState(false);
  const [isNewConnector, setIsNewConnector] = useState(false);
  const [tryToDeleteConnector, setTryToDeleteConnector] = useState(false);
  const [tryToDiscardChanges, setTryToDiscardChanges] = useState(false);
  const [tabsWithError, setTabsWithError] = useState<any[]>([]);
  const [updatedImageFile, setUpdatedImageFile] = useState<any>(null);
  const [connectorDataDefault, setConnectorDataDefault] = useState<Connector | ConnectorV1 | undefined>();
  const [connectorData, setConnectorData] = useState<Connector | ConnectorV1 | undefined>();

  const connectorsServiceManager = new ConnectorService();
  useEffect(() => {
    // Get current connector data
    const getConnectorData = async () => {
      setLoading(true);
      try {
        const connectorsResponseObject = await connectorsServiceManager.getConnectorById(
          orgID,
          connectorID,
          APIVersion,
        );
        if (connectorsResponseObject?.data) {
          setIsNewConnector(false);
          setConnectorData(connectorsResponseObject?.data);
          setConnectorDataDefault(connectorsResponseObject?.data);
          setLoading(false);
        }
      } catch (error: any) {
        setLoading(false);
        console.error(error);
        addNotificationError(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
        history.push({
          pathname: '/error',
          state: {
            title: t('integrations:connectors.not_found.title', 'Uh-oh.'),
            message: t(
              'integrations:connectors.not_found.message',
              "The connector you're looking for doesn't seem to exist. Which begs the question: How certain are you that what you're looking for is actually what you need?",
            ),
            buttonText: t('integrations:connectors.not_found.buttonText', 'Stop thinking and go back'),
            buttonURL: `/organization/${orgID}/integrations/v1/#connectors`,
          },
        });
      }
    };

    if (connectorID === 'new') {
      setIsNewConnector(true);
      let defaults = {
        ...newConnector,
        updatedAt: Date.now() / 1000,
      };
      setConnectorDataDefault(defaults);
      if (isImport) {
        defaults = { ...defaults, ...importFile };
      }

      setConnectorData(defaults);
    } else {
      // Editing
      setIsNewConnector(false);
      getConnectorData();
    }
  }, []);

  const HandleTabError = (tab: string, hasError: boolean) => {
    if (loading) return;
    if (hasError && !tabsWithError.includes(tab)) setTabsWithError([...tabsWithError, tab]);
    if (!hasError && tabsWithError.includes(tab)) setTabsWithError(tabsWithError.filter((item) => item !== tab));
  };
  const [isValidConfig, setIsValidConfig] = useState(false);
  useEffect(() => {
    if (!connectorData) return;
    if (ValidateData('activate')) {
      setIsValidConfig(true);
    } else {
      setIsValidConfig(false);
    }
    return () => setIsValidConfig(false);
  }, [connectorData]);
  const ValidateData = (action = 'save') => {
    const sectionErrors: string[] = [];
    const connectorStatus = (connectorData as ConnectorV1).metadata.status;
    const connectorName = !APIVersion ? (connectorData as Connector).name : (connectorData as ConnectorV1).display.name;
    // If connector is already active or is trying to activate, check all required fields
    if (connectorStatus === 'active' || (action !== 'save' && connectorStatus === 'draft')) {
      // Required:
      // - name
      // - config template
      // - deployments (at least 1)
      let hasConfigTemplates = false;
      let hasDeployment = false;
      const connectorV1 = connectorData as ConnectorV1;
      if (connectorV1.configs && connectorV1.configs.configSchema) {
        hasConfigTemplates = true;
      }
      if (connectorData?.deployments) {
        if (Object.keys(connectorData.deployments).length) {
          hasDeployment = true;
        }
      }
      if (connectorName && hasConfigTemplates && hasDeployment) {
        setTabsWithError([]);
        return true;
      }
      if (!connectorName) sectionErrors.push('basic');
      if (!hasConfigTemplates) {
        sectionErrors.push('configuration');
      }
      if (!hasDeployment) sectionErrors.push('deployment');
      setTabsWithError(sectionErrors);
      return false;
    }
    return true;
  };
  const handleDeleteConnector = (deleted: boolean, message?: string) => {
    if (deleted) {
      setTryToDeleteConnector(false);
      addNotification(t('integrations:connectors.delete.success', 'Connector deleted successfully'));
      history.replace(`/organization/${orgID}/integrations/${APIVersion}`);
    } else {
      addNotificationError(
        message || t('integrations:connectors.delete.error', 'Error while trying to delete this connector.'),
      );
    }
  };
  const handleConnectorAction = async () => {
    if (!organization || !connectorData || isNewConnector) return;
    const connectorStatus = (connectorData as ConnectorV1).metadata.status;
    if (connectorStatus !== 'active') {
      if (!ValidateData()) {
        toggleAlert(
          true,
          t('integrations:connectors.details.messages.activate_error_title', 'Unable to activate connector'),
          t(
            'integrations:connectors.details.messages.activate_error_msg',
            'Your must have at least one deployment configuration and a configuration template to activate your connector.',
          ),
        );
        return false;
      }
    }
    setLoading(true);
    try {
      const connectorsResponseObject = await connectorsServiceManager.updateConnectorStatus(
        organization.id,
        connectorID,
        connectorStatus !== 'active' ? 'activate' : 'deactivate',
        APIVersion,
      );
      setLoading(false);
      if (connectorsResponseObject && [200, 201, 204].includes(connectorsResponseObject.status)) {
        if (connectorsResponseObject?.data?.errorMessage) {
          addNotificationError(connectorsResponseObject?.data?.errorMessage);
          return false;
        } else {
          addNotification(
            t('integrations:connectors.details.messages.update_status', 'Connector status was updated successfully!'),
          );
          setIsNewConnector(false);
          setConnectorData(connectorsResponseObject?.data);
          setConnectorDataDefault(connectorsResponseObject?.data);
          return true;
        }
      }
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      if (error?.response?.data?.message) {
        addNotificationError(error.response.data.message);
      } else {
        addNotificationError(GENERIC_INTERNAL_ERROR);
      }
      return false;
    }
    return false;
  };
  const handleImageChange = (imgFile: any) => {
    if (!imgFile) return;
    setUpdatedImageFile(imgFile);
  };
  const handleCancelEdits = () => {
    addNotification(t('integrations:connectors.details.messages.changes_discarded', 'All changes were discarded'));
    setTryToDiscardChanges(false);
    setUpdatedImageFile(null);
    const resetData = isNewConnector ? newConnector : connectorDataDefault;
    if (resetData) {
      setConnectorData({
        ...resetData,
      });
      setConnectorDataDefault({
        ...resetData,
      });
    }
  };
  const handleSaveEdits = async () => {
    if (!connectorData) return;
    if (!ValidateData()) return;
    setLoading(true);
    const connectorImageData = (connectorData as ConnectorV1).display.imageUrl;
    try {
      const _connectorUiSchema = (connectorData as ConnectorV1)?.configs?.uiSchema || {};
      const _connectorSchema = (connectorData as ConnectorV1)?.configs?.configSchema || {};
      const _connectorUiSchemaAttr = (connectorData as ConnectorV1)?.configs?.additionalAttributes?.uiSchema || {};
      const _connectorSchemaAttr = (connectorData as ConnectorV1)?.configs?.additionalAttributes?.configSchema || {};
      let connectorImage = connectorImageData;
      // handle Image upload
      if (updatedImageFile && connectorImageData?.startsWith('data:')) {
        const ImgResponseObject = await connectorsServiceManager.updateImage(orgID, updatedImageFile, APIVersion);
        const connectorImageDataResponse = ImgResponseObject?.data.imageUrl;
        connectorImage = `${
          !connectorImageDataResponse.startsWith('http') ? 'https://controltower.flybits.com/' : ''
        }${connectorImageDataResponse}`;
      }
      const propsToRemove = [
        'id',
        'status',
        'createdAt',
        'shareStatus',
        'shared',
        'ttl',
        'owner',
        'organization',
        'supportEmailVerified',
        'updatedAt',
        'updatedBy',
        'metadata',
        'configsVersion',
        'usSchema',
      ];

      const propsToRemoveWrongName = ['usSchema'];
      const payload = {
        ...connectorData,
        configs: {
          ...removeProperties((connectorData as ConnectorV1).configs, ...propsToRemoveWrongName),
          uiSchema: _connectorUiSchema,
          configSchema: _connectorSchema,
          additionalAttributes: {
            ...removeProperties(
              (connectorData as ConnectorV1).configs?.additionalAttributes || {},
              ...propsToRemoveWrongName,
            ),
            uiSchema: _connectorUiSchemaAttr,
            configSchema: _connectorSchemaAttr,
          },
        },
        display: {
          ...(connectorData as ConnectorV1).display,
          imageUrl: connectorImage,
        },
      };
      const saveResponseObject = isNewConnector
        ? await connectorsServiceManager.createConnector(orgID, removeProperties(payload, ...propsToRemove), APIVersion)
        : await connectorsServiceManager.updateConnectorData(
            orgID,
            connectorID,
            removeProperties(payload, ...propsToRemove),
            APIVersion,
          );
      if (saveResponseObject && [200, 201].includes(saveResponseObject.status)) {
        setUpdatedImageFile(null);
        setConnectorData(saveResponseObject.data);
        setConnectorDataDefault(saveResponseObject.data);
        setModified(false);
        if (isNewConnector) {
          //redirect to newly created connector
          addNotification(t('integrations:connectors.details.messages.created', 'Connector created successfully!'));
          setIsNewConnector(false);
          history.push(`/organization/${orgID}/integrations/${APIVersion}connector/${saveResponseObject.data.id}`);
        } else {
          addNotification(t('integrations:connectors.details.messages.saved', 'All changes were saved'));
        }
      }
      if (saveResponseObject && saveResponseObject.status >= 400) {
        // Error
        setConnectorData(connectorDataDefault);
        addNotificationError(
          saveResponseObject.data.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR),
        );
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      addNotificationError(e.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
      console.error(e.message);
    }
  };
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const toggleAlert = (show: boolean, title = '', msg = '') => {
    setShowAlert(show);
    setAlertTitle(title);
    setAlertMessage(msg);
  };
  const changeTab = (tab = 'basic') => {
    if (tab !== selectedTab) window.location.hash = tab;
  };
  const { name, imageUrl, updatedAt, status } = (connectorData as Connector) || {};
  const { display, metadata } = (connectorData as ConnectorV1) || {};

  const connectorCommonInfo = {
    name: !APIVersion ? name : display?.name,
    imageUrl: !APIVersion ? imageUrl : display?.imageUrl,
    updatedAt: !APIVersion ? updatedAt : metadata?.updatedAt,
    status: !APIVersion ? status : metadata?.status,
  };
  useEffect(() => {
    setTab(window.location.hash.replace('#', '') || 'basic');
    // eslint-disable-next-line
  }, [window.location.hash]);
  return (
    <>
      {loading && <LoadingIcon fullScreen={true} width={80} height={80} visible={true} />}
      <SaveToolbar
        current={connectorData}
        defaultValues={connectorDataDefault}
        onClickCancel={() => setTryToDiscardChanges(true)}
        disabled={
          !APIVersion
            ? ['basic', 'config-template'].some((item) => tabsWithError.includes(item))
            : ['basic', 'configuration', 'user-defined-attributes'].some((item) => tabsWithError.includes(item))
        }
        isCodeModified={isCodeModified}
        onClickSave={handleSaveEdits}
        setModified={(res) => setModified(res)}
      />
      {/* DELETE CONNECTOR MODAL */}
      <DeleteConnectorModal
        handleDeleteConnector={handleDeleteConnector}
        showModal={tryToDeleteConnector}
        t={t}
        toggleModal={() => setTryToDeleteConnector(false)}
        connectorData={[connectorID, connectorCommonInfo.name]}
        organizationID={orgID}
        apiVersion={APIVersion}
      />
      <FlightModal
        isVisible={tryToDiscardChanges}
        toggleModalShown={() => setTryToDiscardChanges(false)}
        scrollable={false}
        size="small"
        warning={false}
        className={`${MAIN_CLASS}__alert`}
        header={
          <span>
            {t(
              'integrations:connectors.details.modals.discard.message',
              'Are you sure you want to discard all unsaved changes?',
            )}
          </span>
        }
        footer={
          <div className={`${MAIN_CLASS}__alert_footer`}>
            <FlightButton
              type="button"
              theme="secondary"
              label={t('translation:general.buttons.cancel', 'Cancel')}
              onClick={() => {
                setTryToDiscardChanges(false);
              }}
            />
            <FlightButton
              type="button"
              theme="primary"
              className="delete-full"
              label={t('integrations:connectors.details.modals.discard.button', 'Discard changes')}
              onClick={handleCancelEdits}
            />
          </div>
        }
      />
      <FlightModal
        isVisible={showAlert}
        toggleModalShown={() => toggleAlert(false)}
        scrollable={false}
        size="small"
        warning={false}
        className={`${MAIN_CLASS}__alert`}
        header={<span>{alertTitle}</span>}
        content={<span>{alertMessage}</span>}
        footer={
          <div className={`${MAIN_CLASS}__alert_footer`}>
            <FlightButton
              type="button"
              theme="primary"
              label={t('integrations:connectors.details.messages.got_it', 'Got it')}
              onClick={() => {
                toggleAlert(false);
              }}
            />
          </div>
        }
      />
      <div className={MAIN_CLASS}>
        <div className={`${CONTENT_CLASS} full-width`}>
          <div className={`${CONTENT_CLASS}__wrapper with-separator`}>
            <div className={`${CONTENT_CLASS}__header`}>
              <h1>
                <div className="row-icon" aria-hidden>
                  {connectorCommonInfo.imageUrl ? (
                    <img
                      src={connectorCommonInfo.imageUrl}
                      onError={(ev) => {
                        (ev.target as Element).setAttribute('src', imageErrorConnector);
                      }}
                      alt={connectorCommonInfo.name || 'Connector image'}
                    />
                  ) : (
                    <ConnectorDefaultImage />
                  )}
                </div>{' '}
                {connectorCommonInfo.name}{' '}
                <span>
                  (
                  {capitalize(t(`integrations:general.list.${connectorCommonInfo.status}`, connectorCommonInfo.status))}
                  )
                </span>
              </h1>
              <div>
                {typeof connectorCommonInfo.updatedAt !== 'undefined' && connectorCommonInfo.updatedAt > 0 && (
                  <div className="updated-at">
                    {t('integrations:connectors.last-update', { time: timeFromNowTo(connectorCommonInfo.updatedAt) })}
                  </div>
                )}
                <FlightButton
                  type="button"
                  theme="secondary"
                  size="medium"
                  label={capitalize(
                    connectorCommonInfo.status !== 'active'
                      ? t(`integrations:general.list.activate`, 'Activate')
                      : t(`integrations:general.list.deactivate`, 'Deactivate'),
                  )}
                  disabled={
                    connectorCommonInfo.status !== 'active' && (!isValidConfig || isModified || !!tabsWithError.length)
                  }
                  onClick={handleConnectorAction}
                />
              </div>
            </div>
          </div>
          <div className={`${CONTENT_CLASS}__wrapper with-tabs`}>
            <div className={`${CONTENT_CLASS}__tabs`}>
              <FlightButton
                type="primary"
                className={`button_link button_link-back`}
                theme="link"
                size="medium"
                label={`< ${t('intgrations:general.back-integrations', 'Back to integrations')}`}
                ariaLabel={`${t('intgrations:general.back-integrations', 'Back to integrations')}`}
                onClick={() => {
                  history.push(`/organization/${orgID}/integrations/v1/#connectors`);
                }}
              />
              {tabsArray.map(({ slug, name }, key) => (
                <div key={key + slug}>
                  {tabsWithError.includes(slug) && getIcon('error', { fill: '#DC3616' })}
                  <FlightRadioButton
                    key={slug}
                    label={name}
                    checked={selectedTab === slug}
                    disabled={selectedTab === slug}
                    className={`${CONTENT_CLASS}__tabs-header-item`}
                    onSelect={() => {
                      changeTab(slug);
                    }}
                    value={slug}
                  />
                </div>
              ))}
            </div>
            <div className={`${CONTENT_CLASS}__tabs-content`}>
              {selectedTab === 'basic' && APIVersion && (
                <DisplayInformation
                  current={connectorData as ConnectorV1}
                  newConnector={isNewConnector}
                  onError={HandleTabError}
                  onChangeValue={setConnectorData}
                  onClickDelete={setTryToDeleteConnector}
                  onChangeImage={handleImageChange}
                  t={t}
                />
              )}
              {selectedTab === 'deployment' && APIVersion && (
                <DeploymentV1
                  current={connectorData as ConnectorV1}
                  onError={HandleTabError}
                  onChangeValue={setConnectorData}
                  t={t}
                />
              )}
              {selectedTab === 'configuration' && APIVersion && (
                <Configurations
                  current={connectorData as ConnectorV1}
                  onError={HandleTabError}
                  onChangeValue={setConnectorData}
                  setCodeModified={setCodeModified}
                  t={t}
                  APIVersion={APIVersion}
                />
              )}
              {selectedTab === 'pre-defined-attributes' && APIVersion && (
                <PreDefinedAttributes
                  current={connectorData as ConnectorV1}
                  onError={HandleTabError}
                  onChangeValue={setConnectorData}
                  t={t}
                />
              )}
              {selectedTab === 'user-defined-attributes' && APIVersion && (
                <UserDefinedAttributes
                  current={connectorData as ConnectorV1}
                  onError={HandleTabError}
                  onChangeValue={setConnectorData}
                  setCodeModified={setCodeModified}
                  t={t}
                  APIVersion={APIVersion}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectorPage;
