import { FlightButton } from '@flybits/design-system';
import React, { CSSProperties, memo } from 'react';
import { useDrop } from 'react-dnd';
import { ReactComponent as ParentIcon } from 'src/assets/icons/return.svg';
import { ReactComponent as DatasourcesDefaultImage } from 'src/assets/datasources-icon.svg';

const style: CSSProperties = {};

export interface PluginAttrProps {
  accept: string[];
  data: any;
  onDrop: (item: any) => void;
  datasourceInfo: (id: any) => any;
  onRemoveDrop: () => void;
}

export const PluginAttribute: React.FunctionComponent<PluginAttrProps> = memo(function PluginAttr(
  props: PluginAttrProps,
) {
  const { accept, data, onDrop, onRemoveDrop, datasourceInfo } = props;
  const { name, valueType, remapping, parameters } = data;
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = isOver && canDrop;
  const outputFieldName = remapping?.from?.split('.')[remapping?.from?.split('.').length - 1];
  const hasParams = !!parameters?.length;
  let backgroundColor = '#f2f3f5';
  let borderColor = '#cdd0d7';
  if (hasParams) {
    backgroundColor = '#f2f3f5';
    borderColor = '#FFC107';
  }
  if (isActive) {
    backgroundColor = '#DEFCF5';
    borderColor = '#00882f';
  } else if (canDrop) {
    backgroundColor = '#DEFCF5';
    borderColor = '#ccc';
  }

  return (
    <div className="plugin-field" ref={drop} data-testid="plugin-field">
      <div className={`plugin-name`}>{name}</div>
      <div className={`plugin-mapping ${hasParams ? 'has-parameters' : ''}`}>
        <span title={`${hasParams ? 'Field has parameters' : ''}`}>Output field</span>
        <div
          className={`plugin-card ${remapping?.from ? 'filled' : 'empty'}`}
          style={{
            ...style,
            backgroundColor,
            borderColor,
            opacity: canDrop ? 1 : 0.85,
          }}
        >
          <input
            value={'plugin'}
            type="checkbox"
            checked={!!remapping.from}
            aria-checked={!!remapping.from}
            name="plugin"
            readOnly
            tabIndex={0}
            onChange={() => {
              if (!!remapping.from) onRemoveDrop();
            }}
          />
          <div className="plugin-card-content">
            {remapping?.from ? (
              <>
                <span>{outputFieldName}</span>
                <div className="parent">
                  <ParentIcon />
                  <DatasourcesDefaultImage className="parent-icon" />{' '}
                  {datasourceInfo(remapping?.from?.split('.')[2])?.display?.name}
                </div>
              </>
            ) : (
              <span>Add an output field</span>
            )}
          </div>
          <code className="plugin-card-type code mono">{valueType}</code>
          {remapping?.from && (
            <div className="plugin-card-actions">
              <FlightButton
                type="button"
                theme="minor"
                size="small"
                iconLeft="trashCan"
                label=""
                ariaLabel="Remove mapping"
                onClick={onRemoveDrop}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
