import React, { useRef, useEffect } from 'react';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import { handleKeyDownHelper } from 'src/helpers/general.helper';
import './SidePanel.scss';

interface SidePanelProps {
  className?: string;
  title?: string;
  showPanel: boolean;
  content: JSX.Element;
  footer?: JSX.Element;
  togglePanel: () => void;
}

export const SidePanel = (props: SidePanelProps) => {
  const { className, title, showPanel, content, footer, togglePanel } = props;
  const MAIN_CLASS = 'side-panel';
  const focusRef = useRef<HTMLElement>(null);

  const handleClose = () => {
    togglePanel();
  };
  useEffect(() => {
    if (showPanel && focusRef) {
      focusRef.current !== null && focusRef.current.focus();
    }
  }, [showPanel]);
  return (
    <aside
      className={`${MAIN_CLASS} ${className} ${!showPanel && 'hidden'}`}
      ref={focusRef}
      role="dialog"
      tabIndex={showPanel ? 0 : -1}
      onKeyDown={handleKeyDownHelper(false, handleClose)}
      title={title || ''}
      aria-live="polite"
      aria-label="Side panel"
    >
      <div className={`${MAIN_CLASS}__header`}>
        <span
          tabIndex={0}
          onClick={handleClose}
          role="button"
          aria-label="Close info panel"
          title="Close info panel"
          onKeyDown={handleKeyDownHelper(handleClose, handleClose)}
        >
          <CloseIcon className={`${MAIN_CLASS}__close`} />
        </span>
      </div>
      <div className={`${MAIN_CLASS}__content`} aria-live="polite">
        {content}
      </div>
      {footer && <div className={`${MAIN_CLASS}__footer`}>{footer}</div>}
    </aside>
  );
};
