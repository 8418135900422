const CREATE_PROJECT_JDENTICOON = 'We generate a unique project identifier based on your project name. Pretty cool eh?';
const CREATE_PROJECT_PERSONAL_ENV = 'Your own lil baby.';
const CREATE_PROJECT_DEVELOPMENT_ENV = 'A developer sandbox. Go play!';
const CREATE_PROJECT_STAGING_ENV = 'This should be a mirror of a production environment.';
const CREATE_PROJECT_PRODUCTION_ENV = 'This project serves end-users/clients.';

export {
  CREATE_PROJECT_JDENTICOON,
  CREATE_PROJECT_DEVELOPMENT_ENV,
  CREATE_PROJECT_PERSONAL_ENV,
  CREATE_PROJECT_PRODUCTION_ENV,
  CREATE_PROJECT_STAGING_ENV,
};
