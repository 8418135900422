import React, { useState, useEffect } from 'react';
import { FlightRadioButton, FlightTextInput } from '@flybits/design-system';
import PreviewFormEditor from './PreviewFormEditor/PreviewFormEditor';
import '../Preview/Preview.scss';
import { ConnectorV1 } from 'src/model/connectors/connectors';
import { DataSourceV1 } from 'src/model/datasources/datasources';
interface Props {
  current: DataSourceV1 | undefined;
  connector: ConnectorV1 | undefined;
  onError: (tab: string, hasError: boolean) => void;
  onChangeValue: (e: any) => void;
  t: any;
}
const Configurations: React.FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'ConfigPreview';
  const { current, connector, onChangeValue, onError, t } = props;
  const { flybitsConfig, connectorConfig } = current?.configs || {};
  const { configSchema: connectorConfigSchema, uiSchema: connectorUISchema } = connector?.configs || {};

  const [valueIndentityTagSelected, setValueIndentityTagSelected] = useState(
    flybitsConfig?.identityType || 'syntheticId',
  );

  useEffect(() => {
    setValueIndentityTagSelected(flybitsConfig?.identityType || 'syntheticId');
  }, []);

  const handleChangeFormData = (e: any) => {
    onError('configs', !!e?.schemaValidationErrors?.length);
    if (!current || !connector) return;
    onChangeValue({
      ...current,
      configs: {
        ...current?.configs,
        connectorConfig: e.formData,
      },
    });
  };
  const handleChangeIdentityType = (value: string) => {
    onChangeValue({
      ...current,
      configs: {
        ...current?.configs,
        flybitsConfig: {
          identityType: value,
        },
      },
    });
  };
  return (
    <div className={MAIN_CLASS}>
      <>
        <section>
          <h3>
            {t(
              'integrations:datasources.details.sections.configs.flybits_core_configuration',
              'Flybits Core Configuration',
            )}
          </h3>
          <label>
            {t(
              'integrations:datasources.details.sections.configs.user_identifying_column_name_core',
              'User Identifying Column Name',
            )}
          </label>
          <div className={'section__item'}>
            <FlightRadioButton
              label={t('integrations:datasources.details.sections.configs.user_identifying_tag_name', 'Synthectic ID')}
              key={'user_identifying_tag_name'}
              checked={valueIndentityTagSelected === `syntheticId`}
              onSelect={() => {
                setValueIndentityTagSelected('syntheticId');
                handleChangeIdentityType('syntheticId');
              }}
              value={'syntheticId'}
            />
            <FlightRadioButton
              label={t(
                'integrations:datasources.details.sections.configs.user_identifying_tag_name_external',
                'External ID',
              )}
              key={'other'}
              checked={valueIndentityTagSelected !== `syntheticId`}
              onSelect={() => {
                if (valueIndentityTagSelected !== 'syntheticId') return;
                setValueIndentityTagSelected('other');
              }}
              value={'other'}
            />
          </div>
          {valueIndentityTagSelected !== `syntheticId` && (
            <div>
              <FlightTextInput
                placeholderText=""
                width="300px"
                type="text"
                name="identityType"
                value={flybitsConfig?.identityType}
                onChange={(ev) => {
                  if (valueIndentityTagSelected === 'syntheticId') setValueIndentityTagSelected('other');
                  handleChangeIdentityType(ev.target.value);
                }}
                data-lpignore="true"
              />
            </div>
          )}
        </section>
        {/* FORM */}
        <section>
          <h3>Integration-specific Configuration</h3>
          <div>
            <PreviewFormEditor
              formData={connectorConfig}
              schema={connectorConfigSchema || {}}
              uiSchema={connectorUISchema || {}}
              onChange={handleChangeFormData}
              connectorInfo={connector}
              t={t}
            />
          </div>
        </section>
      </>
    </div>
  );
};

export default Configurations;
