import React, { useEffect, useState } from 'react';
import './Deployment.scss';
import { isEqual } from 'lodash';
import { FlightButton, FlightOverflowMenu } from '@flybits/design-system';
import { ConnectorV1, ConnectorDeploymentV1 } from 'src/model/connectors/connectors';
import { ReactComponent as EmptyDeploymentsImg } from 'src/assets/world-map.svg';
import { ReactComponent as IconGlobeCheck } from 'src/assets/globe-check.svg';
import CollapsibleMultiple from 'src/components/Shared/CollapsibleMultiple/CollapsibleMultiple';
import { capitalize, handleize } from 'src/helpers/general.helper';
import DeploymentModalV1 from './DeploymentModalV1/DeploymentModalV1';
import useNotification from 'src/hooks/useNotification';
import { GENERIC_INTERNAL_ERROR } from 'src/constants/errors';
interface Props {
  current: ConnectorV1 | undefined;
  t: any;
  onError: (tab: string, hasError: boolean) => void;
  onChangeValue: (e: any) => void;
}
const DeploymentV1: React.FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'Deployment';
  const { current, onChangeValue, t } = props;
  const {
    deployments = {},
    metadata: { status },
  } = current || { metadata: {} };
  const { addNotification, addNotificationError } = useNotification();
  const [selectedDeploymentData, setSelectedDeploymentData] = useState<ConnectorDeploymentV1 | null>(null);
  const [deploymentData, setDeploymentData] = useState<
    | {
        [propName: string]: ConnectorDeploymentV1;
      }
    | Record<string, never>
  >(deployments);
  const [selectedDeploymentID, setSelectedDeploymentID] = useState<string>('');

  const HandleDeleteDeployment = (deployment: string) => {
    if (deployment && deployments[deployment]) {
      delete deployments[deployment];
      onChangeValue({
        ...current,
        deployments: { ...deployments },
      });
      addNotification(t('integrations:connectors.details.sections.deployment.messages.removed', 'Deployment removed.'));
    }
  };
  const HandleSaveDeployment = async (deployment: string, deploymentData: ConnectorDeploymentV1) => {
    if (!deployment || !deploymentData) {
      addNotificationError(
        t(
          'integrations:connectors.details.sections.deployment.messages.error',
          'Error saving deployment. Please try again later.',
        ),
      );
      return;
    }
    try {
      const newDeploymentId =
        deploymentData?.name || handleize(`${deploymentData.name}-${Date.now().toString().substr(-6)}`);
      deploymentData['name'] = newDeploymentId;
      onChangeValue({
        ...current,
        deployments: {
          ...deployments,
          [newDeploymentId]: deploymentData,
        },
      });
      addNotification(
        t('integrations:connectors.details.sections.deployment.messages.saved', 'Deployment saved successfully.'),
      );
      return true;
    } catch (e) {
      addNotificationError(GENERIC_INTERNAL_ERROR);
      console.error(e.message);
      return false;
    }
  };
  const HandleOpenModal = (deployment: string) => {
    if (!deployment || deployment === 'new') {
      setSelectedDeploymentData(null);
      setSelectedDeploymentID('new');
    } else {
      Object.entries(deploymentData).map(([id, data]) => {
        if (id === deployment) {
          setSelectedDeploymentID(id);
          setSelectedDeploymentData(data);
        }
      });
    }
  };
  const cancelModalDeployment = () => {
    setSelectedDeploymentID('');
    setSelectedDeploymentData(null);
  };
  useEffect(() => {
    if (!isEqual(deployments, deploymentData)) {
      setDeploymentData(deployments);
    }
  }, [deployments]);
  return (
    <div className={MAIN_CLASS}>
      <DeploymentModalV1
        openModal={!!selectedDeploymentID}
        toggleModal={cancelModalDeployment}
        cancelModal={cancelModalDeployment}
        saveAction={HandleSaveDeployment}
        id={selectedDeploymentID}
        data={selectedDeploymentData}
      />
      <h2 className="section__title">
        {t('integrations:connectors.details.sections.deployment.title', 'Deployment configuration')}
      </h2>
      {Object.keys(deployments).length < 1 ? (
        <div className="empty-deployments">
          <EmptyDeploymentsImg />
          <p>
            {t(
              'integrations:connectors.details.sections.deployment.messages.empty',
              'Add deployment configurations for each region you want to host the connector in.',
            )}
          </p>
          {status !== 'active' && (
            <FlightButton
              type="button"
              theme="secondary"
              iconLeft="add"
              label={t('integrations:connectors.details.sections.deployment.add_button', 'Add deployment')}
              ariaLabel={t(
                'integrations:connectors.details.sections.deployment.add_button_aria',
                'Add deployment, opens a dialog',
              )}
              onClick={() => HandleOpenModal('new')}
            />
          )}
        </div>
      ) : (
        <div className="section__fields">
          <CollapsibleMultiple
            items={Object.entries(deploymentData).map(
              ([deployment, { name, refreshRequestUrl, errorNotificationUrl, configRegistrationUrl, jwks_uri }]) => {
                return {
                  header: (
                    <>
                      <div className="Deployment-title">
                        <IconGlobeCheck /> {capitalize(name)}
                      </div>
                      <FlightOverflowMenu
                        ariaLabel={'Open deployment actions'}
                        optionGroups={[
                          [
                            {
                              key: 1,
                              name: t('translation:general.buttons.edit', 'Edit'),
                              disabled: status === 'active',
                              onClick: () => HandleOpenModal(deployment),
                            },
                            {
                              key: 2,
                              name: t('translation:general.buttons.delete', 'Delete'),
                              disabled: status === 'active',
                              onClick: () => HandleDeleteDeployment(deployment),
                            },
                          ],
                        ]}
                      />
                    </>
                  ),
                  key: deployment,
                  depth: -1,
                  disable: false,
                  name: capitalize(name),
                  content: (
                    <div className="Deployment-form-wrapper">
                      <div className="Deployment-form">
                        {refreshRequestUrl && (
                          <div className="Deployment-form-row">
                            <div className="title">
                              {t(
                                'integrations:connectors.details.sections.deployment.labels.endpoint_url',
                                'Refresh request URL',
                              )}
                            </div>
                            <div>{refreshRequestUrl}</div>
                          </div>
                        )}
                        {configRegistrationUrl && (
                          <div className="Deployment-form-row">
                            <div className="title">
                              {t(
                                'integrations:connectors.details.sections.deployment.labels.registration_url',
                                'Registration URL',
                              )}
                            </div>
                            <div>{configRegistrationUrl}</div>
                          </div>
                        )}
                        {jwks_uri && (
                          <div className="Deployment-form-row">
                            <div className="title">
                              {t(
                                'integrations:connectors.details.sections.deployment.labels.jwks_uri',
                                'JSON Web Key Set URI',
                              )}
                            </div>
                            <div>{jwks_uri}</div>
                          </div>
                        )}
                        {errorNotificationUrl && (
                          <div className="Deployment-form-row">
                            <div className="title">
                              {t(
                                'integrations:connectors.details.sections.deployment.labels.error_endpoint',
                                'Error Notification Endpoint',
                              )}
                            </div>
                            <div>{errorNotificationUrl}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  ),
                };
              },
            )}
          />
          {status !== 'active' && (
            <FlightButton
              type="button"
              theme="secondary"
              iconLeft="add"
              label={t('integrations:connectors.details.sections.deployment.add_button', 'Add deployment')}
              ariaLabel={t(
                'integrations:connectors.details.sections.deployment.add_button_aria',
                'Add deployment, opens a dialog',
              )}
              onClick={() => HandleOpenModal('new')}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DeploymentV1;
