import React, { FunctionComponent } from 'react';
import './OrganizationUsersListItem.scss';
interface Props {
  id: string;
  givenName: string;
  familyName: string;
  email: string;
  roles: Array<string>;
  currentUserEmail?: string;
  t: any;
}

const OrganizationUserListItem: FunctionComponent<Props> = (props: Props) => {
  const { id, givenName, familyName, email, currentUserEmail, t } = props;

  const MAIN_CLASS = 'org_users_list';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  return (
    <div className={`${CONTENT_CLASS}__infos-item`} key={id}>
      <div className={`${CONTENT_CLASS}__infos-item-left`}>
        <p>
          <strong>
            {givenName} {familyName}{' '}
            {currentUserEmail === email && t('translation:pages.projects.details.you', '(you)')}
          </strong>
        </p>
        <p>
          <small>{email}</small>
        </p>
      </div>
    </div>
  );
};

export default OrganizationUserListItem;
