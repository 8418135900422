import React, { Suspense, useEffect } from 'react';
import NotificationProvider from 'src/contexts/NotificationContext';
import { ControlTowerProvider } from 'src/contexts/ControlTowerContext';
import Routes from 'src/routes';
import { version, build } from '../../package.json';
import UpdatedVersionNotification from './UpdatedVersionNotification/UpdatedVersionNotification';

const consoleBanner = `
__________________________________________________________________________

   ___            _             _   _____                       
  / __\\___  _ __ | |_ _ __ ___ | | /__   \\_____      _____ _ __ 
 / /  / _ \\| '_ \\| __| '__/ _ \\| |   / /\\/ _ \\ \\ /\\ / / _ \\ '__|
/ /__| (_) | | | | |_| | | (_) | |  / / | (_) \\ V  V /  __/ |   
\\____/\\___/|_| |_|\\__|_|  \\___/|_|  \\/   \\___/ \\_/\\_/ \\___|_|   
                                                                

v${version || '1.0.x'} (build: ${build}) - Support: controltower@flybits.com   
__________________________________________________________________________                                                     
`;
// console.clear();
console.log(`%c${consoleBanner}`, 'font-family: monospace; font-size: 10pt; white-space: pre;font-weight:bold;');

const App = () => {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  return (
    <div className="application">
      <Suspense fallback={null}>
        <ControlTowerProvider>
          <NotificationProvider>
            <UpdatedVersionNotification />
            <Routes />
          </NotificationProvider>
        </ControlTowerProvider>
      </Suspense>
    </div>
  );
};

export default App;
