export const PUBLIC_PAGE_TITLES: { [key: string]: string } = {
  '': 'Flybits',
  '/': 'Sign in',
  '/signin': 'Sign in',
  '/signup': 'Sign up',
  '/verify': 'Account verification',
  '/2fa': 'Verification',
  '/terms-of-service': 'Terms of Service',
  '/privacy-policy': 'Privacy Policy',
  '/confirmEmail': 'Confirm email',
  '/acceptInvite': 'Accept invite',
  '/password/reset': 'Reset password',
  '/password/change': 'Change password',
  '/resetPassword': 'Reset password',
  '/error': 'Error page',
  '/404': 'Page not found',
  '/organizations': 'Organizations list',
  '/default': 'Flybits',
  undefined: 'Flybits',
};
export const PRIVATE_PAGE_TITLES: { [key: string]: string } = {
  '/organizations/new': 'Create Organization',
  '/organizations': 'Organizations list',
  '/organization/:id': 'Organization projects',
  '/organization/:id/directory': 'Organization directory',
  '/organization/:id/invites': 'Organization directory: invites',
  '/organization/:id/settings': 'Organization settings',
  '/organization/:id/projects': 'Organization projects',
  '/organization/:id/teams/new': 'Organization directory: Create team',
  '/organization/:id/team/:team': 'Organization directory: Team details',
  '/organization/:id/project/:project': 'Project details',
  '/organization/:id/integrations': 'Integrations',
  '/organization/:id/integrations/connector/:connector': 'Integrations: Connector details',
  '/organization/:id/integrations/datasource/new': 'Integrations: New Data source',
  '/organization/:id/integrations/datasource/:datasource': 'Integrations: Data source details',
  '/organization/:id/integrations/v1': 'Integrations',
  '/organization/:id/integrations/v1/connector/:connector': 'Integrations: Connector details',
  '/organization/:id/integrations/v1/datasource/new': 'Integrations: New Data source',
  '/organization/:id/integrations/v1/datasource/:datasource': 'Integrations: Data source details',
  '/user/account': 'User account',
  '': 'Organization projects',
  '/default': 'Organization projects',
  '/': 'Organization projects',
  undefined: 'Organization projects',
};
