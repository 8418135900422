import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import './Thumb.scss';

const Thumb = (props: any) => {
  const [thumb, setThumb] = useState<any>(undefined);
  const [thumbLoading, setThumbLoading] = useState(false);
  const clearThumb = () => {
    setThumbLoading(false);
    setThumb(undefined);
  };
  useEffect(() => {
    if ('string' !== typeof props.file) {
      const { file } = props;
      setThumb(undefined);
      if (file?.size) {
        setThumb(undefined);
        setThumbLoading(true);
        const reader = new FileReader();

        reader.onloadend = () => {
          setThumbLoading(false);
          setThumb(reader.result);
        };

        reader.readAsDataURL(file);
      } else {
        clearThumb();
      }
    } else {
      setThumb(undefined);
      if (props.file && (props.file.startsWith('http') || props.file.startsWith('images'))) {
        setThumb(undefined);
        if (props.file !== 'https://controltower.flybits.com/') {
          setThumb(undefined);
          setThumb((!props.file.startsWith('http') ? 'https://controltower.flybits.com/' : '') + props.file);
        } else {
          clearThumb();
        }
      } else {
        clearThumb();
      }
    }
    return function cleanup() {
      clearThumb();
    };
  }, [props]);

  return (
    <>
      {thumbLoading && <div className="thumbLoading"></div>}
      {thumb && (
        <img
          src={DOMPurify.sanitize(thumb)}
          alt="File preview"
          role="presentation"
          className={`thumbnail_preview ${thumb && 'show'}`}
        />
      )}
    </>
  );
};
export default Thumb;
