import React, { useEffect, useRef, useState } from 'react';
import '../BasicInfo.scss';
import { FlightTextInput, FlightTextArea, FlightButton } from '@flybits/design-system';
import UploadThumb from 'src/components/Shared/UploadThumb/UploadThumb';
import { getUserRoles, validateComplexName, validateEmail, validateURL } from 'src/helpers/auth.helper';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import imageErrorConnector from 'src/assets/connectors-icon.svg';
import { ReactComponent as IconGlobe } from 'src/assets/icon-globe.svg';
import { DataSourceV1 } from 'src/model/datasources/datasources';
import { ConnectorV1 } from 'src/model/connectors/connectors';
import useDebounce from 'src/hooks/useDebounce';
interface Props {
  current: DataSourceV1 | undefined;
  connector: ConnectorV1 | undefined;
  t: any;
  onError: (tab: string, hasError: boolean) => void;
  onClickDelete: (e: any) => void;
  onClickDetails: (e: any) => void;
  onChangeValue: (e: any) => void;
  onChangeImage: (file: any) => void;
}
const DisplayInformation: React.FunctionComponent<Props> = (props: Props) => {
  const { current, connector, onClickDelete, onChangeValue, onChangeImage, onError, t } = props;

  const [userRoles, setUserRoles] = useState<string[]>([]);
  const { name, description, documentationUrl, supportEmail, imageUrl } = current?.display || {};
  // Error checking
  const debouncedURL = useDebounce(documentationUrl, 750);
  const debouncedEmail = useDebounce(supportEmail, 750);
  const nameInput = useRef<HTMLInputElement>(null);
  const debouncedName = useDebounce(name, 750);
  useEffect(() => {
    if (checkErrors()) {
      onError('basic', checkErrors());
      return;
    }
    if ((supportEmail && !validateEmail(supportEmail)) || (documentationUrl && !validateURL(documentationUrl))) {
      onError('basic', true);
      return;
    }
    onError('basic', false);
  }, [debouncedName, debouncedURL, debouncedEmail]);
  const { deployment } = current || {};

  useEffect(() => {
    if (nameInput) {
      nameInput.current !== null && nameInput.current.focus();
    }
    const getStoredUserRoles = async () => {
      const roles = await getUserRoles();
      setUserRoles(roles);
    };
    getStoredUserRoles();
  }, []);

  const HandleChange = (e: any) => {
    const display = current?.display;
    onChangeValue({
      ...current,
      display: {
        ...display,
        [e.target.name]: e.target.value,
      },
    });
  };
  const HandleChangePicture = (imgFile: any, imgPreview: any) => {
    const display = current?.display;
    onChangeImage(imgFile);
    onChangeValue({
      ...current,
      display: {
        ...display,
        imageUrl: imgPreview,
      },
    });
  };
  const HandleClickDelete = () => {
    onClickDelete(true);
  };
  const checkErrors = () => {
    if (!current) return false;
    if (!name || name.length > 150 || !validateComplexName(name.trim(), /^[a-zA-Z0-9- _,?!'.()]*$/)) {
      return true;
    }
    return false;
  };
  return (
    <div className="BasicInfo">
      <h2 className="section__title">{t('integrations:datasources.details.sections.basic.title', 'Basic info')}</h2>
      <div className="section__fields">
        <div className="section__fields-field">
          <label htmlFor="name">
            {t('integrations:datasources.details.sections.basic.labels.name', 'Datasource name')}*
          </label>
          {!current ? (
            <LoadingIcon height={41} type="block" visible={true} />
          ) : (
            <FlightTextInput
              placeholderText=""
              width="600px"
              type="text"
              name="name"
              labelId="name"
              maxLength={150}
              inputRef={nameInput}
              value={name}
              hasError={checkErrors()}
              errorMessage={
                <span role="alert" aria-atomic>
                  <b>Error:</b>
                  {t(
                    'integrations:datasources.details.sections.basic.labels.name_error',
                    "Datasource name can't be empty, have special characters or have more than 150 characters",
                  )}
                </span>
              }
              onChange={HandleChange}
            />
          )}
        </div>
        <div className="section__fields-field">
          <label htmlFor="description">
            {t('integrations:datasources.details.sections.basic.labels.description', 'Description')}
          </label>
          {!current ? (
            <LoadingIcon height={100} type="block" visible={true} />
          ) : (
            <FlightTextArea
              hideCharCounter={true}
              label=""
              width="600px"
              name="description"
              value={description}
              onChange={HandleChange}
            />
          )}
        </div>
        <div className="section__fields-field">
          <label htmlFor="support-email">
            {t('integrations:connectors.details.sections.contact.labels.email', 'Contact support email')}{' '}
          </label>
          <FlightTextInput
            placeholderText="support@example.com"
            width="600px"
            type="text"
            name="supportEmail"
            labelId="support-email"
            value={supportEmail}
            hasError={!!(supportEmail && !validateEmail(supportEmail))}
            errorMessage={
              <span role="alert" aria-atomic>
                <b>Error: </b>
                {t(
                  'integrations:connectors.details.sections.contact.labels.email_error',
                  'Invalid support email format',
                )}
              </span>
            }
            onChange={HandleChange}
          />
        </div>
        <div className="section__fields-field">
          <label htmlFor="documentation-url">
            {t('integrations:connectors.details.sections.contact.labels.documentation', 'Documentation URL')}{' '}
          </label>
          <FlightTextInput
            placeholderText="https://www.example.com/setup"
            width="600px"
            type="text"
            name="documentationUrl"
            labelId="documentation-url"
            value={documentationUrl}
            hasError={!!(documentationUrl && !validateURL(documentationUrl))}
            errorMessage={
              <span role="alert" aria-atomic>
                <b>Error: </b>
                {t(
                  'integrations:connectors.details.sections.contact.labels.documentation_error',
                  'Invalid documentation url',
                )}
              </span>
            }
            onChange={HandleChange}
          />
        </div>
        <div className="section__fields-field section__fields-field-upload">
          <div className="section__fields-field-upload-text">
            <label>{t('integrations:datasources.details.sections.basic.labels.logo_title', 'Datasource logo')}</label>
            <p>
              {t(
                'integrations:datasources.details.sections.basic.labels.logo_msg',
                'A square logo that will be used to identify your datasource.',
              )}
            </p>
          </div>
          <UploadThumb onChangeImage={HandleChangePicture} imgURL={imageUrl} />
        </div>
      </div>
      <div className="section__deployments">
        <h3>
          {t(
            'integrations:datasources.details.sections.display-information.labels.integration_deployment_title',
            'Integration & deployment',
          )}
        </h3>
        <div className="deployment__item">
          <div className="deployment__item-logo">
            <img
              src={`${!connector?.display?.imageUrl.startsWith('http') ? 'https://controltower.flybits.com/' : ''}${
                connector?.display?.imageUrl
              }`}
              onError={(ev) => {
                (ev.target as Element).setAttribute('src', imageErrorConnector);
              }}
              alt={`${connector?.display?.name} logo`}
            />
          </div>
          <div className="deployment__item-info">
            <h5>{connector?.display?.name}</h5>
            <span>By {connector?.metadata?.organization?.name}</span>
          </div>
          <div className="deployment__item-content">
            <IconGlobe /> <strong>{deployment?.name}</strong>
          </div>
        </div>
      </div>
      {(userRoles.includes('owner') || userRoles.includes('datasources')) && (
        <div className="section__delete">
          <h2>{t('integrations:datasources.details.sections.basic.labels.delete_title', 'Delete this datasource')}</h2>
          <p>
            {t(
              'integrations:datasources.details.sections.basic.labels.delete_msg',
              'Deleting this datasource will affect projects and organizations using it and organizations where this datasource has been shared.',
            )}
            <br />
            <strong>
              {t(
                'integrations:datasources.details.sections.basic.labels.delete_restore_msg',
                'You can restore this datasource within a 30 day window.',
              )}
            </strong>
          </p>
          <FlightButton
            type="button"
            theme="primary"
            label={t('integrations:datasources.details.sections.basic.labels.delete_button', 'Delete datasource')}
            onClick={HandleClickDelete}
          />
        </div>
      )}
    </div>
  );
};

export default DisplayInformation;
