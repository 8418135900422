import React, { useState, useEffect } from 'react';
import { FlightButton, FlightModal } from '@flybits/design-system';
import './Organizations.scss';
import OrganizationsList from 'src/components/OrganizationList/OrganizationList';
import OrganizationService from 'src/services/organization.service';
import { Organization, OrganizationResponseBody } from 'src/model/organizations/organizations';
import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import { USER_NOT_CONFIRMED } from 'src/constants/errors';
import { Link, useHistory } from 'react-router-dom';
import { deleteStoredOrganizationId } from 'src/helpers/auth.helper';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import { ReactComponent as NoOrganizationImage } from 'src/assets/no-organization.svg';
import { useTranslation } from 'react-i18next';

interface Error {
  title: string;
  text: string;
}

const Organizations = () => {
  const { user, unsetControlTowerContextDataOrg } = useControlTowerContext();
  const history = useHistory();
  const { t } = useTranslation(['translation', 'errors']);

  const MAIN_CLASS = 'organizations';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;
  const HEADER_CLASS = `${MAIN_CLASS}__header`;
  const CREATE_ORGANIZATION_CLASS = `${MAIN_CLASS}__create-organization`;
  const ORGANIZATION_NO_ORG_CLASS = `${MAIN_CLASS}__no-organization`;
  const EMPTY_STATE_CLASS = `${MAIN_CLASS}__organizations-empty-state`;
  const LINK_CLASS = `${CREATE_ORGANIZATION_CLASS}__link`;
  const GRID_CLASS = `${MAIN_CLASS}__grid`;
  const ORGANIZATION_LIST_CLASS = `${MAIN_CLASS}__organizationList`;

  const [organizationsList, setOrganizationsList] = useState<Organization[]>([]);
  const [loading, setLoading] = useState(true);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    unsetControlTowerContextDataOrg();
    const fetchOrganizations = async () => {
      await deleteStoredOrganizationId();
      const organizationServiceManager = new OrganizationService();
      try {
        const params = {
          limit: 10,
        };
        const organizationResponseObject = await organizationServiceManager.getOrganizations(params);
        if (organizationResponseObject?.data?.length) {
          const transformedResponse: Organization[] = [];
          organizationResponseObject.data.map((organization: OrganizationResponseBody) => {
            return transformedResponse.push({
              id: organization.id,
              name: organization.name,
              createdAt: organization.createdAt,
              verified: organization.verified,
              externalUrl: organization.externalUrl,
              imageUrl: organization.imageUrl,
              roles: organization.roles,
            });
          });
          setOrganizationsList(transformedResponse);
        }
        setLoading(false);
      } catch (error) {
        // Add empty page response
        const transformedResponse: Organization[] = [];
        setOrganizationsList(transformedResponse);
        setLoading(false);
        console.error(error);
        if (error.response.status === 401) {
          setError({
            title: t('errors:USER_TIMED_OUT', 'Login expired'),
            text: t('errors:USER_TIMED_OUT_PLEASE_LOGIN_AGAIN', 'Your login has expired. Please login again.'),
          });
          setRedirectToLogin(true);
        }
      }
    };
    user?.userId && fetchOrganizations();
  }, [user, unsetControlTowerContextDataOrg]);

  const createOrganization = () => {
    if (user?.confirmed === true) {
      history.push('/organizations/new');
    } else {
      setError({
        title: t('translation:pages.organizations.verification_needed', 'Verification needed'),
        text: t('errors:USER_NOT_CONFIRMED', USER_NOT_CONFIRMED),
      });
    }
  };

  const toggleError = () => {
    setError(null);
    if (redirectToLogin) {
      const redirectURL = `${window.location.pathname}${window.location.search}${window.location.hash}`;
      history.replace(`/signin?next=${window.btoa(redirectURL)}`);
    }
  };

  return (
    <div className={MAIN_CLASS}>
      <FlightModal
        isVisible={!!error}
        toggleModalShown={toggleError}
        scrollable={false}
        size="small"
        warning={false}
        className="custom-modal-class"
        header={<span>{error?.title}</span>}
        content={<span>{error?.text}</span>}
      />
      <div className={CONTENT_CLASS}>
        <div className={GRID_CLASS}>
          <div className={ORGANIZATION_LIST_CLASS}>
            {<LoadingIcon width={80} height={80} fullScreen visible={loading} />}
            {organizationsList.length ? (
              <div className={GRID_CLASS}>
                <div className={HEADER_CLASS}>
                  <h1>{t('translation:pages.organizations.select_organization', 'Select your organization')}</h1>
                </div>
                <OrganizationsList list={organizationsList} />
                <div className={ORGANIZATION_NO_ORG_CLASS}>
                  {t(
                    'translation:pages.organizations.footer',
                    'If you don’t see an organization you should be a part of, try contacting an admin of that organization.',
                  )}
                </div>
                <FlightButton
                  label={t('translation:pages.organizations.create_org', 'Create a new organization')}
                  size="medium"
                  className={LINK_CLASS}
                  theme="link"
                  onClick={createOrganization}
                />
              </div>
            ) : (
              <div className={EMPTY_STATE_CLASS}>
                {!loading && (
                  <div className={CREATE_ORGANIZATION_CLASS}>
                    <h1>
                      {t('translation:pages.organizations.no_org_title', 'You have not joined any organizations.')}
                    </h1>
                    <div className={`${EMPTY_STATE_CLASS}__description`}>
                      {t(
                        'translation:pages.organizations.no_org_message',
                        'Check your inbox and wait patiently for an expected invitation or create your own organization.',
                      )}{' '}
                      <Link
                        to={{
                          pathname: t(
                            'translation:pages.organizations.no_org_link_url',
                            'https://app.gitbook.com/@flybits/s/product-guide/',
                          ),
                        }}
                        target="_blank"
                      >
                        {t('translation:pages.organizations.no_org_link_label', 'Learn more')}
                      </Link>
                    </div>
                    <FlightButton
                      label={t('translation:pages.organizations.create_org', 'Create a new organization')}
                      size="medium"
                      theme="primary"
                      className={`${EMPTY_STATE_CLASS}__button`}
                      onClick={createOrganization}
                    />
                  </div>
                )}

                {!organizationsList.length && !loading && <NoOrganizationImage />}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organizations;
