import React, { useEffect, useRef } from 'react';
import './BasicInfo.scss';
import { FlightButton, FlightTextInput, FlightTextArea, FlightTooltip } from '@flybits/design-system';
import { ConnectorV1 } from 'src/model/connectors/connectors';
import UploadThumb from 'src/components/Shared/UploadThumb/UploadThumb';
import { validateComplexName, validateEmail, validateURL } from 'src/helpers/auth.helper';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import useDebounce from 'src/hooks/useDebounce';
import { ReactComponent as QuestionCircle } from 'src/assets/icons/question-circle.svg';
interface Props {
  current: ConnectorV1 | undefined;
  newConnector: boolean;
  t: any;
  onError: (tab: string, hasError: boolean) => void;
  onClickDelete: (e: any) => void;
  onChangeValue: (e: any) => void;
  onChangeImage: (file: any) => void;
}
const DisplayInformation: React.FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'BasicInfo';
  const { current, onClickDelete, newConnector, onChangeValue, onChangeImage, onError, t } = props;
  const { name, description, documentationUrl, supportEmail, imageUrl } = current?.display || {};
  // Error checking
  const debouncedURL = useDebounce(documentationUrl, 750);
  const debouncedEmail = useDebounce(supportEmail, 750);
  const nameInput = useRef<HTMLInputElement>(null);
  const debouncedName = useDebounce(name, 750);
  useEffect(() => {
    onError('basic', checkErrors());
    if ((supportEmail && !validateEmail(supportEmail)) || (documentationUrl && !validateURL(documentationUrl))) {
      onError('basic', true);
    } else {
      onError('basic', false);
    }
  }, [debouncedName, debouncedURL, debouncedEmail]);

  const checkErrors = () => {
    if (!current) return false;
    if (!name || name.length > 150 || !validateComplexName(name.trim(), /^[a-zA-Z0-9- _,?!'.()]*$/)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (nameInput) {
      nameInput.current !== null && nameInput.current.focus();
    }
  }, []);

  const HandleChange = (e: any) => {
    const display = current?.display;
    onChangeValue({
      ...current,
      display: {
        ...display,
        [e.target.name]: e.target.value,
      },
    });
  };
  const HandleChangePicture = (imgFile: any, imgPreview: any) => {
    onChangeImage(imgFile);
    // onChangeValue({
    //   ...current,
    //   imageUrl: imgPreview,
    // });
    const display = current?.display;
    onChangeValue({
      ...current,
      display: {
        ...display,
        imageUrl: imgPreview,
      },
    });
  };
  const HandleClickDelete = () => {
    onClickDelete(true);
  };

  return (
    <div className={MAIN_CLASS}>
      <h2 className="section__title">
        {t('integrations:connectors.details.sections.display-information.title', 'Display information')}
      </h2>
      <div className="section__fields">
        <div className="section__fields-field">
          <label htmlFor="name">
            {t('integrations:connectors.details.sections.basic.labels.name', 'Connector name')}*
          </label>
          {!current ? (
            <LoadingIcon height={41} type="block" visible={true} />
          ) : (
            <FlightTextInput
              placeholderText=""
              width="600px"
              type="text"
              name="name"
              labelId="name"
              maxLength={150}
              inputRef={nameInput}
              value={name}
              hasError={checkErrors()}
              errorMessage={
                <span role="alert" aria-atomic>
                  <b>Error: </b>
                  {t(
                    'integrations:connectors.details.sections.basic.labels.name_error',
                    "Connector name can't be empty, have special characters or have more than 150 characters",
                  )}
                </span>
              }
              onChange={HandleChange}
            />
          )}
        </div>
        <div className="section__fields-field">
          <label htmlFor="description">
            {t('integrations:connectors.details.sections.basic.labels.description', 'Description')}
          </label>
          {!current ? (
            <LoadingIcon height={100} type="block" visible={true} />
          ) : (
            <FlightTextArea
              hideCharCounter={true}
              label=""
              width="600px"
              name="description"
              value={description}
              onChange={HandleChange}
            />
          )}
        </div>
        <div className="section__fields-field">
          <label htmlFor="support-email">
            {t('integrations:connectors.details.sections.contact.labels.email', 'Contact support email')}{' '}
            <FlightTooltip
              direction="right"
              isEnabled={true}
              description={t(
                'integrations:connectors.details.sections.contact.labels.email_tooltip',
                'Contact information for connector troubleshooting.',
              )}
            >
              <QuestionCircle />
            </FlightTooltip>
          </label>
          <FlightTextInput
            placeholderText="support@example.com"
            width="600px"
            type="text"
            name="supportEmail"
            labelId="support-email"
            value={supportEmail}
            hasError={!!(supportEmail && !validateEmail(supportEmail))}
            errorMessage={
              <span role="alert" aria-atomic>
                <b>Error: </b>
                {t(
                  'integrations:connectors.details.sections.contact.labels.email_error',
                  'Invalid support email format',
                )}
              </span>
            }
            onChange={HandleChange}
          />
        </div>
        <div className="section__fields-field">
          <label htmlFor="documentation-url">
            {t('integrations:connectors.details.sections.contact.labels.documentation', 'Documentation URL')}{' '}
            <FlightTooltip
              direction="right"
              isEnabled={true}
              description={t(
                'integrations:connectors.details.sections.contact.labels.documentation_tooltip',
                'URL of the documentation for the connector to help people learn how it works or troubleshoot.',
              )}
            >
              <QuestionCircle />
            </FlightTooltip>
          </label>
          <FlightTextInput
            placeholderText="https://www.example.com/setup"
            width="600px"
            type="text"
            name="documentationUrl"
            labelId="documentation-url"
            value={documentationUrl}
            hasError={!!(documentationUrl && !validateURL(documentationUrl))}
            errorMessage={
              <span role="alert" aria-atomic>
                <b>Error: </b>
                {t(
                  'integrations:connectors.details.sections.contact.labels.documentation_error',
                  'Invalid documentation url',
                )}
              </span>
            }
            onChange={HandleChange}
          />
        </div>
        <div className="section__fields-field section__fields-field-upload">
          <div className="section__fields-field-upload-text">
            <label>{t('integrations:connectors.details.sections.basic.labels.logo_title', 'Connector logo')}</label>
            <p>
              {t(
                'integrations:connectors.details.sections.basic.labels.logo_msg',
                'A square logo that will be used to identify your connector.',
              )}
            </p>
          </div>
          <UploadThumb onChangeImage={HandleChangePicture} imgURL={imageUrl} />
        </div>
      </div>
      {!newConnector && (
        <div className="section__delete">
          <h2>{t('integrations:connectors.details.sections.basic.labels.delete_title', 'Delete this connector')}</h2>
          <p>
            {t(
              'integrations:connectors.details.sections.basic.labels.delete_msg',
              'Deleting this connector will affect datasources using it and organizations where this connector has been shared.',
            )}
            <br />
            <strong>
              {t(
                'integrations:connectors.details.sections.basic.labels.delete_restore_msg',
                'You can restore this connector within a 30 day window.',
              )}
            </strong>
          </p>
          <FlightButton
            type="button"
            theme="primary"
            label={t('integrations:connectors.details.sections.basic.labels.delete_button', 'Delete connector')}
            onClick={HandleClickDelete}
          />
        </div>
      )}
    </div>
  );
};

export default DisplayInformation;
