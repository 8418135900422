import { FlightTextInput } from '@flybits/design-system';
import React, { useState } from 'react';
import { FieldsConfig } from 'src/model/connectors/connectors';
import ItemHeader from '../ItemHeader/ItemHeader';
import './PasswordPreview.scss';

const PasswordPreview = (props: FieldsConfig) => {
  const { name, onChangeValue, configField } = props;
  const [value, setValue] = useState(configField ? configField[2]?.configVal : '');
  const handleChange = (e: any) => {
    setValue(e.target.value);
    typeof onChangeValue === 'function' &&
      onChangeValue({
        field: [configField[0], configField[1]],
        data: { ...configField[2], configVal: e.target.value },
      });
  };
  return (
    <>
      <ItemHeader {...props} />
      <div>
        <FlightTextInput
          placeholderText=""
          width="400px"
          type="password"
          name={name}
          value={value}
          onChange={handleChange}
          data-lpignore="true"
          data-autocomplete="off"
        />
      </div>
    </>
  );
};
export default PasswordPreview;
