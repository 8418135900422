/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios';
import { getBrowserLocales } from 'src/helpers/general.helper';
import i18n from 'i18next';
const currentLocale = getBrowserLocales();
const currentLanguage =
  i18n.language ||
  (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
  (currentLocale ? currentLocale[0] : 'en');

const AXIOS_SERVICE = axios.create({
  baseURL: '/api',
  timeout: 60000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': `${currentLanguage}, en;q=0.9, *;q=0.5`,
  },
});

const tokenNeeded = (url: string) => {
  const tokenNeededUrls = [
    '?projectId',
    '/organizations',
    'projects',
    'datasources',
    'connectors',
    'imageUpload',
    'auth/redirect',
    'auth/logout',
    'auth/verify',
    'auth/resendCode',
    'auth/me',
    'auth/sendConfirmationEmail',
    'auth/confirmEmail',
    'organizations/acceptInvite',
    'auth/changePassword',
    'userdata/me',
    'sso/settings/configs',
    'sso/oauth-manager/clientInfo',
    'sso/oauth-manager/authorize',
    'context/connector-manager/connectorInstance',
    'context/connector-manager/connector',
    'context/connector-manager/connectorInstance/checkName',
    'context/ctxschema/contextplugins',
  ];
  return tokenNeededUrls.includes(url) || tokenNeededUrls.some((route) => url.startsWith(route));
};

const useProjectToken = (url: string) => {
  return url === '/sso/settings/configs';
};

const useFileUpload = (url: string, method: string) => {
  return (
    url.startsWith('/organizations') &&
    !url.endsWith('/projects') &&
    !url.endsWith('/sharing') &&
    !url.endsWith('/teams') &&
    !url.endsWith('/users') &&
    !url.endsWith('/connectors') &&
    !url.endsWith('/datasources') &&
    !url.endsWith('/authorize') &&
    !url.endsWith('/invites') &&
    method === 'post'
  );
};

// For Requests
AXIOS_SERVICE.interceptors.request.use(
  async function (config) {
    // Remapping
    if (config.headers && config?.data?.header_subdomain) {
      config.headers['X-flybits-project'] = config.data.header_subdomain;
      delete config.data.header_subdomain;
    }
    if (config && config.url && config.method && config.headers && tokenNeeded(config.url)) {
      if (useProjectToken(config.url)) {
        if (config.data.projectToken) {
          config.headers['X-Authorization'] = config.data.projectToken;
          delete config.data.projectToken;
        }
      } else if (useFileUpload(config.url, config.method)) {
        config.headers['Content-Type'] = 'multipart/form-data';
        return config;
      } else {
        return config;
      }
    }

    return config;
  },
  function (error: AxiosError<string>) {
    return error;
  },
);

// For Responses
AXIOS_SERVICE.interceptors.response.use(
  function (response) {
    // Add debug logging
    return response;
  },
  function (error: AxiosError<string>) {
    console.error(error.message);
    return error.response;
  },
);

class BaseService {
  axios: any;
  constructor() {
    this.axios = AXIOS_SERVICE;
  }
  async get(endpoint: string, data: any) {
    return AXIOS_SERVICE.get(endpoint, data);
  }

  async post(endpoint: string, data: any) {
    return AXIOS_SERVICE.post(endpoint, data);
  }

  put(endpoint: string, data: any) {
    return AXIOS_SERVICE.put(endpoint, data);
  }

  patch(endpoint: string, data: any) {
    return AXIOS_SERVICE.patch(endpoint, data);
  }

  delete(endpoint: string, data: any) {
    return AXIOS_SERVICE.delete(endpoint, data);
  }

  request(type: any, url: string, data?: any, headers?: any) {
    let promise = null;
    const extras: any = {};
    if (data) extras['params'] = data;
    if (headers) extras['headers'] = headers;
    switch (type) {
      case 'GET':
        promise = this.get(url, extras);
        break;
      case 'POST':
        promise = this.post(url, data);
        break;
      case 'PUT':
        promise = this.put(url, data);
        break;
      case 'PATCH':
        promise = this.patch(url, data);
        break;
      case 'DELETE':
        promise = this.delete(url, { params: data });
        break;
      default:
        promise = this.get(url, { params: data });
        break;
    }
    return promise;
  }
}

export default BaseService;
