import React, { FunctionComponent, useEffect, useState } from 'react';
import { FlightButton, FlightTextInput, FlightSelect, FlightTextArea } from '@flybits/design-system';
import { ReactComponent as IdeaIcon } from 'src/assets/icons/idea.svg';
import { ReactComponent as ParentIcon } from 'src/assets/icons/flow-chart.svg';
import './TeamsCreate.scss';
// Services
import TeamService from 'src/services/teams.service';

// Helpers
import { PROJECT_NAME_TWO_CHARS_REQUIRED, TEAM_NAME_REQUIRED, TEAM_NAME_VALIDATION } from 'src/constants/errors';
import { projectNameRegex, setStoredOrganizationId } from 'src/helpers/auth.helper';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import OrganizationService from 'src/services/organization.service';
import useNotification from 'src/hooks/useNotification';
import { useTranslation } from 'react-i18next';

interface Props {
  match?: any;
}

const TeamsCreate: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation(['translation', 'errors']);
  const MAIN_CLASS = 'TeamsCreate';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  const { match } = props;
  if (match.params.id) setStoredOrganizationId(match.params.id);
  const orgID = match.params.id;
  const { addNotification, addNotificationError } = useNotification();

  const urlParams = new URLSearchParams(window.location.search);

  const [loading, setLoading] = useState(false);
  const [selectedParent, setSelectedParent] = useState<SelectOptionProps | null>(null);
  const [organizationTeams, setOrganizationTeams] = useState<SelectOptionProps[]>([]);

  const history = useHistory();

  if (!orgID) {
    history.push('/organizations');
  }
  interface SelectOptionProps {
    key: string;
    name: string;
  }
  interface FormValues {
    name: string;
    parent: any;
    description: string;
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('errors:TEAM_NAME_REQUIRED', TEAM_NAME_REQUIRED))
      .trim()
      .min(2, t('errors:PROJECT_NAME_TWO_CHARS_REQUIRED', PROJECT_NAME_TWO_CHARS_REQUIRED))
      .matches(projectNameRegex, t('errors:TEAM_NAME_VALIDATION', TEAM_NAME_VALIDATION)),
  });

  const initialValues = { name: '', parent: '', description: '' };
  const cancelCreation = () => {
    history.goBack();
  };

  const createTeam = async (values: FormValues) => {
    setLoading(true);
    let parentData = values.parent === 'none' || values.parent === null ? '' : values.parent;
    if (urlParams.has('parent') && selectedParent && !values.parent && selectedParent.key === urlParams.get('parent')) {
      parentData = urlParams.get('parent');
    }
    const teamRequestObject = {
      name: values.name,
      parent: parentData,
      description: values.description,
    };
    const teamServiceManager = new TeamService();
    try {
      const teamResponseObject = await teamServiceManager.createTeam(orgID, teamRequestObject);
      if (teamResponseObject && (teamResponseObject.status === 201 || teamResponseObject.status === 200)) {
        //redirect to newly created team page
        return teamResponseObject.data.id;
      }
    } catch (e) {
      console.error(e);
      addNotificationError(e?.response?.data?.message);
      setLoading(false);
      return false;
    }
  };
  useEffect(() => {
    const getTeams = async () => {
      const organizationServiceManager = new OrganizationService();
      try {
        const organizationTeamsResponseObject = await organizationServiceManager.getOrganizationTeams(orgID);
        if (organizationTeamsResponseObject?.data) {
          setOrganizationTeams(
            organizationTeamsResponseObject.data.map((team: any) => {
              if (urlParams.has('parent') && urlParams.get('parent')) {
                if (urlParams.get('parent') === team.id) {
                  setSelectedParent({
                    key: team.id,
                    name: team.name,
                  });
                }
              }
              return {
                key: team.id,
                name: team.name,
              };
            }),
          );
        }
      } catch (e) {
        console.error('Err:', e?.response?.data?.message);
        addNotificationError(e?.response?.data?.message);
      }
    };
    getTeams();
  }, [orgID]);

  return (
    <div className={MAIN_CLASS}>
      <LoadingIcon
        width={80}
        height={80}
        fullScreen
        text={t('translation:pages.teams.create.setting_up', 'Setting up your team...')}
        visible={loading}
      />
      <div className={CONTENT_CLASS}>
        <div className={`${CONTENT_CLASS}__wrapper`}>
          <h1 className={`${CONTENT_CLASS}__heading-text`}>
            <FlightButton
              type="primary"
              className={`${CONTENT_CLASS}__button_link ${CONTENT_CLASS}__button_link-back`}
              theme="link"
              size="medium"
              label={`< ${t('translation:general.buttons.back', 'Back')}`}
              ariaLabel={`< ${t('translation:general.buttons.back', 'Back')}`}
              onClick={() => {
                history.goBack();
              }}
            />
            {t('translation:pages.teams.create.title', 'Create new team')}
          </h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
              const result = await createTeam(values);
              if (result) {
                resetForm();
                addNotification(t('translation:pages.teams.messages.create_success', 'Team added successfully!'));
                setTimeout(() => {
                  history.push(`/organization/${orgID}/team/${result}`);
                }, 3000);
              }
            }}
          >
            {({ values, errors, touched, isSubmitting, handleSubmit, handleChange, handleBlur, setFieldValue }) => (
              <form className={FORM_CLASS} autoComplete="off">
                <input autoComplete="off" name="hidden" type="text" className={`${ITEM_CLASS}__hide`}></input>
                <div className={`${FORM_CLASS}__left`}>
                  <div>
                    <h2>{t('translation:pages.teams.create.team_name', 'Team name *')}</h2>
                    <Field
                      type="text"
                      name="name"
                      aria-required
                      required
                      className={`${ITEM_CLASS}__input`}
                      width="400px"
                      ariaLabel="team name"
                      label={`Team name (required)`}
                      isLabelAlwaysOn
                      as={FlightTextInput}
                      hasError={touched.name && errors.name ? true : false}
                      value={values.name}
                      errorMessage={
                        <span role="alert" aria-atomic>
                          <b>Error:</b> {errors.name}
                        </span>
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div>
                    <h2>{t('translation:pages.teams.create.parent_team', 'Parent team')}</h2>
                    <Field
                      as={FlightSelect}
                      type="select"
                      name="parent"
                      ariaLabel="Parent team (optional)"
                      label={`Parent team`}
                      hasLabelAnimation
                      options={organizationTeams}
                      selected={selectedParent}
                      dropdownMaxHeight="150px"
                      handleOptionClick={async (value: SelectOptionProps) => {
                        setFieldValue('parent', value.key, false);
                        setSelectedParent(value);
                      }}
                      width="220px"
                    />
                  </div>
                  <div>
                    <h2>{t('translation:pages.teams.create.description', 'Description')}</h2>
                    <Field
                      name="description"
                      width="400px"
                      as={FlightTextArea}
                      ariaLabel="team description (optional)"
                      label=""
                      hasError={touched.description && errors.description ? true : false}
                      value={values.description}
                      errorMessage={
                        <span role="alert" aria-atomic>
                          <b>Error:</b> {errors.description}
                        </span>
                      }
                      onChange={handleChange}
                      maxLength={160}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className={`${FORM_CLASS}__right`}>
                  <div className="item">
                    <IdeaIcon />
                    <div className="group">
                      <h2>{t('translation:pages.teams.create.infos.did_you_know', 'Did you know?')}</h2>
                      <p>
                        {t(
                          'translation:pages.teams.create.infos.did_you_know_desc',
                          'You can mirror your organization access structure by assigning your team to a parent team.',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <ParentIcon />
                    <div className="group">
                      <h2>{t('translation:pages.teams.create.infos.what_is_parent', 'What is a parent team?')}</h2>
                      <p>
                        {t(
                          'translation:pages.teams.create.infos.what_is_parent_desc',
                          'A parent team’s permissions and project access will be passed down to child teams.',
                        )}
                        <br />
                        <Link
                          to={{
                            pathname: t(
                              'translation:pages.teams.create.infos.learn_more_url',
                              'https://flybits.gitbook.io/product-guide/-MCJQnb9xKBWvcUyw_9V/for-owners/managing-teams#about-parent-teams',
                            ),
                          }}
                          target="_blank"
                        >
                          {t('translation:pages.teams.create.infos.learn_more', 'Learn more')}
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`${FORM_CLASS}__footer`}>
                  <FlightButton
                    type="primary"
                    size="medium"
                    label={t('translation:pages.teams.btn-create', 'Create team')}
                    ariaLabel={t('translation:pages.teams.btn-create', 'Create team')}
                    loading={loading}
                    disabled={loading || !touched.name || !!errors.name || isSubmitting}
                    onClick={handleSubmit}
                  />
                  <FlightButton
                    type="button"
                    theme="secondary"
                    size="medium"
                    label={t('translation:general.buttons.cancel', 'Cancel')}
                    ariaLabel={t('translation:general.buttons.cancel', 'Cancel')}
                    onClick={cancelCreation}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default TeamsCreate;
