import { AuthorizationRequest, CreateDataSource } from 'src/model/datasources/datasources';
import BaseService from './base.service';

export default class DataSourcesService extends BaseService {
  CURRENT_PATH: string;
  BASE_PATH: string;
  constructor() {
    super();
    this.BASE_PATH = '/organizations/';
    this.CURRENT_PATH = '/datasources';
  }

  async updateImage(organizationId: string, file: any, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    const form = new FormData();
    if (file) form.append('image', file);
    return this.request('POST', this.BASE_PATH + version + organizationId + '/imageUpload', form);
  }

  async getDataSources(organizationId: string, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request('GET', this.BASE_PATH + version + organizationId + this.CURRENT_PATH);
  }

  async getDataSourceById(organizationId: string, dataSourceId: string, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request('GET', this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + dataSourceId);
  }

  async getDataSourceSharedOrganizations(organizationId: string, dataSourceId: string, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request(
      'GET',
      this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + dataSourceId + '/sharing',
    );
  }

  async getDataSourceAllProjects(organizationId: string, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request('GET', this.BASE_PATH + version + organizationId + '/allProjects');
  }
  async getDataSourceSharedProjects(organizationId: string, dataSourceId: string, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request(
      'GET',
      this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + dataSourceId + '/projects',
    );
  }

  async shareDataSource(organizationId: string, dataSourceId: string, data: any, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request(
      'POST',
      this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + dataSourceId + '/sharing',
      data,
    );
  }

  async shareWithProject(organizationId: string, dataSourceId: string, data: any, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request(
      'POST',
      this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + dataSourceId + '/projects',
      data,
    );
  }

  async createDataSource(organizationId: string, data: CreateDataSource, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request('POST', this.BASE_PATH + version + organizationId + this.CURRENT_PATH, data);
  }

  async updateDataSourceData(organizationId: string, dataSourceId: string, data: any, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request(
      'PATCH',
      this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + dataSourceId,
      data,
    );
  }
  async updateDataSourceStatus(organizationId: string, dataSourceId: string, status: string, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    // POSSIBLE STATUSES
    // activate
    // deactivate
    // delete
    // cancelDelete
    // approve_sharing
    // approve_3rd_party
    return this.request(
      'POST',
      this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + dataSourceId + '/' + status,
    );
  }

  async deleteDataSource(organizationId: string, dataSourceId: string, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request('DELETE', this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + dataSourceId);
  }
  // Project access
  async addProjectAccess(organizationId: string, dataSourceId: string, projectId: string) {
    // POST /api/organizations/v1/{organizationID}/datasources/{connector}_{datasource}/projects/{deployment}_{subdomain}
    return this.request(
      'POST',
      this.BASE_PATH + 'v1/' + organizationId + '/datasources/' + dataSourceId + '/projects/' + projectId,
    );
  }
  async deleteProjectAccess(organizationId: string, dataSourceId: string, projectId: string) {
    // DELETE /api/organizations/v1/{organizationID}/datasources/{connector}_{datasource}/projects/{deployment}_{subdomain}
    return this.request(
      'DELETE',
      this.BASE_PATH + 'v1/' + organizationId + '/datasources/' + dataSourceId + '/projects/' + projectId,
    );
  }

  // Auth
  async authorizeDataSource(organizationId: string, dataSourceId: string, data: AuthorizationRequest, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request(
      'POST',
      this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + dataSourceId + '/authorize',
      data,
    );
  }
  async deleteDataSourceToken(organizationId: string, dataSourceId: string, data: AuthorizationRequest, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    const { authTag } = data;
    return this.request(
      'DELETE',
      this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + dataSourceId + '/tokens?authTag=' + authTag,
      data,
    );
  }
}
