import React, { FunctionComponent, useState } from 'react';
import { ProjectUser, ProjectTeam } from 'src/model/projects/projects';
import { PROJECT_LEVELS } from 'src/constants/levels';
import { ReactComponent as Chevron } from 'src/assets/icons/chevron.svg';
import './ProjectUsersAndTeamsListItem.scss';
import { FlightButton } from '@flybits/design-system';
interface Props {
  id: string;
  user?: ProjectUser;
  team?: ProjectTeam;
  currentUserEmail?: string;
  t: any;
  onClick?: () => void;
  onHover?: () => void;
  onLeave?: () => void;
}

const ProjectUsersAndTeamsList: FunctionComponent<Props> = (props: Props) => {
  const { id, user, team, currentUserEmail, onClick, onHover, onLeave, t } = props;

  const MAIN_CLASS = 'project_users_list';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  const [currentVisibleTeam, setCurrentVisibleTeam] = useState('');

  const getProjectLevel = (lvl: number) => {
    return (
      PROJECT_LEVELS.map((item) => {
        if (item.level === lvl) {
          return t(`translation:pages.projects.details.labels.access_${item.name.toLowerCase()}`, item.name);
        }
        return false;
      }) || t(`translation:pages.projects.details.labels.access_member`, 'Member')
    );
  };

  const teamToggleLabel = (team: ProjectTeam) => {
    return (
      <>
        {team?.subteams?.length} team{team?.subteams && team?.subteams?.length > 1 ? 's' : ''} <Chevron />
      </>
    );
  };
  const teamToggleVisible = (teamId: string) => {
    setCurrentVisibleTeam(teamId === currentVisibleTeam ? '' : teamId);
  };

  return (
    <div
      className={`${CONTENT_CLASS}__infos-item`}
      key={id}
      onClick={onClick}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      {team ? (
        <>
          <div className={`${CONTENT_CLASS}__infos-item-left team`}>
            <p>
              <strong>{team?.name}</strong>
            </p>
            <p>
              <small>{team?.description}</small>
            </p>
          </div>
          <div className={`${CONTENT_CLASS}__infos-item-right team`}>
            <div className={`${CONTENT_CLASS}__infos-item-left`}>{getProjectLevel(team?.level || 1)}</div>
            <div className={`${CONTENT_CLASS}__infos-item-right`}>
              <div className={`${CONTENT_CLASS}__infos-item-subteams-list`}>
                {team?.subteams?.length && (
                  <FlightButton
                    type="button"
                    className={`${CONTENT_CLASS}__button_team-toggle`}
                    theme="minor"
                    size="small"
                    label={teamToggleLabel(team)}
                    onClick={() => {
                      teamToggleVisible(team.id);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {team?.subteams?.length &&
            team?.subteams?.map((item: ProjectTeam) => (
              <div
                key={item.id}
                id={item.id}
                className={`${CONTENT_CLASS}__infos-item ${CONTENT_CLASS}__infos-item-subteam ${
                  currentVisibleTeam === team.id ? 'open' : ''
                }`}
              >
                <div className={`${CONTENT_CLASS}__infos-item-left team subteam`}>
                  <p>
                    <strong>{item?.name}</strong>
                  </p>
                  <p>
                    <small>{item?.description}</small>
                  </p>
                </div>
                <div className={`${CONTENT_CLASS}__infos-item-right team subteam`}>
                  <div className={`${CONTENT_CLASS}__infos-item-left`}>{getProjectLevel(item?.level || 1)}</div>
                  <div className={`${CONTENT_CLASS}__infos-item-right`}>
                    <div className={`${CONTENT_CLASS}__infos-item-subteams-list`}>
                      {item?.subteams?.length && (
                        <FlightButton
                          type="button"
                          className={`${CONTENT_CLASS}__button_team-toggle`}
                          theme="minor"
                          size="small"
                          label={teamToggleLabel(item)}
                          onClick={() => {
                            teamToggleVisible(item.id);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </>
      ) : (
        <>
          <div className={`${CONTENT_CLASS}__infos-item-left`}>
            <p>
              <strong>
                {user?.givenName} {user?.familyName}{' '}
                {currentUserEmail === user?.email && t('translation:pages.projects.details.you', `(you)`)}
              </strong>
            </p>
            <p>
              <small>{user?.email}</small>
            </p>
          </div>
          <div className={`${CONTENT_CLASS}__infos-item-right`}>{getProjectLevel(user?.level || 1)}</div>
        </>
      )}
    </div>
  );
};

export default ProjectUsersAndTeamsList;
