import React, { useEffect, useState } from 'react';
import { ReactComponent as ESPlaceholder } from 'src/assets/illustrations/es.svg';
import './CreateProjectModal.scss';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import {
  PROJECT_NAME_REQUIRED,
  ONLY_LOWER_CASE,
  SUBDOMAIN_TWO_CHARS_REQUIRED,
  PROJECT_NAME_TWO_CHARS_REQUIRED,
  SUBDOMAIN_REQUIRED,
  PROJECT_NAME_INVALID,
} from 'src/constants/errors';
import {
  FlightModal,
  FlightTextInput,
  FlightButton,
  FlightTooltip,
  getIcon,
  FlightSelect,
} from '@flybits/design-system';
import Jdenticon from '../Jdenticon';
import { CREATE_PROJECT_JDENTICOON } from 'src/constants/tooltips';
import ProjectService from 'src/services/project.service';
import { CreateProject } from 'src/model/projects/projects';
import { OrganizationDeployment } from 'src/model/organizations/organizations';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import { projectNameRegex } from 'src/helpers/auth.helper';
import { strip_tags } from 'src/helpers/general.helper';
import { isEmpty } from 'lodash';

// required validation scehma definition
const subDomainRegex = /^[a-zA-Z0-9-]+$/;

interface Props {
  openModal: boolean;
  orgID: string;
  orgType: string;
  orgDeployments: Array<OrganizationDeployment>;
  toggleModal: () => void;
  fetchProjects: (fetch: boolean) => void;
  t: any;
}

interface SelectOptionProps {
  key: string;
  name: string;
}

interface ItemObject {
  [key: string]: ItemObjectChild;
}

interface ItemObjectChild {
  [key: string]: Array<string>;
}

//* form will be populated with these default values /
const initialFormValues = {
  name: 'My Awesome Project',
  deployment: '<deployment>',
  subdomain: 'my-awesome-project',
  tier: 'standard',
};

//* CSS class mapping //*
const MAIN_CLASS = 'create-project__modal';
const CONTENT_CLASS = `${MAIN_CLASS}__content`;
const LOADING_CLASS = `${MAIN_CLASS}__loading`;
const HEADER_CLASS = `${MAIN_CLASS}__header`;
const CONTAINER_CLASS = `${MAIN_CLASS}__container`;
const PLACEHOLDER_TEXT_CLASS = `${MAIN_CLASS}__placeholder__text`;
const PLACEHOLDER_JDENTICON_CLASS = `${MAIN_CLASS}__placeholder__jdenticon`;
const PLACEHOLDER_NAME_CLASS = `${MAIN_CLASS}__placeholder__name`;
const PLACEHOLDER_IMG_CLASS = `${MAIN_CLASS}__placeholder__img`;
const ROW_CLASS = `${MAIN_CLASS}__row`;
const ROW_ICON_CLASS = `${MAIN_CLASS}__row__icon`;
const ROW_LABEL_CLASS = `${MAIN_CLASS}__row__label`;
const ROW_LABEL_FIELD_CLASS = `${MAIN_CLASS}__row__label__field`;
const SELECT_CLASS = `${MAIN_CLASS}__select`;
const SELECT_LABEL_CLASS = `${MAIN_CLASS}__select__label`;
const SUBDOMAIN_LABEL_CLASS = `${MAIN_CLASS}__subdomain__label`;
const SUBDOMAIN_DESCRIPTION_CLASS = `${MAIN_CLASS}__subdomain__description`;
const SUBDOMAIN_EDIT_CLASS = `${MAIN_CLASS}__subdomain__edit`;
const SUBDOMAIN_EDIT_TEXT_CLASS = `${MAIN_CLASS}__subdomain__edit__text`;
const SUBDOMAIN_EDIT_DOMAIN_CLASS = `${MAIN_CLASS}__subdomain__edit__domain`;
const SUBDOMAIN_EDIT_FIELD_CLASS = `${MAIN_CLASS}__subdomain__edit__field`;
const SUBDOMAIN_EDIT_ERROR_CLASS = `${MAIN_CLASS}__subdomain__edit__error`;
const FOOTER_CLASS = `${MAIN_CLASS}__footer`;
const SUBDOMAIN_EDIT_ICON_CLASS = {
  className: `${MAIN_CLASS}__subdomain__edit__icon`,
};

export function CreateProjectModal(props: Props) {
  const showExperienceStudioImage = false;
  const { openModal, toggleModal, fetchProjects, orgID, orgType, orgDeployments, t } = props;
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('errors:PROJECT_NAME_REQUIRED', PROJECT_NAME_REQUIRED))
      .trim()
      .min(2, t('errors:PROJECT_NAME_TWO_CHARS_REQUIRED', PROJECT_NAME_TWO_CHARS_REQUIRED))
      .matches(projectNameRegex, t('errors:PROJECT_NAME_INVALID', PROJECT_NAME_INVALID)),
    subdomain: Yup.string()
      .required(t('errors:SUBDOMAIN_REQUIRED', SUBDOMAIN_REQUIRED))
      .matches(subDomainRegex, t('errors:ONLY_LOWER_CASE', ONLY_LOWER_CASE))
      .min(2, t('errors:SUBDOMAIN_TWO_CHARS_REQUIRED', SUBDOMAIN_TWO_CHARS_REQUIRED)),
  });

  const [apiError, setApiError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [editSubDomain, setEditSubDomain] = useState(false);
  const [initialValues, setInitialValues] = useState(initialFormValues);
  const [deploymentData, setDeploymentData] = useState<ItemObject>({});

  const [allTiers, setAllTiers] = useState<SelectOptionProps[]>([]);
  const [allPlatforms, setAllPlatforms] = useState<SelectOptionProps[]>([]);
  const [allDeployments, setAllDeployments] = useState<SelectOptionProps[]>([]);

  const [tier, setTier] = useState<SelectOptionProps | null>(null);
  const [platform, setPlatform] = useState<SelectOptionProps | null>(null);
  const [deployment, setDeployment] = useState<SelectOptionProps | null>(null);

  useEffect(() => {
    // make the select objects based on tiers
    if (openModal) {
      setDeployment(null);
    }
    if (!orgDeployments.length || !openModal) return;
    //first, get all possible tiers
    const allPossibleTiers: SelectOptionProps[] = [];
    const tierData: ItemObject = {};

    orgDeployments.map((item) => {
      return item.tiers.map((tier) => {
        // tiers list
        if (typeof tierData[tier] !== 'undefined') {
          tierData[tier].deployments.push(item.id);
          tierData[tier].platforms.push(item.region);
        } else {
          let extraInfo = '';
          switch (tier.toLowerCase()) {
            case 'standard':
              extraInfo = t('translation:pages.projects.create.tiers.standard', ' (0-100,000 Users)');
              break;
            case 'advanced':
              extraInfo = t('translation:pages.projects.create.tiers.advanced', ' (100,000-500,000 Users)');
              break;
            case 'enterprise':
              extraInfo = t('translation:pages.projects.create.tiers.enterprise', ' (500,000+ Users)');
              break;
            default:
              extraInfo = '';
          }
          const tierName = `${tier.charAt(0).toUpperCase() + tier.slice(1)}${extraInfo}`;
          allPossibleTiers.push({
            key: tier,
            name: tierName,
          });
          tierData[tier] = { deployments: [item.id], platforms: [item.region] };
        }
        return tier;
      });
    });
    setAllTiers(allPossibleTiers);
    setDeploymentData(tierData);
    if (allPossibleTiers?.[0] && !isEmpty(allPossibleTiers)) {
      const filteredPlatforms: string[] = tierData[allPossibleTiers[0].key].platforms.filter(
        (value, index, self) => self.indexOf(value) === index,
      );
      setAllPlatforms(
        filteredPlatforms.map((platform) => {
          return {
            key: platform,
            name: platform.toUpperCase(),
          };
        }),
      );
      const filteredDeployments = orgDeployments.filter(
        ({ region, tiers }) =>
          region === tierData[allPossibleTiers[0].key].platforms[0] &&
          allPossibleTiers[0].key &&
          tiers.includes(allPossibleTiers[0].key),
      );
      setAllDeployments(
        filteredDeployments.map((deployment) => {
          return {
            key: deployment.id,
            name: deployment.id.toUpperCase(),
          };
        }),
      );
    }
  }, [openModal, orgDeployments]);

  const changePlatform = (platform: string, tier: string) => {
    setPlatform({
      key: platform,
      name: platform.toUpperCase(),
    });
    const filteredDeployments = orgDeployments.filter(
      ({ region, tiers }) => region === platform && tier && tiers.includes(tier),
    );
    setAllDeployments(
      filteredDeployments.map((deployment) => {
        return {
          key: deployment.id,
          name: deployment.id.toUpperCase(),
        };
      }),
    );
  };
  const changeTier = (tier: string) => {
    const filteredPlatforms: string[] = deploymentData[tier].platforms.filter(
      (value, index, self) => self.indexOf(value) === index,
    );
    setAllPlatforms(
      filteredPlatforms.map((platform) => {
        return {
          key: platform,
          name: platform.toUpperCase(),
        };
      }),
    );
    setDeployment(null);
    setPlatform(null);
    setAllDeployments([]);
  };

  //* manages edit-pencil toggle/
  function toggleEditSubDomain() {
    setEditSubDomain((editSubDomain) => !editSubDomain);
  }

  //* makes api calls, if successful returns callback to parent to fetch projects/
  const createProject = async (data: CreateProject) => {
    setLoading(true);
    const projectServiceManager = new ProjectService();

    try {
      const projectResponseObject = await projectServiceManager.createProject(orgID, data);
      if (projectResponseObject?.status === 201 || projectResponseObject?.status === 200) {
        return true;
      }
      setLoading(false);
      return false;
    } catch (error) {
      if (error?.response?.data?.message) {
        setEditSubDomain(true);
        setApiError(error?.response?.data?.message);
      }
      setLoading(false);
      return false;
    }
  };

  //* handles api error message locally
  function resetApiErrorMessage() {
    setApiError('');
  }

  return (
    <div className={MAIN_CLASS}>
      {/* {Begin New Project Modal} */}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        enableReinitialize
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          let subDomainValueForServer = '';
          subDomainValueForServer = values.subdomain.toLocaleLowerCase();
          const submitData: CreateProject = {
            name: strip_tags(values.name),
            subdomain: subDomainValueForServer,
            deployment: values.deployment,
            tier: values.tier,
          };
          setSubmitting(true);
          const result = await createProject(submitData);
          if (result) {
            setTimeout(() => {
              resetForm();
              setLoading(false);
              fetchProjects(true);
              toggleModal();
              return true;
            }, 1000);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, setFieldValue, handleReset }) => (
          <FlightModal
            isVisible={openModal}
            toggleModalShown={(e: React.FormEvent<HTMLInputElement>) => {
              handleReset(e);
              setInitialValues(initialFormValues);
              resetApiErrorMessage();
              toggleModal();
              setEditSubDomain(false);
              setLoading(false);
            }}
            scrollable={false}
            size="large"
            content={
              <div className={CONTENT_CLASS}>
                {isLoading && (
                  <div className={LOADING_CLASS}>
                    <LoadingIcon width={80} height={80} fullScreen visible={isLoading} />
                    <h3>{t('translation:pages.projects.create.creating', 'Setting up your project...')}</h3>
                  </div>
                )}
                <h1 className={HEADER_CLASS}>{t('translation:pages.projects.create.title', 'Create new project')}</h1>
                <div>
                  {showExperienceStudioImage && (
                    <div>
                      <div className={CONTAINER_CLASS}>
                        {values.subdomain === '' ? (
                          <div className={PLACEHOLDER_TEXT_CLASS}>
                            {values.name.replace(/\s+/g, '-').toLowerCase() +
                              (values.deployment ? '.' + values.deployment.toLocaleLowerCase() : '')}
                            .flybits.com
                          </div>
                        ) : (
                          <div className={PLACEHOLDER_TEXT_CLASS}>
                            {values.subdomain + (values.deployment ? '.' + values.deployment.toLocaleLowerCase() : '')}
                            .flybits.com
                          </div>
                        )}
                        <Jdenticon size="24" value={values.name} className={PLACEHOLDER_JDENTICON_CLASS} />
                        <div className={PLACEHOLDER_NAME_CLASS}>{values.name + ' '}</div>
                        <div>
                          <ESPlaceholder height="100%" width="100%" className={PLACEHOLDER_IMG_CLASS} />
                        </div>
                      </div>
                    </div>
                  )}
                  <div>
                    <div>
                      <div className={ROW_CLASS}>
                        <FlightTooltip
                          direction="right bottom"
                          isEnabled={true}
                          description={CREATE_PROJECT_JDENTICOON}
                          ariaLabel={CREATE_PROJECT_JDENTICOON}
                        >
                          <Jdenticon size="53" value={values.name} className={ROW_ICON_CLASS} />
                        </FlightTooltip>
                        <div className={ROW_LABEL_CLASS}>
                          <label htmlFor="project-name">
                            {t('translation:pages.projects.create.labels.project_name', 'Project name')}
                          </label>
                          <Field
                            type="text"
                            name="name"
                            as={FlightTextInput}
                            hasError={touched.name && errors.name ? true : false}
                            className={ROW_LABEL_FIELD_CLASS}
                            value={values.name}
                            width="100%"
                            labelId={'project-name'}
                            disabled={isLoading}
                            ariaLabel={t('translation:pages.projects.create.labels.project_name', 'Project name')}
                            isLabelAlwaysOn
                            errorMessage={
                              <span role="alert" aria-atomic>
                                <b>Error:</b> {errors.name}
                              </span>
                            }
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                              handleChange(e);
                              const cleanInputForSubdomain = e.currentTarget.value
                                .replace(/[^a-zA-Z0-9]/g, '-')
                                .toLowerCase();
                              setFieldValue('subdomain', cleanInputForSubdomain);
                            }}
                            onBlur={handleBlur}
                          />{' '}
                        </div>
                      </div>
                    </div>
                    <div className={SELECT_CLASS}>
                      <div className={SELECT_LABEL_CLASS}>
                        <FlightTooltip
                          direction="right"
                          isEnabled={true}
                          description={t(
                            'translation:pages.projects.create.labels.choose_license_tooltip',
                            'Select a licence type',
                          )}
                          ariaLabel={t(
                            'translation:pages.projects.create.labels.choose_license_tooltip',
                            'Select a licence type',
                          )}
                        >
                          <label htmlFor="select-license">
                            {t('translation:pages.projects.create.labels.license', 'License')}
                          </label>
                        </FlightTooltip>
                        <div>
                          <Field
                            type="select"
                            name="tier"
                            hasLabelAnimation
                            label={t('translation:pages.projects.create.labels.choose_license', 'Choose a license')}
                            ariaLabel={t('translation:pages.projects.create.labels.choose_license', 'Choose a license')}
                            width="100%"
                            options={allTiers}
                            selected={tier}
                            disabled={isLoading}
                            dropdownMaxHeight="250px"
                            ariaControlsId="license"
                            as={FlightSelect}
                            handleOptionClick={(value: SelectOptionProps) => {
                              setFieldValue('tier', value.key);
                              setTier(value);
                              changeTier(value.key);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={SELECT_CLASS}>
                      <div className={SELECT_LABEL_CLASS}>
                        <FlightTooltip
                          direction="right"
                          isEnabled={true}
                          description={t(
                            'translation:pages.projects.create.labels.cloud_platform_tooltip',
                            'Select a Cloud Service Platform',
                          )}
                          ariaLabel={t(
                            'translation:pages.projects.create.labels.cloud_platform_tooltip',
                            'Select a Cloud Service Platform',
                          )}
                        >
                          <label htmlFor="select-platform">
                            {t('translation:pages.projects.create.labels.cloud_platform', 'Cloud service platform')}
                          </label>
                        </FlightTooltip>
                        <div>
                          <Field
                            type="select"
                            name="platform"
                            label={t('translation:pages.projects.create.labels.choose_region', 'Choose a region')}
                            ariaLabel={t('translation:pages.projects.create.labels.choose_region', 'Choose a region')}
                            disabled={isLoading || !tier}
                            hasLabelAnimation
                            options={allPlatforms}
                            selected={platform}
                            width="100%"
                            ariaControlsId="platform"
                            dropdownMaxHeight="120px"
                            as={FlightSelect}
                            handleOptionClick={(value: SelectOptionProps) => {
                              setFieldValue('platform', value.key);
                              setPlatform(value);
                              changePlatform(value.key, values.tier);
                            }}
                          />
                        </div>
                        <div>
                          <Field
                            type="select"
                            name="deployment"
                            label={t(
                              'translation:pages.projects.create.labels.choose_deployment',
                              'Choose your deployment',
                            )}
                            ariaLabel={t(
                              'translation:pages.projects.create.labels.choose_deployment',
                              'Choose your deployment',
                            )}
                            hasLabelAnimation
                            options={allDeployments}
                            selected={deployment}
                            width="100%"
                            ariaControlsId="deployment"
                            disabled={isLoading || !platform || !tier}
                            dropdownMaxHeight="90px"
                            as={FlightSelect}
                            handleOptionClick={(value: SelectOptionProps) => {
                              setFieldValue('deployment', value.key);
                              setDeployment(value);
                            }}
                          />
                        </div>
                        {orgType === 'enterprise' && (
                          <div className={SUBDOMAIN_DESCRIPTION_CLASS}>
                            <em>
                              {getIcon('infoStroke', {
                                width: 12,
                                height: 12,
                                fill: '#9BA1AF',
                                style: { marginTop: '3px' },
                              })}{' '}
                              {t(
                                'translation:pages.projects.create.labels.need_custom',
                                'Need a new custom deployment?',
                              )}{' '}
                              <a href={`mailto:support@flybits.com?Subject=Custom deployment [Org: ${orgID}]`}>
                                {t('translation:pages.projects.create.labels.contact_us', 'Contact us')}
                              </a>
                            </em>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={SUBDOMAIN_LABEL_CLASS}>
                      {t('translation:pages.projects.create.labels.subdomain', 'Subdomain')}
                    </div>
                    <div className={SUBDOMAIN_DESCRIPTION_CLASS}>
                      {t(
                        'translation:pages.projects.create.labels.subdomain_description',
                        'This is your experience studio subdomain. You cannot change your project URL after project creation',
                      )}
                    </div>
                    {!editSubDomain ? (
                      <div className={SUBDOMAIN_EDIT_CLASS}>
                        <span onClick={() => toggleEditSubDomain()}>
                          {getIcon('pencil', SUBDOMAIN_EDIT_ICON_CLASS)}
                        </span>
                        <span className={SUBDOMAIN_EDIT_TEXT_CLASS}>
                          {values.name === '' ? '' : `${values.subdomain}.${values.deployment}.flybits.com`}
                        </span>
                      </div>
                    ) : (
                      <div className={`${SUBDOMAIN_EDIT_DOMAIN_CLASS}__holder`}>
                        <span className={SUBDOMAIN_EDIT_DOMAIN_CLASS}>{values.deployment}.flybits.com</span>
                        <Field
                          type="text"
                          name="subdomain"
                          ariaLabel="Subdomain"
                          label="Subdomain"
                          isLabelAlwaysOn
                          as={FlightTextInput}
                          width="60%"
                          disabled={isLoading}
                          hasError={
                            (touched.subdomain && errors.subdomain ? true : false) || (apiError !== '' ? true : false)
                          }
                          className={SUBDOMAIN_EDIT_FIELD_CLASS}
                          value={values.subdomain.toLowerCase()}
                          errorMessage={
                            <div role="alert" aria-atomic className={SUBDOMAIN_EDIT_ERROR_CLASS}>
                              {errors.subdomain || apiError}
                            </div>
                          }
                          onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            handleChange(e);
                            resetApiErrorMessage();
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            }
            footer={
              <div className={FOOTER_CLASS}>
                <FlightButton
                  type="primary"
                  label={t('translation:pages.projects.create.btn-create', 'Create project')}
                  ariaLabel={t('translation:pages.projects.create.btn-create', 'Create project')}
                  onClick={handleSubmit}
                  disabled={!isValid}
                />
              </div>
            }
          />
        )}
      </Formik>
    </div>
  );
}
