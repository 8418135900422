import React, { FunctionComponent } from 'react';
import OrganizationUserListItem from './OrganizationUsersListItem';
import { OrganizationUser } from '../../model/organizations/organizations';
import { useControlTowerContext } from '../../contexts/ControlTowerContext';
import './OrganizationUsersList.scss';

interface Props {
  list: Array<OrganizationUser>;
  t: any;
}

const OrganizationUserList: FunctionComponent<Props> = (props: Props) => {
  const { list, t } = props;

  const controlTowerContextData = useControlTowerContext();

  const { user } = controlTowerContextData;

  const MAIN_CLASS = 'org_users_list';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  return (
    <div className={FORM_CLASS}>
      <div className={`${CONTENT_CLASS}__infos-wrapper_list`}>
        {list.map((item: OrganizationUser) => (
          <OrganizationUserListItem
            key={item.id}
            id={item.id}
            givenName={item.givenName}
            familyName={item.familyName}
            email={item.email}
            roles={item.roles}
            currentUserEmail={user?.username}
            t={t}
          />
        ))}
      </div>
    </div>
  );
};

export default OrganizationUserList;
