import React, { useEffect, lazy } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
const NotFound = lazy(() => import('src/pages/NotFound/NotFound'));
import PasswordChange from 'src/pages/ChangePassword';
import PasswordReset from 'src/pages/PasswordReset';
import Organizations from 'src/pages/Organizations/List/Organizations';
import CreateOrganizations from 'src/pages/Organizations/Create/CreateOrganizations';
import OrganizationDetails from 'src/pages/Organizations/Details/OrganizationDetails';
import OrganizationInvites from 'src/pages/Organizations/Invite/OrganizationInvites';
import OrganizationDirectory from 'src/pages/Organizations/Directory/OrganizationDirectory';
import TeamsCreate from 'src/pages/Teams/Create/TeamsCreate';
import TeamDetails from 'src/pages/Teams/Details/TeamDetails';
import Projects from 'src/pages/Projects/List/Projects';
import ProjectDetails from 'src/pages/Projects/Details/ProjectDetails';
import IntegrationsList from 'src/pages/Integrations/List/IntegrationsList';
import ConnectorPage from 'src/pages/Integrations/Details/Connector';
import DataSourcePage from 'src/pages/Integrations/Details/DataSource';
import CreateDataSourcePage from 'src/pages/Integrations/Create/DataSourceCreate';
import UserAccount from 'src/pages/UserAccount/UserAccount';
import SignIn from 'src/pages/SignIn';
import SignUp from 'src/pages/SignUp';
import VerifyAccount from 'src/pages/VerifyAccount';
import AcceptInvite from 'src/pages/AcceptInvite';
import TokenClear from 'src/pages/authentication/token/Clear';
import TokenGet from 'src/pages/authentication/token/Get';
import TokenValidate from 'src/pages/authentication/token/Validate';
import NavBar from 'src/components/NavBar/NavBar';
import PrivateRoute from 'src/components/PrivateRoute/PrivateRoute';
import PublicPage from 'src/components/PublicPage/PublicPage';
import { PublicPageChildProps } from 'src/model/general/public-pages';
import AppSignout from 'src/pages/authentication/signout/AppSignout';
import AppAuthCallback from 'src/pages/PkceOidc/AppAuthCallback';
import ApiAuthRequest from 'src/pages/PkceOidc/ApiAuthRequest';
import { deleteStoredPassword, getStoredPassword } from 'src/helpers/auth.helper';
import DataSourceAuthCallback from 'src/pages/DataSources/DataSourceAuthCallback';
import TwoFactorAuthentication from 'src/pages/TwoFactorAuthentication';
import TermsOfService from 'src/pages/Static/TermsOfService/TermsOfService';
import PrivacyPolicy from 'src/pages/Static/PrivacyPolicy/PrivacyPolicy';

const ControlTowerRoutes = () => {
  const [showBanner, setBannerVisible] = React.useState(true);
  const setBannerVisibility = async (visible = true, instant = false) => {
    setBannerVisible(visible);
    return new Promise((resolve) => {
      setTimeout(
        () => {
          resolve(true);
        },
        instant ? 0 : 400,
      );
    });
  };
  const commonPublicProps: PublicPageChildProps = {
    showBanner: showBanner,
    setBannerVisibility: setBannerVisibility,
  };

  const publicRoutes = [
    '/signin',
    '/signup',
    '/verify',
    '/2fa',
    '/confirmEmail',
    '/acceptInvite',
    '/password/change',
    '/password/reset',
    '/resetPassword',
    '/app/signout',
    '/app-auth-callback/:projectId',
    '/auth/request',
  ];
  // make sure no password is stored
  useEffect(() => {
    getStoredPassword().then(deleteStoredPassword);
  }, []);
  return (
    <Router>
      <Switch>
        {/* Public Routes */}
        <Route exact path={publicRoutes}>
          <PublicPage {...commonPublicProps}>
            <Route path="/signin">
              <SignIn {...commonPublicProps} />
            </Route>
            <Route path="/signup">
              <SignUp {...commonPublicProps} />
            </Route>
            <Route path="/2fa">
              <TwoFactorAuthentication {...commonPublicProps} />
            </Route>
            <Route path={['/verify', '/confirmEmail']}>
              <VerifyAccount {...commonPublicProps} />
            </Route>
            <Route path="/acceptInvite">
              <AcceptInvite {...commonPublicProps} />
            </Route>
            <Route path="/authentication/token/clear" component={TokenClear} />
            <Route path="/authentication/token/get" component={TokenGet} />
            <Route path="/authentication/token/validate" component={TokenValidate} />
            <Route path={['/password/change', '/resetPassword']}>
              <PasswordChange {...commonPublicProps} />
            </Route>
            <Route path="/password/reset">
              <PasswordReset {...commonPublicProps} />
            </Route>
            <Route path="/app/signout" component={AppSignout} />
            <Route path="/app-auth-callback/:projectId" component={AppAuthCallback} />
            <Route path="/auth/request" component={ApiAuthRequest} />
          </PublicPage>
        </Route>
        <Route
          path="/api/auth/request"
          exact
          component={() => {
            console.log('Redirecting:', window.location.href);
            window.location.href = `https://bff.controltower.flybits.com/api/auth/request/${window.location.search}`;
            return null;
          }}
        />
        <Route path="/terms-of-service" component={TermsOfService} exact={true} />
        <Route path="/privacy-policy" component={PrivacyPolicy} exact={true} />
        <Route path="/error" component={NotFound} exact={true} />
        <Redirect from="/" to="/signin" exact={true} />
        <PrivateRoute exact={true} path="/datasource-auth-callback" component={DataSourceAuthCallback} />
        {/* Authenticated */}
        <NavBar style={{ height: '100%' }}>
          <Switch>
            <PrivateRoute exact={true} path="/organizations/new" component={CreateOrganizations} />
            <PrivateRoute exact={true} path="/organizations" component={Organizations} />
            <PrivateRoute exact={true} path="/organization/:id/directory" component={OrganizationDirectory} />
            <PrivateRoute exact={true} path="/organization/:id/invites" component={OrganizationInvites} />
            <PrivateRoute exact={true} path="/organization/:id/settings" component={OrganizationDetails} />
            <PrivateRoute exact={true} path="/organization/:id/projects" component={Projects} />
            <PrivateRoute exact={true} path="/organization/:id/project/:project" component={ProjectDetails} />
            <PrivateRoute
              exact={true}
              path={['/organization/:id/integrations', '/organization/:id/integrations/:version']}
              component={IntegrationsList}
            />
            {/* V1 - 2023 */}
            <PrivateRoute
              exact={true}
              path="/organization/:id/integrations/:version/connector/:connector"
              component={ConnectorPage}
            />
            <PrivateRoute
              exact={true}
              path="/organization/:id/integrations/:version/datasource/new"
              component={CreateDataSourcePage}
            />
            <PrivateRoute
              exact={true}
              path="/organization/:id/integrations/:version/datasource/:datasource"
              component={DataSourcePage}
            />
            <PrivateRoute exact={true} path="/organization/:id/teams/new" component={TeamsCreate} />
            <PrivateRoute exact={true} path="/organization/:id/team/:team" component={TeamDetails} />
            <PrivateRoute exact={true} path="/organization/:id" component={Projects} />
            <PrivateRoute exact={true} path="/user/account" component={UserAccount} />
            <Route
              path="/api/auth/request"
              exact
              component={() => {
                console.log('[Private] Redirecting:', window.location.href);
                window.location.href = `https://bff.controltower.flybits.com/api/auth/request/${window.location.search}`;
                return null;
              }}
            />
            <Route component={NotFound} />
          </Switch>
        </NavBar>
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default ControlTowerRoutes;
