import React, { FunctionComponent, useState, ReactElement, useEffect } from 'react';
import { FlightNav, FlightSnackbar } from '@flybits/design-system';
import SvgFlybitsLogo from 'src/assets/FlybitsLogo';
import AuthService from 'src/services/authentication.service';
import './NavBar.scss';
import {
  clearDB,
  deleteStoredEmail,
  deleteStoredOrganizationId,
  deleteStoredPassword,
  deleteStoredProjectId,
  deleteUserConfirmed,
  deleteUserRoles,
  setUserConfirmed,
} from 'src/helpers/auth.helper';
import { useHistory } from 'react-router-dom';
import { User } from 'src/model/users/users';

import NavBarMenu from '../NavBarMenu/NavBarMenu';

import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import useNotification from 'src/hooks/useNotification';

interface Props {
  children: ReactElement;
  style?: any;
}

const NavBar: FunctionComponent<Props> = (navProps: Props) => {
  const authServiceManager = new AuthService();
  const { message, removeNotification, addNotificationError } = useNotification();
  const {
    user: contextUser,
    setControlTowerContextDataUser,
    organization,
    unsetControlTowerContextDataOrg,
  } = useControlTowerContext();
  const history = useHistory();

  const { children, style } = navProps;

  const [user, setUser] = useState<User>({
    username: '',
    givenName: '',
    familyName: '',
    confirmed: false,
    role: '',
    userId: '',
  });
  const [currentOrganization, setOrganization] = useState(organization);

  //get user data
  useEffect(() => {
    const checkSignedInStatus = async () => {
      try {
        const signInUserResponseObject = await authServiceManager.getSignedInUser();
        if (signInUserResponseObject?.status === 200 && signInUserResponseObject?.data) {
          const {
            userId,
            username,
            given_name: givenName,
            family_name: familyName,
            role,
            confirmed,
          } = signInUserResponseObject.data;
          if (confirmed === true) {
            await setUserConfirmed(btoa(username));
          } else {
            addNotificationError('Your e-mail is not confirmed, please check your inbox or try again later.');
            history.replace('/verify');
          }
          setUser({
            givenName: givenName,
            familyName: familyName,
            username: username,
            role: role,
            confirmed: confirmed,
            userId: userId,
          });
        } else {
          initSignOut();
        }
      } catch (error) {
        console.error(error);
        initSignOut();
      }
    };
    checkSignedInStatus();
  }, [history]);

  //Update context
  useEffect(() => {
    setControlTowerContextDataUser({
      username: user.username,
      givenName: user.givenName,
      familyName: user.familyName,
      confirmed: user.confirmed,
      role: user.role,
      userId: user.userId,
    });
  }, [user, setControlTowerContextDataUser]);

  useEffect(() => {
    setOrganization(organization);
  }, [organization]);

  const initSignOut = async () => {
    try {
      if (user?.userId) {
        await authServiceManager.signOutUser();
      }
      await clearDB();
      await deleteStoredProjectId();
      await deleteStoredOrganizationId();
      await deleteStoredEmail();
      await deleteStoredPassword();
      await deleteUserConfirmed();
      await deleteUserRoles();
      const redirectURL = `${window.location.pathname}${window.location.search}${window.location.hash}`;
      history.replace(`/signin?loggedOut=true&next=${btoa(redirectURL)}`);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSwitchOrganizations = async () => {
    try {
      await deleteStoredProjectId();
      await deleteStoredOrganizationId();
      unsetControlTowerContextDataOrg();
      history.push('/organizations');
    } catch (error) {
      console.error(error);
    }
  };

  const handleManageFlybitsAccount = async () => {
    try {
      history.push('/user/account');
    } catch (error) {
      console.error(error);
    }
  };
  const clickLogoHandler = () => {
    history.push(currentOrganization?.id ? `/organization/${currentOrganization.id}` : '/organizations');
  };
  return (
    <>
      <FlightSnackbar
        isVisible={!!message}
        isFloating
        className={`show__info`}
        animation
        isAutoDismiss={true}
        dismissAfter={5000}
        type={message.startsWith('!!!') ? 'error' : message.startsWith('~~~') ? 'warning' : 'default'}
        icon={message.startsWith('!!!') ? 'error' : message.startsWith('~~~') ? 'warning' : ''}
        actionName=""
        content={message.replace(/[\!\~]{3}/g, '')}
        handleClose={() => {
          removeNotification();
        }}
      />
      <FlightNav
        handleSignOut={initSignOut}
        hasHeaderBorder={false}
        hasSidebar={false}
        actionables={
          <NavBarMenu
            currentOrganization={currentOrganization || null}
            user={contextUser || null}
            handleSwitchOrganizations={handleSwitchOrganizations}
            handleManageFlybitsAccount={handleManageFlybitsAccount}
            initSignOut={initSignOut}
          />
        }
        handleLogoClick={clickLogoHandler}
        logo={
          <div className="main-logo-container">
            <SvgFlybitsLogo
              width={86}
              height={24}
              tabIndex={-1}
              role="logo"
              viewBox="0 0 104 30"
              {...{ className: '' }}
            />
          </div>
        }
      >
        <div role="main" style={style}>
          {children}
        </div>
      </FlightNav>
    </>
  );
};
export default NavBar;
