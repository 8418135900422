import BaseService from './base.service';
import { CreateOrganization, Invites, OrganizationInviteCancel } from '../model/organizations/organizations';
import { UserRoles } from 'src/model/users/users';

interface GetOrganization {
  limit: number;
}

export default class OrganizationService extends BaseService {
  CURRENT_PATH: string;
  constructor() {
    super();
    this.CURRENT_PATH = '/organizations';
  }

  async getOrganizations(params?: GetOrganization) {
    return this.request('GET', this.CURRENT_PATH, params);
  }

  async getOrganization(organizationId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId);
  }

  async getOrganizationByCode(code: string) {
    return this.request('GET', this.CURRENT_PATH + '/pseudonym/' + code);
  }

  async getOrganizationDeployments(organizationId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId + '/deployments');
  }

  async getOrganizationUsers(organizationId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId + '/users');
  }

  async getOrganizationInvites(organizationId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId + '/invites');
  }
  async cancelOrganizationInvites(organizationId: string, data: OrganizationInviteCancel) {
    return this.request('PUT', this.CURRENT_PATH + '/' + organizationId + '/invites', data);
  }

  async getOrganizationUserInfo(organizationId: string, userId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId + '/users/' + userId);
  }

  async updateOrganizationUserRoles(organizationId: string, userId: string, data: UserRoles) {
    return this.request('PATCH', this.CURRENT_PATH + '/' + organizationId + '/users/' + userId, data);
  }

  async getOrganizationTeams(organizationId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId + '/teams');
  }

  async getOrganizationUserTeamsInfo(organizationId: string, userId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId + '/users/' + userId + '/teams');
  }

  async deleteUserFromOrganization(organizationId: string, userId: string) {
    return this.request('DELETE', this.CURRENT_PATH + '/' + organizationId + '/users/' + userId);
  }

  async getOrganizationOwners(organizationId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId + '/owners');
  }

  async inviteUsersToOrganization(organizationId: string, data: Invites) {
    return this.request('POST', this.CURRENT_PATH + '/' + organizationId + '/invites', data);
  }

  async leaveOrganization(organizationId: string) {
    return this.request('DELETE', this.CURRENT_PATH + '/' + organizationId + '/users/me');
  }

  async deleteOrganization(organizationId: string) {
    return this.request('DELETE', this.CURRENT_PATH + '/' + organizationId);
  }

  async updateOrganization(organizationId: string, data: CreateOrganization) {
    const form = new FormData();
    if (data.name) form.append('name', data.name);
    if (data.externalUrl) form.append('externalUrl', data.externalUrl);
    if (data.image) form.append('image', data.image);
    return this.request('POST', this.CURRENT_PATH + '/' + organizationId, form);
  }
  async createOrganization(data: CreateOrganization) {
    const form = new FormData();
    form.append('name', data.name);
    form.append('externalUrl', data.externalUrl || '');
    form.append('image', data.image);
    return this.request('POST', this.CURRENT_PATH + '/new', form);
  }
}
