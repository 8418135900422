import React from 'react';
import './BannerImage.scss';
import { getIcon } from '@flybits/design-system';
import { BANNER_IMAGE_DESCRIPTION } from 'src/constants/descriptions';

interface Props {
  style?: any;
}

const BannerImage = (props: Props) => {
  const { style } = props;

  const MAIN_CLASS = 'BannerImage';
  const WRAPPER_CLASS = `${MAIN_CLASS}__wrapper`;
  const CONTAINER_CLASS = `${MAIN_CLASS}__container`;
  const HEADER_CLASS = `${CONTAINER_CLASS}__header`;
  const DESCRIPTION_CLASS = `${CONTAINER_CLASS}__description`;
  const REDIRECT_CLASS = `${CONTAINER_CLASS}__redirect`;
  const ICON_CLASS = {
    className: `${REDIRECT_CLASS}__icon`,
    'aria-hidden': true,
  };

  return (
    <div className={WRAPPER_CLASS} style={style}>
      <div className={MAIN_CLASS} style={style}>
        <div className={`${MAIN_CLASS}__image`} />
        <div className={CONTAINER_CLASS}>
          <div className={HEADER_CLASS}>Elevate the engagement with your customers</div>
          <div className={DESCRIPTION_CLASS}>{BANNER_IMAGE_DESCRIPTION}</div>
          <div className={REDIRECT_CLASS}>
            <a
              className={`${REDIRECT_CLASS}__link`}
              href="https://www.flybits.com"
              target="_blank"
              rel="noopener noreferrer"
              title="This link opens in a new tab"
            >
              {' '}
              Learn more {getIcon('baselineArrowRight', ICON_CLASS)}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerImage;
