import BaseService from './base.service';
import {
  SignInParameters,
  SignUpParameters,
  EmailConfirmParameters,
  PasswordResetParameters,
  ChangePasswordParameters,
  ChangeAccountPasswordParameters,
  AcceptInviteParameters,
  RedirectParameters,
  ChangeNameParameters,
  VerifyParameters,
} from '../model/auth/auth';
export default class AuthService extends BaseService {
  CURRENT_PATH: string;
  PRIMARY_OPTIONAL_RESOURCE: string;
  SECONDARY_OPTIONAL_RESOURCE: string;
  SIGN_IN_RESOURCE: string;
  TWO_FA_RESOURCE: string;
  TWO_FA_RESEND: string;
  REDIRECT_RESOURCE: string;
  SIGN_UP_RESOURCE: string;
  SIGN_OUT_RESOURCE: string;
  ME_RESOURCE: string;
  PASSWORD_RESET_RESOURCE: string;
  PASSWORD_ACCOUNT_CHANGE_RESOURCE: string;
  PASSWORD_CHANGE_RESOURCE: string;
  EMAIL_CONFIRMATION_RESOURCE: string;
  EMAIL_CONFIRM_RESOURCE: string;
  ACCEPT_INVITE_RESOURCE: string;

  constructor() {
    super();
    this.CURRENT_PATH = 'auth/';
    this.PRIMARY_OPTIONAL_RESOURCE = 'userdata/me';
    this.SECONDARY_OPTIONAL_RESOURCE = 'organizations/';

    this.SIGN_IN_RESOURCE = 'login';
    this.TWO_FA_RESOURCE = 'verify';
    this.TWO_FA_RESEND = 'resendCode';
    this.SIGN_UP_RESOURCE = 'register';
    this.REDIRECT_RESOURCE = 'redirect';
    this.SIGN_OUT_RESOURCE = 'logout';
    this.ME_RESOURCE = 'me';
    this.PASSWORD_RESET_RESOURCE = 'sendResetPasswordEmail';
    this.PASSWORD_ACCOUNT_CHANGE_RESOURCE = 'changePassword';
    this.PASSWORD_CHANGE_RESOURCE = 'resetPassword';
    this.EMAIL_CONFIRMATION_RESOURCE = 'sendConfirmationEmail';
    this.EMAIL_CONFIRM_RESOURCE = 'confirmEmail';
    this.ACCEPT_INVITE_RESOURCE = 'acceptInvite';
  }

  async getSignedInUser() {
    return this.request('GET', this.PRIMARY_OPTIONAL_RESOURCE);
  }

  async getProjectToken(projectId: string) {
    return this.request('GET', this.CURRENT_PATH + this.SECONDARY_OPTIONAL_RESOURCE + `?projectId=${projectId}`);
  }

  async getApiAuthRequest(url: string) {
    return this.request('GET', url);
  }

  async getOrganizationToken(organizationId: string) {
    return this.request(
      'GET',
      this.CURRENT_PATH + this.SECONDARY_OPTIONAL_RESOURCE + `?organizationId=${organizationId}`,
    );
  }

  async signInUser(params: SignInParameters) {
    const queryString = window.location.search;
    return this.request('POST', this.CURRENT_PATH + this.SIGN_IN_RESOURCE + queryString, params);
  }
  async signInUserWithToken(params: RedirectParameters) {
    return this.request('POST', this.CURRENT_PATH + this.REDIRECT_RESOURCE, params);
  }
  async checkVerificationCode(params: VerifyParameters) {
    return this.request('POST', this.CURRENT_PATH + this.TWO_FA_RESOURCE, params);
  }
  async sendVerificationCode() {
    return this.request('POST', this.CURRENT_PATH + this.TWO_FA_RESEND);
  }

  async signUpUser(params: SignUpParameters) {
    return this.request('POST', this.CURRENT_PATH + this.SIGN_UP_RESOURCE, params);
  }

  async signOutUser() {
    return this.request('POST', this.CURRENT_PATH + this.SIGN_OUT_RESOURCE);
  }

  async resetPassword(params: PasswordResetParameters) {
    return this.request('POST', this.CURRENT_PATH + this.PASSWORD_RESET_RESOURCE, params);
  }

  async sendConfirmationEmail() {
    return this.request('POST', this.CURRENT_PATH + this.EMAIL_CONFIRMATION_RESOURCE);
  }

  async confirmEmail(params: EmailConfirmParameters) {
    return this.request('POST', this.CURRENT_PATH + this.EMAIL_CONFIRM_RESOURCE, params);
  }

  async acceptInvite(params: AcceptInviteParameters) {
    return this.request('POST', this.SECONDARY_OPTIONAL_RESOURCE + this.ACCEPT_INVITE_RESOURCE, params);
  }

  async changePassword(params: ChangePasswordParameters) {
    return this.request('POST', this.CURRENT_PATH + this.PASSWORD_CHANGE_RESOURCE, params);
  }

  async changeAccountPassword(params: ChangeAccountPasswordParameters) {
    return this.request('POST', this.CURRENT_PATH + this.PASSWORD_ACCOUNT_CHANGE_RESOURCE, params);
  }

  async changeName(params: ChangeNameParameters) {
    return this.request('POST', this.CURRENT_PATH + this.ME_RESOURCE, params);
  }
}
