import React, { useCallback, useEffect, useState } from 'react';
import './SharingSettings.scss';
import { FlightButton, FlightModal, FlightTextInput, FlightToggleSwitch, getIcon } from '@flybits/design-system';
import imageErrorOrganization from 'src/assets/organization.svg';
import Jdenticon from 'src/components/Jdenticon';
import { ReactComponent as VerifiedSeal } from 'src/assets/verified.svg';
import { Link } from 'react-router-dom';
import useDebounce from 'src/hooks/useDebounce';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import { Organization } from 'src/model/organizations/organizations';

import { GENERIC_INTERNAL_ERROR } from 'src/constants/errors';
import DataSourcesService from 'src/services/datasources.service';
import { getUserRoles } from 'src/helpers/auth.helper';
import OrganizationService from 'src/services/organization.service';
import { DataSource } from 'src/model/datasources/datasources';
import ShareDatasourceModal from 'src/components/Shared/ShareDatasourceModal/ShareDatasourceModal';
import { Project } from 'src/model/projects/projects';
interface Props {
  current: DataSource | undefined;
  sharedOrgs: any;
  projects: Project[];
  t: any;
  organization: Organization | null;
  onError: (tab: string, hasError: boolean) => void;
  onChangeValue: (e: any) => void;
  onChangeShareStatus: (e: any) => void;
  addNotification: (e: any) => void;
  addNotificationError: (e: any) => void;
}
const SharingSettings: React.FunctionComponent<Props> = (props: Props) => {
  const datasourceServiceManager = new DataSourcesService();
  const organizationServiceManager = new OrganizationService();

  const {
    sharedOrgs,
    onChangeValue,
    onChangeShareStatus,
    organization,
    current,
    projects,
    addNotification,
    addNotificationError,
    t,
  } = props;
  const MAIN_CLASS = 'SharingSettings';
  const [shareCode, setShareCode] = useState('');
  const [tryToRevokeAccess, setTryToRevokeAccess] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [isSharingProject, setSharingProject] = useState(false);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [sharedProjects, setSharedProjects] = useState<string[]>([]);
  const [orgToRevoke, setOrgToRevoke] = useState<any>(null);
  const [orgToShare, setOrgToShare] = useState<any>(null);
  const debouncedShareCode = useDebounce(shareCode, 750);
  const [searchTermProject, setSearchTermProject] = useState('');

  const editSearchTermProject = (e: any) => {
    setSearchTermProject(e.target.value);
  };

  const HandleChangeShareCode = (e: any) => {
    if (e.target.value === '') {
      setSearching(false);
      setOrgToShare(null);
    }
    setShareCode(e.target.value);
  };
  const clickRevokeAccess = (org: any) => {
    setOrgToRevoke(org);
    setTryToRevokeAccess(true);
  };
  const searchSharedCode = async (code: string) => {
    if (!code) {
      setSearching(false);
      setOrgToShare(null);
      return;
    }
    setSearching(true);
    try {
      const orgResponseObject = await organizationServiceManager.getOrganizationByCode(code);
      if (orgResponseObject?.data) {
        setOrgToShare(orgResponseObject?.data);
      }
      setSearching(false);
    } catch (e: any) {
      console.error(e.message);
      setSearching(false);
      setOrgToShare(null);
    }
  };
  useEffect(() => {
    if (debouncedShareCode === '') {
      setSearching(false);
      setOrgToShare(null);
    } else {
      searchSharedCode(debouncedShareCode);
    }
  }, [debouncedShareCode]);
  useEffect(() => {
    const getStoredUserRoles = async () => {
      const roles = await getUserRoles();
      setUserRoles(roles);
    };
    getSharedProjects();
    getStoredUserRoles();
    setShowWarning(
      !['shared_allow3rdParty', '3rdPartyApproved', 'shared', 'approved'].includes(current?.shareStatus || ''),
    );
  }, [current]);

  const HandleRevokeAccess = async () => {
    try {
      if (!organization || !current) return;
      const shareResponseObject: any = await datasourceServiceManager.shareDataSource(
        organization?.id,
        current?.connectorId + '_' + current?.id,
        {
          add: [],
          remove: [orgToRevoke.id],
        },
      );
      if ([200, 201, 204].includes(shareResponseObject.status)) {
        onChangeValue(sharedOrgs.filter(({ id }: any) => id !== orgToRevoke.id));
        setTryToRevokeAccess(false);
        setOrgToRevoke(null);
        addNotification(
          t('integrations:datasources.details.sections.sharing.messages.revoked', 'Access revoked successfully'),
        );
      }
    } catch (e: any) {
      console.error(e);
      addNotificationError(e?.response?.data?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
    }
  };
  const HandleAddAccess = async (org: any) => {
    try {
      if (!organization || !current) return;
      const shareResponseObject: any = await datasourceServiceManager.shareDataSource(
        organization?.id,
        current?.connectorId + '_' + current?.id,
        {
          add: [org.id],
          remove: [],
        },
      );
      if ([200, 201, 204].includes(shareResponseObject.status)) {
        if (shareResponseObject?.data?.errorMessage) {
          addNotificationError(shareResponseObject?.data?.errorMessage);
        } else {
          sharedOrgs.push(org);
          onChangeValue(sharedOrgs);
          addNotification(
            t('integrations:datasources.details.sections.sharing.messages.granted', 'Access granted successfully'),
          );
          setShareCode('');
          setOrgToShare(null);
        }
        setSearching(false);
      }
    } catch (e: any) {
      console.error(e);
      addNotificationError(e?.response?.data?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
    }
  };
  const SharedOrganizationListItem = (props: any) => {
    if (!props) return null;
    const { name, id, imageUrl, verified } = props;
    return (
      <li>
        <div className="organization__item">
          <div className="organization__item-logo">
            <img
              src={`${!imageUrl.startsWith('http') ? 'https://controltower.flybits.com/' : ''}${imageUrl}`}
              onError={(ev) => {
                (ev.target as Element).setAttribute('src', imageErrorOrganization);
              }}
              alt={`${name} logo`}
            />
          </div>
          <div className="organization__item-content">
            <h5>
              {name} {verified && <VerifiedSeal />}
            </h5>
            <span>#{id}</span>
            {(userRoles.includes('owner') || userRoles.includes('datasources')) && (
              <FlightButton
                type="button"
                theme="link"
                className="organization__item-content-revoke-btn"
                label={t('integrations:datasources.details.sections.sharing.labels.revoke_access', 'Revoke access')}
                onClick={() => {
                  clickRevokeAccess({ name, id, imageUrl, verified });
                }}
              />
            )}
          </div>
        </div>
      </li>
    );
  };
  const openShareWithProjectModal = (projectId: string) => {
    if (!projectId) return;
    if (!sharedProjects.includes(projectId)) {
      // show modal for projects warning
      setTryToShareProject(projectId);
    } else {
      HandleProjectShare(projectId);
    }
  };
  const HandleProjectShare = async (projectId: string) => {
    if (isSharingProject || !organization || !current) return;
    try {
      if (!projectId) return;
      setSharingProject(true);
      setTryToShareProject(''); // close warning modal
      const shareResponseObject: any = await datasourceServiceManager.shareWithProject(
        organization?.id,
        current?.connectorId + '_' + current?.id,
        {
          add: !sharedProjects.includes(projectId) ? [projectId] : [],
          remove: sharedProjects.includes(projectId) ? [projectId] : [],
        },
      );
      if ([200, 201, 204].includes(shareResponseObject.status)) {
        if (shareResponseObject?.data?.errorMessage) {
          addNotificationError(shareResponseObject?.data?.errorMessage);
        } else {
          if (!sharedProjects.includes(projectId)) sharedProjects.push(projectId);
          else sharedProjects.splice(sharedProjects.indexOf(projectId), 1);
          await getSharedProjects();
          addNotification(
            t('integrations:datasources.details.sections.sharing.messages.updated', 'Access updated successfully'),
          );
        }
        setSharingProject(false);
      }
    } catch (e: any) {
      console.error(e);
      addNotificationError(e?.response?.data?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
      setSharingProject(false);
    }
  };
  const getSharedProjects = async () => {
    try {
      if (!organization || !current) return;
      setSharingProject(true);
      const shareResponseObject: any = await datasourceServiceManager.getDataSourceSharedProjects(
        organization?.id,
        current?.connectorId + '_' + current?.id,
      );
      if ([200, 201, 204].includes(shareResponseObject.status)) {
        shareResponseObject.data &&
          setSharedProjects(
            shareResponseObject.data.map(({ deployment, subdomain }: any) => deployment + '_' + subdomain),
          );
        setSharingProject(false);
      }
    } catch (e: any) {
      console.error(e);
      addNotificationError(e?.response?.data?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
      setSharingProject(false);
    }
  };
  const projectsFilteredData = () => {
    return projects.filter((proj) => {
      if (!searchTermProject) return true;
      if (proj.name.toLowerCase().includes(searchTermProject.toLowerCase())) {
        return true;
      }
      return false;
    });
  };
  const SharedProjectListItem = (props: any) => {
    if (!props) return null;
    const { deployment, subdomain, name, active, region } = props;
    const projectId = deployment + '_' + subdomain;
    return (
      <li>
        <div className={`project__item ${sharedProjects.includes(projectId) ? 'shared' : ''}`}>
          <div className="project__item-toggle">
            <FlightToggleSwitch
              label=""
              checked={sharedProjects.includes(projectId)}
              disabled={isSharingProject}
              onChange={() => openShareWithProjectModal(projectId)}
            />
          </div>
          <div className="project__item-logo">
            <Jdenticon size="40" value={subdomain} />
          </div>
          <div className="project__item-content">
            <h5>{name}</h5>
            <span className={`project__status ${active ? 'active' : 'inactive'}`}>
              {active
                ? t(`integrations:general.list.active`, 'Active')
                : t(`integrations:general.list.inactive`, 'Inactive')}
            </span>{' '}
            - <span>{region.toUpperCase()}</span>
          </div>
        </div>
      </li>
    );
  };
  // Sharing Modal
  const [tryToShareDatasource, setTryToShareDatasource] = useState<string[]>([]);
  const handleShareDatasource = useCallback(async (data: string[], values: any) => {
    if (!data.length || !values.approve_sharing || !organization?.id) return false;
    try {
      const datasourcesResponseObject = await datasourceServiceManager.updateDataSourceStatus(
        organization.id,
        data[0],
        'approve_sharing',
      );
      if (datasourcesResponseObject && [200, 201, 204].includes(datasourcesResponseObject.status)) {
        if (datasourcesResponseObject?.data?.errorMessage) {
          addNotificationError(datasourcesResponseObject?.data?.errorMessage);
          return false;
        } else {
          addNotification(t('integrations:datasources.share.success', 'Datasource shared successfully!'));
          onChangeShareStatus({
            ...current,
            shareStatus: datasourcesResponseObject?.data?.shareStatus,
          });
          setShowWarning(false);
          return true;
        }
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.message) {
        addNotificationError(error.response.data.message);
      } else {
        addNotificationError(GENERIC_INTERNAL_ERROR);
      }
      return false;
    }
    return false;
  }, []);
  const [tryToShareProject, setTryToShareProject] = useState('');
  return (
    <div className={MAIN_CLASS}>
      {/* SHARE WITH PROJECT/TENANT (warning) */}
      <FlightModal
        isVisible={!!tryToShareProject}
        toggleModalShown={() => setTryToShareProject('')}
        scrollable={false}
        size="small"
        warning={false}
        className={`Connector__alert`}
        header={<span>{t('integrations:datasources.details.sections.sharing.labels.share', 'Share')}</span>}
        content={
          <span>
            {t(
              'integrations:datasources.details.sections.sharing.messages.plugin_warning',
              'Every time you enable this data source for a project, a new context plugin is created and linked to this data source. If you have previously enabled this data source on the same project, please go into your project setting and delete the old context plugin. If you have any questions, please contact support@flybits.com',
            )}
          </span>
        }
        footer={
          <div className={`Connector__alert_footer`}>
            <FlightButton
              type="button"
              theme="secondary"
              label={t('translation:general.buttons.cancel', 'Cancel')}
              onClick={() => {
                setTryToShareProject('');
              }}
            />
            <FlightButton
              type="button"
              label={t('integrations:datasources.details.sections.sharing.labels.share', 'Share')}
              onClick={() => {
                tryToShareProject && HandleProjectShare(tryToShareProject);
              }}
            />
          </div>
        }
      />
      {/* REVOKE ACCESS MODAL */}
      <FlightModal
        isVisible={tryToRevokeAccess}
        toggleModalShown={() => setTryToRevokeAccess(false)}
        scrollable={false}
        size="small"
        warning={false}
        className={`Connector__alert revoke`}
        header={
          <span>
            {t('integrations:datasources.details.sections.sharing.modals.revoke.header', {
              org_name: orgToRevoke?.name || '',
            })}
          </span>
        }
        content={
          <span>
            {t(
              'integrations:datasources.details.sections.sharing.modals.revoke.body',
              'The organization selected will no longer be able to create new datasources using this connector.',
            )}
          </span>
        }
        footer={
          <div className={`Connector__alert_footer`}>
            <FlightButton
              type="button"
              theme="secondary"
              label={t('translation:general.buttons.cancel', 'Cancel')}
              onClick={() => {
                setTryToRevokeAccess(false);
              }}
            />
            <FlightButton
              type="button"
              label={t('integrations:datasources.details.sections.sharing.labels.revoke_access', 'Revoke access')}
              onClick={async () => {
                await HandleRevokeAccess();
              }}
            />
          </div>
        }
      />
      {/* SHARE DATASOURCE MODAL */}
      <ShareDatasourceModal
        handleShare={handleShareDatasource}
        showModal={tryToShareDatasource.length > 0}
        toggleModal={() => setTryToShareDatasource([])}
        modalData={tryToShareDatasource}
      />
      <h2 className="section__title">
        {t('integrations:datasources.details.sections.sharing.title', 'Sharing settings')}
      </h2>
      {current && showWarning && (
        <div className="warning">
          <div className="icon">{getIcon('error', { fill: '#FF9C02' })}</div>
          <div className="message">
            <h6>
              {t('integrations:datasources.details.sections.sharing.warning.title', 'This datasource is private')}
            </h6>
            <p>
              {t(
                'integrations:datasources.details.sections.sharing.warning.message',
                'Private or draft datasources cannot be shared with other organizations. Sharing of this datasource needs to be authorized by your ',
              )}{' '}
              <u>{t('integrations:datasources.details.sections.sharing.warning.org_owners', 'organization owners')}</u>.{' '}
            </p>
            <div className="links">
              <Link
                to={{
                  pathname: t(
                    'integrations:datasources.details.sections.sharing.warning.learn_more_url',
                    'https://app.gitbook.com/@flybits/s/product-guide/',
                  ),
                }}
                target="_blank"
              >
                {t('integrations:datasources.details.sections.sharing.warning.learn_more', 'Learn more')}
              </Link>
              {current &&
                current.id !== 'new' &&
                current.status === 'active' &&
                userRoles.includes('owner') &&
                (current as any).owner.id === (current as any).organization.id && (
                  <FlightButton
                    type="button"
                    theme="link"
                    className="approve-link"
                    label={t(
                      'integrations:datasources.details.sections.sharing.warning.approve_sharing',
                      'Approve this datasource for sharing',
                    )}
                    onClick={() => {
                      setTryToShareDatasource([current.connectorId + '_' + current.id, current.name]);
                    }}
                  />
                )}
            </div>
          </div>
        </div>
      )}
      <div className="section__fields">
        {(userRoles.includes('owner') || userRoles.includes('datasources')) &&
          current &&
          current.shareStatus !== 'private' &&
          !['draft', 'deleted'].includes(current?.status) && (
            <div className="section__fields-field">
              <label>{t('integrations:datasources.details.sections.sharing.add_org.title', 'Add organization')}</label>
              <div className="searching">
                <LoadingIcon width={20} height={20} visible={isSearching} />
              </div>
              <FlightTextInput
                label={t(
                  'integrations:datasources.details.sections.sharing.add_org.enter_code',
                  'Enter organization share code',
                )}
                width="600px"
                type="text"
                name="shareCode"
                value={shareCode}
                disabled={
                  !userRoles.includes('owner') ||
                  current?.id === 'new' ||
                  (current && ['draft', 'deleted'].includes(current?.status))
                }
                onChange={HandleChangeShareCode}
                onBlur={HandleChangeShareCode}
              />
              {!isSearching && shareCode && (
                <div className="section__fields-organizations-search-response">
                  <div className="organization__item-result">
                    {!orgToShare ? (
                      <div className="not-found">
                        <p>
                          {t(
                            'integrations:datasources.details.sections.sharing.add_org.not_found',
                            'You must enter a valid organization share code.',
                          )}
                        </p>
                        <Link
                          to={{
                            pathname: t(
                              'integrations:datasources.details.sections.sharing.add_org.learn_more_url',
                              'https://app.gitbook.com/@flybits/s/product-guide/for-owners/organizations',
                            ),
                          }}
                          target="_blank"
                        >
                          {t('integrations:datasources.details.sections.sharing.add_org.learn_more', 'Learn more')}
                        </Link>
                      </div>
                    ) : (
                      <>
                        <div className="organization__item-result-logo">
                          <img
                            src={`${
                              !orgToShare?.imageUrl.startsWith('http') ? 'https://controltower.flybits.com/' : ''
                            }${orgToShare?.imageUrl}`}
                            onError={(ev) => {
                              (ev.target as Element).setAttribute('src', imageErrorOrganization);
                            }}
                            alt={`${orgToShare?.name} logo`}
                          />
                        </div>
                        <div className="organization__item-result-content">
                          <h5>
                            {orgToShare?.name} {orgToShare?.verified && <VerifiedSeal />}
                          </h5>
                          <span>#{orgToShare?.id}</span>
                          <FlightButton
                            type="button"
                            className="organization__item-result-content-revoke-btn"
                            label={t(
                              'integrations:datasources.details.sections.sharing.add_org.give_access',
                              'Give access',
                            )}
                            onClick={() => {
                              HandleAddAccess(orgToShare);
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              <p>
                {t(
                  'integrations:datasources.details.sections.sharing.add_org.give_access_info',
                  'The organization you want to share this datasource with will give you a sharing code. Paste this code here to give them access to this datasource. Shared organizations cannot edit the datasource.',
                )}
              </p>
            </div>
          )}
        {sharedOrgs.length > 0 && (
          <div className="section__fields-field">
            <label>
              {t('integrations:datasources.details.sections.sharing.organization_access', 'Organization access')}
            </label>
            <ul className="section__fields-organizations">
              {sharedOrgs.map((org: any) => (
                <SharedOrganizationListItem key={org.id} {...org} />
              ))}
            </ul>
          </div>
        )}
        {(userRoles.includes('owner') || userRoles.includes('datasources')) &&
          current &&
          !['draft', 'deleted'].includes(current?.status) && (
            <div className="section__fields-field">
              <label>
                {t('integrations:datasources.details.sections.sharing.project_access', 'Internal project access')}
              </label>
              <div>
                <FlightTextInput
                  placeholderText=""
                  width="212px"
                  hasClearIcon
                  trailingIcon="search"
                  type="text"
                  name="table-filter"
                  value={searchTermProject}
                  onChange={editSearchTermProject}
                  ariaLabel={`Filter projects`}
                  label={`Filter projects`}
                  isLabelAlwaysOn
                />
              </div>
              <ul className="section__fields-projects">
                {projectsFilteredData().map((project: any, idx) => (
                  <SharedProjectListItem key={project.id + idx} {...project} />
                ))}
              </ul>
            </div>
          )}
      </div>
    </div>
  );
};

export default SharingSettings;
