import React, { FunctionComponent } from 'react';
import IconVerified from '../../assets/icons/verified';
import { FlightCard, getIcon } from '@flybits/design-system';
import imageErrorOrganization from 'src/assets/organization.svg';
import './OrganizationListItem.scss';
import { setStoredOrganizationId } from '../../helpers/auth.helper';
import { useHistory } from 'react-router-dom';
import { animated } from 'react-spring';
import { updateRanking } from 'src/helpers/ranking.helper';
interface Props {
  id: string;
  name: string;
  verified: boolean;
  imageUrl: string;
  externalUrl: string;
  style?: any;
}

const OrganizationListItem: FunctionComponent<Props> = (props: Props) => {
  const history = useHistory();

  const { id, name, imageUrl, verified: isVerified, style } = props;
  const DEFAULT_CLASS = 'organizationListItem';
  const WRAPPER_CLASS = `${DEFAULT_CLASS}__wrapper`;
  const BORDER_CLASS = `${DEFAULT_CLASS}__border`;
  const ICON_CLASS = `${DEFAULT_CLASS}__project-icon`;
  const INACTIVE_CLASS = `${DEFAULT_CLASS}__inactive`;
  const VERIFIED_CLASS = `${DEFAULT_CLASS}__verified`;

  const HeaderTitle = () => (
    <>
      {name}
      {isVerified && <IconVerified />}
      <div className="project__id">#{id}</div>
    </>
  );

  const handleOnClick = async () => {
    // if (!isActive) return;
    try {
      await setStoredOrganizationId(id);
      updateRanking('organizations', id);
      history.push('/organization/' + id);
    } catch (error) {
      console.error(error);
    }
  };
  const handleKeyDown = (func: any) => (e: any) => {
    switch (e.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        e.preventDefault();
        func();
        break;
      default:
        break;
    }
  };

  return (
    <animated.div
      className={WRAPPER_CLASS}
      key={id}
      style={style}
      tabIndex={0}
      role="button"
      onKeyDown={handleKeyDown(handleOnClick)}
    >
      <FlightCard
        onClick={handleOnClick}
        className={`${BORDER_CLASS} ${!isVerified ? INACTIVE_CLASS : ''} ${!isVerified ? VERIFIED_CLASS : ''}`}
        header={<HeaderTitle />}
        image={
          imageUrl && imageUrl !== 'https://controltower.flybits.com/' ? (
            <img
              src={`${!imageUrl.startsWith('http') ? 'https://controltower.flybits.com/' : ''}${imageUrl}`}
              width="40"
              className={ICON_CLASS}
              onError={(ev) => {
                const imageElement = ev.target as HTMLImageElement;
                const errcount = parseInt(imageElement.getAttribute('errcount') || '');
                if (!isNaN(errcount) || imageElement.getAttribute('src')?.endsWith(imageErrorOrganization)) {
                  imageElement.onerror = null;
                  imageElement.setAttribute('src', '');
                  imageElement.style.visibility = 'hidden';
                  imageElement.ariaHidden = 'true';
                  return;
                }
                imageElement.setAttribute('errcount', '1');
                imageElement.setAttribute('src', imageErrorOrganization);
                return;
              }}
              alt={`Image for: ${name}`}
            />
          ) : (
            <img
              src={imageErrorOrganization}
              width="40"
              className={ICON_CLASS}
              alt={`Image for: ${name}`}
              onError={(ev) => {
                const imageElement = ev.target as HTMLImageElement;
                imageElement.onerror = null;
                imageElement.setAttribute('src', '');
                imageElement.style.visibility = 'hidden';
                imageElement.ariaHidden = 'true';
                return;
              }}
            />
          )
        }
        actionables={<div className="card__icon">{getIcon('baselineArrowRight', '')}</div>}
      />
    </animated.div>
  );
};

export default OrganizationListItem;
