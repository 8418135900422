import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { FlightModal, FlightButton } from '@flybits/design-system';

const Get = () => {
  const [redirect, setRedirect] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const initiateValidation = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      try {
        if (queryParams.has('hash')) {
          const hash = queryParams.get('hash');
          if (hash) {
            const decodedHash = window.atob(hash);
            const decodedJson = JSON.parse(decodedHash);
            const token = decodedJson['x-authorization'];
            if (decodedJson && token) {
              setRedirect(`authentication/token/validate?hash=${hash}`);
            } else {
              setRedirect('signIn');
            }
          }
        }
      } catch (error) {
        setErrorMessage('could not decode hash');
        setError(true);
      }
    };
    initiateValidation();
  }, []);

  const toggleModal = () => {
    setError(false);
    setRedirect('authentication/token/clear');
  };

  return (
    <div>
      <FlightModal
        isVisible={error}
        toggleModalShown={toggleModal}
        scrollable={false}
        size="small"
        warning={false}
        className="custom-modal-class"
        header={<span>Authentication Failed</span>}
        content={
          <span role="alert" aria-atomic>
            <b>Error:</b> {errorMessage}
          </span>
        }
        footer={
          <div className="modal-footer">
            <FlightButton type="primary" label="Back to sign in" onClick={toggleModal} />
          </div>
        }
      />
      <div>{redirect.length > 0 && <Redirect to={'/' + redirect} />}</div>
    </div>
  );
};

export default Get;
