import BaseService from './base.service';

export default class ConnectorService extends BaseService {
  CURRENT_PATH: string;
  BASE_PATH: string;
  constructor() {
    super();
    this.BASE_PATH = '/organizations/';
    this.CURRENT_PATH = '/connectors';
  }

  async updateImage(organizationId: string, file: any, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    const form = new FormData();
    if (file) form.append('image', file);
    return this.request('POST', this.BASE_PATH + version + organizationId + '/imageUpload', form);
  }

  async getConnectors(organizationId: string, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request('GET', this.BASE_PATH + version + organizationId + this.CURRENT_PATH);
  }

  async getConnectorById(organizationId: string, connectorId: string, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request('GET', this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + connectorId);
  }

  async getConnectorSharedOrganizations(organizationId: string, connectorId: string, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request(
      'GET',
      this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + connectorId + '/sharing',
    );
  }

  async shareConnector(organizationId: string, connectorId: string, data: any, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request(
      'POST',
      this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + connectorId + '/sharing',
      data,
    );
  }

  async createConnector(organizationId: string, data: any, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request('POST', this.BASE_PATH + version + organizationId + this.CURRENT_PATH, data);
  }

  async updateConnectorData(organizationId: string, connectorId: string, data: any, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request(
      'PATCH',
      this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + connectorId,
      data,
    );
  }
  async updateConnectorStatus(organizationId: string, connectorId: string, status: string, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    // POSSIBLE STATUSES
    // activate
    // deactivate
    // delete
    // cancelDelete
    // approve_sharing
    // approve_3rd_party
    return this.request(
      'POST',
      this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + connectorId + '/' + status,
    );
  }

  async deleteConnector(organizationId: string, connectorId: string, version = '') {
    if (version && !version.endsWith('/')) version += '/';
    return this.request('DELETE', this.BASE_PATH + version + organizationId + this.CURRENT_PATH + '/' + connectorId);
  }
  // Share code
  async getShareCode(organizationId: string) {
    return this.request('GET', this.BASE_PATH + organizationId + '/pseudonym');
  }

  async generateShareCode(organizationId: string) {
    return this.request('POST', this.BASE_PATH + organizationId + '/pseudonym');
  }
}
