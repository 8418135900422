import React, { FunctionComponent, useEffect } from 'react';
import { FlightButton } from '@flybits/design-system';
import './PrivacyPolicy.scss';
import { useHistory } from 'react-router-dom';

interface Props {
  location: any;
}
// Translation
import { useTranslation } from 'react-i18next';
import SvgFlybitsLogo from 'src/assets/FlybitsLogo';

const PrivacyPolicy: FunctionComponent<Props> = () => {
  const MAIN_CLASS = 'static';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  const { t } = useTranslation(['translation']);
  const history = useHistory();
  useEffect(() => {
    // Set dynamic document title
    document.title = 'Privacy Policy - Control Tower';
    history.listen(() => {
      // check for sw updates on page change
      navigator.serviceWorker.getRegistrations().then((regs) => regs.forEach((reg) => reg.update()));
    });
  }, []);

  return (
    <div className={MAIN_CLASS}>
      <div className={CONTENT_CLASS}>
        <div className={`${CONTENT_CLASS}__wrapper`}>
          <h1 className={`${CONTENT_CLASS}__heading-text`}>
            <FlightButton
              type="primary"
              className={`${CONTENT_CLASS}__button_link ${CONTENT_CLASS}__button_link-back`}
              theme="link"
              size="medium"
              label={`< ${t('translation:general.buttons.back', 'Back')}`}
              onClick={() => {
                history.goBack();
              }}
            />
            Privacy Policy
          </h1>
          <article className={`${CONTENT_CLASS}__infos-wrapper`}>
            <p>
              <SvgFlybitsLogo tabIndex={-1} role="logo" viewBox="0 0 104 30" {...{ className: '' }} />
            </p>
            <p>
              Effective date: Jun 27th, 2022
              <br />
              Version: 3.1
            </p>
            <h2>1. Introduction</h2>
            <p>
              In this policy, we explain how Flybits (Flybits Inc.) and its subsidiaries handle any personal data that
              we obtain through our website{' '}
              <a
                href={'https://www.flybits.com'}
                target="_blank"
                rel="noopener noreferrer"
                title="This link opens in a new tab"
              >
                https://www.flybits.com
              </a>{' '}
              or when you contact us by mail, phone or other electronic means. We will follow the law when you share
              personal data with us.
            </p>
            <p>
              When you continue to browse our website, such as reading the content, clicking on a link or contact us by
              mail or phone, you consent to us using your personal data in the way that we describe here. This also
              includes our use of cookies as explained below.
            </p>
            <p>
              <em>
                Flybits offers a platform used by businesses to create personalized and relevant digital experiences for
                their consumers
              </em>
              .
            </p>
            <h2>2. Information you provide to us</h2>
            <p>
              <b>We collect the following information when you use our websites or contact us:</b>
            </p>
            <ul>
              <li>
                When you browse the website or contact us with commercial inquiries: we collect general analytics data
                and any contact and other information you provide to us.
              </li>
              <li>
                When you contact us by mail, phone or through the contact form or chat on our websites: we collect your
                name, email address or phone number, and any other information you may provide to us.
              </li>
              <li>When you contact us about a job opening: we collect any relevant information you provide to us.</li>
            </ul>
            <p>
              <b>We use this information:</b>
            </p>
            For our own marketing communication correspondence, including to follow up on leads. To reply to your
            information request when you contacted us with a question. To keep you in our recruitment database if you
            contacted us about job openings. To be able to provide our service to our customers, for onboarding, support
            and billing.
            <p>
              <b>We store:</b>
            </p>
            General contact information related to inquiries for up to two (2) years. Recruitment information for up to
            five (5) years. Marketing information for up to two (2) years. Billing and other information for up to ten
            (10) years, to meet tax law requirements.
            <h2>3. Information we obtain about you</h2>
            <p>
              Sometimes we obtain your personal data from other parties for general marketing purposes, such as
              companies that provide us with an overview of publicly available information on people that have submitted
              a job application. We will use this information in the same way as the information that you provided to
              us.
            </p>
            <p>We also use HubSpot, Google Analytics, Facebook and Hotjar in the way described below.</p>
            <h2>4. Legal Basis</h2>
            <p>
              We process data from employees of customers on the basis of our and our customer’s legitimate interest to
              deliver our services in a regular manner.
            </p>
            <p>
              We process data from visitors of our website and people that contact us, including for job openings, on
              the basis of our legitimate interest to provide information about our business.
            </p>
            <h2>5. Sharing information</h2>
            <p>
              We only share your personal data to outside service providers that we use for storing and processing your
              data. This includes outside service providers that we use for storing and processing your data, for
              example:
            </p>
            <ul>
              <li>Companies that provide chat and other support functionality.</li>
              <li>Companies that support our recruitment activities.</li>
              <li>Companies that support us in sending emails and other messages related to our marketing.</li>
            </ul>
            <p>
              We require these companies to adequately safeguard your personal data and not use your data for any other
              purpose not authorized by us.{' '}
            </p>
            <p>
              In the event that (part of) our business is sold to another company, transfer of ownership could include
              the transfer of your personal data to the buyer if the data directly relates to that (part of the)
              business.
            </p>
            <p>
              We will reveal personal data when we have reason to believe that the disclosure of this information is
              required to establish the identity of, to contact or to initiate legal proceedings against a person or
              persons who are suspected of infringing rights or property belonging to Flybits or to others who could be
              harmed by the user’s activities or of persons who could (deliberately or otherwise) transgress upon these
              rights and property.
            </p>
            <p>
              Flybits also shall comply with any government or law enforcement entity investigation that provides proper
              law enforcement warrants and subpoenas.
            </p>
            <p>
              We will not sell or provide your personal data to other third parties allowing them to use your personal
              data for their own purposes.
            </p>
            <p>
              Flybits also shall comply with any government or law enforcement entity investigation that provides proper
              law enforcement warrants and subpoenas.
            </p>
            <h2>6. Transfer of your Personal Data</h2>
            <p>
              You should also be aware that your personal data may be transferred from your home country to other
              Flybits entities around the world or to our service providers. If you are an EU resident, this could imply
              that we also transfer data to countries or organizations that do not offer a so-called adequate level of
              protection for your personal data. When we transfer this data, we will do so only when we have put in
              place appropriate safeguards to protect your personal data. Please contact us to obtain a copy of the
              safeguards that we have put in place. Our contact details can be found at the bottom.
            </p>
            <h2>7. Security</h2>
            <p>
              We take all reasonably necessary steps to protect all information we hold from misuse, loss, unauthorized
              access, modification or disclosure. All information is kept secure, encrypted at rest and in transit where
              possible and protected using industry standard measures. No method of transmission over the Internet or
              method of electronic storage (including via 3rd parties) is 100% secure; therefore, while we strive to use
              commercially acceptable means to protect your information, we cannot guarantee absolute security.
            </p>
            <h2>8. Information relating to children</h2>
            <p>
              Our website and service is aimed at and designed for those 18 years of age and older. We do not knowingly
              collect personal data from anyone under the age of 16. If the company is made aware that it has received
              personal data from someone under 16, it will use reasonable efforts to remove that information from its
              records.
            </p>
            <h2>9. Data integrity, purpose limitation</h2>
            <p>
              We process information in a way that is compatible with and relevant for the purpose for which it was
              collected as described above. To the extent necessary for those purposes, we take reasonable steps to
              ensure that any information in our care is accurate, complete, current and reliable for its intended use.
            </p>
            <h2>10. Cookies Policy</h2>
            <h3>10.1 Introduction</h3>
            <p>
              The Flybits website and online services use cookies to customize the online experience. This is done
              through small amounts of information being stored in regards to preferences, browsing behaviour and
              analytical data to ultimately provide a better service for our users. The Flybits website will ask users
              to consent to the use of non-strictly necessary cookies when one first visits the website.
            </p>
            <h3>10.2 About Cookies</h3>
            <p>
              Cookies are files made up of letters and numbers stored within the user’s browser in order to retain
              certain data about said user. The use of cookies allows Flybits to provide a website which is tailored to
              individual users.
            </p>
            <p>
              Cookies can either be persistent or session cookies. A persistent cookie will be stored by a web browser
              and remains valid until the user deletes it, or it reaches its expiry date. A session cookie will expire
              at the end of the user session, upon the web browser being closed.
            </p>
            <p>
              Cookies do not typically contain any information that personally identifies a user, but personal
              information that is stored about the user may be linked to the information stored in and obtained from
              cookies.
            </p>
            <h3>10.3 Cookies Flybits Uses</h3>
            <p>Flybits could generate the following cookies:</p>
            <ul>
              <li>
                Anonymous analytics cookies: We use these to analyze the use and performance of our website and
                services.
              </li>
              <li>Geo-targeting cookies: This allows us to customize the content you see based on your location.</li>
              <li>
                Registration cookies: These allow us to recognize users if they have previously filled out one of our
                forms, or signed in.
              </li>
              <li>
                Third party advertising network cookies: We may use these to show ad banners including future updates or
                notifications
              </li>
            </ul>
            <p>
              The main sources of cookies we use originate from Google Analytics, HubSpot, YouTube, LinkedIn (Insight
              Tag) and WordPress.
            </p>
            <h3>10.4 Disabling Cookies</h3>
            <p>
              Cookies allow for a more personalized user experience while on the Flybits website, however they are not
              necessary and can be disabled even after having accepted them. Acceptance can be revoked from the privacy
              section within the user’s browser preferences.
            </p>
            <h3>10.5 More Information</h3>
            <p>
              To view all cookies currently being used on our site, please refer to Cookie settings in the Manage
              consent tab on our website.
            </p>
            <h2>11. Your rights</h2>
            <p>
              You have the right to request from us access to your personal data that we process. You also have the
              right to:
            </p>
            <ul>
              <li>Rectify incorrect personal data or erase it in certain circumstances.</li>
              <li>Restrict or object to the processing of your personal data.</li>
              <li>Receive your data so that you can use it elsewhere (data portability).</li>
            </ul>
            <p>
              You also have the right to withdraw your consent at any time, where our processing is based on your
              consent. Please be aware that a withdrawal of your consent does not affect the lawfulness of the
              processing of your data before the date on which you withdrew your consent.
            </p>
            <p>
              Finally, you have the right to lodge a complaint with a supervisory authority. If you do not know who your
              supervisory authority is, please contact us and we will tell you.
            </p>
            <h2>12. Flybits Data Protection Officer</h2>
            <p>
              Flybits has appointed a Data Protection Officer who is responsible for matters relating to privacy and
              data protection. This Data Protection Officer can be reached at the following address, including with any
              questions you may have about the processing of your personal data or the use of cookies by us, or to
              exercise the above rights, at:
            </p>
            <address>
              Flybits Inc
              <br /> Attn: Data Protection Officer
              <br /> North, 175 Bloor St E Tower, 14th Floor,
              <br /> Toronto, ON M4W 3R8
              <p>
                Email for{' '}
                <a
                  href={'mailto:dataprotectionofficer@flybits.com'}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="This link opens your email software"
                >
                  dataprotectionofficer@flybits.com
                </a>
              </p>
            </address>
            <h2>13. Version information</h2>
            <p>Version 3.1 – Jun 27th, 2022</p>
            <p>Cookies were last updated on Jun 27th, 2022</p>
            <p>
              This information can be viewed on{' '}
              <a
                href={'https://www.flybits.com/legal-2/corporate-privacy-notice/'}
                target="_blank"
                rel="noopener noreferrer"
                title="This link opens in a new tab"
              >
                https://www.flybits.com/legal-2/corporate-privacy-notice/
              </a>
            </p>
            <p>
              If you have any further questions regarding the data Flybits collects, or how we use it, then please feel
              free to contact us by email at:{' '}
              <a
                href={'mailto:legal@flybits.com'}
                target="_blank"
                rel="noopener noreferrer"
                title="This link opens your email software"
              >
                legal@flybits.com
              </a>
              .
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
