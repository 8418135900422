import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import OrganizationListItem from './OrganizationListItem';
import { animated } from 'react-spring';
import { Trail } from 'react-spring/renderprops';
import { getSortedRankArrayByName } from 'src/helpers/ranking.helper';
import { Organization } from 'src/model/organizations/organizations';
import './OrganizationList.scss';

interface Props {
  list: Array<Organization>;
}

const OrganizationList: FunctionComponent<Props> = (props: Props) => {
  const [ranking, setRanking] = useState<string[]>([]);
  const trailRef = useRef();
  const { list } = props;
  const DEFAULT_CLASS = 'organizationList';
  const WRAPPER_CLASS = `${DEFAULT_CLASS}__wrapper`;

  useEffect(() => {
    const getRanking = async () => {
      const ranking = await getSortedRankArrayByName('organizations');
      setRanking(ranking);
      if (trailRef.current) (trailRef.current as any).start();
    };

    getRanking();
  }, []);

  const AnimatedOrganizationListItem = animated(OrganizationListItem);

  return (
    <div className={WRAPPER_CLASS}>
      <Trail
        items={list.sort((a, b) => ranking.indexOf(b.id) - ranking.indexOf(a.id))}
        ref={trailRef.current}
        config={{ duration: list.length * 200 > 1800 ? 1000 : list.length * 160 }}
        keys={(item) => item.id}
        from={{ opacity: 0, transform: 'translate3d(0,-10%,0)' }}
        to={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
      >
        {(item) =>
          item &&
          ((props) => (
            <AnimatedOrganizationListItem
              key={item.id}
              id={item.id}
              name={item.name}
              imageUrl={item.imageUrl}
              externalUrl={item.externalUrl}
              verified={item.verified}
              style={props}
            />
          ))
        }
      </Trail>
    </div>
  );
};

export default OrganizationList;
