import React from 'react';
import './PreviewFormEditor.scss';
import { ReactComponent as PreviewErrorImage } from 'src/assets/error-generic.svg';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
interface Props {
  schema: RJSFSchema;
  uiSchema: UiSchema;
  formData: any;
  connectorInfo: any;
  t: any;
  onChange: (e: any) => void;
  onError?: (e: any) => void;
}

const PreviewFormEditor: React.FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'PreviewForm';
  const { schema, uiSchema, formData, onChange, onError, t } = props;
  return (
    <div className={MAIN_CLASS}>
      <>
        {schema && Object.keys(schema).length > 0 ? (
          <Form
            schema={schema as RJSFSchema}
            uiSchema={{
              ...uiSchema,
              'ui:submitButtonOptions': {
                props: {
                  disabled: false,
                },
                norender: true,
              },
            }}
            validator={validator}
            formData={formData}
            liveValidate
            showErrorList={false}
            onChange={onChange}
            onError={onError}
          />
        ) : (
          <div className="Preview-error">
            <PreviewErrorImage />
            <p>
              {t(
                'integrations:connectors.details.sections.config-template.preview.nothing_to_preview',
                'Nothing to preview, please add a valid schema in the',
              )}{' '}
              {t(
                'integrations:connectors.details.sections.config-template.preview.nothing_to_preview_btn',
                'JSON editor',
              )}
            </p>
          </div>
        )}
      </>
    </div>
  );
};

export default PreviewFormEditor;
