import React, { useEffect, useState } from 'react';
import { FlightButton, FlightTextArea, FlightTextInput, FlightSelect, FlightRadioButton } from '@flybits/design-system';
import { Formik, Field, FieldArray } from 'formik';
import { PluginAttribute } from 'src/model/connectors/connectors';
import './PluginAttributesPanel.scss';
import {
  ATTR_NAME_INVALID,
  ATTR_NAME_REQUIRED,
  ATTR_BEGIN_LETTER_NUMBER,
  ATTR_UNIQUE,
  ATTR_PARAM_INVALID,
  ATTR_PARAM_EMPTY,
} from 'src/constants/errors';
import { beginsWithNumberOrLetter, containsNoSpecialCharacters, paramNameRegex } from 'src/helpers/auth.helper';
import * as Yup from 'yup';
import { getDataExpiryText, getDefaultExpiry } from 'src/helpers/timeConverters';
import { AdditionalAttribute } from 'src/model/datasources/datasources';
interface Props {
  attributeValue: PluginAttribute | AdditionalAttribute | null;
  pluginAttributes: PluginAttribute[] | AdditionalAttribute[] | null;
  isNewAttribute: boolean;
  isReadOnly?: boolean;
  originalAttributes?: any | null;
  onSave: (arg0: any, arg1?: any) => void;
  onCancel: () => void;
  t: any;
}
interface SelectOptionProps {
  key: string;
  name: string;
}
// defaults
const defaultExpiryCond = { key: 'expiresEvery', name: 'Data expires every' };
const defaultExpiryDuration = { key: 24, name: 24 };
const defaultExpiryUnit = { key: 'hours', name: 'Hours' };
const contextAttributeDataExpiryOptions = [
  {
    key: 'expiresEvery',
    name: 'Data expires every',
  },
  {
    key: 'neverExpires',
    name: 'Never expires',
  },
];

const contextExpiryUnitOptions = [
  {
    key: 'minutes',
    name: 'Minutes',
  },
  {
    key: 'hours',
    name: 'Hours',
  },
  {
    key: 'days',
    name: 'Days',
  },
  {
    key: 'weeks',
    name: 'Weeks',
  },
  {
    key: 'months',
    name: 'Months',
  },
  {
    key: 'years',
    name: 'Years',
  },
];

const contextDataFormatOptions = [
  {
    key: 'string',
    name: 'String',
  },
  {
    key: 'int',
    name: 'Integer',
  },
  {
    key: 'bool',
    name: 'Yes/No',
  },
  {
    key: 'float',
    name: 'Float',
  },
  {
    key: 'dateTime',
    name: 'Date Time',
  },
];

const PluginAttributesPanel = (props: Props) => {
  const { isReadOnly, attributeValue, pluginAttributes, originalAttributes, isNewAttribute, onSave, onCancel, t } =
    props;

  const initialAttribute = attributeValue || {
    name: '', //will be the attribute name in the plugin
    dataType: '',
    scope: '', //user or tenant
    parameters: [],
    defaultExpDuration: -1,
    description: '', //display when creating the datasource
  };
  const [dataExpiryCond, setDataExpiryCond] = useState(defaultExpiryCond);
  const [dataExpiryDuration, setDataExpiryDuration] = useState(defaultExpiryDuration);
  const [dataExpiryUnit, setDataExpiryUnit] = useState<SelectOptionProps>(defaultExpiryUnit);
  const isUniqueAttributeName = (value: any) => {
    // check if it is the same attribute value
    return !pluginAttributes?.some((attr) => {
      if (attr.name.toLocaleLowerCase() === value?.toLocaleLowerCase()) {
        // not unique, check if it's because we're editing the same prop
        if (originalAttributes?.name === value) {
          // it was the same prop
          return false;
        }
        return true;
      }
      return false;
    });
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('errors:ATTR_NAME_REQUIRED', ATTR_NAME_REQUIRED))
      .matches(beginsWithNumberOrLetter, t('errors:ATTR_BEGIN_LETTER_NUMBER', ATTR_BEGIN_LETTER_NUMBER))
      .matches(containsNoSpecialCharacters, t('errors:ATTR_NAME_INVALID', ATTR_NAME_INVALID))
      .test('is-unique-attr', t('errors:ATTR_UNIQUE', ATTR_UNIQUE), (value) => isUniqueAttributeName(value)),
    parameters: Yup.array().of(
      Yup.string()
        .required(t('errors:ATTR_PARAM_EMPTY', ATTR_PARAM_EMPTY))
        .matches(paramNameRegex, t('errors:ATTR_PARAM_INVALID', ATTR_PARAM_INVALID)),
    ),
  });
  const MAIN_CLASS = 'PluginAttributesPanel';
  useEffect(() => {
    if (attributeValue) {
      //set expiry and condition for exp
      const _expiry = getDataExpiryText(Number(attributeValue?.defaultExpDuration));
      const _expiryVal = getDataExpiryText(Number(attributeValue?.defaultExpDuration)).value;
      if (typeof _expiryVal === 'string') {
        setDataExpiryCond({ key: 'neverExpires', name: 'Never expires' });
      } else if (typeof _expiryVal === 'number') {
        setDataExpiryCond(defaultExpiryCond);
        setDataExpiryDuration({ key: _expiryVal, name: _expiryVal });
        setDataExpiryUnit({ key: _expiry.unit, name: _expiry.unit });
      }
    }
  }, [attributeValue]);
  return (
    <>
      <Formik
        initialValues={initialAttribute}
        validationSchema={validationSchema}
        validateOnChange
        enableReinitialize
        onSubmit={(values) => {
          onSave({
            ...values,
            defaultExpDuration:
              dataExpiryCond.key === 'neverExpires'
                ? -1
                : getDefaultExpiry(dataExpiryUnit.key) * dataExpiryDuration.key,
          });
        }}
      >
        {({ values, touched, errors, setFieldValue, handleChange, handleSubmit }) => (
          <form aria-live="assertive" className={MAIN_CLASS}>
            <h2>{isNewAttribute ? 'Create' : isReadOnly ? 'View' : 'Edit'} attribute</h2>

            <div className={`${MAIN_CLASS}__wrapper`}>
              <div className={`${MAIN_CLASS}__input`}>
                <Field
                  type="text"
                  name="name"
                  width="95%"
                  as={FlightTextInput}
                  hasError={touched.name && errors.name ? true : false}
                  label={'Attribute name *'}
                  required
                  disabled={isReadOnly}
                  value={values.name}
                  errorMessage={
                    <span role="alert" aria-atomic>
                      <b>Error:</b> {errors.name}
                    </span>
                  }
                  onChange={handleChange}
                />
              </div>
              <div className={`${MAIN_CLASS}__input`}>
                <Field
                  name={`description`}
                  maxLength={250}
                  width="95%"
                  label={'Description'}
                  className={`${MAIN_CLASS}__textarea`}
                  value={values.description}
                  as={FlightTextArea}
                  hasLabelAnimation
                  isLabelAlwaysOn
                  disabled={isReadOnly}
                  hasError={errors.description && touched.description ? true : false}
                  errorMessage={errors.description || ''}
                  onChange={handleChange}
                />
              </div>
              <div className={`${MAIN_CLASS}__input ${MAIN_CLASS}__input-group`}>
                <div>
                  <Field
                    as={FlightSelect}
                    type="select"
                    name="dataType"
                    label={'Data format'}
                    options={contextDataFormatOptions}
                    selected={contextDataFormatOptions.find((attr) => attr.key === values.dataType)}
                    hasLabelAnimation
                    dropdownMaxHeight="150px"
                    disabled={isReadOnly}
                    handleOptionClick={async (value: SelectOptionProps) => {
                      setFieldValue('dataType', value.key, false);
                    }}
                    width="100%"
                  />
                </div>
                <div className="radio-buttons">
                  <label htmlFor="radio">Scope</label>
                  <div>
                    <Field
                      type="radio"
                      name="scope"
                      as={FlightRadioButton}
                      label="User"
                      value="user"
                      checked={values.scope === 'user'}
                      disabled={isReadOnly}
                      onSelect={() => {
                        setFieldValue('scope', 'user', false);
                      }}
                    />
                    <Field
                      type="radio"
                      name="scope"
                      as={FlightRadioButton}
                      label="Tenant"
                      value="tenant"
                      disabled={isReadOnly}
                      checked={values.scope === 'tenant'}
                      onSelect={() => {
                        setFieldValue('scope', 'tenant', false);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={`${MAIN_CLASS}__input ${MAIN_CLASS}__input-expiry`}>
                <FlightSelect
                  label="Data expires every"
                  selected={dataExpiryCond}
                  handleOptionClick={setDataExpiryCond}
                  width={'50%'}
                  disabled={isReadOnly}
                  options={contextAttributeDataExpiryOptions}
                />
                {dataExpiryCond.key === 'expiresEvery' && (
                  <>
                    <FlightSelect
                      label="24"
                      selected={dataExpiryDuration}
                      disabled={isReadOnly}
                      handleOptionClick={setDataExpiryDuration}
                      options={Array.from({ length: 100 }, (_, k) => k + 1)?.map((item) => ({
                        key: item.toString(),
                        name: item.toString(),
                      }))}
                      width={'20%'}
                    />

                    <FlightSelect
                      selected={dataExpiryUnit}
                      disabled={isReadOnly}
                      handleOptionClick={setDataExpiryUnit}
                      options={contextExpiryUnitOptions}
                      width={'25%'}
                    />
                  </>
                )}
              </div>
              {/* Parameters */}
              {!isReadOnly && (
                <div className={`${MAIN_CLASS}__parameters-btn`}>
                  <FlightButton
                    theme="link"
                    size="medium"
                    iconLeft={'add'}
                    label={`Add parameter`}
                    className={`${MAIN_CLASS}__input-add`}
                    onClick={() => setFieldValue(`parameters.${values.parameters?.length || 0}`, '', true)}
                  />
                </div>
              )}
              {values.parameters && values.parameters.length > 0 && <h3>Parameters:</h3>}
              <div className={`${MAIN_CLASS}__parameters-items`}>
                <FieldArray
                  name="parameters"
                  render={(arrayHelpers) => (
                    <>
                      {values.parameters?.map((parameter, i) => {
                        const parametersErrors: any = (errors.parameters?.length && errors.parameters[i]) || '';
                        return (
                          <div key={i}>
                            <Field
                              type="text"
                              name={`parameters.${i}`}
                              width="85%"
                              label={'Key'}
                              ariaLabel={'Parameter ' + (i + 1)}
                              isLabelAlwaysOn
                              isAriaRequired
                              as={FlightTextInput}
                              hasError={parametersErrors ? true : false}
                              errorMessage={
                                <span role="alert" aria-atomic>
                                  <b>Error:</b> {parametersErrors || ''}
                                </span>
                              }
                              onChange={handleChange}
                              disabled={isReadOnly}
                            />
                            {!isReadOnly && (
                              <FlightButton
                                type="button"
                                theme="minor"
                                size="medium"
                                ariaLabel={'Remove parameter ' + (i + 1)}
                                iconLeft="trashCan"
                                label=""
                                onClick={() => arrayHelpers.remove(i)}
                              />
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}
                />
              </div>
              {!isReadOnly && (
                <div className={`${MAIN_CLASS}__input ${MAIN_CLASS}__input-footer`}>
                  <FlightButton
                    type="primary"
                    size="medium"
                    label={`${isNewAttribute ? 'Create' : 'Update'}`}
                    disabled={!!errors.name || !!errors.parameters}
                    onClick={handleSubmit}
                  />
                  <FlightButton
                    theme="secondary"
                    size="medium"
                    label={t('translation:general.buttons.cancel', 'cancel')}
                    onClick={onCancel}
                  />
                </div>
              )}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
export default PluginAttributesPanel;
