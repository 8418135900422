import React, { useEffect, useRef, useState } from 'react';
import { FlightModal, FlightButton, FlightTextInput, getIcon, FlightTag, FlightCheckbox } from '@flybits/design-system';
import './ProjectDetails.scss';
import ProjectUsersAndTeamsList from 'src/components/ProjectUsersAndTeamsList/ProjectUsersAndTeamsList';
import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import useNotification from 'src/hooks/useNotification';
// Services
import ProjectService from 'src/services/project.service';
// Icons
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
// Helpers
import {
  GENERIC_INTERNAL_ERROR,
  PROJECT_NAME_INVALID,
  PROJECT_NAME_REQUIRED,
  PROJECT_NAME_TWO_CHARS_REQUIRED,
} from 'src/constants/errors';
import { getSubTeams } from 'src/helpers/teams.helper';
import { EVENT_KEYS } from 'src/constants/general';
import {
  deleteStoredProjectId,
  getStoredOrganizationId,
  getStoredProjectId,
  getUserRoles,
  projectNameRegex,
  setStoredOrganizationId,
  setStoredProjectId,
} from 'src/helpers/auth.helper';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import useDebounce from 'src/hooks/useDebounce';
import Jdenticon from 'src/components/Jdenticon';
import { EXP_STUDIO_1_ENABLED, EXP_STUDIO_2_ENABLED, EXP_STUDIO_2_OVERRIDES } from 'src/constants/featureFlags';
import { strip_tags } from 'src/helpers/general.helper';
import useRoleAccess from 'src/hooks/useRoleAccess';
import { generateAuthCode, getValidAccessToken } from 'src/pkce-oidc/services';
import { useTranslation } from 'react-i18next';

interface Props {
  location?: any;
  match?: any;
}

const ProjectDetails = (props: Props) => {
  const MAIN_CLASS = 'project_details';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;
  const LOADING_PROJECT_CLASS = `${MAIN_CLASS}__loading`;
  const { t } = useTranslation(['translation', 'errors']);

  const { featureFlags, organization, organizationFlags, setControlTowerProjectFlags } = useControlTowerContext();
  const { match } = props;
  if (match.params.id) setStoredOrganizationId(match.params.id);
  const orgID = match.params.id || organization?.id || getStoredOrganizationId();
  const projectId = match.params.project || getStoredProjectId();

  const { addNotification, addNotificationError } = useNotification();

  const [deploymentEnv, setDeploymentEnv] = useState('');
  const [hideESAndDP, setHideESAndDP] = useState(false);
  const [isExpStudio1Enabled, setIsExpStudio1Enabled] = useState(true);
  const [isExpStudio2Enabled, setIsExpStudio2Enabled] = useState(false);
  const [currentFlags, setCurrentFlags] = React.useState(organizationFlags);

  const editIcon = getIcon('pencil', { width: 16, height: 16, fill: '#0851DF' });
  const checkIcon = getIcon('checkCircle', { width: 16, height: 16, fill: '#00ab50' });
  const errorIcon = getIcon('error', { width: 16, height: 16, fill: '#dc3616' });

  const history = useHistory();

  if (!orgID) {
    history.push('/organizations');
  }
  setStoredOrganizationId(orgID);
  if (orgID && !projectId) {
    history.push('/organization/' + orgID);
  }
  interface FormValues {
    name: string;
    active: boolean;
  }

  interface ModalData {
    values: FormValues;
    callback: () => void;
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('errors:PROJECT_NAME_REQUIRED', PROJECT_NAME_REQUIRED))
      .trim()
      .min(2, t('errors:PROJECT_NAME_TWO_CHARS_REQUIRED', PROJECT_NAME_TWO_CHARS_REQUIRED))
      .matches(projectNameRegex, t('errors:PROJECT_NAME_INVALID', PROJECT_NAME_INVALID)),
  });

  const [valuesToSave, setValuesToSave] = useState({});
  const [previousValues, setPreviousValues] = useState<any>({});
  const [isSaved, setIsSaved] = useState(false);
  const [currentProjectID, setCurrentProjectID] = useState<any>('');
  const [deactivateModalData, setDeactivateModalData] = useState<ModalData | null>();
  const [error, setError] = useState(false);
  const [projectDetails, setProjectDetails] = useState({
    id: '',
    deployment: '',
    name: '',
    tier: '',
    active: false,
    endpoint: '',
    organization: '',
    region: '',
    subdomain: '',
  });
  const [projectUsersAndTeams, setProjecUsersAndTeams] = useState<any>({ users: [], teams: [] });
  const [usersAndTeamsLoading, setUsersAndTeamsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [deleting, setDeleting] = useState(false);
  const [tryDeleteProject, setTryDeleteProject] = useState(false);
  const [tryDeactivate, setTryDeactivate] = useState(false);
  const [errorMessageDel, setErrorMessageDel] = useState('');
  const [isValidatingTokenForES1, setIsValidatingTokenForES1] = useState(false);
  const [isValidatingTokenForES2, setIsValidatingTokenForES2] = useState(false);

  const initialValues = {
    name: projectDetails.name || '',
    active: projectDetails.active || true,
  };
  const initialValuesDelete = {
    confirmId: '',
  };

  const validationSchemaDelete = Yup.object().shape({
    confirmId: Yup.string()
      .required(t('errors:PROJECT_EMPTY_ID', 'Project ID should not be empty'))
      .matches(currentProjectID, t('errors:INVALID_PROJECT_ID', 'Invalid Project ID')),
  });

  const autoSaveHandler = async (values: FormValues) => {
    resetErrors();
    // Check if the new value is valid
    if (!values.name || strip_tags(values.name) === '') {
      setError(true);
      return;
    }
    if (
      values.name.trim().toLowerCase() === projectDetails.name.trim().toLowerCase() &&
      values.active === previousValues.active
    ) {
      return;
    }
    const projectRequestObject = {
      name: strip_tags(values.name),
      active: values.active,
    };
    const projectServiceManager = new ProjectService();
    try {
      const projectResponseObject = await projectServiceManager.updateProject(orgID, projectId, projectRequestObject);
      if (projectResponseObject && [200, 201, 202].includes(projectResponseObject.status)) {
        //handles HTTP status codes 200/201/202
        setProjectDetails({ ...projectResponseObject.data, active: projectResponseObject.data.update.active });
        addNotification(
          t('translation:pages.projects.details.messages.update_success', 'Project updated successfully'),
        );
        setError(false);
        return true;
      }
    } catch (e) {
      console.error(e);
      addNotificationError(
        e?.response?.data?.message +
          t('translation:pages.projects.details.messages.update_error', ' Please try again later'),
      );
      if (!error) setError(true);
      setProjectDetails({ ...projectDetails, name: previousValues?.name, active: previousValues?.active });
      return false;
    }
    setError(false);
    return true;
  };
  const autoSave = useDebounce(valuesToSave, 500);
  useEffect(() => {
    // Make sure we have a value (user has entered something in input)
    if (autoSave) {
      // Set isSaved state
      setIsSaved(false);
      // Fire off our API call
      autoSaveHandler(autoSave).then((res) => {
        // Set back to false since request finished
        if (res) setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
        }, 3000);
      });
    }
    // eslint-disable-next-line
  }, [autoSave]);

  const resetErrors = () => {
    setError(false);
    setErrorMessage('');
    setErrorMessageDel('');
  };

  const goToExperienceStudio = async () => {
    const { active, subdomain, id } = projectDetails;
    if (!active) return;
    let host = `${subdomain}.${deploymentEnv}.flybits.com`;
    if (deploymentEnv == 'td') {
      host = `${subdomain}.flybits.com`;
    }
    setIsValidatingTokenForES1(true);
    const validAccessToken = await getValidAccessToken(id, deploymentEnv);
    setIsValidatingTokenForES1(false);

    if (validAccessToken) {
      let es1Url = `https://${host}/#/authenticate?sourceRouteName=experiences&token=${validAccessToken}`;
      if (subdomain == 'bff-test') {
        es1Url = `https://${host}/es1/#/authenticate?sourceRouteName=experiences&token=${validAccessToken}`;
      }
      window.open(es1Url, '_blank');
    } else {
      let es1Url = `https://${host}/#/authenticate?sourceRouteName=experiences`;
      if (subdomain == 'bff-test') {
        es1Url = `https://${host}/es1/#/authenticate?sourceRouteName=experiences`;
      }

      generateAuthCode(id, host, es1Url, deploymentEnv);
    }
  };

  const goToExperienceStudio2 = async () => {
    const { active, subdomain, id } = projectDetails;

    if (!active) return;

    setIsValidatingTokenForES2(true);
    const validAccessToken = await getValidAccessToken(id, deploymentEnv);
    setIsValidatingTokenForES2(false);

    let host = `${subdomain}.${deploymentEnv}.flybits.com`;
    if (deploymentEnv == 'td') {
      host = `${subdomain}.flybits.com`;
    }
    const es1Url = `https://${host}`;

    if (validAccessToken) {
      let es2Url = `https://experiences.${deploymentEnv}.flybits.com/redirect?path=project/${id}&origin=${encodeURIComponent(
        es1Url,
      )}&token=${validAccessToken}`;
      if (subdomain == 'bff-test') {
        es2Url = `https://${subdomain}.${deploymentEnv}.flybits.com/es2/project/redirect?path=project/${id}&origin=${encodeURIComponent(
          es1Url,
        )}&token=${validAccessToken}`;
      }
      window.open(es2Url, '_blank');
    } else {
      let es2Url = `https://experiences.${deploymentEnv}.flybits.com/redirect?path=project/${id}&origin=${encodeURIComponent(
        es1Url,
      )}`;
      if (subdomain == 'bff-test') {
        es2Url = `https://${subdomain}.${deploymentEnv}.flybits.com/es2/project/redirect?path=project/${id}&origin=${encodeURIComponent(
          es1Url,
        )}&token=${validAccessToken}`;
      }
      generateAuthCode(id, host, es2Url, deploymentEnv);
    }
  };

  const goToDevPortal2 = () => {
    const { active, id } = projectDetails;

    if (!active) return;

    const _url = `https://developer.${deploymentEnv}.flybits.com/project/${id}?fromControlTower=true`;
    window.open(_url);
  };

  useEffect(() => {
    setStoredProjectId(projectId);
    const getProjectDetails = async () => {
      resetErrors();
      setLoading(true);
      const projectServiceManager = new ProjectService();
      try {
        const projectResponseObject = await projectServiceManager.getProject(orgID, projectId);
        if (projectResponseObject && typeof projectResponseObject.data === 'string') {
          history.push('/404/');
        }
        if (projectResponseObject?.data) {
          setProjectDetails(projectResponseObject?.data);
          setCurrentProjectID(projectResponseObject?.data.id);

          const projectTeamsResponseObject = await projectServiceManager.getProjectTeams(orgID, projectId);
          const projectUsersResponseObject = await projectServiceManager.getProjectUsers(orgID, projectId);
          //Success! Now get users/teams list
          const teamsAndSubteams = projectTeamsResponseObject?.data
            ? getSubTeams(projectTeamsResponseObject?.data, '')
            : [];

          setProjecUsersAndTeams({
            users: projectUsersResponseObject?.data,
            teams: teamsAndSubteams,
          });
          setLoading(false);
          setUsersAndTeamsLoading(false);
        }
      } catch (error) {
        console.error(error?.response);
        if (error?.response?.data?.message) {
          setErrorMessage(error.response.data.message);
          setError(true);
        } else {
          setErrorMessage(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
          setError(true);
        }
        addNotificationError(errorMessage);
        history.push('/organization/' + orgID);
      }
    };
    getProjectDetails();
    // eslint-disable-next-line
  }, [orgID, projectId, history, errorMessage]);

  const deleteProject = async () => {
    resetErrors();
    setDeleting(true);
    try {
      const projectServiceManager = new ProjectService();
      const projectResponseObject = await projectServiceManager.deleteProject(orgID, projectId);
      if (projectResponseObject && projectResponseObject.status === 201) {
        await deleteStoredProjectId();
        addNotification(
          t('translation:pages.projects.details.messages.remove_success', 'Project removed successfully.'),
        );
        setDeleting(false);
        setTryDeleteProject(false);
      }
      return true;
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        setErrorMessageDel(error.response.data.message);
        setError(true);
      } else {
        setErrorMessageDel(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
        setError(true);
      }
      addNotificationError(errorMessageDel);
      setDeleting(false);
      return false;
    }
  };
  const deactivateProject = async () => {
    if (deactivateModalData) {
      const projectRequestObject = {
        name: strip_tags(deactivateModalData.values.name),
        active: false,
      };
      const projectServiceManager = new ProjectService();
      try {
        const projectResponseObject = await projectServiceManager.updateProject(orgID, projectId, projectRequestObject);
        if (projectResponseObject && [200, 201, 202, 203, 204].includes(projectResponseObject.status)) {
          //handles HTTP status codes 200/201/202
          deactivateModalData.callback();
          setProjectDetails({ ...projectResponseObject.data, active: false });
          setTryDeactivate(false);
          addNotification(
            t('translation:pages.projects.details.messages.deactivate_success', 'Project is now inactive'),
          );
        }
      } catch (error) {
        console.error(error);
        addNotificationError(
          `${t(
            'translation:pages.projects.details.messages.deactivate_error',
            'We could not deactivate this project.',
          )} ${error?.response?.data?.message} ${t(
            'translation:pages.projects.details.messages.remove_success',
            'Please try again later',
          )}`,
        );
      }
    }
  };

  const [currentRole, setCurrentRole] = useState(['member']);
  useEffect(() => {
    async function getRole() {
      const savedRoles = await getUserRoles();
      const roles = !organization || !organization.roles.length ? savedRoles : organization.roles;
      setCurrentRole(roles);
    }
    getRole();
  }, [orgID, organization, projectId]);
  const hasAccess = (required: string[]) => useRoleAccess(required, currentRole);

  useEffect(() => {
    const { deployment } = projectDetails;

    if (deployment) {
      setDeploymentEnv(deployment === 'europe' ? 'v3-europe' : deployment);

      deployment !== 'infra' && setHideESAndDP(false);
    }
  }, [projectDetails]);

  useEffect(() => {
    // Env/Deployment level
    if (
      projectDetails.deployment &&
      (projectDetails.deployment === 'arbsvruzy063' ||
        projectDetails.deployment === 'core-dev' ||
        projectDetails.deployment === 'infra-gcp')
    ) {
      setIsExpStudio1Enabled(false);
      setIsExpStudio2Enabled(true);
    } else if (projectDetails.deployment) {
      if (EXP_STUDIO_2_OVERRIDES.includes(projectDetails.deployment)) {
        setIsExpStudio1Enabled(featureFlags[EXP_STUDIO_1_ENABLED]);
        setIsExpStudio2Enabled(true);
        const flags: any = {};
        const traits: any = {};
        flags[EXP_STUDIO_1_ENABLED] = featureFlags[EXP_STUDIO_1_ENABLED];
        flags[EXP_STUDIO_2_ENABLED] = true;
        traits[`proj|${projectId}|experience_studio_1.0`] = featureFlags[EXP_STUDIO_1_ENABLED];
        traits[`proj|${projectId}|experience_studio_2.0`] = true;
        setCurrentFlags({
          ...organizationFlags,
          ...traits,
        });
      } else {
        // ES Feature flags check
        if (Object.keys(organizationFlags).length && projectId) {
          const hasFF = Object.keys(organizationFlags).some((flag) => flag.startsWith(`proj|${projectId}`));
          if (!hasFF) {
            const traits: any = {};
            traits[`proj|${projectId}|experience_studio_1.0`] = organizationFlags['org_enable_es1.0'];
            traits[`proj|${projectId}|experience_studio_2.0`] = organizationFlags['org_enable_es2.0'];
            setCurrentFlags({
              ...organizationFlags,
              ...traits,
            });
          } else {
            // ES Feature flags update
            setCurrentFlags({
              ...organizationFlags,
            });
          }
        }
        // Org level
        setIsExpStudio1Enabled(
          typeof organizationFlags['org_enable_es1.0'] !== 'undefined' ? organizationFlags['org_enable_es1.0'] : true,
        );
        setIsExpStudio2Enabled(
          typeof organizationFlags['org_enable_es2.0'] !== 'undefined' ? organizationFlags['org_enable_es2.0'] : false,
        );
        // Project level
        if (
          typeof organizationFlags[`proj|${projectId}|experience_studio_1.0`] !== 'undefined' ||
          typeof organizationFlags[`proj|${projectId}|experience_studio_2.0`] !== 'undefined'
        ) {
          setIsExpStudio1Enabled(organizationFlags[`proj|${projectId}|experience_studio_1.0`]);
          setIsExpStudio2Enabled(organizationFlags[`proj|${projectId}|experience_studio_2.0`]);
        }
        // User level
        !organizationFlags['org_enable_es1.0'] && featureFlags[EXP_STUDIO_1_ENABLED] && setIsExpStudio1Enabled(true);
        !organizationFlags['org_enable_es2.0'] && featureFlags[EXP_STUDIO_2_ENABLED] && setIsExpStudio2Enabled(true);
      }
    }
  }, [featureFlags, organizationFlags, projectId, projectDetails]);

  useEffect(() => {
    setTimeout(() => {
      pageRef.current.focus();
      pageRef.current.scrollTop = '-100px';
    }, 100);
  }, []);
  const pageRef = useRef<any>();
  return (
    <div className={MAIN_CLASS}>
      <FlightModal
        isVisible={tryDeleteProject}
        toggleModalShown={() => {
          setTryDeleteProject(false);
        }}
        scrollable={false}
        size="small"
        warning={true}
        header={
          <span>
            {t('translation:pages.projects.details.modal_delete.header', 'Are you sure you want to delete project:')}{' '}
            {projectDetails.name}?
          </span>
        }
        content={
          <span>
            {deleting ? (
              <div className={LOADING_PROJECT_CLASS}>
                <LoadingIcon width={80} height={80} className={LOADING_PROJECT_CLASS} visible={deleting} />
                <h3>{t('translation:pages.projects.details.modal_delete.please_wait', 'Please wait...')}</h3>
                <p>{t('translation:pages.projects.details.modal_delete.removing', 'Removing your project.')}</p>
              </div>
            ) : (
              <>
                <p>
                  {t(
                    'translation:pages.projects.details.modal_delete.text',
                    'To permanently delete your Flybits project, enter your project ID below. This action cannot be undone.',
                  )}
                </p>
                <Formik
                  initialValues={initialValuesDelete}
                  validationSchema={validationSchemaDelete}
                  validateOnChange
                  enableReinitialize
                  onSubmit={async () => {
                    const result = await deleteProject();
                    setTimeout(() => {
                      if (result) {
                        history.push('/organization/' + orgID);
                      }
                    }, 3000);
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form>
                      {error && (
                        <div role="alert" aria-atomic className="form__error">
                          {errorMessageDel}
                        </div>
                      )}
                      <div className={CONTENT_CLASS}>
                        <Field
                          type="text"
                          name="confirmId"
                          className={`${CONTENT_CLASS}__input`}
                          width="100%"
                          as={FlightTextInput}
                          label={t(
                            'translation:pages.projects.details.modal_delete.enter_project_id',
                            'Enter Project ID',
                          )}
                          ariaLabel={t(
                            'translation:pages.projects.details.modal_delete.enter_project_id',
                            'Enter Project ID',
                          )}
                          value={values.confirmId}
                          errorMessage={
                            <span role="alert" aria-atomic>
                              <b>Error:</b> {errors.confirmId}
                            </span>
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <br />
                      <div className="modal_buttons">
                        <FlightButton
                          type="secondary"
                          size="medium"
                          theme="secondary"
                          label={t('translation:general.buttons.back', 'Cancel')}
                          ariaLabel={t('translation:general.buttons.back', 'Cancel')}
                          onClick={() => {
                            setTryDeleteProject(false);
                          }}
                        />
                        <FlightButton
                          type="primary"
                          size="medium"
                          label={t('translation:pages.projects.labels.delete', 'Delete project')}
                          ariaLabel={t('translation:pages.projects.labels.delete', 'Delete project')}
                          className={`${CONTENT_CLASS}__button_delete`}
                          disabled={!touched.confirmId || !!errors.confirmId}
                          onClick={handleSubmit}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </>
            )}
          </span>
        }
      />
      <FlightModal
        isVisible={tryDeactivate}
        toggleModalShown={() => {
          setTryDeactivate(false);
        }}
        scrollable={false}
        size="small"
        warning={false}
        header={
          <span>
            {t(
              'translation:pages.projects.details.modal_deactivate.header',
              'Are you sure you want to deactivate project:',
            )}{' '}
            {projectDetails.name}?
          </span>
        }
        content={
          <span>
            {deleting ? (
              <div className={LOADING_PROJECT_CLASS}>
                <LoadingIcon width={80} height={80} className={LOADING_PROJECT_CLASS} visible={deleting} />
              </div>
            ) : (
              <div className="modal_buttons">
                <FlightButton
                  type="button"
                  size="medium"
                  theme="secondary"
                  label={t('translation:general.buttons.back', 'Cancel')}
                  ariaLabel={t('translation:general.buttons.back', 'Cancel')}
                  onClick={() => {
                    setTryDeactivate(false);
                  }}
                />
                <FlightButton
                  type="button"
                  size="medium"
                  label={t('translation:pages.projects.details.modal_deactivate.btn-deactivate', 'Deactivate Project')}
                  ariaLabel={t(
                    'translation:pages.projects.details.modal_deactivate.btn-deactivate',
                    'Deactivate Project',
                  )}
                  onClick={deactivateProject}
                />
              </div>
            )}
          </span>
        }
      />
      <div className={CONTENT_CLASS}>
        <div className={`${CONTENT_CLASS}__wrapper`}>
          <h1 className={`${CONTENT_CLASS}__heading-text`}>
            <FlightButton
              type="primary"
              className={`${CONTENT_CLASS}__button_link ${CONTENT_CLASS}__button_link-back`}
              theme="link"
              size="medium"
              buttonRef={pageRef}
              label={`< ${t('translation:pages.projects.details.back_projects', 'Back to projects')}`}
              ariaLabel={`< ${t('translation:pages.projects.details.back_projects', 'Back to projects')}`}
              onClick={() => {
                history.push('/organization/' + orgID);
              }}
            />
            {t('translation:pages.projects.details.title', 'About this project')}
          </h1>
          <div className={`${CONTENT_CLASS}__infos`}>
            <div className={`${CONTENT_CLASS}__infos-left`}>
              <div className={`${CONTENT_CLASS}__infos-wrapper`}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnChange
                  enableReinitialize
                  onSubmit={() => {
                    //
                  }}
                >
                  {({ values, handleChange, handleBlur, setFieldValue }) => (
                    <form className={FORM_CLASS} autoComplete="off">
                      <input autoComplete="off" name="hidden" type="text" className={`${ITEM_CLASS}__hide`} />

                      <div className={`${CONTENT_CLASS}__avatar-wrapper`}>
                        <div className={`${ITEM_CLASS}__group`}>
                          <div className={`${ITEM_CLASS}__image-field`}>
                            <Jdenticon size="80" value={projectDetails.name} />
                          </div>
                        </div>
                        <div className={`${ITEM_CLASS}__edit`}>
                          {(loading || !projectDetails.name) && (
                            <LoadingIcon width={32} height={32} type="block" visible={loading} />
                          )}
                          <label htmlFor="name" className={`${!loading ? 'loaded' : ''}`}>
                            {values.name}{' '}
                            {hasAccess(['owner', 'projects']) && (
                              <>{error ? errorIcon : isSaved ? checkIcon : editIcon}</>
                            )}
                          </label>
                          <Field
                            type="text"
                            name="name"
                            disabled={
                              !hasAccess(['owner', 'projects']) || (loading && hasAccess(['owner', 'projects']))
                            }
                            className={`${ITEM_CLASS}__edit-input ${!loading ? 'loaded' : ''}`}
                            width="100%"
                            value={values.name}
                            ariaLabel="Project name"
                            onChange={handleChange}
                            onKeyPress={(e: any) => {
                              if (e?.key === EVENT_KEYS.ENTER) {
                                e?.target?.blur();
                              }
                            }}
                            onBlur={async (e: any) => {
                              handleBlur(e);
                              setValuesToSave(values);
                            }}
                          />
                          <Field type="checkbox" name="active" ariaLabel="Active" className={`${ITEM_CLASS}__hide`} />
                          {loading || !projectDetails.name ? (
                            <LoadingIcon type="block" />
                          ) : (
                            <div className="status">
                              <FlightTag
                                label={
                                  projectDetails.active
                                    ? t('translation:pages.projects.card.active', 'Active')
                                    : t('translation:pages.projects.card.inactive', 'Inactive')
                                }
                                type={!projectDetails.active ? 'default' : 'success'}
                              />
                              {hasAccess(['owner', 'projects']) && (
                                <FlightButton
                                  type="button"
                                  className={`${CONTENT_CLASS}__button_link small`}
                                  theme="link"
                                  size="medium"
                                  disabled={loading}
                                  label={
                                    !projectDetails.active
                                      ? t('translation:pages.projects.details.activate', 'Activate')
                                      : t('translation:pages.projects.details.deactivate', 'Deactivate')
                                  }
                                  ariaLabel={
                                    !projectDetails.active
                                      ? t('translation:pages.projects.details.activate', 'Activate')
                                      : t('translation:pages.projects.details.deactivate', 'Deactivate')
                                  }
                                  onClick={() => {
                                    setPreviousValues({ name: projectDetails.name, active: projectDetails.active });
                                    if (projectDetails.active) {
                                      setDeactivateModalData({
                                        values: values,
                                        callback: () => {
                                          setFieldValue('active', false);
                                        },
                                      });
                                      setTryDeactivate(true);
                                    } else {
                                      setTryDeactivate(false);
                                      setValuesToSave({ name: values.name, active: true });
                                      setFieldValue('active', true);
                                      setProjectDetails({ ...projectDetails, active: true });
                                    }
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>

                <div className={`${CONTENT_CLASS}__infos-item`}>
                  <div className={`${CONTENT_CLASS}__infos-item-left`}>
                    {t('translation:pages.projects.details.labels.project_id', 'Project ID')}
                  </div>
                  <div className={`${CONTENT_CLASS}__infos-item-right`}>
                    {loading || !projectDetails.id ? (
                      <LoadingIcon width={14.5} height={14.5} type="block" visible={loading} />
                    ) : (
                      projectDetails.id
                    )}
                  </div>
                </div>
                <div className={`${CONTENT_CLASS}__infos-item`}>
                  <div className={`${CONTENT_CLASS}__infos-item-left`}>
                    {t('translation:pages.projects.details.labels.license', 'License')}
                  </div>
                  <div className={`${CONTENT_CLASS}__infos-item-right capitalize`}>
                    {loading || !projectDetails.tier ? (
                      <LoadingIcon width={14.5} height={14.5} type="block" visible={loading} />
                    ) : (
                      projectDetails.tier
                    )}
                  </div>
                </div>
                <div className={`${CONTENT_CLASS}__infos-item`}>
                  <div className={`${CONTENT_CLASS}__infos-item-left`}>
                    {t('translation:pages.projects.details.labels.cloud_platform', 'Cloud Service Platform')}
                  </div>
                  <div className={`${CONTENT_CLASS}__infos-item-right upper`}>
                    {loading || !projectDetails.region ? (
                      <LoadingIcon width={14.5} height={14.5} type="block" visible={loading} />
                    ) : (
                      projectDetails.region
                    )}
                  </div>
                </div>
                <div className={`${CONTENT_CLASS}__infos-item`}>
                  <div className={`${CONTENT_CLASS}__infos-item-left`}>
                    {t('translation:pages.projects.details.labels.host', 'Host')}
                  </div>
                  <div className={`${CONTENT_CLASS}__infos-item-right`}>
                    {loading || !projectDetails.endpoint ? (
                      <LoadingIcon width={14.5} height={14.5} type="block" visible={loading} />
                    ) : (
                      projectDetails.endpoint
                    )}
                  </div>
                </div>
                {!hideESAndDP && isExpStudio1Enabled && (
                  <div className={`${CONTENT_CLASS}__infos-item`}>
                    <div className={`${CONTENT_CLASS}__infos-item-left`}>
                      {t('translation:pages.projects.details.labels.exp_studio_url', 'Experience Studio URL')}
                    </div>
                    <div className={`${CONTENT_CLASS}__infos-item-right`}>
                      {loading || !projectDetails.subdomain ? (
                        <LoadingIcon width={14.5} height={14.5} type="block" visible={loading} />
                      ) : (
                        <FlightButton
                          type="primary"
                          className={`${CONTENT_CLASS}__button_link`}
                          theme="link"
                          size="medium"
                          label={
                            deploymentEnv == 'td'
                              ? projectDetails.subdomain + '.flybits.com'
                              : projectDetails.subdomain + '.' + deploymentEnv + '.flybits.com'
                          }
                          onClick={goToExperienceStudio}
                        />
                      )}
                    </div>
                  </div>
                )}
                {!hideESAndDP && isExpStudio2Enabled && (
                  <div className={`${CONTENT_CLASS}__infos-item`}>
                    <div className={`${CONTENT_CLASS}__infos-item-left`}>
                      {t('translation:pages.projects.details.labels.exp_studio_2.0_url', 'Experience Studio 2.0 URL')}
                    </div>
                    <div className={`${CONTENT_CLASS}__infos-item-right`}>
                      {loading || !projectDetails.subdomain ? (
                        <LoadingIcon width={14.5} height={14.5} type="block" visible={loading} />
                      ) : (
                        <FlightButton
                          type="primary"
                          className={`${CONTENT_CLASS}__button_link`}
                          theme="link"
                          size="medium"
                          label={`experiences.${deploymentEnv}.flybits.com`}
                          onClick={goToExperienceStudio2}
                        />
                      )}
                    </div>
                  </div>
                )}
                {hasAccess(['owner']) && (
                  <div className={`${CONTENT_CLASS}__infos-item`}>
                    <div className={`${CONTENT_CLASS}__infos-item-left`}>
                      <FlightButton
                        type="secondary"
                        className={`${CONTENT_CLASS}__button_delete_link`}
                        theme="link"
                        size="medium"
                        label={t('translation:pages.projects.details.labels.delete', 'Delete project')}
                        ariaLabel={t('translation:pages.projects.details.labels.delete', 'Delete project')}
                        onClick={() => {
                          setTryDeleteProject(true);
                          return;
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {hasAccess(['owner']) && (
                <>
                  {usersAndTeamsLoading ? (
                    <div className="loading__users_teams">
                      <LoadingIcon width={64} height={64} visible={usersAndTeamsLoading} />
                    </div>
                  ) : (
                    <>
                      {projectUsersAndTeams.users.length || projectUsersAndTeams.teams.length ? (
                        <>
                          <h2>
                            {t('translation:pages.projects.details.labels.project_access', 'Project access')}{' '}
                            <FlightButton
                              type="primary"
                              className={`${CONTENT_CLASS}__button_manage`}
                              theme="link"
                              size="medium"
                              label={t(
                                'translation:pages.projects.details.labels.manage_users_teams',
                                'Manage users & teams',
                              )}
                              ariaLabel={t(
                                'translation:pages.projects.details.labels.manage_users_teams',
                                'Manage users & teams',
                              )}
                              onClick={() => {
                                history.push(`/organization/${orgID}/directory`);
                              }}
                            />
                          </h2>
                          <ProjectUsersAndTeamsList list={projectUsersAndTeams} t={t} />
                        </>
                      ) : (
                        <h4 aria-live="polite">
                          {t(
                            'translation:pages.projects.details.labels.no_teams',
                            "We couldn't find any teams for this project.",
                          )}
                        </h4>
                      )}
                    </>
                  )}
                </>
              )}
              {/* Feature Flags */}
              {hasAccess(['owner']) &&
                !EXP_STUDIO_2_OVERRIDES.includes(projectDetails.deployment) &&
                Object.keys(currentFlags).length > 0 && (
                  <>
                    <h2>{t('translation:pages.organizations.details.feature_flags', 'Feature flags')}</h2>
                    <div className={`${CONTENT_CLASS}__infos-wrapper feature_flags`}>
                      {Object.entries(currentFlags)
                        .filter(([flag]) => flag.startsWith(`proj|${projectId}`))
                        .map(([flag, enabled], key) => (
                          <div key={flag + key} className={`${CONTENT_CLASS}__infos-item`}>
                            <div className={`${CONTENT_CLASS}__infos-item-left`}>
                              {flag
                                .split('|')[2]
                                .split('_')
                                .join(' ')
                                .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())}
                            </div>
                            <div className={`${CONTENT_CLASS}__infos-item-right`}>
                              <FlightCheckbox
                                key={flag}
                                label={enabled ? 'On' : 'Off'}
                                checkState={enabled ? 'SELECTED' : 'UNSELECTED'}
                                onSelect={() => {
                                  setControlTowerProjectFlags(projectId, {
                                    ...currentFlags,
                                    [flag]: !enabled,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                )}
            </div>
            {!hideESAndDP && projectDetails.active && (
              <div className={`${CONTENT_CLASS}__links-wrapper`}>
                {isExpStudio1Enabled && (
                  <FlightButton
                    type="secondary"
                    iconRight="openInNew"
                    theme="secondary"
                    label={t('translation:pages.projects.details.labels.link_exp_studio', 'Experience Studio')}
                    ariaLabel={t(
                      'translation:pages.projects.details.labels.link_exp_studio_aria',
                      'Experience Studio, opens in new window',
                    )}
                    loading={isValidatingTokenForES1}
                    onClick={goToExperienceStudio}
                  />
                )}
                {isExpStudio2Enabled && (
                  <FlightButton
                    type="secondary"
                    iconRight="openInNew"
                    theme="secondary"
                    label={t('translation:pages.projects.details.labels.link_exp_studio2', 'Experience Studio 2.0')}
                    ariaLabel={t(
                      'translation:pages.projects.details.labels.link_exp_studio2_aria',
                      'Experience Studio 2.0, opens in new window',
                    )}
                    loading={isValidatingTokenForES2}
                    onClick={goToExperienceStudio2}
                  />
                )}
                <FlightButton
                  type="secondary"
                  iconRight="openInNew"
                  theme="secondary"
                  label={t('translation:pages.projects.card.project_settings', 'Project Settings')}
                  ariaLabel={t(
                    'translation:pages.projects.card.project_settings_aria',
                    'Project Settings, opens in new window',
                  )}
                  onClick={goToDevPortal2}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
