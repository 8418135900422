import React, { FunctionComponent, useState } from 'react';
import SvgFlybitsLogo from 'src/assets/FlybitsLogo';
import { FlightButton, FlightTextInput, FlightCheckbox } from '@flybits/design-system';
import { Link } from 'react-router-dom';
import 'src/styles/pages/SignUp.scss';
import {
  PASSWORD_VALIDATION,
  EMAIL_VALIDATION,
  LAST_NAME_VALIDATION,
  LAST_NAME_REQUIRED,
  FIRST_NAME_VALIDATION,
  FIRST_NAME_REQUIRED,
  PASSWORD_REQUIRED,
  EMAIL_REQUIRED,
  USER_TERMS_OF_SERVICE_REQUIRED,
  GENERIC_INTERNAL_ERROR,
  CONFIRMATION_PASSWORD_VALIDATION,
  EMAIL_TAKEN_ERROR,
} from 'src/constants/errors';
import { passwordRegex, nameRegex } from 'src/helpers/auth.helper';
import AuthService from 'src/services/authentication.service';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { PublicPageChildProps } from 'src/model/general/public-pages';

// Translation
import { useTranslation } from 'react-i18next';
const authServiceManager = new AuthService();
const SignUp: FunctionComponent<PublicPageChildProps> = (props: PublicPageChildProps) => {
  const { t } = useTranslation(['translation', 'public', 'errors']);
  const history = useHistory();
  const MAIN_CLASS = 'signup';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const LINK_CLASS = `${ITEM_CLASS}__link`;
  interface FormValues {
    email: string;
    password: string;
    givenName: string;
    familyName: string;
    confirmPassword: string;
    agreement: boolean;
  }
  const initialValues = {
    email: '',
    password: '',
    givenName: '',
    familyName: '',
    confirmPassword: '',
    agreement: false,
  };

  const [emailTakenError, setIsEmailTakenError] = useState(false);
  const [signUpSuccessful, setSignUpSuccessful] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const nextURL = atob(urlParams.get('next') || btoa('/verify'));
  const signInURL = urlParams.has('next') ? 'signin?next=' + urlParams.get('next') : 'signin';

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('errors:EMAIL_REQUIRED', EMAIL_REQUIRED))
      .email(t('errors:EMAIL_VALIDATION', EMAIL_VALIDATION)),
    password: Yup.string()
      .required(t('errors:PASSWORD_REQUIRED', PASSWORD_REQUIRED))
      .matches(passwordRegex, t('errors:PASSWORD_VALIDATION', PASSWORD_VALIDATION)),
    confirmPassword: Yup.string()
      .required(t('errors:PASSWORD_REQUIRED', PASSWORD_REQUIRED))
      .matches(passwordRegex, t('errors:PASSWORD_VALIDATION', PASSWORD_VALIDATION))
      .test(
        'match',
        t('errors:CONFIRMATION_PASSWORD_VALIDATION', CONFIRMATION_PASSWORD_VALIDATION),
        function (confirmPassword) {
          return confirmPassword === this.parent.password;
        },
      ),
    givenName: Yup.string()
      .required(t('errors:FIRST_NAME_REQUIRED', FIRST_NAME_REQUIRED))
      .matches(nameRegex, t('errors:FIRST_NAME_VALIDATION', FIRST_NAME_VALIDATION)),
    familyName: Yup.string()
      .required(t('errors:LAST_NAME_REQUIRED', LAST_NAME_REQUIRED))
      .matches(nameRegex, t('errors:LAST_NAME_VALIDATION', LAST_NAME_VALIDATION)),
    agreement: Yup.boolean()
      .required(t('errors:USER_TERMS_OF_SERVICE_REQUIRED', USER_TERMS_OF_SERVICE_REQUIRED))
      .oneOf([true], t('errors:USER_TERMS_OF_SERVICE_REQUIRED', USER_TERMS_OF_SERVICE_REQUIRED)),
  });

  const initSignUp = async (values: FormValues) => {
    const signUpRequestObject = {
      givenName: values.givenName,
      familyName: values.familyName,
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      isAgreedToTermsAndPrivacy: values.agreement,
    };
    setError(false);
    setLoading(true);
    try {
      const response = await authServiceManager.signUpUser(signUpRequestObject);
      if (response && response.status === 200) {
        if (response.headers) {
          try {
            if (!urlParams.has('next')) {
              await authServiceManager.sendConfirmationEmail();
            }
            if (urlParams.get('next')) {
              setLoading(false);
              await props.setBannerVisibility(false);
            }
            setTimeout(() => setSignUpSuccessful(true), 50);
            history.push(nextURL);
          } catch (error) {
            console.error(error);
            setLoading(false);
            setSignUpSuccessful(false);
            return false;
          }
          return true;
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        if (error.response.data.message.indexOf('already exists') >= 0) {
          setIsEmailTakenError(true);
          setErrorMessage(t('errors:EMAIL_TAKEN_ERROR', EMAIL_TAKEN_ERROR));
        } else {
          setErrorMessage(error.response.data.message);
          setError(true);
        }
      } else {
        setErrorMessage(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
        setError(true);
      }
      console.error(error);
      setLoading(false);
      return false;
    }
  };

  return (
    <div className={MAIN_CLASS}>
      {!signUpSuccessful && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
          enableReinitialize
          onSubmit={async (values) => {
            await initSignUp(values);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
            <form className={FORM_CLASS} action="/">
              <SvgFlybitsLogo width={104} height={30} viewBox="0 0 104 30" {...{ className: `${MAIN_CLASS}__logo` }} />
              <h1 className={`${MAIN_CLASS}__heading-text`}>{t('public:signup.heading', 'Sign up')}</h1>
              {error && (
                <div className="form__error" role="alert" aria-atomic>
                  <strong>Error:</strong>
                  <ul>
                    <li>{errorMessage}</li>
                  </ul>
                </div>
              )}
              <div className={ITEM_CLASS}>
                <Field
                  type="text"
                  name="givenName"
                  className={`${ITEM_CLASS}__input ${ITEM_CLASS}__input__inline`}
                  width="100%"
                  as={FlightTextInput}
                  hasError={touched.givenName && errors.givenName ? true : false}
                  label={t('public:signup.labels.first-name', 'First name')}
                  value={values.givenName}
                  errorMessage={
                    <span role="alert" aria-atomic>
                      <b>Error:</b> {errors.givenName}
                    </span>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Field
                  type="text"
                  name="familyName"
                  className={`${ITEM_CLASS}__input ${ITEM_CLASS}__input__inline`}
                  width="100%"
                  as={FlightTextInput}
                  hasError={touched.familyName && errors.familyName ? true : false}
                  label={t('public:signup.labels.last-name', 'Last name')}
                  value={values.familyName}
                  errorMessage={
                    <span role="alert" aria-atomic>
                      <b>Error:</b> {errors.familyName}
                    </span>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={ITEM_CLASS}>
                <Field
                  type="text"
                  name="email"
                  className={`${ITEM_CLASS}__input ${ITEM_CLASS}__input__inline`}
                  width="100%"
                  as={FlightTextInput}
                  hasError={(touched.email && errors.email) || emailTakenError ? true : false}
                  label={t('public:signup.labels.username', 'Email')}
                  value={values.email}
                  errorMessage={
                    <span role="alert" aria-atomic>
                      <b>Error:</b> {emailTakenError ? EMAIL_TAKEN_ERROR : errors.email}
                    </span>
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (emailTakenError) {
                      setIsEmailTakenError(false);
                    }
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
              </div>
              <div className={ITEM_CLASS}>
                <Field
                  type="password"
                  name="password"
                  className={`${ITEM_CLASS}__input ${ITEM_CLASS}__input__inline`}
                  width="100%"
                  as={FlightTextInput}
                  hasError={touched.password && errors.password ? true : false}
                  label={t('public:signup.labels.password', 'Password')}
                  value={values.password}
                  errorMessage={
                    <span role="alert" aria-atomic>
                      <b>Error:</b> {errors.password}
                    </span>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={ITEM_CLASS}>
                <Field
                  type="password"
                  name="confirmPassword"
                  className={`${ITEM_CLASS}__input ${ITEM_CLASS}__input__inline`}
                  width="100%"
                  as={FlightTextInput}
                  hasError={touched.confirmPassword && errors.confirmPassword ? true : false}
                  label={t('public:signup.labels.confirm', 'Confirm password')}
                  value={values.confirmPassword}
                  errorMessage={
                    <span role="alert" aria-atomic>
                      <b>Error:</b> {errors.confirmPassword}
                    </span>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={`${ITEM_CLASS}__row`}>
                <div className={`${ITEM_CLASS}__checkbox`}>
                  <div className={`${ITEM_CLASS}__checkbox-container`}>
                    <Field
                      as={FlightCheckbox}
                      name="agreement"
                      checkState={values.agreement ? 'SELECTED' : 'UNSELECTED'}
                      hasError={touched.agreement && values.agreement ? false : false}
                      labelledby="signup-checkbox-label"
                      required
                      aria-required
                      errorMessage={
                        <span role="alert" aria-atomic>
                          <b>Error:</b> {errors.agreement}
                        </span>
                      }
                      value={values.agreement}
                      onSelect={() => {
                        setFieldValue('agreement', !values.agreement, false);
                        setFieldTouched('agreement', true, true);
                      }}
                    />
                  </div>
                  <div className={`${ITEM_CLASS}__agreement`} id="signup-checkbox-label">
                    {t('public:signup.compliance.agreement', 'By signing up, you indicate that you agree to the')}{' '}
                    <Link to={'/terms-of-service'} className={LINK_CLASS}>
                      {t('public:signin.compliance.terms.label', 'Terms of Service')}{' '}
                    </Link>{' '}
                    {t('public:signin.compliance.and', 'and')}
                    <Link to={'/privacy-policy'} className={LINK_CLASS}>
                      {' '}
                      {t('public:signin.compliance.privacy.label', 'Privacy Policy')}
                    </Link>
                    .
                  </div>
                </div>
                {touched.agreement && !values.agreement && (
                  <div role="alert" aria-atomic className={`${ITEM_CLASS}__checkbox-error-container`}>
                    *{USER_TERMS_OF_SERVICE_REQUIRED}
                  </div>
                )}
              </div>
              <FlightButton
                className={`${ITEM_CLASS}__button`}
                type="primary"
                size="large"
                label={t('public:signup.labels.btn-signup', 'Sign up')}
                loading={isLoading}
                disabled={
                  isLoading ||
                  !!errors.givenName ||
                  !!errors.familyName ||
                  !!errors.email ||
                  !values.agreement ||
                  !!errors.password ||
                  !!errors.confirmPassword
                }
                onClick={handleSubmit}
              />
              <div className={`${ITEM_CLASS}__signin`}>
                {t('public:signup.already-have-account.text', 'Already have a Flybits account?')}&nbsp;
                <Link to={signInURL} className={LINK_CLASS}>
                  {t('public:signup.already-have-account.link-label', 'Sign in')}
                </Link>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default SignUp;
