import React, { FunctionComponent, useState, useEffect } from 'react';
import SvgFlybitsLogo from 'src/assets/FlybitsLogo';
import { FlightButton, FlightTextInput, getIcon, FlightModal } from '@flybits/design-system';
import { Link, useHistory } from 'react-router-dom';
import AuthService from 'src/services/authentication.service';
import { passwordRegex } from 'src/helpers/auth.helper';
import {
  PASSWORD_VALIDATION,
  PASSWORD_REQUIRED,
  CONFIRMATION_PASSWORD_VALIDATION,
  CHANGE_PASSWORD_QUERY_PARAM_ERROR,
  GENERIC_INTERNAL_ERROR,
} from 'src/constants/errors';
import 'src/styles/pages/SignIn.scss';
import 'src/styles/pages/PasswordReset.scss';
import 'src/styles/pages/ChangePassword.scss';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { PublicPageChildProps } from 'src/model/general/public-pages';
import useNotification from 'src/hooks/useNotification';
import { useTranslation } from 'react-i18next';

const authServiceManager = new AuthService();
const PasswordChange: FunctionComponent<PublicPageChildProps> = () => {
  const { t } = useTranslation(['translation', 'public', 'errors']);
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('errors:PASSWORD_REQUIRED', PASSWORD_REQUIRED))
      .matches(passwordRegex, t('errors:PASSWORD_VALIDATION', PASSWORD_VALIDATION)),
    confirmPassword: Yup.string()
      .required(t('errors:PASSWORD_REQUIRED', PASSWORD_REQUIRED))
      .matches(passwordRegex, t('errors:CONFIRMATION_PASSWORD_VALIDATION', CONFIRMATION_PASSWORD_VALIDATION)),
  });
  const MAIN_CLASS = 'ChangePassword';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const LINK_CLASS = `${ITEM_CLASS}__link`;
  const initialValues = { password: '', confirmPassword: '' };
  const MODAL_CLASS = `${MAIN_CLASS}__modal`;

  const { addNotification, addNotificationError } = useNotification();
  const history = useHistory();

  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [username, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [confirmationKey, setConfirmationKey] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('confirmationKey') && queryParams.has('email')) {
      const confirmationKey = queryParams.get('confirmationKey');
      const username = queryParams.get('email')?.replace(/\ /g, '+');
      if (!confirmationKey || !username) {
        history.push('/signin');
      } else {
        setConfirmationKey(confirmationKey ? confirmationKey : '');
        setEmail(username ? username : '');
      }
    } else {
      setErrorMessage(t('errors:CHANGE_PASSWORD_QUERY_PARAM_ERROR', CHANGE_PASSWORD_QUERY_PARAM_ERROR));
      setError(true);
    }
  }, []);

  const initChangePassword = async (password: string) => {
    setLoading(true);
    const changePasswordRequestBody = {
      key: confirmationKey,
      username: username,
      password: password,
    };
    setErrorMessage('');
    setError(false);
    try {
      const response = await authServiceManager.changePassword(changePasswordRequestBody);
      if (response) {
        setLoading(false);
        setIsPasswordChanged(true);
        addNotification(t('public:password.messages.change_success', 'Password successfuly changed!'));
      }
    } catch (error) {
      console.error(error);
      const ERROR_MSG = error.response?.data?.message
        ? error.response?.data?.message.indexOf('not exist') > -1
          ? t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR) // avoid showing that the user does not exist
          : error.response?.data?.message
        : t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR);
      addNotificationError(ERROR_MSG);
      setLoading(false);
    }
  };

  const handleRedirect = () => {
    setError(false);
    history.push(`/signin${username ? `?email=${username}` : ''}`);
  };

  return (
    <div className={MAIN_CLASS}>
      <FlightModal
        isVisible={error}
        toggleModalShown={() => setError(!error)}
        scrollable={false}
        size="small"
        warning={true}
        className={MODAL_CLASS}
        header={<span></span>}
        content={
          <span role="alert" aria-atomic>
            <b>Error:</b> {errorMessage}
          </span>
        }
        footer={
          <div className={`${MODAL_CLASS}__footer`}>
            <FlightButton
              type="primary"
              label={t('public:password.labels.go_back', 'Back to sign in')}
              onClick={handleRedirect}
            />
          </div>
        }
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          initChangePassword(values.password);
          resetForm();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form className={FORM_CLASS} action="/">
            <SvgFlybitsLogo width={104} height={30} viewBox="0 0 104 30" {...{ className: `${MAIN_CLASS}__logo` }} />
            {isPasswordChanged ? (
              <div className={`${ITEM_CLASS}__password-change-confirmation`} role="alert">
                <h1 className={`${MAIN_CLASS}__heading-text`}>
                  {getIcon('checkCircle', { 'aria-hidden': true })}{' '}
                  {t('public:password.messages.reset_success', 'Password Sucessfully Reset')}
                </h1>
                <FlightButton
                  className={`${ITEM_CLASS}__button`}
                  type="primary"
                  size="large"
                  label={t('public:signin.labels.btn-signin', 'Sign in')}
                  onClick={() => {
                    handleRedirect();
                  }}
                />
              </div>
            ) : (
              <div className={`${ITEM_CLASS}__password-change-form`}>
                <h1 className={`${MAIN_CLASS}__heading-text`}>
                  {t('public:password.choose_password', 'Choose a new password')}
                </h1>
                <div className={ITEM_CLASS}>
                  <Field
                    type="password"
                    name="password"
                    className={`${ITEM_CLASS}__input`}
                    as={FlightTextInput}
                    width="100%"
                    label={t('public:password.labels.password', 'Password')}
                    disabled={isLoading}
                    hasError={touched.password && errors.password ? true : false}
                    value={values.password}
                    errorMessage={
                      <span role="alert" aria-atomic>
                        <b>Error:</b> {errors.password}
                      </span>
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={ITEM_CLASS}>
                  <Field
                    type="password"
                    name="confirmPassword"
                    className={`${ITEM_CLASS}__input`}
                    as={FlightTextInput}
                    width="100%"
                    label={t('public:password.labels.confirm_password', 'Confirm password')}
                    disabled={isLoading}
                    hasError={touched.confirmPassword && errors.confirmPassword ? true : false}
                    value={values.confirmPassword}
                    errorMessage={
                      <span role="alert" aria-atomic>
                        <b>Error:</b> {errors.confirmPassword}
                      </span>
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <FlightButton
                  className={`${ITEM_CLASS}__button`}
                  type="primary"
                  size="large"
                  label={t('public:password.labels.btn-submit', 'Submit')}
                  loading={isLoading}
                  disabled={
                    isLoading ||
                    !values.confirmPassword ||
                    !values.password ||
                    values.confirmPassword !== values.password ||
                    !!errors.confirmPassword ||
                    !!errors.password
                  }
                  onClick={handleSubmit}
                />
              </div>
            )}
            {!isPasswordChanged && (
              <div className={`${ITEM_CLASS}__signin`}>
                <Link to={`/signin${username ? `?email=${username}` : ''}`} className={LINK_CLASS}>
                  {t('public:password.labels.go_back', 'Back to sign in')}
                </Link>
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PasswordChange;
