import { getIcon } from '@flybits/design-system';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useMessage from 'src/hooks/useMessage';

function DataSourceAuthCallback() {
  const { sendToParent } = useMessage('authenticate');
  const { t } = useTranslation(['integrations']);
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get('error');
  const checkIcon = getIcon('checkCircle', { width: 80, height: 80, fill: '#00ab50' });
  const errorIcon = getIcon('error', { width: 80, height: 80, fill: '#dc3616' });
  const closeWin = () => {
    if (window) window.close();
  };
  useEffect(() => {
    setTimeout(closeWin, 3000);
    sendToParent({ type: 'authenticate', payload: { success: !!error ? false : true } });
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      {!!error ? errorIcon : checkIcon}
      <h2>
        {t(`integrations:datasources.details.sections.authorization.messages.${!!error ? 'failed_auth' : 'success'}`)}
      </h2>
      <h4>
        {t(
          `integrations:datasources.details.sections.authorization.messages.${
            !!error ? 'failed_auth' : 'success'
          }_message`,
        )}
      </h4>
      <p>
        <small>
          <i>
            {t(
              'integrations:datasources.details.sections.authorization.messages.can_close',
              'You can close this window',
            )}
          </i>
        </small>
      </p>
    </div>
  );
}

export default DataSourceAuthCallback;
