import React, { useEffect, useState } from 'react';
import { FlightButton, FlightModal, FlightSelect, FlightTable, FlightTextInput } from '@flybits/design-system';
import './TeamProjectsModal.scss';

import { TeamModalProjectProps, TeamProjects } from 'src/model/teams/teams';
import TeamService from 'src/services/teams.service';
import useNotification from 'src/hooks/useNotification';
import { Link } from 'react-router-dom';
import ProjectService from 'src/services/project.service';
import Jdenticon from 'src/components/Jdenticon';
import { PROJECT_LEVELS } from 'src/constants/levels';
import { getProjectLevel } from 'src/helpers/teams.helper';
import {
  ProjectSelectedProps,
  ProjectColValue,
  ProjectLevelValue,
  ProjectUpdateParams,
} from 'src/model/projects/projects';
import { capitalize } from 'src/helpers/general.helper';

let updatedProjectsList: ProjectSelectedProps[] = [];
let shouldStopPropagation = false;

export function TeamProjectsModal(props: TeamModalProjectProps) {
  const { openModal, toggleModal, cancelModal, projects, team, organization, t } = props;
  const defaultSelection = new Set('');
  const [selectedProjects, setSelectedProjects] = useState(defaultSelection);
  const [firstSelection, setFirstSelection] = useState(defaultSelection);
  const [projectsList, setProjects] = useState<ProjectSelectedProps[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [updating, setUpdating] = useState(false);
  const { addNotification, addNotificationError } = useNotification();

  const teamServiceManager = new TeamService();
  const projectServiceManager = new ProjectService();
  const projectTableHeaders = [
    {
      key: 'projectFormatted',
      isVisible: true,
    },
    {
      key: 'name',
      isVisible: false,
    },
    {
      key: 'deployment',
      isVisible: false,
    },
    {
      key: 'url',
      isVisible: false,
    },
    {
      key: 'id',
      isVisible: false,
    },
    {
      key: 'organization',
      isVisible: false,
    },
    {
      key: 'active',
      isVisible: false,
    },
    {
      key: 'region',
      isVisible: false,
    },
    {
      key: 'team',
      isVisible: false,
    },
    {
      key: 'levelFormatted',
      isVisible: true,
    },
    {
      key: 'removeButton',
      isVisible: true,
    },
    {
      key: 'level',
      isVisible: false,
    },
  ];
  const MAIN_CLASS = 'TeamDetails';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  const LevelCol = (level: number, id: string) => (
    <>
      <FlightSelect
        options={PROJECT_LEVELS.map((item) => {
          return { key: item.level, name: item.name };
        })}
        className="level__select"
        selected={{ key: level || 5, name: getProjectLevel(level || 5) }}
        dropdownMaxHeight="80px"
        width="170px"
        label={'Select a level:'}
        hasLabelAnimation
        onClick={() => {
          shouldStopPropagation = true;
        }}
        handleOptionClick={async (e) => await handleLevelSelect(e, id)}
      />
    </>
  );

  const handleLevelSelect = async (item: ProjectLevelValue, id: string) => {
    shouldStopPropagation = false;
    const projects = updatedProjectsList.map((project) => {
      if (project.id === id) {
        project.level = item.key;
        project.levelFormatted = LevelCol(item.key, id);
      }
      return project;
    });
    setProjects(projects);
  };
  const ProjectTableCol = (data: ProjectColValue) => (
    <div className="projectHeader">
      <div className="projectHeader-icon">
        <Jdenticon size="48" value={data.url} />
      </div>
      <div className="projectHeader-content">
        <p>
          <strong aria-label={'Project name: ' + data.name + '.'}>{data.name}</strong>
        </p>
        <p>
          <span className={data.active ? 'active' : 'inactive'}>
            {data.active ? t('translation:general.active', 'Active') : t('translation:general.inactive', 'Inactive')}
          </span>{' '}
          - <span>{data.region}</span>
        </p>
      </div>
    </div>
  );

  const handleTableDataSelect = (item: ProjectSelectedProps) => {
    if (shouldStopPropagation) return;
    const selectedData = selectedProjects;
    if (selectedData.has(item.id)) selectedData.delete(item.id);
    else selectedData.add(item.id);
    setSelectedProjects(new Set(selectedData));
  };
  useEffect(() => {
    // get projects
    const getProjects = async () => {
      try {
        const teamProjectsResponseObject = await teamServiceManager.getTeamProjects(organization, team);
        let selectedProjects: any[] = [];
        if (teamProjectsResponseObject?.data) {
          const selected = teamProjectsResponseObject?.data.map(
            (project: any) => `${project.deployment}_${project.subdomain}`,
          );
          selectedProjects = teamProjectsResponseObject?.data.map((project: any) => ({
            id: `${project.deployment}_${project.subdomain}`,
            level: project.level,
          }));
          setSelectedProjects(new Set(selected.map((p: string) => p)));
          setFirstSelection(new Set(selected.map((p: string) => p)));
        }
        const getSelectedProjectLevel = (id: string, defaultLevel = 5) => {
          let returnVal = defaultLevel;
          selectedProjects.map((selected) => {
            if (id === selected.id) returnVal = selected.level;
          });
          return returnVal;
        };
        const projectsResponseObject = await projectServiceManager.getProjects(organization);
        if (projectsResponseObject?.data) {
          const projectData = projectsResponseObject?.data.map(
            ({ team, level, deployment, subdomain, name, active, region }: any) => ({
              key: `${deployment}_${subdomain}`,
              projectFormatted: ProjectTableCol({
                url: subdomain,
                name: name,
                id: `${deployment}_${subdomain}`,
                active: active,
                region: capitalize(deployment),
              }),
              levelFormatted: LevelCol(
                getSelectedProjectLevel(`${deployment}_${subdomain}`, level),
                `${deployment}_${subdomain}`,
              ),
              name: name,
              active: active,
              id: `${deployment}_${subdomain}`,
              deployment: deployment,
              url: subdomain,
              organization: organization,
              team: team,
              region: region,
              level: getSelectedProjectLevel(`${deployment}_${subdomain}`, level),
              removeButton: null,
            }),
          );
          setProjects(projectData);
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          console.error(error.response.data.message);
        }
      }
    };
    if (updating) return;
    team && organization && openModal && getProjects();
    // eslint-disable-next-line
  }, [projects, openModal]);
  useEffect(() => {
    if (projectsList.length) {
      updatedProjectsList = projectsList;
    }
  }, [projectsList]);
  const handleProjectsUpdate = async (projectsList: ProjectSelectedProps[]) => {
    setUpdating(true);
    const projectsToAdd: ProjectUpdateParams[] = [];
    const projectsToRemove: ProjectUpdateParams[] = [];
    const projectsToUpdate: ProjectUpdateParams[] = [];
    projectsList.map((project) => {
      if (selectedProjects.has(project.id) && !firstSelection.has(project.id)) {
        projectsToAdd.push({ id: `${project.deployment}_${project.url}`, level: project.level || 5 });
      }
      if (!selectedProjects.has(project.id) && firstSelection.has(project.id)) {
        projectsToRemove.push({ id: `${project.deployment}_${project.url}`, level: project.level || 5 });
      }
      if (selectedProjects.has(project.id) && firstSelection.has(project.id)) {
        projectsToUpdate.push({ id: `${project.deployment}_${project.url}`, level: project.level || 5 });
      }
    });
    try {
      // Add
      if (projectsToAdd.length) {
        const submitData: TeamProjects = {
          projects: projectsToAdd,
        };
        const teamResponseObject = await teamServiceManager.addProjectsToTeam(organization, team, submitData);
        if (teamResponseObject && (teamResponseObject.status === 201 || teamResponseObject.status === 200)) {
          // Check for success/fails
          if (teamResponseObject?.data?.failed?.length > 0) {
            addNotificationError(
              t('translation:pages.teams.messages.add_team_projects_error', {
                total: teamResponseObject?.data?.failed?.length,
              }),
            );
          } else {
            addNotification(
              t('translation:pages.teams.messages.add_team_projects_success', 'Team projects updated successfully!'),
            );
          }
        }
      }
      // Update
      if (projectsToUpdate.length) {
        await Promise.all(
          projectsToUpdate.map(async (project: any) => {
            const teamUpdateResponseObject = await teamServiceManager.updateTeamProjectLevel(
              organization,
              team,
              project.id,
              {
                level: project.level,
              },
            );
            if (
              teamUpdateResponseObject &&
              teamUpdateResponseObject.status >= 200 &&
              teamUpdateResponseObject.status < 400
            ) {
              return true;
            }
            return false;
          }),
        );
      }
      // Remove
      if (projectsToRemove.length) {
        await Promise.all(
          projectsToRemove.map(async (project: any) => {
            const teamRemoveResponseObject = await teamServiceManager.deleteProjectFromTeam(
              organization,
              team,
              project.id,
            );
            if (
              teamRemoveResponseObject &&
              teamRemoveResponseObject.status >= 200 &&
              teamRemoveResponseObject.status < 400
            ) {
              return true;
            }
            return false;
          }),
        );
      }
      setUpdating(false);
      toggleModal();
      return true;
    } catch (error) {
      if (error?.response?.data?.message) {
        addNotificationError(
          t(
            'translation:pages.teams.messages.add_team_projects_error_generic',
            'Some Projects were unable to be added to team',
          ),
        );
        console.error(error.response.data.message);
      }
      setUpdating(false);
      return false;
    }
  };
  const editSearchTerm = (e: any) => {
    setSearchTerm(e.target.value || '');
  };
  const projectsFilteredData = () => {
    return projectsList.filter((proj) => !searchTerm || proj.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  return (
    <>
      <FlightModal
        isVisible={openModal}
        toggleModalShown={toggleModal}
        className="TeamProjectsModal"
        scrollable={false}
        size="large"
        header={<span>{t('translation:pages.teams.modal_projects.header', 'Add projects')}</span>}
        content={
          <div>
            <div className="TeamProjectsModal__search_field">
              <FlightTextInput
                placeholderText=""
                width="292px"
                hasClearIcon
                trailingIcon="search"
                type="text"
                name="table-filter"
                value={searchTerm}
                onChange={editSearchTerm}
                ariaLabel={`Filter projects`}
                label={`Filter projects`}
                isLabelAlwaysOn
              />
            </div>

            <div
              className="TeamProjectsModal__teams_list"
              role="group"
              aria-label={t('translation:pages.teams.modal_projects.projects_list', 'Projects list')}
            >
              <div className="info-text">
                {t('translation:pages.teams.learn_more_projects', 'To learn more about project access levels')}{' '}
                <Link
                  to={{
                    pathname: t(
                      'translation:pages.teams.learn_more_projects_url',
                      'https://flybits.gitbook.io/product-guide/-MCJQnb9xKBWvcUyw_9V/for-owners/managing-teams#access-levels',
                    ),
                  }}
                  target="_blank"
                >
                  {t('translation:pages.teams.learn_more_projects_label', 'click here')}
                </Link>
              </div>
              <FlightTable
                className={`${CONTENT_CLASS}__tabs-content-table`}
                allowRowSelect
                allowMultiSelect
                selectedDataKey={selectedProjects}
                handleDataSelect={(item) => {
                  if (!shouldStopPropagation) handleTableDataSelect(item);
                  shouldStopPropagation = false;
                }}
                hasError={false}
                emptyState={
                  <div className={`${CONTENT_CLASS}__tabs-content-table-no-results`}>
                    <p>
                      <strong>0</strong>{' '}
                      {t('translation:pages.teams.messages.no_projects_found', 'projects found in this team')}{' '}
                      {searchTerm && (
                        <>
                          {t('translation:pages.teams.messages.with_filter', 'with the filter')} &ldquo;
                          <strong>{searchTerm}</strong>&rdquo;
                        </>
                      )}
                    </p>
                  </div>
                }
                tableData={projectsFilteredData()}
                tableHeaders={projectTableHeaders}
                hasPaginationBeforeTable={false}
                isShowHeader={false}
              />
            </div>
          </div>
        }
        footer={
          <div>
            <FlightButton
              type="button"
              label={t('translation:pages.teams.modal_projects.btn-add', 'Add selected')}
              onClick={async () => await handleProjectsUpdate(projectsList)}
            />
            <FlightButton
              type="button"
              theme="secondary"
              label={t('translation:general.buttons.cancel', 'Cancel')}
              onClick={cancelModal}
            />
          </div>
        }
      />
    </>
  );
}
// export default TeamProjectsModal;
