import React from 'react';
import './PreviewFormEditor.scss';
import { FlightButton } from '@flybits/design-system';
import { ReactComponent as PreviewErrorImage } from 'src/assets/error-generic.svg';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
interface Props {
  schema: RJSFSchema;
  uiSchema: UiSchema;
  previewError: boolean;
  t: any;
  setTab: (e: any) => void;
}
const PreviewFormEditor: React.FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'Preview';
  const { schema, uiSchema, previewError, setTab, t } = props;

  return (
    <div className={MAIN_CLASS}>
      {previewError ? (
        <div className="Preview-error">
          <PreviewErrorImage />
          <p>
            {t(
              'integrations:connectors.details.sections.config-template.preview.unable_to_render',
              'Unable to render preview',
            )}
          </p>
          <FlightButton
            type="button"
            theme="link"
            size="small"
            label={t(
              'integrations:connectors.details.sections.config-template.preview.check_schema',
              'Check your JSON schema',
            )}
            ariaLabel={t(
              'integrations:connectors.details.sections.config-template.preview.check_schema',
              'Check your JSON schema',
            )}
            onClick={() => setTab('editor')}
          />
        </div>
      ) : (
        <>
          {!previewError && schema ? (
            <Form
              schema={schema}
              uiSchema={{
                ...uiSchema,
                'ui:submitButtonOptions': {
                  props: {
                    disabled: false,
                  },
                  norender: true,
                },
              }}
              validator={validator}
            />
          ) : (
            <div className="Preview-error">
              <PreviewErrorImage />
              <p>
                {t(
                  'integrations:connectors.details.sections.config-template.preview.nothing_to_preview',
                  'Nothing to preview, please add a valid schema in the',
                )}{' '}
                <FlightButton
                  type="button"
                  theme="link"
                  size="small"
                  label={t(
                    'integrations:connectors.details.sections.config-template.preview.nothing_to_preview_btn',
                    'JSON editor',
                  )}
                  ariaLabel={t(
                    'integrations:connectors.details.sections.config-template.preview.nothing_to_preview_btn',
                    'JSON editor',
                  )}
                  onClick={() => setTab('editor')}
                />
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PreviewFormEditor;
