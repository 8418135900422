const NOT_FOUND_404 = 'We encountered an error trying to process your request. You can retry now or come back later.';
const PASSWORD_VALIDATION =
  'Must be at least 10 characters with at least 1 lowercase letter, uppercase letter, number, and symbol.';
const EMAIL_VALIDATION = 'Must be a valid email.';
const CONFIRMATION_PASSWORD_VALIDATION = 'Passwords must match.';
const SAME_PASSWORD_VALIDATION = 'New password could not be the same as current.';
const FIRST_NAME_REQUIRED = 'First name is required.';
const LAST_NAME_REQUIRED = 'Last name is required.';
const LAST_NAME_VALIDATION = 'Last Name may not contain numbers or special characters.';
const FIRST_NAME_VALIDATION = 'First Name may not contain numbers or special characters.';
const ORGANIZATION_NAME_REQUIRED = 'Organization name is required.';
const ORGANIZATION_NAME_VALIDATION = 'Organization name may not contain numbers or special characters.';
const ORGANIZATION_URL_VALIDATION = 'Organization URL should be a valid URL.';
const TEAM_NAME_REQUIRED = 'Team name is required.';
const TEAM_NAME_VALIDATION = 'Team name may not contain special characters.';
const JSON_VALIDATION = 'Schema validation failed. Please enter a JSON according to our schema guidelines.';
const CHANGE_PASSWORD_QUERY_PARAM_ERROR =
  'An error occured while attemping to change the password. Please contact support@flybits.com.';
const USER_TERMS_OF_SERVICE_REQUIRED = 'You must agree to the Terms of Service and Privacy Policy.';
const USER_NOT_FOUND = 'This username/password combination does not exist.';
const CODE_NOT_MATCH = 'Invalid code. Please check your email for the latest code.';
const CODE_NOT_FOUND = "Your code expired. We've just sent you a new code, please check your email.";
const PASSWORD_REQUIRED = 'Password is required.';
const EMAIL_REQUIRED = 'Email is required.';
const CODE_REQUIRED = 'Verification code is required.';
const PROJECT_NAME_REQUIRED = 'Project name is required.';
const PROJECT_NAME_INVALID = 'Invalid project name.';
const PROJECT_NAME_TWO_CHARS_REQUIRED = 'Name must be atleast two characters.';
const ATTR_NAME_REQUIRED = 'Attribute name is required.';
const ATTR_NAME_INVALID = 'Attribute name cannot contain special characters or spaces.';
const ATTR_BEGIN_LETTER_NUMBER = 'Attribute name must begin with a letter or a number.';
const ATTR_UNIQUE = 'Attribute name must be unique.';
const ATTR_PARAM_EMPTY = 'Key cannot be empty.';
const ATTR_PARAM_INVALID = "Key can't have '.' or '_' or spaces";
const ONLY_LOWER_CASE = 'Only lowercase, numbers and dashes allowed.';
const SUBDOMAIN_REQUIRED = 'Subdomain is required.';
const SUBDOMAIN_TWO_CHARS_REQUIRED = 'Subdomain must be atleast two characters.';
const EMAIL_TAKEN_ERROR = 'This email is already taken. Please try another.';
const CONFIRM_EMAIL_ERROR = 'This confirmation key is invalid.';
const EMAIL_ALREADY_CONFIRMED = 'This account is already confirmed.';
const DATASOURCE_NAME_ERROR = 'Datasource Name is required';
const DATASOURCE_NAME_LENGTH_ERROR = 'Datasource Name must be 80 characters or less';
const DATASOURCE_GENERIC_URL_ERROR = 'URL must be valid';
const PROVIDER_NAME_ERROR = 'Provider Name is required';
const CLIENT_ID_ERROR = 'Client ID is required';
const CLIENT_SECRET_ERROR = 'Client Secret is required';
const USER_NOT_CONFIRMED = 'To perform this action, your account must be verified.';
const GENERIC_INTERNAL_ERROR =
  'An internal error occurred. Please try again or contact an administrator at support@flybits.com';

export {
  NOT_FOUND_404,
  PASSWORD_VALIDATION,
  PASSWORD_REQUIRED,
  SAME_PASSWORD_VALIDATION,
  EMAIL_VALIDATION,
  EMAIL_REQUIRED,
  CODE_REQUIRED,
  CONFIRMATION_PASSWORD_VALIDATION,
  LAST_NAME_VALIDATION,
  LAST_NAME_REQUIRED,
  FIRST_NAME_VALIDATION,
  FIRST_NAME_REQUIRED,
  CHANGE_PASSWORD_QUERY_PARAM_ERROR,
  PROJECT_NAME_REQUIRED,
  PROJECT_NAME_INVALID,
  PROJECT_NAME_TWO_CHARS_REQUIRED,
  ATTR_NAME_REQUIRED,
  ATTR_NAME_INVALID,
  ATTR_BEGIN_LETTER_NUMBER,
  ATTR_UNIQUE,
  ATTR_PARAM_EMPTY,
  ATTR_PARAM_INVALID,
  ONLY_LOWER_CASE,
  SUBDOMAIN_REQUIRED,
  SUBDOMAIN_TWO_CHARS_REQUIRED,
  USER_TERMS_OF_SERVICE_REQUIRED,
  DATASOURCE_NAME_ERROR,
  DATASOURCE_NAME_LENGTH_ERROR,
  DATASOURCE_GENERIC_URL_ERROR,
  PROVIDER_NAME_ERROR,
  GENERIC_INTERNAL_ERROR,
  EMAIL_TAKEN_ERROR,
  CONFIRM_EMAIL_ERROR,
  EMAIL_ALREADY_CONFIRMED,
  CLIENT_ID_ERROR,
  CLIENT_SECRET_ERROR,
  USER_NOT_CONFIRMED,
  ORGANIZATION_NAME_REQUIRED,
  ORGANIZATION_NAME_VALIDATION,
  ORGANIZATION_URL_VALIDATION,
  USER_NOT_FOUND,
  CODE_NOT_MATCH,
  CODE_NOT_FOUND,
  TEAM_NAME_REQUIRED,
  TEAM_NAME_VALIDATION,
  JSON_VALIDATION,
};
