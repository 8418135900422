import React from 'react';
import formatDistance from 'date-fns/formatDistance';
import { parseJson, JSONError } from './json.helper';
import Ajv from 'ajv';

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);
export const generateRandomString = (length: number) => {
  let randomString = '';
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    randomString += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return randomString;
};
export const toType = (obj: any) => {
  return Object.prototype.toString.call(obj).slice(8, -1);
};
//  Usage: await sleep(2); // 2 seconds
export const sleep = async (timeout = 1) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout * 1000);
  });
};
export const arrayUnique = (array: any[]) => {
  const a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i].key === a[j].key) a.splice(j--, 1);
    }
  }
  return a;
};
export const isValidJson = (jsonString: string) => {
  try {
    parseJson(jsonString);
    return { valid: true };
  } catch (objError) {
    if (objError instanceof JSONError) {
      console.error(objError.name, objError.message);
    }
    return { valid: false, error: objError };
  }
};
export const isValidSchema = (data: any, schema: any) => {
  const ajv = new Ajv({ strictTuples: false });
  const validate = ajv.compile(schema);
  const valid = validate(data); // validates with jsonschema
  if (!valid) {
    return false;
  }
  return true;
};
export const copyToClipboard = (content: string) => {
  navigator.clipboard.writeText(content).then(
    () => {
      console.log('Copied:', content);
    },
    () => {
      const el = document.createElement('textarea');
      el.value = content;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
  );
};
export const pasteFromClipboard = () => {
  return new Promise((resolve) => {
    navigator.clipboard.readText().then(
      (clipText) => {
        resolve(clipText);
      },
      () => {
        // failed, use deprecate format
        const el = document.createElement('textarea');
        document.body.appendChild(el);
        el.focus();
        el.select();
        document.execCommand('Paste');
        const pastedContent = el.value;
        document.body.removeChild(el);
        resolve(pastedContent);
      },
    );
  });
};
export interface ObjectComparison {
  added: any;
  updated: {
    [propName: string]: Change;
  };
  removed: any;
  unchanged: any;
}
export interface Change {
  oldValue: any;
  newValue: any;
}
/**
 * @return if obj is an Object, including an Array.
 */
export const isObject = (obj: any) => {
  return obj !== null && typeof obj === 'object';
};

export const diffObject = (o1: any, o2: any, deep = false): ObjectComparison => {
  const added: any = {};
  const updated: any = {};
  const removed: any = {};
  const unchanged: any = {};
  for (const prop in o1) {
    if (o1.hasOwnProperty(prop)) {
      const o2PropValue = o2[prop];
      const o1PropValue = o1[prop];
      if (o2.hasOwnProperty(prop)) {
        if (o2PropValue === o1PropValue) {
          unchanged[prop] = o1PropValue;
        } else {
          updated[prop] =
            deep && isObject(o1PropValue) && isObject(o2PropValue)
              ? diffObject(o1PropValue, o2PropValue, deep)
              : { newValue: o2PropValue };
        }
      } else {
        removed[prop] = o1PropValue;
      }
    }
  }
  for (const prop in o2) {
    if (o2.hasOwnProperty(prop)) {
      const o1PropValue = o1[prop];
      const o2PropValue = o2[prop];
      if (o1.hasOwnProperty(prop)) {
        if (o1PropValue !== o2PropValue) {
          if (!deep || !isObject(o1PropValue)) {
            updated[prop].oldValue = o1PropValue;
          }
        }
      } else {
        added[prop] = o2PropValue;
      }
    }
  }
  return { added, updated, removed, unchanged };
};

export const timeFromNowTo = (timeEpoch: number) => {
  if (timeEpoch === -1) return 'Never';
  return formatDistance(Date.now(), new Date(timeEpoch * 1000));
};

export const removeProperties = (object: any, ...keys: any) =>
  Object.entries(object).reduce(
    (prev, [key, value]) => ({ ...prev, ...(!keys.includes(key) && { [key]: value }) }),
    {},
  );

export const handleize = (str: string) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaaeeeeiiiioooouuuunc------';

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, ''); // trim - from end of text

  return str;
};

export const mapChildrenRecursive = (children: any, callback: (arg0: any) => any): any => {
  return React.Children.map(children, (child) =>
    child.props && child.props.children
      ? [callback(child), mapChildrenRecursive(child.props.children, callback)]
      : callback(child),
  );
};

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const epochToUTC = (epoch: number) => {
  const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCSeconds(epoch);
  return d.toLocaleDateString('en-ca', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const strip_tags = (input: string, allowed = '') => {
  allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');
  const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  return input.replace(tags, ($0, $1) => (allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''));
};

export const addPropsToChild = (child: any, newProps: any) => React.cloneElement(child, newProps);

export const addPropsToChildren = (children: any, newProps: any) =>
  React.Children.map(children, (child) => addPropsToChild(child, newProps));

export const mockApiCall = (success = true, timeout = 1000, mockResultObject?: any) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (success) {
        resolve(
          mockResultObject
            ? { status: 200, data: { ...mockResultObject } }
            : { status: 200, data: { message: 'Success' } },
        );
      } else {
        reject({ status: 400, message: 'Generic Error' });
      }
    }, timeout);
  });
};

// Language detector
export const getBrowserLocales = (options = {}) => {
  const defaultOptions = {
    languageCodeOnly: true, // when TRUE, it uses 'en' instead of 'en-US'
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();

    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
};
export function calculateCenterPoint(targetWidth: number, targetHeight: number) {
  const screenX = window.screenX || window.screenLeft;
  const screenY = window.screenY || window.screenTop;
  const outerWidth = window.outerWidth || document.documentElement.clientWidth;
  const outerHeight = window.outerHeight || document.documentElement.clientHeight;

  const left = screenX + (outerWidth - targetWidth) / 2;
  const top = screenY + (outerHeight - targetHeight) / 2;

  return {
    left,
    top,
  };
}

export function windowOptionsMapper(object: Record<string, unknown>) {
  const options = Object.entries(object).map(([key, value]) => `${key}=${value}`);
  return options.join(',');
}

export const openInNewWindow = (callbackOptions?: any, event?: React.MouseEvent) => {
  event?.preventDefault?.();
  const defaultOptions = {
    name: '_blank',
    centered: true,
    focus: true,
    specs: {
      height: 450,
      width: 800,
      scrollbars: 'yes',
    },
  };
  const url = callbackOptions.url;
  const options = callbackOptions;
  const { specs } = options;
  const { specs: defaultSpecs } = defaultOptions;
  let mixedOptions = {
    ...defaultOptions,
    ...options,
  };
  let mixedSpecs = { ...defaultSpecs, ...specs };
  const urlToOpen = callbackOptions?.url || url;

  // If options passed as first argument then serve different callback
  if (typeof callbackOptions === 'object') {
    const { specs: callbackSpecs } = callbackOptions;
    mixedOptions = { ...mixedOptions, ...callbackOptions };
    mixedSpecs = { ...mixedSpecs, ...callbackSpecs };
  }

  const { focus, name, centered } = mixedOptions;
  let windowOptions = '';

  if (centered) {
    const { width, height, ...restSpecs } = mixedSpecs;
    const centerPoint = calculateCenterPoint(width, height);
    windowOptions = windowOptionsMapper({
      width,
      height,
      ...centerPoint,
      ...restSpecs,
    });
  } else {
    windowOptions = windowOptionsMapper(mixedSpecs);
  }

  const newWindow = window.open(urlToOpen, name, windowOptions);

  // Puts focus on the newWindow
  if (focus && newWindow) newWindow.focus();

  return newWindow;
};

export const handleKeyDownHelper = (activateFunc: any, cancelFunc?: any) => (e: any) => {
  switch (e.key) {
    case 'Esc':
    case 'Escape':
      if (typeof cancelFunc === 'function') {
        e.preventDefault();
        cancelFunc();
      }
      break;
    case ' ':
    case 'SpaceBar':
    case 'Enter':
      if (typeof activateFunc === 'function') {
        e.preventDefault();
        activateFunc();
      }
      break;
    default:
      break;
  }
};

export const handleKeyDownHelperMap = (keyMappings: any) => (e: any) => {
  if (keyMappings.has(e.key) && typeof keyMappings.get(e.key) === 'function') {
    e.preventDefault();
    keyMappings.get(e.key)(e);
  }
};

export const isSame = (obj1: any, obj2: any) => {
  if (!obj1 || !obj2) return false;
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  return obj1Keys.length === obj2Keys.length && obj1Keys.every((key) => obj1[key] === obj2[key]);
};
