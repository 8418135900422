import React, { useEffect, useState } from 'react';
import { useSecretCode } from 'src/hooks/useSecretCode';
import SvgFlybitsLogo from 'src/assets/FlybitsLogo';
import './SecretCode.scss';
import localforage from 'localforage';

const konamiCode = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'KeyB',
  'KeyA',
];

const rocketLaunch = new Audio(process.env.PUBLIC_URL + '/rocket.mp3');
const errorAudio = new Audio(process.env.PUBLIC_URL + '/error.mp3');
const tadaSound = new Audio(process.env.PUBLIC_URL + '/tada.mp3');

const SecretCode = () => {
  const secretCode = useSecretCode(konamiCode);
  const [justinEnabled, setJustinEnabled] = useState(false);
  useEffect(() => {
    localforage.getItem('JustinEnabled').then((v) => {
      if (!v) setJustinEnabled(false);
      else setJustinEnabled(true);
    });
  }, []);
  useEffect(() => {
    if (secretCode === true) {
      if (justinEnabled) {
        errorAudio.play();
        setJustinEnabled(false);
        localforage.removeItem('JustinEnabled');
      } else {
        setJustinEnabled(true);
        rocketLaunch.play();
        setTimeout(() => {
          tadaSound.play();
        }, 6000);
        localforage.setItem('JustinEnabled', true);
      }
    }
  }, [secretCode]);
  return (
    <>
      {secretCode && (
        <div className="secret">
          <div className="window">{justinEnabled && <div className="burn"></div>}</div>
          {justinEnabled && (
            <div className="space-shuttle">
              <div className="orbiter">
                <SvgFlybitsLogo width={52} viewBox="0 0 104 30" {...{ className: `flybitsFullLogo` }} />
                <div className="orbiter_window"></div>
                <div className="orbiter_wing orbiter_wing--left">
                  <SvgFlybitsLogo width={104} viewBox="0 0 104 30" />
                </div>
                <div className="orbiter_wing orbiter_wing--right">
                  <SvgFlybitsLogo width={104} viewBox="0 0 104 30" />
                </div>
                <div className="orbiter_fin orbiter_fin--left"></div>
                <div className="orbiter_fin orbiter_fin--right"></div>
              </div>
              <div className="rocket">
                <div className="rocket_booster rocket_booster--left"></div>
                <div className="rocket_booster rocket_booster--right"></div>
              </div>
            </div>
          )}
          <div className={`justin ${justinEnabled ? 'animated' : ''}`}>
            <h1>Justin mode is {justinEnabled ? 'ON' : 'OFF'}!</h1>
            {justinEnabled && <h3>Please put your headphones for a better experience.</h3>}
            <p>
              <i>
                * Run this code again to ENABLE/DISABLE this <u>Feature</u>
                <br />
                ** Press <strong>ESC</strong> to close this window.
              </i>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SecretCode;
