/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { FlightButton, FlightModal, getIcon } from '@flybits/design-system';
import { ReactComponent as TailSvg } from 'src/assets/icons/red-cross.svg';
import './Remapping.scss';
import Xarrow, { Xwrapper } from 'react-xarrows';

// DND
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ParamSource } from './ParamSource';
import { ParamDest } from './ParamDest';
const errorIcon = getIcon('error', { fill: '#dc3616' });
const warningIcon = getIcon('warning', { fill: '#ffb005' });

interface Props {
  t: any;
  param_name?: string;
  initialValues?: any;
  currentMapping?: any;
  handleRemove?: any;
  resolve?: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummyParamRemapping = [
  { from: 'source1', to: 'dest1' },
  { from: 'source3', to: 'dest2' },
];

// RULES FOR PARAMETER REMAPPING

// #00
// if sources === 0 AND destinations === 0
// ==> just map 1:1 + skip dialog

// #01
// if sources === 0 AND destinations > 0 AND some destinations don't have a default value
// ==> type: error
// ==> message: 'All destination params must be mapped or have a default value'
// ==> save button: disabled

// #02
// if sources > 0 AND destinations === 0 AND some sources don't have a condition value
// ==> type: warning
// ==> message: 'All source params without conditions will be ignored'
// ==> save button: enabled

// #03
// if sources > 0 AND destinations > 0 AND sources > destinations
// ==> type: warning
// ==> message: 'All source params without mapping or conditions will be ignored'
// ==> save button: enabled
/**
 "from": {
    "attribute": "ctx.integrations-datasource.dsid.query.attr1",
    "sourceParams": [
      {
        "to": 0,
        "condition": ""
      },
      {
        "to": 1,
        "condition": "hi" // not mandatory
      }
    ],
    "destinationParams": [
      {
        "from": 0,
        "default": ""
      },
      {
        "from": 1,
        "default": ""
      },
      {
        "from": -1, // not mapped = extra goes to -1 index
        "default": "bye" // mandatory condition
      }
    ]
  }
 */

// #04
// if sources > 0 AND destinations > 0 AND sources < destinations
// ==> type: error
// ==> message: 'All destination params must be mapped or have a default value and some destinations don't have a default value'
// ==> save button: disabled

// #05
// if sources > 0 AND destinations > 0 AND sources === destinations [swap order?]
// ==> type: warning
// ==> message: 'All destination params must be mapped or have a default value'
// ==> save button: enabled
/** example
 "from": {
    "attribute": "ctx.integrations-datasource.dsid.query.attr1",
    "sourceParams": [
      {
        "to": 1, //dest index
        "condition": ""
      },
      {
        "to": 0, // dest index
        "condition": "hi"
      }
    ],
    "destinationParams": [
      {
        "from": 1, // src index
        "default": ""
      },
      {
        "from": 0, // src index
        "default": ""
      }
    ]
  }
 */

// Async Modal
export default function getParameterRemappingValue(initialValue: any, currentMapping: any, title: string, t: any) {
  return new Promise((resolve) => {
    addDialog(initialValue, currentMapping, title, t, resolve);
  });
}
function addDialog(initialValue: any, currentMapping: any, title: string, t: any, resolve: any) {
  const body = document.getElementsByTagName('body')[0];
  const div = document.createElement('div');
  div.setAttribute('id', 'parameter-remapping-dialog');
  body.appendChild(div);
  ReactDOM.render(
    <ParameterRemappingDialog
      initialValues={initialValue}
      currentMapping={currentMapping}
      param_name={title}
      resolve={resolve}
      t={t}
    />,
    div,
  );
}

function removeDialog() {
  const div: any = document.getElementById('parameter-remapping-dialog');
  div.remove();
}
export const ParameterRemappingDialog: React.FunctionComponent<Props> = (props: Props) => {
  const { t, param_name, initialValues, currentMapping, resolve } = props;
  const { sources = [], destinations = [] } = initialValues;
  const MAIN_CLASS = 'Remapping';
  const [parametersRemapping, setParametersRemapping] = useState<any>({ sources, destinations });
  const [errors, setErrors] = useState<any>({ type: '', message: '', skip: false, enabled: true });
  const [updatedRemapping, setUpdatedRemapping] = useState<any>(currentMapping);
  const [paramLinks, setParamLinks] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clickedSource, setClickedSource] = useState<any>(null); //for accessibility
  const removeLink = (node: any) => {
    const fromAttribute: any = {
      sourceParams: updatedRemapping?.sourceParams || [],
      destinationParams: updatedRemapping?.destinationParams || [],
    };
    const sourceIndex = parametersRemapping?.sources?.findIndex((src: any) => src.id === node.start);
    const destinationIndex = parametersRemapping?.destinations?.findIndex((dest: any) => dest.id === node.end);
    fromAttribute.sourceParams[sourceIndex].to = -1;
    fromAttribute.destinationParams[destinationIndex].from = -1;
    setUpdatedRemapping(fromAttribute);
  };
  const errorCheck = useCallback(() => {
    // Rule #01
    if (!parametersRemapping?.sources?.length && parametersRemapping?.destinations?.length) {
      setErrors({
        type: 'error',
        message: 'All destination params must be mapped or have a default value',
        skip: false,
        enabled: false,
      });
      return;
    }
    // Rule #02
    if (parametersRemapping?.sources?.length && !parametersRemapping?.destinations?.length) {
      setErrors({
        type: 'warning',
        message: 'All source params without mapping or conditions will be ignored',
        skip: false,
        enabled: true,
      });
      return;
    }
    // Rules #03 and #4
    if (parametersRemapping?.sources?.length && parametersRemapping?.destinations?.length) {
      // Rule 03
      if (parametersRemapping?.sources?.length >= parametersRemapping?.destinations?.length) {
        setErrors({
          type: 'warning',
          message: 'All source params without mapping or conditions will be ignored',
          skip: false,
          enabled: true,
        });
        return;
      }
      // Rule 04
      if (parametersRemapping?.sources?.length <= parametersRemapping?.destinations?.length) {
        // check for destinationParams if some item has -1 index
        const hasError = updatedRemapping?.destinationParams?.some((dest: any) => {
          return dest.from === -1 && dest.default === '' ? true : false;
        });

        if (hasError) {
          setErrors({
            type: 'error',
            message:
              "All destination params must be mapped or have a default value and some destinations don't have a default value",
            skip: false,
            enabled: false,
          });
          return;
        }
      }
    }
    setErrors({ type: '', message: '', skip: false, enabled: true });
  }, [parametersRemapping, updatedRemapping]);
  const handleChange = useCallback(
    async (paramOrigin: string, id: string, value: string) => {
      const { sources, destinations } = parametersRemapping;
      let srcIndex = -1;
      let destIndex = -1;
      const fromAttribute: any = {
        sourceParams: updatedRemapping?.sourceParams || [],
        destinationParams: updatedRemapping?.destinationParams || [],
      };
      setParametersRemapping({
        sources:
          paramOrigin === 'source'
            ? sources?.map((param: any) => {
                if (param.id === id) {
                  srcIndex = param.index;
                  return { ...param, condition: value };
                }
                return param;
              })
            : sources,
        destinations:
          paramOrigin === 'destination'
            ? destinations?.map((param: any) => {
                if (param.id === id) {
                  destIndex = param.index;
                  return { ...param, default: value };
                }
                return param;
              })
            : destinations,
      });
      if (paramOrigin === 'destination' && destIndex >= 0) {
        fromAttribute.destinationParams[destIndex].default = value;
      }
      if (paramOrigin === 'source' && srcIndex >= 0) {
        fromAttribute.sourceParams[srcIndex].condition = value;
      }
      setUpdatedRemapping(fromAttribute);
    },
    [parametersRemapping, updatedRemapping],
  );
  // TODO:
  // Add onclick/onkeydown event to source
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClickSource = useCallback((source: any) => {
    //This is for accessibility reasons
  }, []);
  const handleDrop = useCallback(
    async (source: any, destination: any) => {
      const sourceParam = {
        to: destination.index, //dest index
        condition: source.condition,
      };
      const destinationParam = {
        from: source.index, //dest index
        default: destination.default,
      };
      const fromAttribute: any = {
        sourceParams: updatedRemapping?.sourceParams || [],
        destinationParams: updatedRemapping?.destinationParams || [],
      };
      // check for previous data from updatedRemapping and find the from/to indexes for each to update
      // remove old link (set to -1)
      const oldDestIndex: number = fromAttribute.destinationParams?.findIndex(
        (item: any) => item.from === source.index,
      );
      if (oldDestIndex >= 0) fromAttribute.destinationParams[oldDestIndex].from = -1;
      // add new link
      fromAttribute.sourceParams[source.index] = sourceParam;
      fromAttribute.destinationParams[destination.index] = destinationParam;
      setUpdatedRemapping(fromAttribute);
    },
    [updatedRemapping, parametersRemapping],
  );
  const formatedSourceDest = () => {
    // transform the sourceParams/destinationParams formt into a more readable format than the default
    // build the array
    const arrArrows: any[] = [];
    updatedRemapping?.destinationParams?.map((dest: any) => {
      // get the index from the destination because of... reasons

      if (dest.from !== -1) {
        const from = parametersRemapping?.sources?.find((src: any) => src.index === dest.from);
        const to = parametersRemapping?.destinations?.find(
          (destination: any) => updatedRemapping?.sourceParams[dest.from].to === destination.index,
        );
        if (from && to) arrArrows.push({ from: from.id, to: to.id });
      }
    });
    // connect the arrows
    setParamLinks(arrArrows);
  };
  // pre format while everything is empty
  const setDefaultRemapping = () => {
    // set all to -1
    // An index value of -1 indicates no source or destination index, either source param will be ignored OR destination param requires a default value
    const fromAttribute: any = {
      sourceParams: [],
      destinationParams: [],
    };
    parametersRemapping?.sources?.map(() => {
      fromAttribute.sourceParams.push({
        to: -1, //dest index
        condition: '',
      });
    });
    parametersRemapping?.destinations?.map(() => {
      fromAttribute.destinationParams.push({
        from: -1, //src index
        default: '',
      });
    });

    setUpdatedRemapping(fromAttribute);
  };
  useEffect(() => {
    if (updatedRemapping?.sourceParams && updatedRemapping?.destinationParams) {
      formatedSourceDest();
    } else {
      setDefaultRemapping();
    }
    errorCheck();
  }, [parametersRemapping, updatedRemapping]);
  return (
    <FlightModal
      isVisible={true}
      toggleModalShown={async () => {
        resolve({ ok: false });
        removeDialog();
      }}
      scrollable={true}
      size="large"
      warning={false}
      className={`${MAIN_CLASS}__parameter-remapping`}
      header={<span>Remapping parameters for '{param_name}'</span>}
      content={
        <div className={`${MAIN_CLASS}__parameter-remapping-content`}>
          <DndProvider backend={HTML5Backend}>
            <Xwrapper>
              <div className="column">
                <strong>Source</strong>
                <div className="params">
                  {parametersRemapping?.sources?.map((source: any) => (
                    <ParamSource
                      key={source.key}
                      id={source.key}
                      name={source.key}
                      type={source.valueType}
                      index={source.index}
                      condition={source.condition}
                      onChange={handleChange}
                      isDropped={false}
                    />
                  ))}
                </div>
                {parametersRemapping?.sources.length < 1 && <small>No parameters found</small>}
              </div>
              <div className="column destination">
                <strong>Destination</strong>
                <div className="params">
                  {parametersRemapping?.destinations?.map((dest: any) => (
                    <ParamDest
                      accept={[dest.valueType]}
                      onDrop={(src: any) => handleDrop(src, dest)}
                      onChange={handleChange}
                      key={dest.key}
                      mapping={updatedRemapping?.destinationParams}
                      data={{
                        id: dest.key,
                        index: dest.index,
                        name: dest.key,
                        type: dest.valueType,
                        default: dest.default,
                        defaultValue: dest.default,
                      }}
                    />
                  ))}
                </div>
                {parametersRemapping?.destinations.length < 1 && <small>No parameters found</small>}
              </div>
              {/* Each connection between src.id <-> dest.id */}
              {paramLinks.map((arrow: any, idx: number) => {
                return (
                  <Xarrow
                    key={`arrow-${idx}`}
                    start={arrow.from}
                    end={arrow.to}
                    strokeWidth={3.5}
                    headSize={3.5}
                    headShape="circle"
                    showTail
                    tailSize={8}
                    path={'grid'}
                    divContainerProps={{ className: 'arrow-body' }}
                    tailShape={{
                      svgElem: <TailSvg className="remove-icon" />,
                      offsetForward: 1,
                    }}
                    animateDrawing
                    curveness={0.5}
                    color="#0074F9"
                    passProps={{ onClick: () => removeLink({ index: idx, start: arrow.from, end: arrow.to }) }}
                  />
                );
              })}
            </Xwrapper>
          </DndProvider>
        </div>
      }
      footer={
        <div className={`${MAIN_CLASS}__parameter-remapping-footer`}>
          <div className={`infos ${errors ? (errors.type === 'warning' ? 'warning' : 'error') : ''}`}>
            {errors && errors.type ? (errors.type === 'warning' ? warningIcon : errorIcon) : null} {errors.message}
          </div>
          <FlightButton
            type="button"
            label={'Save mapping'}
            disabled={!errors.enabled || !errors}
            onClick={async () => {
              resolve({ ok: true, parametersRemapping, updatedRemapping });
              removeDialog();
            }}
          />
          <FlightButton
            type="button"
            theme="secondary"
            label={t('translation:general.buttons.cancel', 'Cancel')}
            onClick={() => {
              setParametersRemapping(null);
              resolve({ ok: false });
              removeDialog();
            }}
          />
        </div>
      }
    />
  );
};
