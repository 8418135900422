import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FlightButton, FlightTextInput } from '@flybits/design-system';
import useNotification from 'src/hooks/useNotification';
import AuthService from 'src/services/authentication.service';
import {
  FIRST_NAME_REQUIRED,
  FIRST_NAME_VALIDATION,
  GENERIC_INTERNAL_ERROR,
  LAST_NAME_REQUIRED,
  LAST_NAME_VALIDATION,
} from 'src/constants/errors';
import LoadingIcon from '../Shared/LoadingIcon/LoadingIcon';
import './ChangeNameModal.scss';
import { nameRegex } from 'src/helpers/auth.helper';
import { ChangeNameParameters } from 'src/model/auth/auth';

type Props = {
  defaultValues: ChangeNameParameters;
  toggleModal: () => void;
  onSuccess: (params: ChangeNameParameters) => void;
};

const MAIN_CLASS = 'change-name-modal';
const CONTENT_CLASS = `${MAIN_CLASS}__content`;
const authServiceManager = new AuthService();
const ChangeNameModal: FunctionComponent<Props> = ({ toggleModal, defaultValues, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { addNotification, addNotificationError } = useNotification();
  const { t } = useTranslation(['translation', 'account']);

  const validationSchema = Yup.object().shape({
    givenName: Yup.string()
      .required(t('errors:FIRST_NAME_REQUIRED', FIRST_NAME_REQUIRED))
      .matches(nameRegex, t('errors:FIRST_NAME_VALIDATION', FIRST_NAME_VALIDATION)),
    familyName: Yup.string()
      .required(t('errors:LAST_NAME_REQUIRED', LAST_NAME_REQUIRED))
      .matches(nameRegex, t('errors:LAST_NAME_VALIDATION', LAST_NAME_VALIDATION)),
  });

  const initChangeName = async (values: ChangeNameParameters) => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await authServiceManager.changeName({
        givenName: values.givenName,
        familyName: values.familyName,
      });
      if (response) {
        setIsLoading(false);
        addNotification(t('account:change_name_modal.messages.change_success', 'Name successfully updated.'));
        onSuccess(values);
        return true;
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        setErrorMessage(error.response.data.message);
        addNotificationError(error.response.data.message);
      } else {
        setErrorMessage(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
        addNotificationError(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
      }
      console.error(error);
      setIsLoading(false);
      return false;
    }
    return true;
  };

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema}
      validateOnChange
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        const result = await initChangeName(values);
        if (result) {
          resetForm();
          toggleModal();
        }
      }}
    >
      {({ values, errors, touched, dirty, handleChange, handleBlur, handleSubmit }) => (
        <form>
          {isLoading && <LoadingIcon width={80} height={80} visible={isLoading} fullScreen />}
          {!!errorMessage && (
            <div className="form__error" role="alert" aria-atomic>
              <b>Error:</b> {errorMessage}
            </div>
          )}
          <div className={CONTENT_CLASS}>
            <Field
              type="text"
              name="givenName"
              className={`${CONTENT_CLASS}__input`}
              width="100%"
              as={FlightTextInput}
              hasError={touched.givenName && errors.givenName ? true : false}
              isLabelAlwaysOn
              ariaLabel={t('account:change_name_modal.given_name', 'First name')}
              label={t('account:change_name_modal.given_name', 'First name')}
              value={values.givenName}
              errorMessage={
                <span role="alert" aria-atomic>
                  <b>Error:</b> {errors.givenName}
                </span>
              }
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className={CONTENT_CLASS}>
            <Field
              type="text"
              name="familyName"
              className={`${CONTENT_CLASS}__input`}
              width="100%"
              as={FlightTextInput}
              isLabelAlwaysOn
              hasError={touched.familyName && errors.familyName ? true : false}
              label={t('account:change_name_modal.family_name', 'Last name')}
              ariaLabel={t('account:change_name_modal.family_name', 'Last name')}
              value={values.familyName}
              errorMessage={
                <span role="alert" aria-atomic>
                  <b>Error:</b> {errors.familyName}
                </span>
              }
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <FlightButton
            type="primary"
            size="medium"
            label={t('translation:general.buttons.submit', 'Submit')}
            ariaLabel={t('translation:general.buttons.submit', 'Submit')}
            className={`${CONTENT_CLASS}__submit`}
            disabled={
              (!dirty && !touched.givenName && !touched.familyName) || !!errors.givenName || !!errors.familyName
            }
            onClick={handleSubmit}
          />
        </form>
      )}
    </Formik>
  );
};

export default ChangeNameModal;
