import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import ConnectorService from 'src/services/connectors.service';
import { GENERIC_INTERNAL_ERROR } from 'src/constants/errors';
import { FlightButton, FlightModal, FlightTextInput } from '@flybits/design-system';
import './DeleteConnectorModal.scss';

interface ModalProps {
  className?: string;
  showModal: boolean;
  t: any;
  connectorData: any[];
  organizationID: string;
  apiVersion?: string;
  handleDeleteConnector: (deleted: boolean, message?: string) => void;
  toggleModal: () => void;
}

const DeleteConnectorModal = (props: ModalProps) => {
  const { className, showModal, connectorData, organizationID, toggleModal, handleDeleteConnector, t, apiVersion } =
    props;
  const connectorsServiceManager = new ConnectorService();
  const MAIN_CLASS = 'DeleteConnectorModal';

  const [title, setTitle] = useState(
    t('integrations:connectors.details.modals.delete.title', `Are you sure you want to delete this connector?`),
  );
  const [message, setMessage] = useState(
    t(
      'integrations:connectors.details.modals.delete.message',
      `To delete this connector enter the connector name below.`,
    ),
  );

  const initialValuesDeleteModal = {
    connectorNameDelete: '',
  };
  const deleteModalValidationSchema = Yup.object().shape({
    connectorNameDelete: Yup.string()
      .required(t('integrations:connectors.details.modals.delete.label', 'Connector name should not be empty'))
      .test(
        'name-match',
        t('integrations:connectors.details.modals.delete.error', 'Connector name does not match'),
        (value) => value?.trim() === connectorData[1].trim(),
      ),
  });
  const handleClose = () => {
    toggleModal();
  };
  const handleDelete = async (data: string[]) => {
    if (!data.length) return;
    try {
      const connectorsResponseObject = await connectorsServiceManager.updateConnectorStatus(
        organizationID,
        data[0],
        'delete',
        apiVersion,
      );
      if (connectorsResponseObject && [200, 201, 204].includes(connectorsResponseObject.status)) {
        handleDeleteConnector(true);
        return true;
      }
    } catch (error: any) {
      console.error(error);
      handleDeleteConnector(
        false,
        error?.response?.data?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR),
      );

      return false;
    }
    return false;
  };

  useEffect(() => {
    const getSharedOrgs = async () => {
      try {
        const connectorsResponseObject = await connectorsServiceManager.getConnectorSharedOrganizations(
          organizationID,
          connectorData[0],
        );
        if (connectorsResponseObject?.data && connectorsResponseObject?.data.length) {
          setTitle(
            t(
              'integrations:connectors.details.modals.delete.in-use',
              'This connector is currently in use. Are you sure you want to delete?',
            ),
          );
          setMessage(
            t('integrations:connectors.details.modals.delete.in-use-message', {
              org_number: connectorsResponseObject?.data.length,
            }),
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    showModal && getSharedOrgs();
  }, [showModal]);
  return (
    <FlightModal
      isVisible={showModal}
      toggleModalShown={handleClose}
      scrollable={false}
      size="small"
      warning={false}
      className={` ${MAIN_CLASS}__alert ${className || ''}`}
      header={<span>{title}</span>}
      content={
        <Formik
          initialValues={initialValuesDeleteModal}
          validationSchema={deleteModalValidationSchema}
          validateOnChange
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            const result = await handleDelete(connectorData);
            if (result) {
              resetForm();
              handleClose();
            }
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <form>
              <span>{message}</span>
              <div className="input_remove">
                <Field
                  type="text"
                  name="connectorNameDelete"
                  width="100%"
                  as={FlightTextInput}
                  label={t('integrations:connectors.details.modals.delete.label', 'Enter connector name')}
                  ariaLabel={t('integrations:connectors.details.modals.delete.label', 'Enter connector name')}
                  value={values.connectorNameDelete}
                  hasError={errors.connectorNameDelete ? true : false}
                  errorMessage={
                    <span role="alert" aria-atomic>
                      <b>Error:</b> {errors.connectorNameDelete}
                    </span>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={`${MAIN_CLASS}__alert_footer modal_buttons`}>
                <FlightButton
                  type="button"
                  theme="secondary"
                  label={t('translation:general.buttons.cancel', 'Cancel')}
                  ariaLabel={t('translation:general.buttons.cancel', 'Cancel')}
                  onClick={handleClose}
                />
                <FlightButton
                  type="button"
                  theme="primary"
                  disabled={values.connectorNameDelete?.trim() !== connectorData[1]?.trim()}
                  label={t('integrations:connectors.details.modals.delete.button', 'Delete conector')}
                  ariaLabel={t('integrations:connectors.details.modals.delete.button', 'Delete conector')}
                  onClick={handleSubmit}
                />
              </div>
            </form>
          )}
        </Formik>
      }
    />
  );
};

export default DeleteConnectorModal;
