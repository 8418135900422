import React, { useEffect, useState } from 'react';
import './PreDefinedAttributes.scss';
import { ConnectorV1, PluginAttribute } from 'src/model/connectors/connectors';
import { ReactComponent as UserIcon } from 'src/assets/icons/person-icon.svg';
import { ReactComponent as TenantScopeIcon } from 'src/assets/icons/tenant-scope.svg';
import { FlightTable, FlightTextInput, FlightSelect, FlightOverflowMenu } from '@flybits/design-system';
import { SidePanel } from 'src/components/Shared/SidePanel/SidePanel';
import PluginAttributesPanel from 'src/components/Shared/PluginAttributesPanel/PluginAttributesPanel';
import { getDataExpiryText } from 'src/helpers/timeConverters';
interface Props {
  current: ConnectorV1 | undefined;
  t: any;
  onError: (tab: string, hasError: boolean) => void;
  onChangeValue: (e: any) => void;
}
const tableHeaders = [
  {
    key: 'scope',
    isVisible: false,
  },
  {
    key: 'scopeIcon',
    name: 'Scope',
    isVisible: true,
    hideTooltip: true,
  },
  {
    key: 'name',
    name: 'Name',
    isVisible: true,
    hideTooltip: true,
  },
  {
    key: 'nameValue',
    isVisible: false,
    hideTooltip: true,
  },
  {
    key: 'type',
    name: 'Input type',
    isVisible: true,
    hideTooltip: true,
  },
  {
    key: 'expiry',
    name: 'Data expiry',
    isVisible: true,
    hideTooltip: true,
  },
  {
    key: 'options',
    name: 'Options',
    isVisible: true,
    hideTooltip: true,
  },
];
const NameCol = (name: string) => {
  return <div className="name-col">{name}</div>;
};
interface AttrSelectedProps {
  key: number;
  name: JSX.Element | string;
  nameValue: string;
  scope: string;
  type: string;
  expiry: any;
  options: any;
}

const PreDefinedAttributes: React.FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'PreDefinedAttributes';
  const { current, t } = props;
  const { pluginAttributes = [] } = current?.configs || {};
  const [selectedAttribute, setSelectedAttribute] = useState<AttrSelectedProps | null>(null);
  const [, setSelectedAttributeIndex] = useState<number | null>(null);
  const [newAttribute, setNewAttribute] = useState<boolean>(false);
  const [currentAttribute, setCurrentAttribute] = useState<PluginAttribute | null>(null);
  const [typeFilters, setTypeFilters] = useState<any[]>([
    {
      key: 'all',
      name:
        t('integrations:connectors.details.sections.pre-defined-attributes.filters.any-type', `Any input type`) ||
        'Any input type',
    },
  ]);
  const [selectedFilterType, setSelectedFilterType] = useState({
    key: 'all',
    name:
      t('integrations:connectors.details.sections.pre-defined-attributes.filters.any-type', `Any input type`) ||
      'Any input type',
  });
  const [selectedFilterScope, setSelectedFilterScope] = useState({
    key: 'all',
    name:
      t('integrations:connectors.details.sections.pre-defined-attributes.filters.any-scope', `Any scope`) ||
      'Any scope',
  });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // set current type filters
    const filters = [
      {
        key: 'all',
        name:
          t('integrations:connectors.details.sections.pre-defined-attributes.filters.any-type', `Any input type`) ||
          'Any input type',
      },
    ];
    pluginAttributes?.map((item) => {
      !filters.some((e) => e.key === item.dataType) &&
        filters.push({
          key: item.dataType,
          name: formatInputType(item.dataType) || item.dataType,
        });
    });
    setTypeFilters(filters);
    resetSelection();
  }, [pluginAttributes]);

  const editSearchTerm = (e: any) => {
    setSearchTerm(e.target.value);
  };
  const resetSelection = () => {
    setCurrentAttribute(null);
    setNewAttribute(false);
    setSelectedAttribute(null);
    setSelectedAttributeIndex(null);
  };
  const selectPluginAttribute = (key: any) => {
    if (!key) return;
    const selected = filteredSearchData().find((item: any) => item.key === key);
    if (!selected) return;
    setSelectedAttribute(selected);
    setSelectedAttributeIndex(selected.key - 1);
    setCurrentAttribute(
      pluginAttributes?.find((item) => item.name.toLocaleLowerCase() === selected?.nameValue.toLocaleLowerCase()) ||
        null,
    );
    setNewAttribute(false);
  };
  const closePanel = () => {
    resetSelection();
  };
  const ScopeIcon = (scope: string) => {
    return scope === 'user' ? <UserIcon title="User scope" /> : <TenantScopeIcon title="Tenant scope" />;
  };
  const formatInputType = (type: string) => {
    switch (type) {
      case 'string':
        return 'String';
      case 'int':
        return 'Integer';
      case 'float':
        return 'Float';
      case 'bool':
      case 'boolean':
        return 'Yes/No';
      default:
        return type;
    }
  };
  const filteredSearchData = () => {
    if (!current || !pluginAttributes) return [];
    const selectedResource = pluginAttributes?.map((item: PluginAttribute, idx: number) => ({
      key: idx + 1,
      data: item,
      scope: item.scope,
      scopeIcon: ScopeIcon(item.scope),
      name: NameCol(item.name),
      nameValue: item.name,
      type: formatInputType(item.dataType),
      expiry: `${getDataExpiryText(Number(item.defaultExpDuration)).value} ${
        getDataExpiryText(Number(item.defaultExpDuration)).unit
      }`,
      options: (
        <FlightOverflowMenu
          ariaLabel={'Open more options'}
          optionGroups={[
            [
              {
                key: 1,
                name: t('translation:general.list.menus.view-details', 'View details'),
                disabled: false,
                onClick: () => selectPluginAttribute(idx + 1),
              },
            ],
          ]}
        />
      ),
    }));
    return selectedResource.filter((item) => {
      let matchScope = false;
      let matchType = false;
      // Scope

      switch (selectedFilterScope.key) {
        case 'all':
          matchScope = !searchTerm || item.nameValue.toLowerCase().includes(searchTerm.toLowerCase());
          break;
        case 'user':
          matchScope =
            item.scope === 'user' && (!searchTerm || item.nameValue.toLowerCase().includes(searchTerm.toLowerCase()));
          break;
        case 'tenant':
          matchScope =
            item.scope === 'tenant' && (!searchTerm || item.nameValue.toLowerCase().includes(searchTerm.toLowerCase()));
          break;
      }

      if (selectedFilterType.key !== 'all') {
        matchType =
          item.type === selectedFilterType.name &&
          (!searchTerm || item.nameValue.toLowerCase().includes(searchTerm.toLowerCase()));
      } else {
        matchType = !searchTerm || item.nameValue.toLowerCase().includes(searchTerm.toLowerCase());
      }

      return !!(matchType && matchScope);
    });
  };

  return (
    <div className={MAIN_CLASS}>
      <div className={`${MAIN_CLASS}__header`}>
        <h2 className="section__title">
          {t(
            'integrations:datasources.details.sections.pre-defined-attributes.title',
            'Pre-defined Context Attributes',
          )}
        </h2>
      </div>

      <div className={`${MAIN_CLASS}__filters`}>
        <FlightTextInput
          placeholderText=""
          width="222px"
          hasClearIcon
          trailingIcon="search"
          label={t('integrations:connectors.details.sections.pre-defined-attributes.filters.search', `Search`)}
          isLabelAlwaysOn
          type="text"
          name="table-filter"
          value={searchTerm}
          onChange={editSearchTerm}
        />
        <FlightSelect
          options={typeFilters}
          hasLabelAnimation
          label={`Filter by input type:`}
          selected={selectedFilterType}
          dropdownMaxHeight="150px"
          width="180px"
          handleOptionClick={setSelectedFilterType}
        />
        <FlightSelect
          options={[
            {
              key: 'all',
              name:
                t('integrations:connectors.details.sections.pre-defined-attributes.filters.any-scope', `Any scope`) ||
                'Any scope',
            },
            {
              key: 'user',
              name: t('integrations:connectors.details.sections.pre-defined-attributes.scopes.user', 'User') || 'User',
            },
            {
              key: 'tenant',
              name:
                t('integrations:connectors.details.sections.pre-defined-attributes.scopes.tenant', 'Tenant') ||
                'Tenant',
            },
          ]}
          hasLabelAnimation
          label={`Filter by scope:`}
          selected={selectedFilterScope}
          dropdownMaxHeight="150px"
          width="180px"
          handleOptionClick={setSelectedFilterScope}
        />
      </div>
      <div className={`${MAIN_CLASS}__table`}>
        <FlightTable
          className={`${MAIN_CLASS}__table-content`}
          allowRowSelect
          hasError={false}
          selectedDataKey={selectedAttribute?.key}
          emptyState={
            <div className={`${MAIN_CLASS}__table-content-table-no-results`}>
              <p>
                <strong>0</strong> attributes found{' '}
                {searchTerm && (
                  <>
                    with the term &ldquo;<strong>{searchTerm}</strong>&rdquo;
                  </>
                )}
              </p>
            </div>
          }
          tableData={filteredSearchData()}
          tableHeaders={tableHeaders}
          hasPaginationBeforeTable={false}
          handleDataSelect={(e) => selectPluginAttribute(e.key)}
          isShowHeader
        />
      </div>
      {current && (
        <SidePanel
          showPanel={!!currentAttribute}
          className={`${MAIN_CLASS}__panel`}
          title={
            selectedAttribute?.nameValue
              ? `Showing ${selectedAttribute.nameValue}'s attribute info panel`
              : `Create a new attribute info panel`
          }
          content={
            <PluginAttributesPanel
              t={t}
              onSave={() => undefined}
              onCancel={closePanel}
              isReadOnly
              isNewAttribute={newAttribute}
              pluginAttributes={pluginAttributes || []}
              attributeValue={currentAttribute}
            />
          }
          togglePanel={closePanel}
        />
      )}
    </div>
  );
};

export default PreDefinedAttributes;
