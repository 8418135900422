import React, { useEffect, useRef, useState } from 'react';
import { FlightButton, FlightModal, FlightTextInput } from '@flybits/design-system';
import './DeploymentModalV1.scss';

import { ConnectorDeploymentV1, ConnectorDeploymentModalV1 } from 'src/model/connectors/connectors';
import { deploymentRegex } from 'src/helpers/auth.helper';
import * as Yup from 'yup';
import { PROJECT_NAME_TWO_CHARS_REQUIRED } from 'src/constants/errors';
import { handleize } from 'src/helpers/general.helper';
import { ReactComponent as IconError } from 'src/assets/icon-blocked.svg';
import { KEYCODE_ENTER } from 'src/constants/general';

const defaultDeploymentData: ConnectorDeploymentV1 = {
  //one of (refresh endpoint, registration endpoint) AND jwks_uri is required. nofication endpoint is always optional.
  name: '',
  refreshRequestUrl: '',
  configRegistrationUrl: '',
  errorNotificationUrl: '', //Optional
  jwks_uri: '',
};

import { useTranslation } from 'react-i18next';
export function DeploymentModalV1(props: ConnectorDeploymentModalV1) {
  const { t } = useTranslation(['translation', 'errors', 'integrations', 'components']);
  const { id, openModal, cancelModal, data, saveAction } = props;
  const [action, setAction] = useState('');
  const [error, setError] = useState<string | string[]>('');
  const [touched, setTouched] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [currentID, setCurrentID] = useState(id);
  const [currentData, setCurrentData] = useState(data || defaultDeploymentData);

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .required(
          t('components:connector_deployment_modal.validations.displayName.required', 'Deployment name is required'),
        )
        .label('name')
        .trim()
        .min(2, t('errors:PROJECT_NAME_TWO_CHARS_REQUIRED', PROJECT_NAME_TWO_CHARS_REQUIRED))
        .matches(
          deploymentRegex,
          t(
            'components:connector_deployment_modal.validations.displayName.invalid',
            'Deployment name may not contain special characters.',
          ),
        ),
      jwks_uri: Yup.string()
        .url(t('components:connector_deployment_modal.validations.jwks_uri.invalid', 'Invalid JSON Web Key Set URI'))
        .required(
          t(
            'components:connector_deployment_modal.validations.jwks_uri.required',
            'You need to specify a JSON Web Key Set URI',
          ),
        ),
      errorNotificationUrl: Yup.string().url(
        t(
          'components:connector_deployment_modal.validations.error_endpoint.invalid',
          'Invalid Error Notification Endpoint URL',
        ),
      ),
      refreshRequestUrl: Yup.string()
        .url(t('components:connector_deployment_modal.validations.connectorUrl.invalid', 'Invalid endpoint URL'))
        .when('configRegistrationUrl', {
          is: (val: string) => !!val,
          then: Yup.string().url(
            t('components:connector_deployment_modal.validations.connectorUrl.invalid', 'Invalid endpoint URL'),
          ),
          otherwise: Yup.string().required(
            t(
              'components:connector_deployment_modal.validations.refresh_endpoint_or_registration_url',
              'You need to specify a valid Refresh Request URL or a Registration URL',
            ),
          ),
        }),

      configRegistrationUrl: Yup.string()
        .url(
          t(
            'components:connector_deployment_modal.validations.configRegistrationUrl.invalid',
            'Invalid registration URL',
          ),
        )
        .when('refreshRequestUrl', {
          is: (val: string) => !!val,
          then: Yup.string().url(
            t(
              'components:connector_deployment_modal.validations.configRegistrationUrl.invalid',
              'Invalid registration URL',
            ),
          ),
          otherwise: Yup.string().required(
            t(
              'components:connector_deployment_modal.validations.refresh_endpoint_or_registration_url',
              'You need to specify a valid Refresh Request URL or a Registration URL',
            ),
          ),
        }),
    },
    [['refreshRequestUrl', 'configRegistrationUrl']],
  );

  const handleChange = (e: any) => {
    let currentId = handleize(`${e.target.value}-${Date.now().toString().substr(-6)}`);
    if (e.target.name === 'name' && id === 'new' && e.target.value) {
      setCurrentID(currentId);
    } else {
      currentId = id;
      setCurrentID(id);
    }
    if (!touched.includes(e.target.name)) {
      setTouched([...touched, e.target.name]);
    }
    setCurrentData({ ...currentData, [e.target.name]: e.target.value });
  };
  const resetForm = () => {
    setAction('');
    setError('');
    setTouched([]);
    setIsValid(false);
    setSaving(false);
    setCurrentID('');
    setCurrentData(defaultDeploymentData);
  };
  const handleCloseModal = () => {
    resetForm();
    cancelModal();
  };

  const handleSaveAction = async () => {
    if (!currentData || !currentID || !isValid || saving) return;
    setSaving(true);
    const success = await saveAction(currentID, currentData);

    if (success) handleCloseModal();
    else setSaving(false);
  };
  const validateDeployment = async () => {
    await validationSchema
      .validate(currentData)
      .then(function () {
        setIsValid(true);
        setError('');
        setTouched([]);
      })
      .catch(function (err) {
        setIsValid(false);
        setError(err.errors);
      });
  };
  const submitRef = useRef<HTMLButtonElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent<any>) => {
    if (event.keyCode === KEYCODE_ENTER && (event.metaKey || event.ctrlKey) && isValid) {
      if (submitRef) {
        submitRef.current !== null && submitRef.current.click();
      }
    }
  };
  useEffect(() => {
    return () => {
      resetForm();
    };
  }, []);

  useEffect(() => {
    if (!openModal) setAction('');
    if (id === 'new') {
      setAction(t('translation:general.buttons.add', 'Add'));
      setCurrentID('');
    } else {
      setCurrentID(id);
      setAction(t('translation:general.buttons.edit', 'Edit'));
    }
  }, [id, openModal]);

  useEffect(() => {
    if (!data) return;
    setCurrentData(data);
  }, [data]);

  useEffect(() => {
    if (!currentData) return;
    // validate only if the form is changed
    touched.length && validateDeployment();
  }, [currentData]);

  return (
    <>
      <FlightModal
        isVisible={openModal}
        toggleModalShown={handleCloseModal}
        className="DeploymentModal"
        scrollable={false}
        size="large"
        header={<span>{t('components:connector_deployment_modal.heading', { action: action })}</span>}
        content={
          <div onKeyDown={handleKeyDown}>
            {error && (
              <span className="DeploymentModal__error-message">
                <IconError />
                {error.toString()}
              </span>
            )}
            <div className="section__fields section__fields-columns">
              <div className="section__fields-field">
                <label htmlFor="display-name">
                  {t('components:connector_deployment_modal.labels.deployment_name', 'Deployment name')}*
                </label>
                <FlightTextInput
                  placeholderText=""
                  disabled={saving}
                  width="360px"
                  type="text"
                  name="name"
                  labelId="display-name"
                  value={currentData?.name}
                  hasError={touched.includes('name') && !currentData?.name}
                  onChange={handleChange}
                  isAriaRequired
                />
              </div>
              <div className="section__fields-field">
                <label htmlFor="jwks_uri-url">
                  {t('integrations:connectors.details.sections.deployment.labels.jwks_uri', 'JSON Web Key Set URI')} *
                </label>
                <FlightTextInput
                  placeholderText=""
                  disabled={saving}
                  width="360px"
                  type="text"
                  name="jwks_uri"
                  value={currentData?.jwks_uri}
                  labelId="jwks_uri-url"
                  hasError={!Yup.string().optional().label('jwks_uri').url().isValidSync(currentData?.jwks_uri)}
                  onChange={handleChange}
                />
              </div>
              <div className="section__fields-field">
                <label htmlFor="connector-url">
                  {t('integrations:connectors.details.sections.deployment.labels.endpoint_url', 'Endpoint URL')}
                </label>
                <FlightTextInput
                  placeholderText=""
                  disabled={saving}
                  width="360px"
                  type="text"
                  name="refreshRequestUrl"
                  labelId="connector-url"
                  value={currentData?.refreshRequestUrl}
                  hasError={
                    !Yup.string()
                      .optional()
                      .label('refreshRequestUrl')
                      .url()
                      .isValidSync(currentData?.refreshRequestUrl)
                  }
                  onChange={handleChange}
                />
              </div>

              <div className="section__fields-field">
                <label htmlFor="config-registration-url">
                  {t(
                    'integrations:connectors.details.sections.deployment.labels.registration_url',
                    'Configuration URL',
                  )}
                </label>
                <FlightTextInput
                  placeholderText=""
                  disabled={saving}
                  width="360px"
                  type="text"
                  name="configRegistrationUrl"
                  value={currentData?.configRegistrationUrl}
                  labelId="config-registration-url"
                  hasError={
                    !Yup.string()
                      .optional()
                      .label('configRegistrationUrl')
                      .url()
                      .isValidSync(currentData?.configRegistrationUrl)
                  }
                  onChange={handleChange}
                />
              </div>

              <div className="section__fields-field">
                <label htmlFor="notificationUrl-url">
                  {t(
                    'integrations:connectors.details.sections.deployment.labels.error_endpoint',
                    'Error Notification Endpoint',
                  )}
                </label>
                <FlightTextInput
                  placeholderText=""
                  disabled={saving}
                  width="360px"
                  type="text"
                  name="errorNotificationUrl"
                  value={currentData?.errorNotificationUrl}
                  labelId="errorNotificationUrl-url"
                  hasError={
                    !Yup.string()
                      .optional()
                      .label('errorNotificationUrl')
                      .url()
                      .isValidSync(currentData?.errorNotificationUrl)
                  }
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        }
        footer={
          <div>
            <FlightButton
              type="button"
              disabled={saving}
              theme="secondary"
              label={t('translation:general.buttons.cancel', 'Cancel')}
              onClick={handleCloseModal}
            />
            <FlightButton
              type="button"
              theme="primary"
              label={t('translation:general.buttons.save', 'Save')}
              buttonRef={submitRef}
              loading={saving}
              disabled={!isValid || !!error.toString() || saving}
              onClick={async () => {
                await handleSaveAction();
              }}
            />
          </div>
        }
      />
    </>
  );
}
export default DeploymentModalV1;
