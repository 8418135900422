import React, { useState, useEffect } from 'react';
import { FlightButton, FlightTooltip } from '@flybits/design-system';
import { FieldColumnsConfig, HeadingConfig } from 'src/model/connectors/connectors';
import { Configs } from 'src/model/datasources/datasources';
import { ReactComponent as QuestionCircle } from 'src/assets/icons/question-circle.svg';
import { ReactComponent as IconError } from 'src/assets/icon-blocked.svg';
import './TableEdit.scss';
import EditField from './EditField';
interface TableEditProps {
  columns: FieldColumnsConfig[];
  configs: Configs | Record<string, never>;
  heading: HeadingConfig;
  canChange: boolean;
  onChangeValue: (e?: any) => void;
  t: any;
}

const TableEdit = (props: TableEditProps) => {
  const { t, columns, heading, onChangeValue, canChange, configs = {} } = props;
  const [error, setError] = useState(false);
  const HandleAddRow = () => {
    if (!canChange) return;
    const templateRow = Object.values(columns).map(({ name }: FieldColumnsConfig) => [name, '']);
    if (heading.isOutput) {
      templateRow.push(['dataType', '']);
      templateRow.push(['outputFieldName', '']);
    }
    configs.dynamicConfigs
      .filter((item) => item.headingName === heading.name)[0]
      .dynamicFields.push(Object.fromEntries([...templateRow, ['parameters', '[]']]));
    onChangeValue();
  };
  const HandleRemoveRow = (headerIndex: number, index: number) => {
    if (!canChange) return;
    configs.dynamicConfigs[headerIndex].dynamicFields.splice(index, 1);
    onChangeValue();
  };
  useEffect(() => {
    if (!configs) return;

    if (Object.keys(configs).length > 0) {
      // Dynamic check for errors
      setError(
        configs?.dynamicConfigs?.some((dynamicConfig) => {
          if (dynamicConfig.headingName === heading.name) {
            return dynamicConfig.dynamicFields.some((field) =>
              Object.entries(field).some(
                (column: any) => column[0] !== 'parameters' && String(field[column[0]]).trim() === '',
              ),
            );
          }
          return false;
        }),
      );
    }
  }, [configs]);
  return (
    <>
      {error && (
        <div className="TableError">
          <IconError />{' '}
          {t('integrations:datasources.details.sections.configs.missing_data', 'Required fields missing data.')}
        </div>
      )}
      <div>
        <table className="TableEdit">
          <thead>
            <tr>
              {Object.values(columns).map(({ name, viewName, description }: FieldColumnsConfig) => (
                <th scope="col" key={name}>
                  {viewName}*{' '}
                  {description && (
                    <FlightTooltip direction="right" className="help" isEnabled={true} description={description}>
                      <QuestionCircle />
                    </FlightTooltip>
                  )}
                </th>
              ))}
              {heading.isOutput && (
                <th scope="col">
                  {t('integrations:general.tables.datatype', `Data type`)}*{' '}
                  <FlightTooltip
                    direction="right"
                    className="help"
                    isEnabled={true}
                    description={t('integrations:general.tables.datatype_description')}
                  >
                    <QuestionCircle />
                  </FlightTooltip>
                </th>
              )}
              {heading.isOutput && (
                <th scope="col">
                  {t('integrations:general.tables.outputfield', `Output field name`)}*{' '}
                  <FlightTooltip
                    direction="right"
                    className="help"
                    isEnabled={true}
                    description={t('integrations:general.tables.outputfield_description')}
                  >
                    <QuestionCircle />
                  </FlightTooltip>
                </th>
              )}
              <th scope="col">
                {t('integrations:general.tables.parameters', `Parameters`)}{' '}
                <FlightTooltip
                  direction="right"
                  className="help"
                  isEnabled={true}
                  description={t('integrations:general.tables.parameters_description')}
                >
                  <QuestionCircle />
                </FlightTooltip>
              </th>
              {canChange && <th className="remove"> </th>}
            </tr>
          </thead>
          <tbody>
            {Object.keys(configs).length > 0 &&
              configs?.dynamicConfigs?.map((dynamicConfig, headerIndex) => {
                if (dynamicConfig.headingName === heading.name) {
                  return dynamicConfig.dynamicFields.map((field, itemIndex) => {
                    const sortOrder = ['dataType', 'outputFieldName', 'parameters'];

                    return (
                      <tr key={headerIndex + '-' + itemIndex}>
                        {Object.entries(field)
                          .sort(function (a, b) {
                            // Sort fields before mapping based on sortOrder to keep default parameters in the end
                            return sortOrder.indexOf(a[0]) - sortOrder.indexOf(b[0]);
                          })
                          .map((column: any, idx: number) => {
                            return !['itemIndex', 'headerIndex', 'fieldData'].includes(column[0]) ? (
                              <td
                                key={column[0] + '-' + idx}
                                className={`${column[0] !== 'parameters' && !field[column[0]] ? 'error' : ''}`}
                              >
                                <EditField
                                  name={column[0]}
                                  value={
                                    column[0] !== 'parameters'
                                      ? field[column[0]]
                                      : field[column[0]] !== null
                                      ? String(field[column[0]])
                                      : ''
                                  }
                                  headingName={heading.name}
                                  t={t}
                                  disabled={!canChange}
                                  itemIndex={itemIndex}
                                  headerIndex={headerIndex}
                                  onChangeValue={onChangeValue}
                                />
                              </td>
                            ) : null;
                          })}
                        {canChange && (
                          <td className="remove">
                            <FlightButton
                              type="button"
                              theme="minor"
                              size="medium"
                              iconLeft="trashCan"
                              label=""
                              ariaLabel="Remove row"
                              onClick={() => HandleRemoveRow(headerIndex, itemIndex)}
                            />
                          </td>
                        )}
                      </tr>
                    );
                  });
                }
              })}
          </tbody>
        </table>
      </div>
      {canChange && (
        <div>
          <FlightButton
            type="button"
            className="add-row"
            theme="link"
            iconLeft="add"
            label={t('integrations:general.tables.add_row', `Add row`)}
            onClick={HandleAddRow}
          />
        </div>
      )}
    </>
  );
};
export default TableEdit;
