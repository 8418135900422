import BaseService from './base.service';
import { CreateProject, UpdateProject } from 'src/model/projects/projects';

export default class ProjectService extends BaseService {
  CURRENT_PATH: string;
  BASE_PATH: string;
  constructor() {
    super();
    this.BASE_PATH = '/organizations';
    this.CURRENT_PATH = '/projects';
  }

  async getProjects(organizationId: string) {
    return this.request('GET', this.BASE_PATH + '/' + organizationId + this.CURRENT_PATH);
  }
  async getAllProjects(organizationId: string) {
    return this.request('GET', this.BASE_PATH + '/' + organizationId + '/allProjects');
  }

  async getDeployments(organizationId: string) {
    return this.request('GET', this.BASE_PATH + '/' + organizationId + '/deployments');
  }

  async getProject(organizationId: string, projectId: string) {
    // projectId = {deployment}_{subdomaiin}
    return this.request('GET', this.BASE_PATH + '/' + organizationId + this.CURRENT_PATH + '/' + projectId);
  }

  async getProjectUsers(organizationId: string, projectId: string) {
    return this.request('GET', this.BASE_PATH + '/' + organizationId + this.CURRENT_PATH + '/' + projectId + '/users');
  }

  async getProjectTeams(organizationId: string, projectId: string) {
    return this.request('GET', this.BASE_PATH + '/' + organizationId + this.CURRENT_PATH + '/' + projectId + '/teams');
  }

  async createProject(organizationId: string, data: CreateProject) {
    return this.request('POST', this.BASE_PATH + '/' + organizationId + this.CURRENT_PATH, data);
  }

  async updateProject(organizationId: string, projectId: string, data: UpdateProject) {
    return this.request('PATCH', this.BASE_PATH + '/' + organizationId + this.CURRENT_PATH + '/' + projectId, data);
  }

  async deleteProject(organizationId: string, projectId: string) {
    return this.request('DELETE', this.BASE_PATH + '/' + organizationId + this.CURRENT_PATH + '/' + projectId);
  }
  // Remapping
  async getProjectDataSources(deployment: string, subdomain: string) {
    return this.request('GET', '/' + deployment + '/integrations/datasources', null, {
      'X-flybits-project': subdomain,
    });
  }

  async getProjectPlugins(projectId: string) {
    const [deployment, subdomain] = projectId.split('_');
    return this.request('GET', '/' + deployment + '/context/ctxschema/contextplugins', null, {
      'X-flybits-project': subdomain,
    });
  }

  async getProjectDatasourcePlugins(projectId: string, dataSourceId: string) {
    const [deployment, subdomain] = projectId.split('_');
    return this.request(
      'GET',
      '/' + deployment + '/context/ctxschema/contextplugins/ctx.integrations-datasource.' + dataSourceId,
      null,
      {
        'X-flybits-project': subdomain,
      },
    );
  }
  async updateTenantConfig(projectId: string, pluginId: string, data: any) {
    const [deployment, subdomain] = projectId.split('_');
    return this.request('POST', '/' + deployment + '/context/ctxschema/contextplugins/' + pluginId + '/config', {
      ...data,
      header_subdomain: subdomain,
    });
  }
}
