import React, { FunctionComponent } from 'react';
import { FlightCard, FlightOverflowMenu } from '@flybits/design-system';
import './ConnectorCardItem.scss';
import { ConnectorListItem } from 'src/model/connectors/connectors';
import { unicodeEmailRegex } from 'src/helpers/auth.helper';

import { ReactComponent as ConnectorDefaultImage } from 'src/assets/connectors-icon.svg';
import imageErrorConnector from 'src/assets/connectors-icon.svg';

interface Props {
  id: string;
  connectorData: ConnectorListItem;
  selectConnector: () => void;
  copyToClipboard: (arg0: string) => void;
  addNotification: any;
  t: any;
  className?: string;
}

const ConnectorCardItem: FunctionComponent<Props> = (props: Props) => {
  const {
    connectorData: { name, description, documentationUrl, supportEmail, organization, imageUrl },
    selectConnector,
    copyToClipboard,
    className,
  } = props;

  const CardBody = () => (
    <>
      <h4 className="connector__header">{name}</h4>
      <div className="connector__owner">By {organization.name}</div>
      <div className="connector__description">{description}</div>
    </>
  );

  return (
    <div className={`ConnectorCardItem__wrapper ${className ? className : ''}`}>
      <>
        <FlightCard
          className="ConnectorCardItem__border"
          header={<CardBody />}
          image={
            imageUrl ? (
              <img
                src={imageUrl}
                className="ConnectorCardItem__connector-icon"
                onError={(ev) => {
                  (ev.target as Element).setAttribute('src', imageErrorConnector);
                }}
                alt={name}
              />
            ) : (
              <ConnectorDefaultImage className="ConnectorCardItem__connector-icon" />
            )
          }
          onClick={selectConnector}
        />
        <FlightOverflowMenu
          ariaLabel={'Click to see more options'}
          optionGroups={[
            [
              {
                key: 1,
                name: 'Contact support',
                disabled: supportEmail ? false : true,
                onClick: () => {
                  // Check if is URL or EMAIL
                  if (unicodeEmailRegex.test(String(supportEmail).toLowerCase())) {
                    copyToClipboard(supportEmail);
                    window.open('mailto:' + supportEmail);
                  }
                },
              },
              {
                key: 2,
                name: 'Documentation',
                disabled: documentationUrl ? false : true,
                onClick: () => window.open(documentationUrl, '_blank'),
              },
            ],
          ]}
        />
      </>
    </div>
  );
};

export default ConnectorCardItem;
