import React, { useEffect } from 'react';
import localforage from 'localforage';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import { generateIdToken, oidcLogin } from 'src/pkce-oidc/services';
import { useHistory, useParams } from 'react-router';
import DOMPurify from 'dompurify';

function AppAuthCallback() {
  const { projectId } = useParams<{ projectId: string }>();
  const history = useHistory();

  const { search } = window.location;
  const urlParams = new URLSearchParams(search);
  const authCode = urlParams.get('code');
  const state = urlParams.get('state');
  const error = urlParams.get('error');

  useEffect(() => {
    if (!authCode || !state || error) {
      /* TODO: ashuflybits - Make error handling specific to error message after API implements the same */
      history.push({
        pathname: '/error',
        state: {
          title: error || 'Something went wrong',
        },
      });
    } else {
      (async () => {
        try {
          const [codeVerifier, clientId, authRedirectUri, goToUriAfterAuth, deployment] = state.split('+');
          const {
            data: { id_token: idToken },
          } = await generateIdToken(clientId, codeVerifier, authCode, authRedirectUri);

          if (idToken) {
            const { headers } = await oidcLogin(idToken, deployment);

            const accessToken = headers['x-authorization'];
            await localforage.setItem(`token+${projectId}`, accessToken);

            // eslint-disable-next-line no-console
            console.log('sanitizedUri', DOMPurify.sanitize(goToUriAfterAuth));

            window.location.assign(`${goToUriAfterAuth}&token=${accessToken}`);
          } else {
            throw new Error();
          }
        } catch (error) {
          history.push('/not-found');
        }
      })();
    }
  }, []);

  return (
    <>
      <LoadingIcon width={80} height={80} fullScreen visible />
    </>
  );
}

export default AppAuthCallback;
