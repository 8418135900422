const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_DAY = 86400;
const SECONDS_IN_WEEK = 604800;
const SECONDS_IN_MONTH = 2628000;
const SECONDS_IN_YEAR = 31536000;

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = 1440;
const MINUTES_IN_WEEK = 10080;
const MINUTES_IN_MONTH = 43800;
const MINUTES_IN_YEAR = 525600;

export {
  SECONDS_IN_MINUTE,
  SECONDS_IN_HOUR,
  SECONDS_IN_DAY,
  SECONDS_IN_WEEK,
  SECONDS_IN_MONTH,
  SECONDS_IN_YEAR,
  MINUTES_IN_HOUR,
  MINUTES_IN_DAY,
  MINUTES_IN_WEEK,
  MINUTES_IN_MONTH,
  MINUTES_IN_YEAR,
};
