import React, { useEffect, useState } from 'react';
import './ConfigTemplate.scss';
import { FlightCheckbox, FlightRadioButton, FlightToggleSwitch, getIcon } from '@flybits/design-system';
import { ConnectorV1 } from 'src/model/connectors/connectors';
import { Link } from 'react-router-dom';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import PreviewFormEditor from './PreviewFormEditor/PreviewFormEditor';
import JSONFormEditor from 'src/components/Shared/JSONFormEditor/JSONFormEditor';
interface Props {
  current: ConnectorV1 | undefined;
  APIVersion?: string;
  t: any;
  onError: (tab: string, hasError: boolean) => void;
  onChangeValue: (e: any) => void;
  setCodeModified: (e: any) => void;
}
const UserDefinedAttributes: React.FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'ConfigTemplate';

  const { current, onChangeValue, setCodeModified, onError, t } = props;
  const {
    allowedScopes = ['user'],
    allowAdditional,
    configSchema = {},
    uiSchema = {},
  } = current?.configs?.additionalAttributes || {};
  const [selectedTab, setTab] = useState('editor');
  const [previewError, setPreviewError] = useState(false);
  const { status } = current?.metadata || {};
  const [currentUiSchema, setUiSchema] = useState<UiSchema>(uiSchema);
  const [schema, setSchema] = useState<RJSFSchema>(configSchema as RJSFSchema);

  useEffect(() => {
    onError('user-defined-attributes', previewError);
  }, [previewError]);

  const handleChange = (e: any, type?: string) => {
    if (!type) return;
    const updatedValue = e.target ? e.target.value : e;

    const newData: any = { allowedScopes, allowAdditional, configSchema, uiSchema };
    newData[type] = updatedValue;

    const payload = {
      ...current,
      configs: {
        ...current?.configs,
        additionalAttributes: newData,
      },
    };
    onChangeValue(payload);
  };

  const updateScopes = (scope: string) => {
    if (!scope) return;
    const scopes = allowedScopes || [];
    if (scopes.includes(scope)) {
      scopes.splice(
        scopes.findIndex((item) => item === scope),
        1,
      );
    } else {
      scopes.push(scope);
    }
    return scopes;
  };

  return (
    <div className={MAIN_CLASS}>
      <h2 className="section__title">
        {t('integrations:connectors.details.sections.user-defined-attributes.title', 'User defined attributes')}
      </h2>
      <div className="section__form">
        <div>
          <FlightToggleSwitch
            className="toggle-button-class"
            label={t(
              'integrations:connectors.details.sections.user-defined-attributes.labels.allow',
              'Allow user-defined attributes',
            )}
            checked={allowAdditional}
            onChange={(value) => handleChange(value, 'allowAdditional')}
          />
        </div>
        {allowAdditional && (
          <>
            <div className="group">
              <label>
                {t(
                  'integrations:connectors.details.sections.user-defined-attributes.labels.allowed_scopes',
                  'Allowed Scopes:',
                )}
              </label>
              <FlightCheckbox
                label={t('integrations:connectors.details.sections.user-defined-attributes.labels.user', 'User')}
                checkState={allowedScopes?.includes('user') ? 'SELECTED' : 'UNSELECTED'}
                onSelect={() => handleChange(updateScopes('user'), 'allowedScopes')}
              />
              <FlightCheckbox
                label={t('integrations:connectors.details.sections.user-defined-attributes.labels.tenant', 'Tenant')}
                checkState={allowedScopes?.includes('tenant') ? 'SELECTED' : 'UNSELECTED'}
                onSelect={() => handleChange(updateScopes('tenant'), 'allowedScopes')}
              />
            </div>
            {!allowedScopes?.length && (
              <small className="scope-error">
                {getIcon('error', { fill: '#dc3616', width: '16px' })} Please select at least 1 scope
              </small>
            )}
          </>
        )}
      </div>
      {!allowAdditional && (
        <div className="section__description">
          <div className="warning">
            <div className="icon">{getIcon('error', { fill: '#FF9C02' })}</div>
            <div className="message">
              <strong>
                {t(
                  'integrations:connectors.details.sections.user-defined-attributes.warning.title',
                  'Enable user-defined attributes to add configuration',
                )}
              </strong>
              <p>
                {t(
                  'integrations:connectors.details.sections.user-defined-attributes.warning.message',
                  'This will allow context attributes to be defined when the datasource is configured. An integration which does not allow user-defined attributes must have at least one pre-defined attribute configured.',
                )}
              </p>
              <div className="links">
                <Link
                  to={{
                    pathname: t(
                      'integrations:connectors.details.sections.user-defined-attributes.warning.learn_more_url',
                      'https://app.gitbook.com/@flybits/s/product-guide/',
                    ),
                  }}
                  target="_blank"
                >
                  {t('integrations:connectors.details.sections.sharing.warning.learn_more', 'Learn more')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {allowAdditional && (
        <div className="section__tabs">
          <div className="section__tabs-header">
            <FlightRadioButton
              label={t('integrations:connectors.details.sections.config-template.editor.title', 'JSON')}
              checked={selectedTab === 'editor'}
              className={`section__tabs-header-item`}
              onSelect={() => {
                setTab('editor');
              }}
              value="editor"
            />

            <FlightRadioButton
              label={t('integrations:connectors.details.sections.config-template.preview.title', 'Preview')}
              checked={selectedTab === 'preview'}
              className={`section__tabs-header-item`}
              onSelect={() => {
                setTab('preview');
              }}
              value="preview"
              disabled={previewError}
            />
            <Link
              to={{
                pathname: t(
                  'integrations:connectors.details.sections.configurations.got_questions_url',
                  'https://rjsf-team.github.io/react-jsonschema-form/docs/quickstart/#form-schema',
                ),
              }}
              target="_blank"
            >
              {t('integrations:connectors.details.sections.config-template.got_questions', 'Got schema questions?')}
            </Link>
          </div>
          <div className="section__tabs-content">
            {selectedTab === 'editor' && (
              <div className="tab-inner">
                <div className="json-editor">
                  <JSONFormEditor
                    schema={schema || {}}
                    uiSchema={currentUiSchema || {}}
                    setSchema={setSchema}
                    setUiSchema={setUiSchema}
                    handleChange={handleChange}
                    setPreviewError={setPreviewError}
                    setModified={setCodeModified}
                    selectedTab={selectedTab}
                    disabled={status === 'active' || !allowAdditional}
                    t={t}
                  />
                </div>
              </div>
            )}
            {selectedTab === 'preview' && (
              <div className="tab-inner">
                <PreviewFormEditor
                  setTab={setTab}
                  schema={schema}
                  uiSchema={currentUiSchema}
                  previewError={previewError}
                  t={t}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDefinedAttributes;
