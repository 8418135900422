import { useContext } from 'react';
import { NotificationContext } from 'src/contexts/NotificationContext';

function useNotification() {
  const { message, addNotification, addNotificationWarning, addNotificationError, removeNotification } =
    useContext(NotificationContext);
  return { message, addNotification, addNotificationWarning, addNotificationError, removeNotification };
}

export default useNotification;
