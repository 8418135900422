import React, { FunctionComponent, useState, useEffect } from 'react';
import { FlightModal, FlightAvatar, FlightButton, FlightTextInput, getIcon } from '@flybits/design-system';
import './UserAccount.scss';
import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import ChangeNameModal from 'src/components/ChangeNameModal/ChangeNameModal';
import {
  PASSWORD_VALIDATION,
  PASSWORD_REQUIRED,
  SAME_PASSWORD_VALIDATION,
  GENERIC_INTERNAL_ERROR,
  CONFIRMATION_PASSWORD_VALIDATION,
} from 'src/constants/errors';
import { passwordRegex } from 'src/helpers/auth.helper';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useHistory, Link } from 'react-router-dom';
import AuthService from 'src/services/authentication.service';
import { TRANSLATIONS_ENABLED } from 'src/constants/featureFlags';

// START - SECRET CODE
import SecretCode from 'src/pages/NotFound/SecretCode';
// END SECRET CODE

interface Props {
  location: any;
}
// Translation
import { useTranslation } from 'react-i18next';
import useNotification from 'src/hooks/useNotification';
import { ChangeNameParameters } from 'src/model/auth/auth';
import { User } from 'src/model/users/users';
const authServiceManager = new AuthService();
const UserAccount: FunctionComponent<Props> = () => {
  const MAIN_CLASS = 'user';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  const { t, i18n } = useTranslation(['translation', 'account', 'public']);
  const { featureFlags, user, setControlTowerContextDataUser } = useControlTowerContext();
  const { addNotification, addNotificationError } = useNotification();
  const [areTranslationsEnabled, setAreTranslationsEnabled] = useState(false);
  useEffect(() => {
    featureFlags[TRANSLATIONS_ENABLED] && setAreTranslationsEnabled(true);
  }, [featureFlags]);
  const changeLanguage = (code: string) => {
    i18n.changeLanguage(code);
    document.body.dir = i18n.dir();
  };

  const history = useHistory();

  interface FormValues {
    currentPassword: string;
    password: string;
    confirmPassword: string;
  }
  const initialValues = {
    currentPassword: '',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required(t('errors:PASSWORD_REQUIRED', PASSWORD_REQUIRED))
      .matches(passwordRegex, t('errors:PASSWORD_VALIDATION', PASSWORD_VALIDATION)),
    password: Yup.string()
      .required(t('errors:PASSWORD_REQUIRED', PASSWORD_REQUIRED))
      .matches(passwordRegex, t('errors:PASSWORD_VALIDATION', PASSWORD_VALIDATION))
      .test('match', t('errors:SAME_PASSWORD_VALIDATION', SAME_PASSWORD_VALIDATION), function (confirmPassword) {
        return confirmPassword !== this.parent.currentPassword;
      }),
    confirmPassword: Yup.string()
      .required(t('errors:PASSWORD_REQUIRED', PASSWORD_REQUIRED))
      .matches(passwordRegex, t('errors:PASSWORD_VALIDATION', PASSWORD_VALIDATION))
      .test(
        'match',
        t('errors:CONFIRMATION_PASSWORD_VALIDATION', CONFIRMATION_PASSWORD_VALIDATION),
        function (confirmPassword) {
          return confirmPassword === this.parent.password;
        },
      ),
  });

  const [error, setError] = useState(false);
  const [tryDeactivate, setDeactivate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, showModal] = useState(false);
  const [isEditNameModalVisible, setIsEditNameModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const toggleModal = () => {
    setLoading(false);
    setError(false);
    showModal(!modal);
  };

  const toggleEditNameModal = () => {
    setIsEditNameModalVisible(!isEditNameModalVisible);
  };

  const initChangePassword = async (values: FormValues) => {
    setLoading(true);
    setError(false);

    const changePasswordRequestBody = {
      currentPassword: values.currentPassword,
      newPassword: values.password,
    };
    try {
      const response = await authServiceManager.changeAccountPassword(changePasswordRequestBody);
      if (response) {
        setLoading(false);
        addNotification(t('account:messages.change_success', 'Password successfully updated.'));
        return true;
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        setErrorMessage(error.response.data.message);
        addNotificationError(error.response.data.message);
        setError(true);
      } else {
        setErrorMessage(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
        addNotificationError(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
        setError(true);
      }
      console.error(error);
      setLoading(false);
      return false;
    }
  };

  const updateName = (values: ChangeNameParameters) => {
    setControlTowerContextDataUser({
      ...(user as User),
      ...values,
    });
  };

  return (
    <div className={MAIN_CLASS}>
      <SecretCode />
      <FlightModal
        isVisible={tryDeactivate}
        toggleModalShown={() => {
          setDeactivate(false);
        }}
        scrollable={false}
        size="small"
        warning={true}
        header={<span>{t('account:deactivate_modal.header', 'Deactivate account')}</span>}
        content={
          <span>
            {t('account:deactivate_modal.message', 'Please contact us at')}{' '}
            <a href="mailto:support@flybits.com" target="_blank" rel="noopener noreferrer">
              support@flybits.com
            </a>
          </span>
        }
      />
      <FlightModal
        isVisible={modal}
        toggleModalShown={toggleModal}
        scrollable={false}
        size="medium"
        className={`${CONTENT_CLASS}__modal`}
        warning={false}
        header={<span>{t('account:change_pw_modal.header', 'Change password')}</span>}
        content={
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
              const result = await initChangePassword(values);
              if (result) {
                resetForm();
                showModal(false);
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form>
                {loading && (
                  <LoadingIcon
                    width={80}
                    height={80}
                    className={`${CONTENT_CLASS}__loading`}
                    visible={loading}
                    fullScreen
                  />
                )}
                {error && (
                  <div className="form__error" role={'alert'} aria-atomic>
                    {errorMessage}
                  </div>
                )}
                <div className={CONTENT_CLASS}>
                  <Field
                    type="password"
                    name="currentPassword"
                    className={`${CONTENT_CLASS}__input`}
                    width="100%"
                    as={FlightTextInput}
                    hasError={touched.currentPassword && errors.currentPassword ? true : false}
                    label={t('account:change_pw_modal.current_pw', 'Current Password')}
                    ariaLabel={t('account:change_pw_modal.current_pw', 'Current Password')}
                    value={values.currentPassword}
                    errorMessage={
                      <span role="alert" aria-atomic>
                        <b>Error:</b> {errors.currentPassword}
                      </span>
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={CONTENT_CLASS}>
                  <Field
                    type="password"
                    name="password"
                    className={`${CONTENT_CLASS}__input`}
                    width="100%"
                    as={FlightTextInput}
                    hasError={touched.password && errors.password ? true : false}
                    label={t('account:change_pw_modal.new_pw', 'New password')}
                    ariaLabel={t('account:change_pw_modal.new_pw', 'New password')}
                    value={values.password}
                    errorMessage={
                      <span role="alert" aria-atomic>
                        <b>Error:</b> {errors.password}
                      </span>
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={CONTENT_CLASS}>
                  <Field
                    type="password"
                    name="confirmPassword"
                    disabled={!!errors.password}
                    className={`${CONTENT_CLASS}__input`}
                    width="100%"
                    as={FlightTextInput}
                    hasError={touched.confirmPassword && errors.confirmPassword ? true : false}
                    label={t('account:change_pw_modal.confirm_new_pw', 'Confirm new password')}
                    ariaLabel={t('account:change_pw_modal.confirm_new_pw', 'Confirm new password')}
                    value={values.confirmPassword}
                    errorMessage={
                      <span role="alert" aria-atomic>
                        <b>Error:</b> {errors.confirmPassword}
                      </span>
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <FlightButton
                  type="primary"
                  size="medium"
                  theme="link"
                  label={t('account:change_pw_modal.forgot_pw', 'Forgot password?')}
                  ariaLabel={t('account:change_pw_modal.forgot_pw', 'Forgot password?')}
                  className={`${CONTENT_CLASS}__link`}
                  onClick={() => {
                    history.push('/password/reset');
                  }}
                />
                <FlightButton
                  type="primary"
                  size="medium"
                  label={t('translation:general.buttons.submit', 'Submit')}
                  ariaLabel={t('translation:general.buttons.submit', 'Submit')}
                  className={`${CONTENT_CLASS}__signup`}
                  disabled={
                    !touched.currentPassword ||
                    !!errors.currentPassword ||
                    !touched.password ||
                    !!errors.password ||
                    !touched.confirmPassword ||
                    !!errors.confirmPassword
                  }
                  onClick={handleSubmit}
                />
              </form>
            )}
          </Formik>
        }
      />
      <FlightModal
        isVisible={isEditNameModalVisible}
        toggleModalShown={() => {
          toggleEditNameModal();
        }}
        scrollable={false}
        size="medium"
        className={`${CONTENT_CLASS}__modal`}
        warning={false}
        header={<span>{t('account:change_name_modal.header', 'Change name')}</span>}
        content={
          <ChangeNameModal
            toggleModal={toggleEditNameModal}
            defaultValues={{ givenName: user?.givenName || '', familyName: user?.familyName || '' }}
            onSuccess={(values) => updateName(values)}
          />
        }
      />
      <div className={CONTENT_CLASS}>
        <div className={`${CONTENT_CLASS}__wrapper`}>
          <h1 className={`${CONTENT_CLASS}__heading-text`}>
            <FlightButton
              type="primary"
              className={`${CONTENT_CLASS}__button_link ${CONTENT_CLASS}__button_link-back`}
              theme="link"
              size="medium"
              label={`< ${t('translation:general.buttons.back', 'Back')}`}
              onClick={() => {
                history.goBack();
              }}
            />
            {t('account:heading', 'Your Flybits account')}
          </h1>
          <div className={`${CONTENT_CLASS}__form`}>
            <div className={`${CONTENT_CLASS}__avatar-wrapper`}>
              <FlightAvatar
                className={`${CONTENT_CLASS}__avatar`}
                firstName={user?.givenName}
                lastName={user?.familyName}
                email={user?.username}
              />
              <p>{t('account:info', 'Account avatars are generated based on your first and last name.')}</p>
            </div>
            <div className={`${CONTENT_CLASS}__infos-wrapper`}>
              <div className={`${CONTENT_CLASS}__infos-item`}>
                <div className={`${CONTENT_CLASS}__infos-item-left`}>{t('account:name', 'Name')}</div>
                <div className={`${CONTENT_CLASS}__infos-item-right`}>
                  {user?.givenName} {user?.familyName}
                  <button onClick={toggleEditNameModal} className={`${CONTENT_CLASS}__infos-item-right__icon-button`}>
                    {getIcon('pencil', null)}
                  </button>
                </div>
              </div>
              <div className={`${CONTENT_CLASS}__infos-item`}>
                <div className={`${CONTENT_CLASS}__infos-item-left`}>{t('account:email', 'Email')}</div>
                <div className={`${CONTENT_CLASS}__infos-item-right`}>{user?.username}</div>
              </div>
              <div className={`${CONTENT_CLASS}__infos-item`}>
                <div className={`${CONTENT_CLASS}__infos-item-left`}>{t('account:password', 'Password')}</div>
                <div className={`${CONTENT_CLASS}__infos-item-right`}>
                  <FlightButton
                    type="primary"
                    className={`${CONTENT_CLASS}__button`}
                    theme="link"
                    size="medium"
                    label={t('account:change-password', 'Change password')}
                    onClick={toggleModal}
                  />
                </div>
              </div>
              {areTranslationsEnabled && (
                <div className={`${CONTENT_CLASS}__infos-item`}>
                  <div className={`${CONTENT_CLASS}__infos-item-left`}>
                    {t('account:default-lang', 'Default language')}
                  </div>
                  <div className={`${CONTENT_CLASS}__infos-item-right`}>
                    <FlightButton
                      type="button"
                      theme={i18n.language === 'en' ? 'primary' : 'secondary'}
                      size="small"
                      label={t('translation:languages.en')}
                      onClick={() => changeLanguage('en')}
                    />
                    <FlightButton
                      type="button"
                      theme={i18n.language === 'br' ? 'primary' : 'secondary'}
                      size="small"
                      label={t('translation:languages.br')}
                      onClick={() => changeLanguage('br')}
                    />
                  </div>
                </div>
              )}
              <div className={`${CONTENT_CLASS}__infos-item`}>
                <div className={`${CONTENT_CLASS}__infos-item-left`}>
                  <FlightButton
                    type="primary"
                    className={`${CONTENT_CLASS}__button_deactivate`}
                    theme="link"
                    size="medium"
                    label={t('account:deactivate', 'Deactivate my account')}
                    onClick={() => {
                      setDeactivate(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`${CONTENT_CLASS}__tos`}>
            {' '}
            <Link
              to={'/terms-of-service'}
              aria-label={t('public:signin.compliance.terms.label_aria', 'Terms of Service')}
            >
              {t('public:signin.compliance.terms.label', 'Terms of Service')}{' '}
            </Link>{' '}
            |{' '}
            <Link
              to={'/privacy-policy'}
              aria-label={t('public:signin.compliance.privacy.label_aria', 'Privacy Policy')}
            >
              {' '}
              {t('public:signin.compliance.privacy.label', 'Privacy Policy')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAccount;
