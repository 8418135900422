import React from 'react';

const IconOrganization = (props?: any) => (
  <svg width={80} height={80} viewBox="0 0 80 80" fill="none" {...props}>
    <path
      d="M69.9998 68.3332H46.6665V26.6665H63.3332C66.9982 26.6665 69.9998 29.6682 69.9998 33.3332V68.3332Z"
      fill="#1E88E5"
    />
    <path d="M46.6665 68.3335H69.9998V71.6668H46.6665V68.3335Z" fill="#1976D2" />
    <path
      d="M56.6665 35H63.3332V41.6667H56.6665V35ZM56.6665 46.6667H63.3332V53.3333H56.6665V46.6667ZM56.6665 58.3333H63.3332V65H56.6665V58.3333Z"
      fill="#0D47A1"
    />
    <path d="M15 8.3335H48.3333V15.0002H15V8.3335Z" fill="#64B5F6" />
    <path
      d="M53.3333 68.3335H10V16.6668C10 14.8335 11.5 13.3335 13.3333 13.3335H50C51.8333 13.3335 53.3333 14.8335 53.3333 16.6668V68.3335Z"
      fill="#90CAF9"
    />
    <path d="M10 68.3335H53.3333V71.6668H10V68.3335Z" fill="#64B5F6" />
    <path
      d="M28.3332 55H34.9998V65H28.3332V55ZM16.6665 55H23.3332V65H16.6665V55ZM39.9998 55H46.6665V65H39.9998V55ZM28.3332 31.6667H34.9998V38.3333H28.3332V31.6667ZM16.6665 31.6667H23.3332V38.3333H16.6665V31.6667ZM39.9998 31.6667H46.6665V38.3333H39.9998V31.6667ZM28.3332 43.3333H34.9998V50H28.3332V43.3333ZM16.6665 43.3333H23.3332V50H16.6665V43.3333ZM39.9998 43.3333H46.6665V50H39.9998V43.3333ZM28.3332 20H34.9998V26.6667H28.3332V20ZM16.6665 20H23.3332V26.6667H16.6665V20ZM39.9998 20H46.6665V26.6667H39.9998V20Z"
      fill="#1565C0"
    />
  </svg>
);

export default IconOrganization;
