/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useState } from 'react';
import update from 'immutability-helper';
import { FlightSelectSearchable, FlightTextInput, getIcon } from '@flybits/design-system';
import { ReactComponent as EmptyProject } from 'src/assets/illustrations/no-project.svg';
import { ReactComponent as EmptyDatasources } from 'src/assets/illustrations/no-datasources.svg';
import { ReactComponent as EmptyPlugins } from 'src/assets/illustrations/no-ctx-attribute.svg';
import { ReactComponent as ClickProjectIcon } from 'src/assets/click-project.svg';
import './Remapping.scss';
import { Project, ProjectResponseBody } from 'src/model/projects/projects';
import ProjectService from 'src/services/project.service';
import { handleKeyDownHelper, toType } from 'src/helpers/general.helper';
import Jdenticon from 'src/components/Jdenticon';
import useNotification from 'src/hooks/useNotification';

// DND
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { OutputField } from './OutputField';
import { PluginAttribute } from './PluginAttribute';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import getParameterRemappingValue from './ParameterRemappingDialog';

interface Props {
  t: any;
  orgID: any;
}

const Remapping: React.FunctionComponent<Props> = (props: Props) => {
  const { t, orgID } = props;
  const MAIN_CLASS = 'Remapping';
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [filteredProjectList, setFilteredProjectList] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<any>();
  const [selectedDatasource, setSelectedDatasource] = useState<any>();
  const [datasources, setDatasources] = useState<any[]>([]);
  const [attributePlugins, setAttributePlugins] = useState<any[]>([]);
  const [contextPlugins, setContextPlugins] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [droppedOutputNames, setDroppedOutputNames] = useState<string[]>([]);
  const { addNotification, addNotificationError } = useNotification();

  // Loading
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [loadingDatasources, setLoadingDatasources] = useState<boolean>(false);
  const [loadingContextPlugins, setLoadingContextPlugins] = useState<boolean>(false);
  const [loadingAttributes, setLoadingAttributes] = useState<boolean>(false);

  const projectServiceManager = new ProjectService();

  const handleDrop = useCallback(
    async (attrIndex: number, source: any, destination: any) => {
      const pluginIndex = attributePlugins.findIndex((object) => object.id === destination.id);
      // Get current key
      const attrKey = Object.keys(attributePlugins[pluginIndex].values)[attrIndex];

      // check if it is parameter remapping or just attributes

      // if => src or dest has params
      // = open parameter dialog (await for parameter remapping data)

      // get current mappings from tenant config
      const configAttributes = await attributePlugins[pluginIndex]?.tenantConfig?.attributes;
      const configFrom =
        configAttributes && configAttributes[attrKey] ? configAttributes[attrKey]?.mapping?.from : null;
      const currentMapping: any = configFrom || { sourceParams: null, destinationParams: null };

      let remapped: any = { ok: true, updatedRemapping: currentMapping };

      if (destination.data?.parameters?.length || source?.parameters?.length) {
        // build payload for the dialog
        const destinations = destination.data?.parameters?.map((dest: any) => {
          return {
            index: dest.index,
            key: dest.key,
            id: dest.key,
            valueType: dest.valueType,
            default: currentMapping?.destinationParams?.length
              ? currentMapping?.destinationParams[dest.index]?.default || ''
              : '',
          };
        });
        const sources = source?.parameters?.map((src: any) => {
          return {
            index: src.index,
            key: src.key,
            id: src.key,
            valueType: src.valueType,
            condition: currentMapping?.sourceParams?.length
              ? currentMapping?.sourceParams[src.index]?.condition || ''
              : '',
          };
        });
        const initialValues = {
          sources: sources || [],
          destinations: destinations || [],
        };
        remapped = await getParameterRemappingValue(initialValues, currentMapping, attrKey, t);
      } else {
        // no parameters
        remapped = {
          ok: true,
          updatedRemapping: { sourceParams: null, destinationParams: null, attribute: source.id },
        };
      }
      if (!remapped.ok) {
        return;
      }

      // else => just attributes, go ahead :)

      const saveResult = await handleSaveRemapping(destination.pluginId, attrKey, source.id, remapped.updatedRemapping);

      if (saveResult?.status === 200) {
        setAttributePlugins(
          update(attributePlugins, {
            [pluginIndex]: {
              tenantConfig: { $set: saveResult.data },
            },
          }),
        );
      }
    },
    [attributePlugins, isSaved],
  );
  const removeDrop = useCallback(
    async (attrIndex: number, data: any) => {
      const pluginIndex = attributePlugins.findIndex((object) => object.id === data.id);
      const attrKey = Object.keys(attributePlugins[pluginIndex].values)[attrIndex];
      const result = await handleRemappingAction(data.pluginId, attrKey, {
        from: null,
        to: null,
      });
      if (result?.status === 200) {
        setAttributePlugins(
          update(attributePlugins, {
            [pluginIndex]: {
              tenantConfig: { $set: result.data },
            },
          }),
        );
      }
    },
    [attributePlugins],
  );
  function isDropped(boxName: string) {
    return droppedOutputNames.indexOf(boxName) > -1;
  }
  useEffect(() => {
    // Projects fetching
    const fetchProjectsFromOrg = async () => {
      try {
        const projectResponseObject = await projectServiceManager.getProjects(orgID);
        if (projectResponseObject) {
          const transformedResponse: Project[] = [];

          toType(projectResponseObject.data) === 'Array' &&
            projectResponseObject.data.map((project: ProjectResponseBody) => {
              return transformedResponse.push({
                id: project.id,
                name: project.name,
                subdomain: project.subdomain,
                region: project.region,
                deployment: project.deployment,
                endpoint: project.endpoint,
                tier: project.tier,
                active: project.active,
                organization: project.organization,
              });
            });
          setProjectList(transformedResponse);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchProjectsFromOrg();
  }, []);
  useEffect(() => {
    setFilteredProjectList(projectList);
  }, [projectList]);
  const fetchDataSources = async (deployment: string, subdomain: string) => {
    setContextPlugins([]);
    setDatasources([]);
    setSelectedDatasource(null);
    setLoadingDatasources(true);
    try {
      const responseObject = await projectServiceManager.getProjectDataSources(deployment, subdomain);
      if (responseObject && responseObject.data) {
        setDatasources(responseObject.data.data);
        setLoadingDatasources(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingDatasources(false);
    }
  };
  const fetchContextPlugins = async (projectId: string, datasourceId: string) => {
    setContextPlugins([]);
    setLoadingContextPlugins(true);
    try {
      const projectPluginsResponseObject = await projectServiceManager.getProjectDatasourcePlugins(
        projectId,
        datasourceId,
      );
      if (projectPluginsResponseObject?.data) {
        setContextPlugins(projectPluginsResponseObject.data);
        setLoadingContextPlugins(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingContextPlugins(false);
    }
  };
  const fetchAttributePlugins = async (id: string) => {
    setAttributePlugins([]);
    setLoadingAttributes(true);
    try {
      const projectPluginsResponseObject = await projectServiceManager.getProjectPlugins(id);
      if (projectPluginsResponseObject?.data) {
        setAttributePlugins(projectPluginsResponseObject.data);
        setLoadingAttributes(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingAttributes(false);
    }
  };
  const selectProject = async (project: any) => {
    if (!project) return;
    setSelectedProject(project);
    setAttributePlugins([]);
    setSelectedDatasource(null);
    const projectId = project.key.split('_');
    await fetchDataSources(projectId[0], projectId[1]);
    await fetchAttributePlugins(project.key);
  };

  const selectDataSource = async (datasource: any) => {
    if (!datasource || !selectedProject) return;
    setSelectedDatasource(datasource);
    await fetchContextPlugins(selectedProject.key, datasource.id);
  };
  // Search
  const [searchTermDatasource, setSearchTermDatasource] = useState('');
  const [searchTermOutput, setSearchTermOutput] = useState('');
  const [searchTermPlugins, setSearchTermPlugins] = useState('');
  const editSearchTermDataSource = (e: any) => {
    setSearchTermDatasource(e.target.value);
  };
  const editSearchTermOutput = (e: any) => {
    setSearchTermOutput(e.target.value);
  };
  const editSearchTermPlugins = (e: any) => {
    setSearchTermPlugins(e.target.value);
  };
  const datasourcesFilteredData = () => {
    return datasources.filter(
      (datasource) =>
        !searchTermDatasource || datasource.display.name.toLowerCase().includes(searchTermDatasource.toLowerCase()),
    );
  };
  const outputFilteredData = () => {
    return Object.entries(contextPlugins.values)
      .map(([pluginName, pluginData]) => {
        return { name: pluginName, id: pluginData.uid, type: pluginData.valueType, parameters: pluginData.parameters };
      })
      .filter((field) => !searchTermOutput || field.name.toLowerCase().includes(searchTermOutput.toLowerCase()));
  };
  const pluginsFilteredData = () => {
    return attributePlugins.filter(
      (plugin) =>
        !plugin.isReserved &&
        !plugin.uid.startsWith('ctx.integrations-datasource.') &&
        (!searchTermPlugins || plugin.name.toLowerCase().includes(searchTermPlugins.toLowerCase())),
    );
  };
  // Get Datasource info for display
  const getDatasource = useCallback(
    (id?: string) => {
      if (!id) return null;
      return datasources.find((datasource: any) => datasource.id === id);
    },
    [attributePlugins],
  );
  // Get Plugin info
  const getPlugin = (id?: string, pluginsList?: any) => {
    if (!id) return null;
    const plugins = pluginsList || attributePlugins;
    return plugins.find((plugin: any) => plugin.id === id || plugin.uid === id);
  };

  // Save
  // https://github.com/flybits/rfc/issues/77
  const handleSaveRemapping = async (
    pluginId: string,
    pluginAttributeName: string,
    fromAttribute: any,
    attributeParameters?: any,
  ) => {
    const response = await handleRemappingAction(pluginId, pluginAttributeName, {
      from: {
        attribute: fromAttribute, // "ctx.integrations-datasource.dsid.query.attr1"
        ...(attributeParameters || { sourceParams: null, destinationParams: null }),
      },
      to: [],
    });
    return response;
  };

  const handleRemappingAction = async (pluginId: string, pluginAttributeName: string, payload?: any) => {
    try {
      setIsSaving(true);
      setIsSaved(false);
      // Attribute only - RFC 077
      // ctx.integrations-datasource.dsid.attr1 -> ctx.tenant.cat.attr

      // get plugin's tenantConfig attributes to send
      const config = await getPlugin(pluginId, attributePlugins)?.tenantConfig;
      const attributes: any = {};
      if (config?.attributes && Object.keys(config?.attributes).length) {
        // has attributes, just update
        Object.entries(config?.attributes)?.map(([attrKey, attrData]: any) => {
          if (attrKey === pluginAttributeName) {
            // update attr
            attributes[pluginAttributeName] = {
              mapping: payload,
            };
          } else {
            // not changing
            attributes[attrKey] = attrData || { mapping: {} };
          }
        });
      }

      const updatedPlugin = {
        ...config,
        isEnabled: config && config.hasOwnProperty('isEnabled') ? config.isEnabled : true,
        contextPluginId: pluginId, // "ctx.tenant.cat"
        attributes: {
          ...attributes,
          [pluginAttributeName]: {
            mapping: payload || {},
          },
        },
      };

      const response = await projectServiceManager.updateTenantConfig(selectedProject.key, pluginId, updatedPlugin);

      setIsSaving(false);
      setIsSaved(true);
      addNotification('Plugin updated successfully!');
      return response;
    } catch (error) {
      console.error(error);
      addNotificationError(`Error: ${error.message}`);
      setIsSaving(false);
      setIsSaved(false);
      return null;
    }
  };
  const handleSearchProject = (keyword: string) => {
    setFilteredProjectList(
      projectList.filter((proj) => {
        if (!keyword) return true;
        if (
          proj.name.toLowerCase().includes(keyword.toLowerCase()) ||
          proj.deployment.toLowerCase().includes(keyword.toLowerCase())
        ) {
          return true;
        }
        return false;
      }),
    );
  };
  return (
    <div className={MAIN_CLASS}>
      {/* PARAMETER REMAPPING */}
      {/* <ParameterRemappingDialog t={t} handleDrop={handleDrop} /> */}
      <div className={`${MAIN_CLASS}__select-project`}>
        {!selectedProject ? (
          <ClickProjectIcon />
        ) : (
          <Jdenticon size="60" value={selectedProject.key} className="project-icon" />
        )}
        <FlightSelectSearchable
          options={filteredProjectList.map(({ deployment, subdomain, name }) => ({
            key: deployment + '_' + subdomain,
            name: '[' + deployment + '] ' + name,
          }))}
          isLabelAlwaysOn
          label={`Select Project`}
          selected={selectedProject}
          handleOptionClick={selectProject}
          handleSearch={handleSearchProject}
          width={'360px'}
        />
        <div className="progress">
          {isSaving && <LoadingIcon width={30} height={30} visible={true} />}
          {isSaved && !isSaving && <strong>Saved!</strong>}
        </div>
      </div>
      <div className={`${MAIN_CLASS}__content`}>
        {!selectedProject ? (
          <div className={`${MAIN_CLASS}__content-empty`}>
            <div>
              <h2>{t('integrations:remapping.no-project-title', 'No project selected')}</h2>
              <p>
                {t(
                  'integrations:remapping.no-project-text',
                  "You haven't selected a project yet. Select a project to view its associated data sources and content plugins. Click the 'Select Project' to get started.",
                )}
              </p>
            </div>
            <EmptyProject />
          </div>
        ) : (
          <div className={`${MAIN_CLASS}__content-row`}>
            <DndProvider backend={HTML5Backend}>
              <div className={`${MAIN_CLASS}__content-row datasource`}>
                <div className={`${MAIN_CLASS}__content-column ${!datasources.length ? 'empty' : ''}`}>
                  {!loadingDatasources && datasources.length > 0 ? (
                    <div className={`${MAIN_CLASS}__datasources`}>
                      <h3>Available Data sources</h3>
                      <FlightTextInput
                        placeholderText=""
                        width="180px"
                        hasClearIcon
                        trailingIcon="search"
                        label={`Filter Data sources`}
                        isLabelAlwaysOn
                        type="text"
                        name="table-filter"
                        value={searchTermDatasource}
                        onChange={editSearchTermDataSource}
                      />
                      <nav className="items">
                        {datasourcesFilteredData().map((datasource, i) => {
                          return (
                            <li
                              className={`${
                                selectedDatasource && selectedDatasource.id === datasource.id ? 'active' : ''
                              }`}
                              role="button"
                              onClick={() => {
                                selectDataSource(datasource);
                              }}
                              onKeyDown={handleKeyDownHelper(() => selectDataSource(datasource))}
                              tabIndex={0}
                              key={'d-' + i}
                            >
                              <div className="item-image">
                                {datasource.display.imageUrl ? (
                                  <img src={datasource.display.imageUrl} alt={datasource.display.name} />
                                ) : (
                                  <Jdenticon size="60" value={datasource.display.name} />
                                )}
                              </div>
                              <div className="item-content">{datasource.display.name}</div>
                            </li>
                          );
                        })}
                      </nav>
                    </div>
                  ) : (
                    <div className={`${MAIN_CLASS}__datasources-empty`}>
                      {loadingDatasources ? (
                        <LoadingIcon width={80} height={80} visible={true} />
                      ) : (
                        <>
                          <h2>No Data sources found</h2>
                          <p>
                            This project doesn't have any linked data sources. Choose another project or an integration
                            deployment from your Available Integrations to configure and add a data source.
                          </p>
                          <EmptyDatasources />
                        </>
                      )}
                    </div>
                  )}
                </div>
                {!loadingDatasources && selectedDatasource && Object.keys(contextPlugins.values).length > 0 && (
                  <div className={`${MAIN_CLASS}__content-column`}>
                    <div className={`${MAIN_CLASS}__datasources`}>
                      <h3>Context attributes</h3>
                      <FlightTextInput
                        placeholderText=""
                        width="180px"
                        hasClearIcon
                        trailingIcon="search"
                        label={`Filter attributes`}
                        isLabelAlwaysOn
                        type="text"
                        name="table-filter"
                        value={searchTermOutput}
                        onChange={editSearchTermOutput}
                      />
                      <div className="attributes">
                        {loadingContextPlugins ? (
                          <LoadingIcon width={80} height={80} visible={true} />
                        ) : (
                          <>
                            <div className="titles">
                              <span>Field</span>
                              <span>Type</span>
                            </div>
                            <div className="fields">
                              {outputFilteredData().map(({ name, id, type, parameters }) => (
                                <OutputField
                                  name={name}
                                  type={type}
                                  key={id}
                                  id={id}
                                  parameters={parameters || []}
                                  isDropped={isDropped(name)}
                                />
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={`${MAIN_CLASS}__content-column context-plugins`}>
                {!loadingAttributes && attributePlugins.length > 0 ? (
                  <>
                    <h3>Available context plugins</h3>
                    <FlightTextInput
                      placeholderText=""
                      width="180px"
                      hasClearIcon
                      trailingIcon="search"
                      label={`Filter context plugins`}
                      isLabelAlwaysOn
                      type="text"
                      name="table-filter"
                      value={searchTermPlugins}
                      onChange={editSearchTermPlugins}
                    />
                    <div className="plugins">
                      {pluginsFilteredData().map(({ id, uid, name, values, tenantConfig }) => (
                        <details className="plugin" key={id}>
                          <summary>
                            <span>{name}</span>
                            <span className="plugin-top-arrow">{getIcon('baselineKeyboardArrowDown', {})}</span>
                          </summary>
                          {Object.entries(values).map(([key, data]: any, i: number) => {
                            const mappedFromUID = tenantConfig?.attributes
                              ? tenantConfig?.attributes[key]?.mapping.from.attribute
                              : null;
                            const dropProps = {
                              id,
                              name,
                              mappedFromUID: mappedFromUID,
                              pluginId: uid,
                              attrKey: key,
                              data: data,
                            };
                            return (
                              <PluginAttribute
                                accept={[data.valueType]}
                                onDrop={(item) => handleDrop(i, item, dropProps)}
                                onRemoveDrop={() => removeDrop(i, dropProps)}
                                datasourceInfo={getDatasource}
                                key={i}
                                data={{ ...data, remapping: { from: mappedFromUID } }}
                              />
                            );
                          })}
                        </details>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className={`context-plugins-empty`}>
                    {loadingAttributes ? (
                      <LoadingIcon width={80} height={80} visible={true} />
                    ) : (
                      <>
                        <h2>No Context plugin set up</h2>
                        <p>
                          This project doesn't have any content plugins set up. To get started, go to the Project
                          Setting page and set up a plugin.
                        </p>
                        <EmptyPlugins />
                      </>
                    )}
                  </div>
                )}
              </div>
            </DndProvider>
          </div>
        )}
      </div>
    </div>
  );
};

export default Remapping;
