import React, { useState, useEffect, useRef } from 'react';
import { FlightTextInput } from '@flybits/design-system';

const EditField = (props: any) => {
  const { name, value, headingName, disabled, onChangeValue, itemIndex, headerIndex, t } = props;
  const [fieldValue, setFieldValue] = useState('');
  const textareaRef = useRef<any>();
  const handleChange = (e: any) => {
    if (disabled) return;
    setFieldValue(e.target.value);
    onChangeValue({ itemIndex, name, newValue: e.target.value, headingName, headerIndex });
  };
  useEffect(() => {
    setFieldValue(value);
  }, []);
  return (
    <FlightTextInput
      inputRef={textareaRef}
      placeholderText={t('integrations:general.tables.none_set', `None set`)}
      width="100%"
      type="text"
      name={name}
      value={fieldValue}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};
export default EditField;
