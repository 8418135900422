import { FlightSelect } from '@flybits/design-system';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'src/helpers/general.helper';
import { FieldsConfig } from 'src/model/connectors/connectors';
import ItemHeader from '../ItemHeader/ItemHeader';
import './DropdownPreview.scss';

const DropdownPreview = (props: FieldsConfig) => {
  const { inputOpts, onChangeValue, configField } = props;
  const { t } = useTranslation(['integrations']);
  const [selected, setSelected] = useState<any>(
    configField && configField[2]?.configVal
      ? { key: configField[2]?.configVal, name: capitalize(configField[2]?.configVal) }
      : null,
  );
  useEffect(() => {
    if (!selected || !configField || selected.key === configField[2]?.configVal) return;
    typeof onChangeValue === 'function' &&
      onChangeValue({
        field: [configField[0], configField[1]],
        data: { ...configField[2], configVal: selected?.key || '' },
      });
  }, [selected]);
  return (
    <>
      <ItemHeader {...props} />
      <div className="dropdown">
        {inputOpts?.length ? (
          <FlightSelect
            options={inputOpts?.length ? inputOpts.map((item) => ({ key: item, name: capitalize(item) })) : null}
            selected={selected}
            width="400px"
            handleOptionClick={setSelected}
          />
        ) : (
          <span>{t('integrations:datasources.details.sections.configs.no_options', 'No options found')}</span>
        )}
      </div>
    </>
  );
};
export default DropdownPreview;
