import React, { useEffect } from 'react';
import './Contact.scss';
import { FlightTextInput, FlightTooltip } from '@flybits/design-system';
import { DataSource } from 'src/model/datasources/datasources';
import { ReactComponent as QuestionCircle } from 'src/assets/icons/question-circle.svg';
import { validateEmail, validateURL } from 'src/helpers/auth.helper';
import useDebounce from 'src/hooks/useDebounce';
interface Props {
  current: DataSource | undefined;
  t: any;
  onChangeValue: (e: any) => void;
  onError: (tab: string, hasError: boolean) => void;
}
const Contact: React.FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'Contact';
  const { current, onChangeValue, onError, t } = props;
  const { documentationUrl, supportEmail } = current || {};
  // Error checking
  const debouncedURL = useDebounce(documentationUrl, 750);
  const debouncedEmail = useDebounce(supportEmail, 750);
  useEffect(() => {
    if ((supportEmail && !validateEmail(supportEmail)) || (documentationUrl && !validateURL(documentationUrl))) {
      onError('contact', true);
    } else {
      onError('contact', false);
    }
  }, [debouncedURL, debouncedEmail]);

  const HandleChange = (e: any) => {
    onChangeValue({
      ...current,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className={MAIN_CLASS}>
      <h2 className="section__title">
        {t('integrations:connectors.details.sections.contact.title', 'Contact & documentation')}
      </h2>
      <div className="section__fields">
        <div className="section__fields-field">
          <label htmlFor="support-email">
            {t('integrations:connectors.details.sections.contact.labels.email', 'Contact support email')}{' '}
            <FlightTooltip
              direction="right"
              isEnabled={true}
              description={t(
                'integrations:connectors.details.sections.contact.labels.email_tooltip',
                'Contact information for connector troubleshooting.',
              )}
            >
              <QuestionCircle />
            </FlightTooltip>
          </label>
          <FlightTextInput
            placeholderText="support@example.com"
            width="600px"
            type="text"
            name="supportEmail"
            labelId="support-email"
            value={supportEmail}
            hasError={!!(supportEmail && !validateEmail(supportEmail))}
            errorMessage={
              <span role="alert" aria-atomic>
                <b>Error:</b>
                {t(
                  'integrations:connectors.details.sections.contact.labels.email_error',
                  'Invalid support email format',
                )}
              </span>
            }
            onChange={HandleChange}
          />
        </div>
        <div className="section__fields-field">
          <label htmlFor="documentation-url">
            {t('integrations:connectors.details.sections.contact.labels.documentation', 'Documentation URL')}{' '}
            <FlightTooltip
              direction="right"
              isEnabled={true}
              description={t(
                'integrations:connectors.details.sections.contact.labels.documentation_tooltip',
                'URL of the documentation for the connector to help people learn how it works or troubleshoot.',
              )}
            >
              <QuestionCircle />
            </FlightTooltip>
          </label>
          <FlightTextInput
            placeholderText="https://www.example.com/setup"
            width="600px"
            type="text"
            name="documentationUrl"
            labelId="documentation-url"
            value={documentationUrl}
            hasError={!!(documentationUrl && !validateURL(documentationUrl))}
            errorMessage={
              <span role="alert" aria-atomic>
                <b>Error:</b>
                {t(
                  'integrations:connectors.details.sections.contact.labels.documentation_error',
                  'Invalid documentation url',
                )}
              </span>
            }
            onChange={HandleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
