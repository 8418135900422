import React, { FunctionComponent, useEffect } from 'react';
import BannerImage from 'src/components/BannerImage/BannerImage';
import './PublicPage.scss';
import { PublicPageProps } from 'src/model/general/public-pages';
import { animated } from 'react-spring';
import { Transition } from 'react-spring/renderprops';
import useNotification from 'src/hooks/useNotification';
import { FlightSnackbar } from '@flybits/design-system';
import { useHistory } from 'react-router-dom';
import { Location } from 'history';
import { PUBLIC_PAGE_TITLES } from 'src/constants/titles';

const PublicPage: FunctionComponent<PublicPageProps> = (props: PublicPageProps) => {
  const { children, style, showBanner, setBannerVisibility } = props;
  const { message, removeNotification } = useNotification();
  const history = useHistory();
  useEffect(() => {
    setBannerVisibility(true);
    // Set dynamic document title
    document.title =
      (PUBLIC_PAGE_TITLES[history.location.pathname] || PUBLIC_PAGE_TITLES['/default']) + ' - Control Tower';
    history.listen((location: Location) => {
      // Set dynamic document title
      document.title = (PUBLIC_PAGE_TITLES[location.pathname] || PUBLIC_PAGE_TITLES['/default']) + ' - Control Tower';
      // check for sw updates on page change
      navigator.serviceWorker.getRegistrations().then((regs) => regs.forEach((reg) => reg.update()));
    });
  }, []);

  const MAIN_CLASS = 'public_page';

  const animationPropsLeft = {
    from: { width: '0vw', height: '100%' },
    enter: { width: '50vw' },
    leave: { width: '0vw' },
  };
  const animationPropsRight = {
    from: { width: '100%', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  };
  const AnimateBanner = animated(BannerImage);
  return (
    <>
      <FlightSnackbar
        isVisible={!!message}
        isFloating
        className={`show__info`}
        animation
        isAutoDismiss={true}
        dismissAfter={5000}
        type={message.startsWith('!!!') ? 'error' : message.startsWith('~~~') ? 'warning' : 'default'}
        icon={message.startsWith('!!!') ? 'error' : message.startsWith('~~~') ? 'warning' : ''}
        actionName=""
        content={message.replace(/[\!\~]{3}/g, '')}
        handleClose={() => {
          removeNotification();
        }}
      />
      <div className={MAIN_CLASS} role="main" style={style}>
        <Transition
          items={showBanner}
          reset
          from={animationPropsLeft.from}
          enter={animationPropsLeft.enter}
          leave={animationPropsLeft.leave}
        >
          {(showBanner) => showBanner && ((props) => <AnimateBanner style={props} />)}
        </Transition>
        <Transition
          items={showBanner}
          reset
          from={animationPropsRight.from}
          enter={animationPropsRight.enter}
          leave={animationPropsRight.leave}
        >
          {(showBanner) => showBanner && ((props) => <animated.div style={props}>{children}</animated.div>)}
        </Transition>
      </div>
    </>
  );
};
export default PublicPage;
