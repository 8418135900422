const PROJECT_LEVELS = [
  { level: 5, name: 'View access', info: '' },
  { level: 10, name: 'Editor access', info: '' },
  { level: 15, name: 'Publisher access', info: '' },
  { level: 20, name: 'Manager access', info: '' },
  { level: 21, name: 'Grantor access', info: '' },
];
const ORG_ROLES = [
  {
    role: 'admin',
    name: 'Admin',
    permissions: ['teams', 'projects', 'connectors', 'datasources'],
    info: 'Admins can manage their designated areas of the organization. You must have at least one admin area selected.',
  },
  {
    role: 'owner',
    name: 'Owner',
    permissions: ['owner'],
    info: 'Owners have complete administrative rights to the organization.',
  },
  {
    role: 'member',
    name: 'Member',
    permissions: [],
    info: 'Members can be added to teams to contribute to specific projects.',
  },
];
export { PROJECT_LEVELS, ORG_ROLES };
