import React, { FunctionComponent, useEffect, useState } from 'react';
import SvgFlybitsLogo from 'src/assets/FlybitsLogo';
import { FlightButton, FlightTextInput, FlightModal } from '@flybits/design-system';
import { Link } from 'react-router-dom';
import 'src/styles/pages/PasswordReset.scss';
import { EMAIL_VALIDATION, EMAIL_REQUIRED, GENERIC_INTERNAL_ERROR } from 'src/constants/errors';
import AuthService from 'src/services/authentication.service';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { PublicPageChildProps } from 'src/model/general/public-pages';
import { useTranslation } from 'react-i18next';
interface FormValues {
  username: string;
}
const authServiceManager = new AuthService();
const PasswordReset: FunctionComponent<PublicPageChildProps> = () => {
  const MAIN_CLASS = 'PasswordReset';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const LINK_CLASS = `${ITEM_CLASS}__link`;
  const { t } = useTranslation(['translation', 'public', 'errors']);

  const urlParams = new URLSearchParams(window.location.search);
  const [initialValues, setInitialValues] = useState<FormValues>({ username: '' });

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required(t('errors:EMAIL_REQUIRED', EMAIL_REQUIRED) || EMAIL_REQUIRED)
      .email(t('errors:EMAIL_VALIDATION', EMAIL_VALIDATION) || EMAIL_VALIDATION),
  });

  const [isPasswordReset, setPasswordReset] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const initPasswordReset = async (email: string) => {
    setLoading(true);
    const ResetPasswordRequestBody = {
      username: email,
    };
    try {
      const response = await authServiceManager.resetPassword(ResetPasswordRequestBody);
      if (response && response.status === 200) {
        setPasswordReset(true);
        setLoading(false);
        setTimeout(() => {
          setPasswordReset(false);
        }, 10000);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        setErrorMessage(error.response.data.message);
        setError(true);
      } else {
        setErrorMessage(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR) || GENERIC_INTERNAL_ERROR);
        setError(true);
      }
      console.error(error);
    }
    setLoading(false);
  };

  const toggleModal = () => {
    setError(false);
  };

  useEffect(() => {
    if (urlParams.has('email')) {
      // autofill email based on url
      setInitialValues({
        username: urlParams.get('email')?.replace(/\ /g, '+') || '',
      });
    }
  }, []);

  return (
    <div className={MAIN_CLASS}>
      <FlightModal
        isVisible={error}
        toggleModalShown={toggleModal}
        scrollable={false}
        size="small"
        warning={true}
        className="custom-modal-class"
        header={<span>{t('errors:UNABLE_TO_PERFORM_ACTION', 'Unable to perform action')}</span>}
        content={
          <span role="alert" aria-atomic>
            <b>Error:</b> {errorMessage}
          </span>
        }
      />
      <div className={`${MAIN_CLASS}__form-container`}>
        <SvgFlybitsLogo width={104} height={30} viewBox="0 0 104 30" {...{ className: `${MAIN_CLASS}__logo` }} />
        <h1 className={`${MAIN_CLASS}__heading-text`}>{t('public:password.heading', 'Forgot your password?')}</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          enableReinitialize
          onSubmit={(values, { resetForm }) => {
            initPasswordReset(values.username);
            resetForm();
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form className={FORM_CLASS} action="/">
              <div className={ITEM_CLASS}>
                <Field
                  type="text"
                  name="username"
                  className={`${ITEM_CLASS}__input`}
                  as={FlightTextInput}
                  width="100%"
                  label={t('public:password.labels.username', 'Email')}
                  hasError={touched.username && errors.username ? true : false}
                  value={values.username}
                  errorMessage={
                    <span role="alert" aria-atomic>
                      <b>Error:</b> {errors.username}
                    </span>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <FlightButton
                className={`${ITEM_CLASS}__button`}
                type="primary"
                size="large"
                label={t('public:password.labels.btn-reset', 'Reset Password')}
                loading={isLoading}
                disabled={isLoading || !values.username || !!errors.username}
                onClick={handleSubmit}
              />
              {isPasswordReset && (
                <div className={`${MAIN_CLASS}__email-sent`} role="alert">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    aria-hidden
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0001 1.66669C5.40008 1.66669 1.66675 5.40002 1.66675 10C1.66675 14.6 5.40008 18.3334 10.0001 18.3334C14.6001 18.3334 18.3334 14.6 18.3334 10C18.3334 5.40002 14.6001 1.66669 10.0001 1.66669ZM8.33342 14.1667L4.16675 10L5.34175 8.82502L8.33342 11.8084L14.6584 5.48335L15.8334 6.66669L8.33342 14.1667Z"
                      fill="#00AB50"
                    />
                  </svg>
                  {t('public:password.messages.email_sent', 'Email sent!')}
                </div>
              )}
            </form>
          )}
        </Formik>
        <div className={`${ITEM_CLASS}__signin`}>
          <Link
            to={`/signin${urlParams.has('email') ? `?email=${urlParams.get('email')?.replace(/\ /g, '+')}` : ''}`}
            className={LINK_CLASS}
          >
            {t('public:password.labels.go_back', 'Back to sign in')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
