import React, { FunctionComponent, useState, useEffect } from 'react';
import SvgFlybitsLogo from 'src/assets/FlybitsLogo';
import { FlightButton, FlightTextInput, FlightCheckbox } from '@flybits/design-system';
import { Link, useHistory } from 'react-router-dom';
import 'src/styles/pages/SignIn.scss';
import {
  PASSWORD_REQUIRED,
  EMAIL_REQUIRED,
  EMAIL_VALIDATION,
  USER_NOT_FOUND,
  GENERIC_INTERNAL_ERROR,
} from 'src/constants/errors';
import AuthService from 'src/services/authentication.service';
import { setStoredEmail, deleteStoredEmail, getStoredEmail, deleteStoredPassword } from 'src/helpers/auth.helper';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { PublicPageChildProps } from 'src/model/general/public-pages';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';

// Translation
import { useTranslation } from 'react-i18next';

const authServiceManager = new AuthService();

const SignIn: FunctionComponent<PublicPageChildProps> = (props: PublicPageChildProps) => {
  const { t } = useTranslation(['translation', 'public', 'errors']);

  const { setBannerVisibility } = props;
  const [rememberMe, setRememberMe] = useState(false);
  const [initialValues, setInitialValues] = useState<FormValues>({ username: '', password: '' });
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isRedirecting, setRedirecting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  let isMounted = true;

  const urlParams = new URLSearchParams(window.location.search);
  const shouldSkipTokenCheck = !!urlParams.get('loggedOut') || !!urlParams.get('skipCheck');
  const nextURL = atob(urlParams.get('next') || btoa('/organizations'));
  const signUpURL = urlParams.has('next') ? 'signup?next=' + urlParams.get('next') : 'signup';
  const verifyURL = urlParams.has('next') ? '/2fa?next=' + urlParams.get('next') : '/2fa';

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required(t('errors:EMAIL_REQUIRED', EMAIL_REQUIRED))
      .email(t('errors:EMAIL_VALIDATION', EMAIL_VALIDATION)),
    password: Yup.string().required(t('errors:PASSWORD_REQUIRED', PASSWORD_REQUIRED)),
  });

  const history = useHistory();
  interface FormValues {
    username: string;
    password: string;
  }

  const MAIN_CLASS = 'signin';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const LINK_CLASS = `${ITEM_CLASS}__link`;
  useEffect(() => {
    const checkUserToken = async () => {
      try {
        const signInUserResponseObject = await authServiceManager.getSignedInUser();
        if (signInUserResponseObject?.status === 200 && signInUserResponseObject?.data) {
          if (urlParams.get('req')) {
            const signInRequestObject = {
              req: urlParams.get('req') || '',
            };
            try {
              setRedirecting(true);
              const response = await authServiceManager.signInUserWithToken(signInRequestObject);
              if (response && response.data && [200, 201, 202, 203, 204].includes(response.status)) {
                if (response.headers && response.headers['location']) {
                  window.location.assign(response.headers['location']);
                }
              }
              if (response && [400, 401, 404, 500].includes(response.status)) {
                if (urlParams.get('req')) {
                  history.replace('/signin');
                }
              }
            } catch (error) {
              setRedirecting(false);
              console.error(error);
              if (urlParams.get('req')) {
                history.replace('/signin');
              }
            }
          } else {
            setRedirecting(false);
            await setBannerVisibility(false);
            history.replace(nextURL);
          }
        }
      } catch (err) {
        setRedirecting(false);
        console.error(err);
      }
    };
    const fetchEmailAndPassword = async () => {
      const emailFromStorage = await getStoredEmail();
      const initialFormValues = {
        username: '',
        password: '',
      };
      if (emailFromStorage && emailFromStorage !== 'null') {
        initialFormValues['username'] = emailFromStorage;
      }
      if (urlParams.has('email')) {
        // autofill email based on url
        initialFormValues['username'] = urlParams.get('email')?.replace(/\ /g, '+') || '';
      }
      await deleteStoredPassword(); // ensure no password is saved
      setInitialValues(initialFormValues);
    };
    if (isMounted) {
      fetchEmailAndPassword();
      !shouldSkipTokenCheck && checkUserToken();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const initSignIn = async (username: string, password: string) => {
    setError(false);
    setLoading(true);
    const signInRequestObject = {
      username: username,
      password: password,
    };

    try {
      const response = await authServiceManager.signInUser(signInRequestObject);
      if (response && response.status === 200) {
        if (response.headers && response.headers['location']) {
          window.location.assign(response.headers['location']);
        }
        if (response.headers) {
          // Check if needs 2 Factor Authentication
          if (response.data.verify) {
            history.push(
              urlParams.has('next')
                ? verifyURL + '&email=' + encodeURIComponent(username)
                : verifyURL + '?email=' + encodeURIComponent(username),
            );
          } else {
            if (!urlParams.has('next')) {
              await setBannerVisibility(false);
            }
            setLoading(false);
            if (rememberMe) {
              await setStoredEmail(username);
            } else {
              await deleteStoredEmail();
              await deleteStoredPassword();
            }

            history.push(nextURL);
          }
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        if (error.response.data.message.indexOf('not exist') >= 0) {
          setErrorMessage(t('errors:USER_NOT_FOUND', USER_NOT_FOUND));
        } else {
          setErrorMessage(error.response.data.message);
        }
      } else {
        setErrorMessage(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
      }
      setLoading(false);
      setError(true);
    }
  };

  const ToggleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <div className={MAIN_CLASS}>
      <LoadingIcon width={80} height={80} visible={isRedirecting} fullScreen={true} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          await initSignIn(values.username, values.password);
          resetForm();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <div>
            <form className={FORM_CLASS} action="/">
              <SvgFlybitsLogo width={104} height={30} viewBox="0 0 104 30" {...{ className: `${MAIN_CLASS}__logo` }} />
              <h1 className={`${MAIN_CLASS}__heading-text`}>{t('public:signin.heading', 'Sign in')}</h1>
              {error && (
                <div className="form__error" role="alert" aria-atomic>
                  <strong>Error:</strong>
                  <ul>
                    <li>{errorMessage}</li>
                  </ul>
                </div>
              )}{' '}
              <div className={ITEM_CLASS}>
                <Field
                  type="text"
                  name="username"
                  className={`${ITEM_CLASS}__input`}
                  as={FlightTextInput}
                  width="100%"
                  disabled={isLoading}
                  label={t('public:signin.labels.username', 'Email')}
                  hasError={touched.username && errors.username ? true : false}
                  value={values.username}
                  errorMessage={
                    <span role="alert" aria-atomic>
                      <b>Error:</b> {errors.username}
                    </span>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={ITEM_CLASS}>
                <Field
                  type="password"
                  name="password"
                  label={t('public:signin.labels.password', 'Password')}
                  className={`${ITEM_CLASS}__input`}
                  as={FlightTextInput}
                  width="100%"
                  disabled={isLoading}
                  hasError={touched.password && errors.password ? true : false}
                  value={values.password}
                  errorMessage={
                    <span role="alert" aria-atomic>
                      {errors.password}
                    </span>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={`${ITEM_CLASS}__row`}>
                <div className={`${ITEM_CLASS}__checkbox`}>
                  <FlightCheckbox
                    label={t('public:signin.labels.remember-me', 'Remember me')}
                    checkState={rememberMe ? 'SELECTED' : 'UNSELECTED'}
                    onSelect={ToggleRememberMe}
                    disabled={isLoading}
                  />
                </div>
                <div className={LINK_CLASS}>
                  <Link
                    to={`/password/reset${
                      urlParams.has('email') ? `?email=${urlParams.get('email')?.replace(/\ /g, '+')}` : ''
                    }`}
                    className={LINK_CLASS}
                  >
                    {t('public:signin.forgot-password', 'Forgot your password?')}
                  </Link>
                </div>
              </div>
              <FlightButton
                className={`${ITEM_CLASS}__button`}
                type="primary"
                size="large"
                label={t('public:signin.labels.btn-signin', 'Sign in')}
                loading={isLoading}
                disabled={isLoading || !!errors.username || !!errors.password}
                onClick={handleSubmit}
              />
              <div className={`${ITEM_CLASS}__signup`}>
                {t('public:signin.dont-have-account.text', 'Don’t have a Flybits account?')}&nbsp;
                <Link to={signUpURL} className={LINK_CLASS}>
                  {t('public:signin.dont-have-account.link-label', 'Sign up')}
                </Link>
              </div>
              <div className={`${ITEM_CLASS}__signup`}>
                <hr />
                <small>
                  {t('public:signin.compliance.agreement', 'By signing in, you indicate that you agree to the')}{' '}
                  <Link to={'/terms-of-service'} className={LINK_CLASS}>
                    {t('public:signin.compliance.terms.label', 'Terms of Service')}{' '}
                  </Link>{' '}
                  {t('public:signin.compliance.and', 'and')}
                  <Link to={'/privacy-policy'} className={LINK_CLASS}>
                    {' '}
                    {t('public:signin.compliance.privacy.label', 'Privacy Policy')}
                  </Link>
                  .
                </small>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default SignIn;
