import React, { FunctionComponent, useEffect, useState } from 'react';
import { FlightButton, FlightTextInput, FlightSelect, FlightCheckbox } from '@flybits/design-system';
import './OrganizationInvites.scss';
// Services
import OrganizationService from 'src/services/organization.service';

import CollapsibleMultiple from 'src/components/Shared/CollapsibleMultiple/CollapsibleMultiple';

// Helpers
import { EMAIL_REQUIRED, EMAIL_VALIDATION } from 'src/constants/errors';
import { setStoredOrganizationId } from 'src/helpers/auth.helper';
import { Formik, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import useNotification from 'src/hooks/useNotification';
import { capitalize } from 'src/helpers/general.helper';
import { ORG_ROLES } from 'src/constants/levels';
import { useTranslation } from 'react-i18next';
import { Team, TeamNested, TeamProps } from 'src/model/teams/teams';
import { filterNestedTeams, getSubTeams } from 'src/helpers/teams.helper';
import { EVENT_KEYS } from 'src/constants/general';

interface Props {
  location?: any;
  match?: any;
}

const OrganizationInvites: FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'OrganizationInvites';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;
  const { t } = useTranslation(['translation', 'errors']);

  const { match } = props;
  const orgID = match.params.id;
  const { addNotification, addNotificationError } = useNotification();

  const [loading, setLoading] = useState(false);

  const organizationLevels = [
    {
      key: 'member',
      name: t('translation:pages.organizations.levels.member', 'Member'),
    },
    {
      key: 'admin',
      name: t('translation:pages.organizations.levels.admin', 'Admin'),
    },
    {
      key: 'owner',
      name: t('translation:pages.organizations.levels.owner', 'Owner'),
    },
  ];
  const adminPermissions = ORG_ROLES.filter((item) => item.role === 'admin');

  const history = useHistory();

  if (!orgID) {
    history.push('/organizations');
  }
  setStoredOrganizationId(orgID);
  interface SelectOptionProps {
    key: string;
    name: string;
  }
  interface FormValues {
    recipients: FormValuesRecipient[];
    teams: string[];
  }
  interface UserTeamParams {
    maintainer: boolean;
    id: string;
  }
  interface FormValuesRecipient {
    email: string;
    level: string;
    roles: string[];
    teams: UserTeamParams[];
  }
  const validationSchema = Yup.object().shape({
    recipients: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .trim()
          .required(t('errors:EMAIL_REQUIRED', EMAIL_REQUIRED))
          .email(t('errors:EMAIL_VALIDATION', EMAIL_VALIDATION)),
        level: Yup.string(),
        roles: Yup.array()
          .of(Yup.string())
          .when('level', {
            is: (level: string) => {
              return level === 'admin';
            },
            then: Yup.array()
              .of(Yup.string().required(t('errors:NEED_ADD_PERMISSION', 'You need to add at least 1 permission')))
              .required(t('errors:NEED_ADD_PERMISSION', 'You need to add at least 1 permission'))
              .min(1, t('errors:NEED_ADD_PERMISSION', 'You need to add at least 1 permission')),
          }),
      }),
    ),
  });
  const defaultRecipient = { email: '', level: 'member', roles: [''], teams: [] };
  const initialValues = { recipients: [defaultRecipient], teams: [] };
  const addNewInvites = (e: any, values: any, setValues: any) => {
    // update dynamic form
    const recipients = [...values.recipients];
    recipients.push(defaultRecipient);
    setValues({ ...values, recipients });
  };
  const [organizationTeams, setOrganizationTeams] = useState<Team[]>([]);
  // const [inviteTeams, setInviteTeams] = useState<string[]>([]);
  const TeamHeaderBlock = (team: TeamProps) => {
    const handleSelectTeam = () => {
      // update teams
      const teams = team.values.teams || [];

      if (!teams.includes(team.id)) {
        teams.push(team.id);
      } else {
        teams.splice(teams.indexOf(team.id), 1);
      }
      team.setValues({ ...team.values, teams });
    };

    return (
      <>
        <div className="checkbox__team">
          <span className="checkbox__input">
            <input
              value={team.id}
              type="checkbox"
              checked={team?.values?.teams?.includes(team.id)}
              onChange={handleSelectTeam}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === EVENT_KEYS.SPACE || e.key === EVENT_KEYS.ENTER) {
                  handleSelectTeam();
                  e.stopPropagation();
                }
              }}
              name="teams"
            />
            <label className="checkbox" htmlFor={team.id}></label>
          </span>
        </div>
        <div className="infos">
          <div className="title" id={'team-m-' + team.id}>
            {team.name}
          </div>
        </div>
        <div className={`teams_number ${team.subteams < 1 ? 'zero' : ''}`}>
          {team.subteams}{' '}
          {team.subteams > 1 || team.subteams === 0
            ? t('translation:pages.directory.teams', 'teams').toLocaleLowerCase()
            : t('translation:pages.directory.team', 'team').toLocaleLowerCase()}
        </div>
      </>
    );
  };
  const generateTableData = (data: TeamNested[], values: any, setValues: any) => {
    return data.map(function f(t, i) {
      const depth = t.id.split('_').length - 2;
      if (t.subteams && t.subteams.length) {
        return {
          header: (
            <TeamHeaderBlock
              id={t.id}
              subteams={t.subteams.length}
              name={t.name}
              info={t.description}
              values={values}
              setValues={setValues}
            />
          ),
          key: i,
          name: t.name,
          depth: depth,
          content: <CollapsibleMultiple key={t.id} items={t.subteams.map(f)} />,
        };
      } else {
        return {
          header: (
            <TeamHeaderBlock
              id={t.id}
              subteams={0}
              name={t.name}
              info={t.description}
              values={values}
              setValues={setValues}
            />
          ),
          key: i,
          name: t.name,
          depth: depth,
          content: '',
        };
      }
    });
  };
  const teamsFilteredData = (values: any, setValues: any) => {
    return generateTableData(filterNestedTeams(organizationTeams, ''), values, setValues);
  };
  useEffect(() => {
    const getOrganizationDetails = async () => {
      const organizationServiceManager = new OrganizationService();
      try {
        const organizationTeamsResponseObject = await organizationServiceManager.getOrganizationTeams(orgID);
        if (organizationTeamsResponseObject?.data) {
          setOrganizationTeams(getSubTeams(organizationTeamsResponseObject?.data, ''));
        }
      } catch (error) {
        console.error(error?.response);
      }
    };
    getOrganizationDetails();
  }, []);

  const cancelInvites = () => {
    history.goBack();
  };

  const selectedRole = (role?: string) => {
    if (!role) return null;
    return {
      key: role,
      name: capitalize(t(`translation:pages.organizations.levels.${role}`, role)),
    };
  };

  const inviteUsers = async (values: FormValues) => {
    setLoading(true);
    const organizationServiceManager = new OrganizationService();
    const invitesRequestObject = {
      recipients: values.recipients.map((invite) => ({
        email: invite.email.trim(),
        teams: values.teams.map((team) => ({ maintainer: false, id: team })),
        roles:
          invite.roles.length && !invite.roles.includes('')
            ? invite.roles
            : invite.level === 'member'
            ? []
            : [invite.level],
      })),
    };
    try {
      const invitesResponseObject = await organizationServiceManager.inviteUsersToOrganization(
        orgID,
        invitesRequestObject,
      );
      if (invitesResponseObject && invitesResponseObject.status >= 200 && invitesResponseObject.status < 400) {
        return true;
      }
    } catch (error) {
      console.error(error);
      addNotificationError(
        error?.response?.data?.message || t('translation:pages.invites.error_inviting', 'Error inviting users'),
      );
      setLoading(false);
      return false;
    }
  };

  return (
    <div className={MAIN_CLASS}>
      <LoadingIcon
        width={80}
        height={80}
        fullScreen
        text={t('translation:pages.invites.loading', 'Inviting users...')}
        visible={loading}
      />
      <div className={CONTENT_CLASS}>
        <div className={`${CONTENT_CLASS}__wrapper`}>
          <h1 className={`${CONTENT_CLASS}__heading-text`}>
            <FlightButton
              type="primary"
              className={`${CONTENT_CLASS}__button_link ${CONTENT_CLASS}__button_link-back`}
              theme="link"
              size="medium"
              label={`< ${t('translation:general.buttons.back', 'Back')}`}
              ariaLabel="Go back to previous page"
              onClick={() => {
                history.goBack();
              }}
            />
            {t('translation:pages.invites.btn-invite', 'Invite new users')}
          </h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange
            enableReinitialize
            onSubmit={async (values, { resetForm, setValues }) => {
              const result = await inviteUsers(values);
              if (result) {
                setTimeout(() => {
                  setValues(initialValues);
                  resetForm();
                  setLoading(false);
                  addNotification(t('translation:pages.invites.success', 'Users invited successfully!'));
                }, 2000);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setValues,
            }) => (
              <form className={FORM_CLASS} autoComplete="off">
                <input autoComplete="off" name="hidden" type="text" className={`${ITEM_CLASS}__hide`}></input>
                <div className={`${FORM_CLASS}__left`}>
                  <FieldArray
                    name="recipients"
                    render={(arrayHelpers) => (
                      <>
                        {values.recipients.map((recipient, i) => {
                          const recipientErrors: any = (errors.recipients?.length && errors.recipients[i]) || {};
                          const recipientTouched: any = (touched.recipients?.length && touched.recipients[i]) || {};
                          return (
                            <div key={i}>
                              <Field
                                type="text"
                                name={`recipients.${i}.email`}
                                className={`${ITEM_CLASS}__input`}
                                width="346px"
                                placeholderText="user@email.com"
                                label={t('translation:general.email', 'Email *')}
                                ariaLabel={'Email for user ' + (i + 1)}
                                isLabelAlwaysOn
                                isAriaRequired
                                as={FlightTextInput}
                                hasError={recipientErrors?.email && recipientTouched?.email ? true : false}
                                errorMessage={
                                  <span role="alert" aria-atomic>
                                    <b>Error:</b> {recipientErrors?.email || ''}
                                  </span>
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <Field
                                as={FlightSelect}
                                type="select"
                                name={`recipients.${i}.role`}
                                ariaLabel={`Roles for user ${i + 1}. (Required)`}
                                label={`User role:`}
                                options={organizationLevels}
                                required
                                aria-required
                                isAriaRequired
                                hasLabelAnimation
                                selected={selectedRole(values.recipients[i].level)}
                                dropdownMaxHeight="90px"
                                handleOptionClick={async (value: SelectOptionProps) => {
                                  setFieldValue(`recipients.${i}.level`, value.key, true);
                                }}
                                width="128px"
                              />
                              <FlightButton
                                type="button"
                                theme="minor"
                                size="medium"
                                ariaLabel={'Remove user ' + (i + 1)}
                                iconLeft="trashCan"
                                label=""
                                onClick={() => arrayHelpers.remove(i)}
                              />
                              {values.recipients[i].level === 'admin' && (
                                <>
                                  <div
                                    className={`${FORM_CLASS}__admin_bar ${recipientErrors?.roles ? 'error' : ''}`}
                                    role="group"
                                    aria-label={'Admin role permissions. Selecting at least one is required'}
                                  >
                                    {adminPermissions[0].permissions.map((permission: string, index: number) => {
                                      return (
                                        <Field
                                          as={FlightCheckbox}
                                          value={permission}
                                          key={index}
                                          label={capitalize(permission)}
                                          name={`recipients.${i}.roles`}
                                          checkState={
                                            values.recipients[i].roles.includes(permission) ? 'SELECTED' : 'UNSELECTED'
                                          }
                                          onSelect={() => {
                                            const newPermissions = values.recipients[i].roles.filter(
                                              (p: string) => p !== 'member' && p !== '',
                                            );
                                            if (!values.recipients[i].roles.includes(permission)) {
                                              newPermissions.push(permission);
                                            } else {
                                              newPermissions.splice(newPermissions.indexOf(permission), 1);
                                            }
                                            setFieldValue(`recipients.${i}.roles`, newPermissions, true);
                                          }}
                                        />
                                      );
                                    })}
                                  </div>
                                  {recipientErrors?.roles && (
                                    <div role="alert" aria-atomic className="flight-text-input__error-message">
                                      <span>{recipientErrors.roles}</span>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  />

                  <div>
                    <FlightButton
                      type="button"
                      theme="secondary"
                      size="medium"
                      ariaLabel={t('translation:pages.invites.add_user', '+ Add another user')}
                      label={t('translation:pages.invites.add_user', '+ Add another user')}
                      onClick={(e) => addNewInvites(e, values, setValues)}
                      disabled={values.recipients.length >= 10}
                    />
                  </div>

                  <div>
                    <div className="teams-invite">
                      <h2>{t('translation:pages.invites.select_teams', 'Select teams (optional)')}</h2>
                      <div
                        className="TeamMembershipModal__teams_list"
                        role="group"
                        aria-label={t('translation:pages.teams.modal_memberships.teams_list', 'Teams list')}
                      >
                        <CollapsibleMultiple items={teamsFilteredData(values, setValues)} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${FORM_CLASS}__right`}>
                  <div className="item">
                    <div className="group">
                      <h2 className="title">{t('translation:general.levels.owner.title', 'Owners')}</h2>
                      <p>
                        {t(
                          'translation:general.levels.owner.text',
                          'Owners have complete administrative rights to the organization.',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="group">
                      <h2 className="title">{t('translation:general.levels.admin.title', 'Admins')}</h2>
                      <p>
                        {t(
                          'translation:general.levels.admin.text_sections',
                          'Admins can manage their designated areas of the organization. Admins must have access to at least one of the listed sections:',
                        )}
                      </p>
                      <ul>
                        <li>{t('translation:general.levels.admin.sections.projects', '- Projects')}</li>
                        <li>{t('translation:general.levels.admin.sections.teams', '- Teams')}</li>
                        <li>{t('translation:general.levels.admin.sections.connectors', '- Connectors')}</li>
                        <li>{t('translation:general.levels.admin.sections.datasources', '- Datasources')}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="group">
                      <h2 className="title">{t('translation:general.levels.member.title', 'Members')}</h2>
                      <p>
                        {t(
                          'translation:general.levels.member.text',
                          'Members can be added to teams to contribute to specific projects.',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`${FORM_CLASS}__footer`}>
                  <FlightButton
                    type="primary"
                    size="medium"
                    label={t('translation:pages.invites.btn-send', 'Send invitations')}
                    loading={loading}
                    disabled={
                      loading ||
                      !!(values.recipients.length == 1 && !values.recipients[0].email) ||
                      !!(errors?.recipients?.length && touched?.recipients?.length) ||
                      isSubmitting
                    }
                    onClick={handleSubmit}
                  />
                  <FlightButton
                    type="button"
                    theme="secondary"
                    size="medium"
                    label={t('translation:general.buttons.cancel', 'Cancel')}
                    onClick={cancelInvites}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default OrganizationInvites;
