import React, { useState, useCallback, useEffect } from 'react';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import { ReactComponent as IconError } from 'src/assets/icon-blocked.svg';
import { ReactComponent as IconValid } from 'src/assets/icons/green-check.svg';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism.css';
import './JSONFormEditor.scss';

type EditorProps = {
  title: string;
  code: string;
  disabled?: boolean;
  t: any;
  onChange: (code: string) => void;
};
const EditorWrapper = ({ title, code, disabled, onChange, t }: EditorProps) => {
  const [valid, setValid] = useState(true);
  const [codeValue, setCodeValue] = useState(code);

  const onCodeChange = useCallback(
    (code: string | undefined) => {
      setCodeValue(code || '');
      if (!code) {
        return;
      }

      try {
        const parsedCode = JSON.parse(code);
        setValid(true);
        onChange(parsedCode);
      } catch (err) {
        setValid(false);
      }
    },
    [setValid, onChange],
  );

  const hightlightWithLineNumbers = (input: any, grammar: any, language: any) =>
    highlight(input, grammar, language)
      .split('\n')
      .map((line: any, i: number) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
      .join('\n');
  return (
    <div className="panel panel-default">
      <h3 className="panel-heading">
        {!valid ? <IconError /> : <IconValid />}
        {' ' + title}
      </h3>
      <div className="panel-editor">
        <Editor
          highlight={(code) => hightlightWithLineNumbers(code, languages.json, 'json')}
          value={codeValue}
          padding={10}
          textareaId="codeArea"
          className={`editor`}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            lineHeight: 1.4,
            fontSize: '12px',
            outline: 0,
          }}
          onValueChange={onCodeChange}
          disabled={disabled}
        />
      </div>
      <div className={`json-editor__line-col ${!valid ? ' error' : ''}`}>
        <span>
          {!valid
            ? t(
                'integrations:connectors.details.sections.config-template.editor.schema_error_full',
                'Schema error, please check if you are using the correct JSON.',
              )
            : t('integrations:connectors.details.sections.config-template.editor.syntax_valid', 'Valid syntax')}
        </span>
      </div>
    </div>
  );
};

const toJson = (val: unknown) => JSON.stringify(val, null, 2);

type EditorsProps = {
  schema: RJSFSchema;
  setSchema: React.Dispatch<React.SetStateAction<RJSFSchema>>;
  uiSchema: UiSchema;
  setUiSchema: React.Dispatch<React.SetStateAction<UiSchema>>;
  disabled?: boolean;
  selectedTab: string;
  customSchema?: any;
  setPreviewError?: (args: any) => void;
  handleChange: (event: React.ChangeEvent<any>, type?: string) => void;
  setModified: (e: any) => void;
  t: any;
  APIVersion?: string;
};

const JSONFormEditor: React.FunctionComponent<EditorsProps> = ({
  schema,
  uiSchema,
  setPreviewError,
  handleChange,
  t,
  disabled,
  setSchema,
  setUiSchema,
}: EditorsProps) => {
  const [validSchema, setValidSchema] = useState(true);
  const [validUI, setValidUI] = useState(true);
  useEffect(() => {
    if (setPreviewError) {
      if (!validSchema || !validUI) setPreviewError(true);
      else setPreviewError(false);
    }
  }, [validSchema, validUI]);
  const onSchemaEdited = useCallback(
    (newSchema: any) => {
      setSchema(newSchema);
      if (!newSchema) {
        return;
      }
      try {
        const changeEvent = {
          target: {
            value: newSchema,
          },
        } as React.ChangeEvent<any>;

        handleChange(changeEvent, 'configSchema');
        setValidSchema(true);
      } catch (err) {
        setValidSchema(false);
      }
    },
    [setSchema, schema, uiSchema],
  );

  const onUISchemaEdited = useCallback(
    (newUiSchema: any) => {
      setUiSchema(newUiSchema);
      if (!newUiSchema) {
        return;
      }
      try {
        const changeEvent = {
          target: {
            value: newUiSchema,
          },
        } as React.ChangeEvent<any>;
        handleChange(changeEvent, 'uiSchema');
        setValidUI(true);
      } catch (err) {
        setValidUI(false);
      }
    },
    [setUiSchema, schema, uiSchema],
  );

  return (
    <div className="editors">
      <div className="editor-schema">
        <EditorWrapper title="JSONSchema" code={toJson(schema)} onChange={onSchemaEdited} disabled={disabled} t={t} />
      </div>
      <div className="editor-ui">
        <EditorWrapper title="UISchema" code={toJson(uiSchema)} onChange={onUISchemaEdited} disabled={disabled} t={t} />
      </div>
    </div>
  );
};

export default JSONFormEditor;
