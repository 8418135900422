import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { FlightButton, FlightTextInput } from '@flybits/design-system';
import './CreateOrganizations.scss';
import OrganizationService from 'src/services/organization.service';
import IconOrganization from 'src/assets/icons/organization';
import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import useNotification from 'src/hooks/useNotification';
import Thumb from 'src/components/UploadThumb/Thumb';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import { ORGANIZATION_NAME_REQUIRED, ORGANIZATION_NAME_VALIDATION } from 'src/constants/errors';
import { organizationRegex } from 'src/helpers/auth.helper';
import { useHistory } from 'react-router-dom';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { strip_tags } from 'src/helpers/general.helper';
import { useTranslation } from 'react-i18next';

const CreateOrganizations = () => {
  const controlTowerContextData = useControlTowerContext();
  const { t } = useTranslation(['translation', 'errors']);

  const { user } = controlTowerContextData;

  const MAIN_CLASS = 'organizations';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;
  const CREATE_ORGANIZATION_CLASS = `${MAIN_CLASS}__create-organization`;
  const EMPTY_STATE_CLASS = `${MAIN_CLASS}__organizations-empty-state`;
  const GRID_CLASS = `${MAIN_CLASS}__grid`;

  const [isAllowed, setAllowed] = useState(true);
  const [currentImage, setCurrentImage] = useState<any>(undefined);
  const [isLoading, setLoading] = useState(false);
  const [redirectToList, setRedirectToOrganizationsList] = useState(false);
  const { addNotification, addNotificationError } = useNotification();

  const history = useHistory();

  // Defining the ref constant variable
  const inputRef = React.useRef<HTMLInputElement>(null);

  const initialValues = { name: '', image: '' };
  // const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('errors:ORGANIZATION_NAME_REQUIRED', ORGANIZATION_NAME_REQUIRED))
      .matches(organizationRegex, t('errors:ORGANIZATION_NAME_VALIDATION', ORGANIZATION_NAME_VALIDATION)),
    // image: Yup.mixed()
    //   .test('fileSize', 'File Size is too large', (value) => value.size <= 10000 * 1024) // 10mb
    //   .test('fileType', 'Unsupported File Format', (value) => SUPPORTED_FORMATS.includes(value.type)),
  });

  interface FormValues {
    name: string;
    externalUrl?: string;
    image?: any;
  }

  useEffect(() => {
    if (user?.confirmed === true) {
      setAllowed(true);
    }
  }, [user]);

  const triggerSelectImage = () => {
    (inputRef as any).current.click();
  };

  const cancelCreation = () => {
    history.push('/organizations');
  };

  const createOrganization = async (values: FormValues) => {
    setLoading(true);
    const organizationRequestObject = {
      name: strip_tags(values.name),
      externalUrl: '',
      image: currentImage,
    };
    const organizationServiceManager = new OrganizationService();
    try {
      const organizationResponseObject = await organizationServiceManager.createOrganization(organizationRequestObject);
      if (
        organizationResponseObject &&
        (organizationResponseObject.status === 201 || organizationResponseObject.status === 200)
      ) {
        addNotification(t('translation:pages.organizations.create.success', 'Organization created successfully!'));
        return true;
      }
    } catch (error) {
      console.error(error);
      addNotificationError(t('translation:pages.organizations.create.fail', 'Error creating organization'));
      setLoading(false);
      return false;
    }
  };

  return (
    <div className={MAIN_CLASS}>
      {!isAllowed && <Redirect to="/organizations" />}
      {redirectToList && <Redirect to="/organizations" />}
      <LoadingIcon
        width={80}
        height={80}
        fullScreen
        text={t('translation:pages.organizations.create.setting_up', 'Setting up your organization...')}
        visible={isLoading}
      />
      <div className={CONTENT_CLASS}>
        <div className={GRID_CLASS}>
          <div className={EMPTY_STATE_CLASS}>
            <div className={CREATE_ORGANIZATION_CLASS}>
              <h1>{t('translation:pages.organizations.create.title', 'Create organization')}</h1>
              <div className={`${EMPTY_STATE_CLASS}__description`}>
                {t(
                  'translation:pages.organizations.create.description',
                  "Don't worry, this info can be changed later.",
                )}
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange
                enableReinitialize
                onSubmit={async (values) => {
                  const result = await createOrganization(values);
                  if (result) {
                    setTimeout(() => {
                      setRedirectToOrganizationsList(true);
                      // setLoading(false);
                    }, 3000);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <form className={FORM_CLASS}>
                    <div className={`${ITEM_CLASS}__group`}>
                      <div className={`${ITEM_CLASS}__image-field`}>
                        <div className="actions" role={'menu'}>
                          <FlightButton
                            theme="link"
                            size="medium"
                            ariaLabel={t('Change image')}
                            label={t('translation:general.buttons.change', 'Change')}
                            onClick={triggerSelectImage}
                          />
                          {values.image && (
                            <FlightButton
                              theme="link"
                              size="medium"
                              className={`${ITEM_CLASS}__remove`}
                              label={t('translation:general.buttons.remove', 'Remove')}
                              ariaLabel={t('Remove image')}
                              disabled={!values.image}
                              onClick={() => {
                                addNotification('Image removed');
                                setFieldValue('image', undefined, true);
                                setCurrentImage(undefined);
                                setFieldTouched('image', false, false);
                              }}
                            />
                          )}
                        </div>
                        {!redirectToList && <Thumb file={currentImage} />}
                        <IconOrganization role="presentation" />
                        <div className="flight-text-input-wrapper">
                          <label className="flight-text-input organizations__form__item__file" htmlFor="image">
                            <input
                              className="flight-text-input__input flight-text-input__input--without-label"
                              id="image"
                              name="imageFile"
                              type="file"
                              ref={inputRef}
                              tabIndex={-1}
                              onChange={(e) => {
                                if (!e.target.files) {
                                  e.target.value = '';
                                  e.target.files = null;
                                  setFieldValue('image', undefined, true);
                                  setCurrentImage(undefined);
                                  return;
                                }
                                setFieldValue('image', e.target.files[0], true);
                                setCurrentImage(e.target.files[0]);
                                e.target.files = null;
                              }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className={`${ITEM_CLASS}__group`}>
                        <div className={ITEM_CLASS}>
                          <Field
                            type="text"
                            name="name"
                            className={`${ITEM_CLASS}__input`}
                            as={FlightTextInput}
                            width="100%"
                            label={t('translation:pages.organizations.create.org_name', 'Name')}
                            ariaLabel={'Organization name'}
                            hasError={touched.name && errors.name ? true : false}
                            value={values.name}
                            errorMessage={
                              <span role="alert" aria-atomic>
                                <b>Error:</b> {errors.name}
                              </span>
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                    <FlightButton
                      className={`${ITEM_CLASS}__button`}
                      type="primary"
                      size="medium"
                      label={t('translation:pages.organizations.create.btn-create', 'Create organization')}
                      ariaLabel={t('translation:pages.organizations.create.btn-create', 'Create organization')}
                      loading={isLoading}
                      disabled={isLoading || !values.name || !!errors.name || isSubmitting}
                      onClick={handleSubmit}
                    />
                    <FlightButton
                      type="primary"
                      theme="link"
                      size="medium"
                      label={t('translation:general.buttons.cancel', 'Cancel')}
                      ariaLabel={t('translation:general.buttons.cancel', 'Cancel')}
                      onClick={cancelCreation}
                    />
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOrganizations;
