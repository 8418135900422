import { FlightTextInput, getIcon } from '@flybits/design-system';
import React, { CSSProperties, memo, useMemo, useState } from 'react';
import { useDrag } from 'react-dnd';

const style: CSSProperties = {
  backgroundColor: 'white',
  cursor: 'move',
};

export interface ParamSourceProps {
  name: string;
  type: string;
  id: string;
  index: number;
  condition: string;
  onChange: (type: string, id: string, value: string) => void;
  isDropped: boolean;
}

export const ParamSource: React.FunctionComponent<ParamSourceProps> = memo(function PSource({
  name,
  type,
  id,
  index,
  condition,
  onChange,
}: ParamSourceProps) {
  const options = useMemo(() => {
    return { dropEffect: 'copy' };
  }, []);
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { name, type, id, condition, index },
      options,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [condition, name, type],
  );
  const [newCondition, setCondition] = useState(condition || '');
  const editCondition = (e: any) => {
    onChange('source', id, e.target.value);
    setCondition(e.target.value);
  };
  return (
    <details className="param extra-field" id={id} ref={drag} style={{ ...style, opacity }} role="button">
      <summary>
        <code className="code mono">{type}</code>
        <div>{name}</div>
        <span className="plugin-top-arrow">{getIcon('baselineKeyboardArrowDown', {})}</span>
      </summary>
      <div>
        <FlightTextInput
          placeholderText=""
          width="100%"
          label={`Condition`}
          isLabelAlwaysOn
          type="text"
          name="table-filter"
          value={newCondition}
          onChange={editCondition}
        />
      </div>
    </details>
  );
});
