import React, { useEffect, useState } from 'react';
import './UploadThumb.scss';
import { FlightButton } from '@flybits/design-system';
import { ReactComponent as CloudUploadIcon } from 'src/assets/icons/cloud-upload.svg';
import useNotification from 'src/hooks/useNotification';
import { useTranslation } from 'react-i18next';
interface Props {
  onChangeImage: (e: any, img: any) => void;
  defaultContent?: JSX.Element;
  imgURL?: string;
}
const UploadThumb: React.FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'UploadThumb';
  const { t } = useTranslation(['translation', 'integrations']);
  const { onChangeImage, imgURL, defaultContent } = props;
  const { addNotificationError } = useNotification();
  const imageInputRef = React.useRef<any>(null);
  const [imgData, setImgData] = useState<any>(null);

  const triggerSelectImage = () => {
    (imageInputRef as any).current.click();
  };

  const onChangePicture = (e: any) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgData(reader.result);
        onChangeImage(e.target.files[0], reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    if (imgURL) setImgData(imgURL);
    else setImgData(null);
  }, [imgURL]);

  const clearThumbnail = () => {
    imageInputRef.current.value = '';
    setImgData(null);
    onChangeImage(null, '');
  };

  return (
    <div className={MAIN_CLASS}>
      {imgData && (
        <div className="thumb-preview">
          <div className="actions">
            <FlightButton
              theme="link"
              size="medium"
              label={t('translation:general.buttons.change', 'Change')}
              onClick={triggerSelectImage}
            />
            <FlightButton
              theme="link"
              size="medium"
              className={`bt__remove`}
              label={t('translation:general.buttons.remove', 'Remove')}
              onClick={() => {
                clearThumbnail();
              }}
            />
          </div>
          <img
            src={imgData}
            alt={t('translation:upload.preview', 'Preview')}
            onError={() => {
              if (imgData)
                addNotificationError(
                  t(
                    'translation:upload.messages.error',
                    'Error previewing your image, please use only valid image files',
                  ),
                );
              clearThumbnail();
            }}
          />
        </div>
      )}
      {!defaultContent ? (
        <>
          <CloudUploadIcon />
          <small className="mono">{t('translation:upload.dimensions', '500x500px')}</small>
        </>
      ) : (
        <>{defaultContent}</>
      )}

      <input
        id="logo"
        name="logo"
        type="file"
        className="logo-upload-input"
        onChange={onChangePicture}
        ref={imageInputRef}
        accept="image/*"
        aria-label={`${t(
          'integrations:connectors.details.sections.basic.labels.logo_msg',
          'A square logo that will be used to identify your connector.',
        )} ${!imgData ? 'No file chosen. Choose File' : 'File chosen. Choose another file'}`}
      />
    </div>
  );
};

export default UploadThumb;
