import React, { FunctionComponent, useState, useEffect } from 'react';
import { FlightButton, FlightSelect, FlightTextInput } from '@flybits/design-system';
import localforage from 'localforage';

import './Projects.scss';
import ProjectList from 'src/components/ProjectList/ProjectList';
import ProjectService from 'src/services/project.service';
import { getStoredOrganizationId, getUserRoles, setStoredOrganizationId, setUserRoles } from 'src/helpers/auth.helper';
import { capitalize, toType } from 'src/helpers/general.helper';
import { Project, ProjectResponseBody } from 'src/model/projects/projects';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import { CreateProjectModal } from 'src/components/CreateProjectModal/CreateProjectModal';
import { useHistory } from 'react-router';

import OrganizationService from 'src/services/organization.service';
import { Organization, OrganizationDeployment } from 'src/model/organizations/organizations';
import useRoleAccess from 'src/hooks/useRoleAccess';
import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import { arrayUnique } from 'src/helpers/general.helper';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';

interface Props {
  location?: any;
  match?: any;
}

interface SelectOptionProps {
  key: string;
  name: string;
}

const Projects: FunctionComponent<Props> = (props: Props) => {
  const { match } = props;
  const { setControlTowerContextDataOrg, organization, user } = useControlTowerContext();
  const orgID = match.params.id || organization?.id || getStoredOrganizationId();

  const history = useHistory();
  // organization id is not set/found
  if (!orgID) {
    history.push('/organizations');
  }
  const { t } = useTranslation(['translation', 'errors']);
  setStoredOrganizationId(orgID);
  const MAIN_CLASS = 'project';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;
  const WRAPPER_CLASS = `${MAIN_CLASS}__wrapper`;
  const CREATE_PROJECT_CLASS = `${MAIN_CLASS}__create-project`;
  const EMPTY_STATE_CLASS = `${MAIN_CLASS}__projects-empty-state`;
  const EMPTY_STATE_DESC_CLASS = `${MAIN_CLASS}__projects-empty-state-desc`;
  const GRID_CLASS = `${MAIN_CLASS}__grid`;
  const PROJECT_LIST_CLASS = `${MAIN_CLASS}__projectList`;

  const [response, setResponse] = useState<Project[]>([]);
  const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false);
  const [fetchProjects, setFetchedProjects] = useState(false);
  const [orgData, setOrgData] = useState<Organization | null>(null);
  const [orgDeployments, setOrgDeployments] = useState<OrganizationDeployment[]>([]);
  const [orgDeploymentsFilter, setOrgDeploymentsFilter] = useState([
    { key: 'all', name: t('translation:pages.projects.all_deployments', 'All deployments') },
  ]);
  const [projectDeployments, setProjectDeployments] = useState({
    key: 'all',
    name: t('translation:pages.projects.all_deployments', 'All deployments'),
  });
  const [selectedDeployment, setSelectedDeployment] = useState({
    key: 'all',
    name: t('translation:pages.projects.all_deployments', 'All deployments'),
  });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const editSearchTerm = (e: any) => {
    e.target.value !== searchTerm && setSearchTerm(e.target.value);
  };
  const projectsFilteredData = () => {
    return response.filter((proj) => {
      if (selectedDeployment.key === 'all' || proj.deployment.toLowerCase() === selectedDeployment.key) {
        if (!searchTerm) return true;
        if (proj.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return true;
        }
        return false;
      }
      return false;
    });
  };
  const handleSelectDeployment = (item: SelectOptionProps) => {
    localforage.setItem('lastProjectFilter', { key: item.key, name: item.name });
    setSelectedDeployment({ key: item.key, name: item.name });
  };

  useEffect(() => {
    const loadLastProjectFilter = async () => {
      const lastProjectFilter = await localforage.getItem<SelectOptionProps>('lastProjectFilter');
      if (!!lastProjectFilter) setSelectedDeployment(lastProjectFilter);
    };

    loadLastProjectFilter();
  }, []);

  const organizationServiceManager = new OrganizationService();
  useEffect(() => {
    const getOrganizationDetails = async () => {
      try {
        const organizationResponseObject = await organizationServiceManager.getOrganization(orgID);
        if (organizationResponseObject?.data) {
          setOrgData(organizationResponseObject?.data);
          triggerSetFetchProjects();
          const organizationDeployments = await organizationServiceManager.getOrganizationDeployments(orgID);
          const transformedResponse: Organization = organizationResponseObject?.data;
          transformedResponse.deployments = organizationDeployments?.data;
          setOrgDeployments(organizationDeployments?.data);
          setOrgDeploymentsFilter(
            organizationDeployments?.data.map((item: OrganizationDeployment) => {
              return { key: item.id, name: capitalize(item.id) };
            }),
          );
          let roles = [];
          if (user && user.userId) {
            const organizationUserResponseObject = await organizationServiceManager.getOrganizationUserInfo(
              orgID,
              user.userId,
            );
            if (organizationUserResponseObject?.data) {
              roles = organizationUserResponseObject.data.roles;
              setCurrentRole(roles);
              await setUserRoles(roles);
            }
          }
          setControlTowerContextDataOrg({ ...transformedResponse, roles: roles });
        }
      } catch (error) {
        console.error(error?.response);
      }
    };
    getOrganizationDetails();
  }, [orgID]);

  const getProjectsTransformedResponse = (projectResponseObject: AxiosResponse | undefined): Project[] => {
    const transformedResponse: Project[] = [];
    if (projectResponseObject) {
      toType(projectResponseObject.data) === 'Array' &&
        projectResponseObject.data.map((project: ProjectResponseBody) => {
          return transformedResponse.push({
            id: project.id,
            name: project.name,
            subdomain: project.subdomain,
            region: project.region,
            deployment: project.deployment,
            endpoint: project.endpoint,
            tier: project.tier,
            active: project.active,
            organization: project.organization,
          });
        });
    }
    return transformedResponse;
  };
  function waitFor(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  useEffect(() => {
    const fetchProjectsFromOrg = async () => {
      setLoading(true);
      const projectServiceManager = new ProjectService();
      try {
        let projectResponseObject = await projectServiceManager.getProjects(orgID);
        let transformedResponse = getProjectsTransformedResponse(projectResponseObject);
        if (!projectResponseObject || transformedResponse.length == 0) {
          await waitFor(5000);
          projectResponseObject = await projectServiceManager.getProjects(orgID);
          transformedResponse = getProjectsTransformedResponse(projectResponseObject);
        }

        if (projectResponseObject && transformedResponse.length > 0) {
          setProjectDeployments(
            projectResponseObject.data.map((project: ProjectResponseBody) => {
              return { key: project.deployment, name: capitalize(project.deployment) };
            }),
          );
        }

        setResponse(transformedResponse);

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchProjectsFromOrg();
  }, [fetchProjects]);

  const [currentRole, setCurrentRole] = useState(['member']);
  useEffect(() => {
    async function getRole() {
      const savedRoles = await getUserRoles();
      const roles = !organization || !organization.roles.length ? savedRoles : organization.roles;
      setCurrentRole(roles);
    }
    getRole();
  }, [orgID, organization]);
  const hasAccess = (required: string[]) => useRoleAccess(required, currentRole);

  const toggleModal = () => {
    setOpenCreateProjectModal(!openCreateProjectModal);
  };

  const triggerSetFetchProjects = () => {
    setFetchedProjects(!fetchProjects);
  };

  return (
    <div className={MAIN_CLASS}>
      <CreateProjectModal
        openModal={openCreateProjectModal}
        toggleModal={toggleModal}
        fetchProjects={triggerSetFetchProjects}
        orgID={orgID}
        orgType={orgData?.type || 'standard'}
        orgDeployments={orgDeployments}
        t={t}
      />
      <div className={CONTENT_CLASS}>
        <div className={WRAPPER_CLASS}>
          <h1 className={`${CONTENT_CLASS}__heading-text`}>{t('translation:pages.projects.title', 'Projects')}</h1>
          <div className={`${CONTENT_CLASS}__search`} role="searchbox">
            <FlightTextInput
              placeholderText=""
              width="292px"
              hasClearIcon
              trailingIcon="search"
              type="text"
              name="table-filter"
              label="Search projects"
              ariaLabel="Search projects"
              value={searchTerm}
              onChange={editSearchTerm}
              isLabelAlwaysOn
            />
            <FlightSelect
              label={'Filter by deployment'}
              hasLabelAnimation
              options={[
                { key: 'all', name: t('translation:pages.projects.all_deployments', 'All deployments') },
                ...arrayUnique(orgDeploymentsFilter.concat(projectDeployments)),
              ]}
              selected={selectedDeployment}
              dropdownMaxHeight="120px"
              width="180px"
              handleOptionClick={(item) => handleSelectDeployment(item)}
            />
          </div>

          <div className={GRID_CLASS}>
            <div className={PROJECT_LIST_CLASS}>
              {hasAccess(['owner', 'projects']) && (
                <FlightButton
                  className={CREATE_PROJECT_CLASS}
                  label={t('translation:pages.projects.btn-create', 'Create project')}
                  ariaLabel={t('translation:pages.projects.btn-create-aria', 'Create project, opens dialog')}
                  iconLeft="add"
                  size="medium"
                  type="secondary"
                  onClick={toggleModal}
                />
              )}
              <LoadingIcon width={80} height={80} visible={loading} fullScreen />
              {projectsFilteredData().length ? (
                <ProjectList list={projectsFilteredData()} t={t} />
              ) : (
                <div className={EMPTY_STATE_CLASS}>
                  <p className={EMPTY_STATE_DESC_CLASS}>
                    {searchTerm ? (
                      <span role="alert" aria-atomic>
                        {t('translation:pages.projects.no_results_search', "We couldn't find any projects")}
                        <br />
                        {t('translation:pages.projects.no_results_matching', 'matching')} &ldquo;<b>{searchTerm}</b>
                        &rdquo; {t('translation:pages.projects.no_results_in', 'in')} <b>{selectedDeployment.name}</b>
                      </span>
                    ) : hasAccess(['owner', 'projects']) ? (
                      <>
                        {t(
                          'translation:pages.projects.no_projects',
                          'Create your first Flybits project to get started personalizing your customer experiences.',
                        )}
                      </>
                    ) : (
                      <span role="alert">
                        {t(
                          'translation:pages.projects.no_access',
                          'You do not have access to any projects in this organization and you do not have permission to create a project. Please contact your organization owner if your role in the organization needs to be changed',
                        )}
                      </span>
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
