import React, { useEffect, useState, useRef } from 'react';
import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import { FlightButton, FlightRadioButton, FlightTable, FlightModal, FlightTextInput } from '@flybits/design-system';
import { GENERIC_INTERNAL_ERROR } from 'src/constants/errors';
import { ReactComponent as TeamIcon } from 'src/assets/icons/user-group.svg';
import { SidePanel } from 'src/components/Shared/SidePanel/SidePanel';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import PanelContent from './PanelContent';
import TeamMembershipModal from 'src/components/Shared/TeamMembershipModal/TeamMembershipModal';
import './OrganizationDirectory.scss';
import CollapsibleMultiple from 'src/components/Shared/CollapsibleMultiple/CollapsibleMultiple';
import OrganizationService from 'src/services/organization.service';
import { setStoredOrganizationId } from 'src/helpers/auth.helper';
import { tableHeaders, filterNestedTeams, getFormattedRole, getSubTeams } from 'src/helpers/teams.helper';
import { useHistory } from 'react-router';
import { OrganizationInvitedUser, OrganizationUser } from 'src/model/organizations/organizations';
import { UserSelectedProps, UserColValue } from 'src/model/users/users';
import { Team, TeamNested, TeamProps } from 'src/model/teams/teams';
import { capitalize, handleKeyDownHelper } from 'src/helpers/general.helper';
import { useTranslation } from 'react-i18next';
import useNotification from 'src/hooks/useNotification';
import { EVENT_KEYS } from 'src/constants/general';
interface Props {
  location?: any;
  match?: any;
}

const OrganizationDirectory: React.FunctionComponent<Props> = (props: Props) => {
  const { user } = useControlTowerContext();
  const { t } = useTranslation(['translation', 'errors']);
  const { addNotification, addNotificationError } = useNotification();
  const { match } = props;
  const orgID = match.params.id;
  const history = useHistory();
  if (!orgID) {
    history.push('/organizations');
  }
  setStoredOrganizationId(orgID);
  const [error, setError] = useState('');
  const [selectedTab, setTab] = useState(window.location.hash === '#teams' ? 'teams' : 'users');
  const [selectedUser, setSelectedUser] = useState<UserSelectedProps | null>(null);
  const [alert, showAlert] = useState('');
  const [sidebar, showSidebar] = useState(false);
  const [updateTable, setUpdateTableData] = useState(true);
  const [organizationUsers, setOrganizationUsers] = useState<UserSelectedProps[]>([]);
  const [organizationUsersInvited, setOrganizationUsersInvited] = useState<UserSelectedProps[]>([]);
  const [organizationTeams, setOrganizationTeams] = useState<Team[]>([]);
  const [allOrganizationTeams, setAllOrganizationTeams] = useState<Team[]>([]);
  const focusRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!sidebar && selectedUser && selectedTab === 'users') {
      (document.querySelector(`[data-fieldkey="${selectedUser?.key}"]`) as HTMLElement)?.focus();
    }
    if (sidebar && selectedUser && selectedTab === 'users') {
      (document.querySelector(`.side-panel`) as HTMLElement)?.focus();
    }
  }, [sidebar, selectedUser]);

  const [searchTerm, setSearchTerm] = useState('');

  const [showTeamModal, setShowTeamModal] = useState(false);
  const [updateTree, setUpdateTree] = useState(false);

  const toggleModal = () => {
    setShowTeamModal(!showTeamModal);
  };

  const handleClickTeam = (id: string) => {
    history.push(`/organization/${orgID}/team/${id}`);
  };

  const editSearchTerm = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const toggleAlert = (message = '') => {
    showAlert(message);
  };
  const toggleSidebar = () => {
    showSidebar(!sidebar);
  };
  const toggleUpdateTable = () => {
    // showSidebar(false);
    setUpdateTableData(true);
  };

  const MAIN_CLASS = 'OrganizationDirectory';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  const TableCol = (data: UserColValue) => (
    <div className={`${data.email === t('translation:pages.directory.invite_sent', 'Invite sent') ? 'is-invite' : ''}`}>
      <p>
        <strong aria-label={'Highlighted user: ' + data.name + '.'}>{data.name}</strong>
      </p>
      <p>
        <small aria-label={'E-mail address: ' + data.email + ' .'}>{data.email}</small>
      </p>
    </div>
  );
  const RoleCol = (role: Array<string>) => (
    <span
      aria-label={
        'User role:' +
        capitalize(
          t(
            `translation:general.levels.${getFormattedRole(role) || 'member'}.single`,
            getFormattedRole(role) || 'member',
          ),
        ) +
        ' . Open in side panel.'
      }
    >
      {capitalize(
        t(
          `translation:general.levels.${getFormattedRole(role) || 'member'}.single`,
          getFormattedRole(role) || 'member',
        ),
      )}
      {getFormattedRole(role) === 'admin' && (
        <small>
          <br />
          {role.length > 1
            ? role
                .map((r) =>
                  capitalize(
                    t(`translation:general.levels.admin.permissions.${r.toLocaleLowerCase()}`, r.toLocaleLowerCase()),
                  ),
                )
                .join(', ')
            : capitalize(
                t(
                  `translation:general.levels.admin.permissions.${role.toString().toLocaleLowerCase()}`,
                  role.toString().toLocaleLowerCase(),
                ),
              )}
        </small>
      )}
    </span>
  );
  const TeamHeaderBlock = (team: TeamProps) =>
    React.useMemo(
      () => (
        <>
          <div
            role="button"
            tabIndex={0}
            className="infos"
            onClick={() => handleClickTeam(team.id)}
            onKeyDown={handleKeyDownHelper(() => handleClickTeam(team.id), false)}
          >
            <div className="title" aria-label={`${team.name}. Click to view details.`}>
              {team.name}
            </div>
            <span role="description" className="info">
              {team.info}
            </span>
          </div>
          <div className={`teams_number ${team.subteams < 1 ? 'zero' : ''}`}>
            {team.subteams}{' '}
            {team.subteams > 1 || team.subteams === 0
              ? t('translation:pages.directory.teams', 'teams').toLocaleLowerCase()
              : t('translation:pages.directory.team', 'team').toLocaleLowerCase()}
          </div>
        </>
      ),
      [team],
    );
  const usersFilteredData = () => {
    const usersWithInvites = [...organizationUsersInvited, ...organizationUsers];
    return usersWithInvites.filter(
      (user) =>
        !searchTerm ||
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  };
  const generateTableData = (data: TeamNested[]) => {
    return data.map(function f(t, i) {
      const depth = t.id.split('_').length - 1;
      if (t.subteams && t.subteams.length) {
        return {
          header: <TeamHeaderBlock id={t.id} subteams={t.subteams.length} name={t.name} info={t.description} />,
          key: i,
          name: t.name,
          depth: depth,
          content: <CollapsibleMultiple arrowPosition="left" items={t.subteams.map(f)} />,
        };
      } else {
        return {
          header: <TeamHeaderBlock id={t.id} subteams={0} name={t.name} info={t.description} />,
          key: i,
          name: t.name,
          depth: depth,
          content: '',
        };
      }
    });
  };
  const teamsFilteredData = () => {
    return generateTableData(filterNestedTeams(organizationTeams, searchTerm));
  };

  const handleKeyDownTabNav = (e: any) => {
    switch (e.key) {
      case EVENT_KEYS.ARROW_LEFT:
      case EVENT_KEYS.ARROW_RIGHT:
        e.preventDefault();
        if (selectedTab === 'users') {
          setTab('teams');
        } else {
          setTab('users');
        }
        break;
      default:
        break;
    }
  };

  const [usersLoading, setUsersLoading] = useState(true);
  const [teamsLoading, setTeamsLoading] = useState(true);
  useEffect(() => {
    const getOrganizationDetails = async () => {
      setUsersLoading(true);

      const organizationServiceManager = new OrganizationService();
      try {
        const organizationInvitesResponseObject = await organizationServiceManager.getOrganizationInvites(orgID);
        if (organizationInvitesResponseObject?.data) {
          setOrganizationUsersInvited(
            organizationInvitesResponseObject.data.map((orgUser: OrganizationInvitedUser, idx: number) => ({
              key: idx.toString(),
              userFormatted: TableCol({
                name: orgUser.recipient,
                email: t('translation:pages.directory.invite_sent', 'Invite sent'),
              }),
              roleFormatted: <span className="is-invite">{RoleCol(orgUser.roles)}</span>,
              name: orgUser.recipient,
              email: t('translation:pages.directory.invite_sent', 'Invite sent'),
              id: orgUser.recipient,
              organization: orgUser.organization,
              role: getFormattedRole(orgUser.roles),
              permissions: orgUser.roles.join('|'),
              teams: orgUser.teams,
            })),
          );
        }
        const organizationUsersResponseObject = await organizationServiceManager.getOrganizationUsers(orgID);
        if (organizationUsersResponseObject?.data) {
          setOrganizationUsers(
            organizationUsersResponseObject.data.map((orgUser: OrganizationUser) => ({
              key: orgUser.id,
              userFormatted: TableCol({
                name:
                  orgUser.givenName +
                  ' ' +
                  orgUser.familyName +
                  (user?.username === orgUser.email ? t('translation:pages.projects.details.you', '(you)') : ''),
                email: orgUser.email,
              }),
              roleFormatted: RoleCol(orgUser.roles),
              name: orgUser.givenName + ' ' + orgUser.familyName,
              email: orgUser.email,
              id: orgUser.id,
              organization: orgUser.organization,
              role: getFormattedRole(orgUser.roles),
              permissions: orgUser.roles.join('|'),
            })),
          );

          setUsersLoading(false);
        }
        const organizationTeamsResponseObject = await organizationServiceManager.getOrganizationTeams(orgID);
        if (organizationTeamsResponseObject?.data) {
          setAllOrganizationTeams(organizationTeamsResponseObject?.data);
          setOrganizationTeams(getSubTeams(organizationTeamsResponseObject?.data, ''));
          setTeamsLoading(false);
        }
        setUpdateTableData(false);
      } catch (error) {
        console.error(error?.response);
        if (error?.response?.data?.message) {
          setError(error.response.data.message);
        } else {
          setError(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
        }
        setUpdateTableData(false);
      }
    };
    updateTable && getOrganizationDetails();
    // eslint-disable-next-line
  }, [orgID, history, updateTable]);
  return (
    <div className={MAIN_CLASS}>
      <TeamMembershipModal
        openModal={showTeamModal}
        toggleModal={toggleModal}
        setUpdateTree={setUpdateTree}
        teams={organizationTeams}
        selectedUser={selectedUser}
        t={t}
      />
      <FlightModal
        isVisible={!!alert}
        toggleModalShown={() => toggleAlert('')}
        scrollable={false}
        size="small"
        warning={false}
        className={`${MAIN_CLASS}__alert`}
        header={<span>{alert}</span>}
        footer={
          <div className={`${MAIN_CLASS}__alert_footer`}>
            <FlightButton
              type="primary"
              label={t('translation:pages.directory.got_it', 'Got It!')}
              onClick={() => toggleAlert('')}
            />
          </div>
        }
      />
      <div className={`${CONTENT_CLASS} full-width`}>
        <div className={`${CONTENT_CLASS}__wrapper with-separator`}>
          <div className={`${CONTENT_CLASS}__heading-text-wrapper`}>
            <h1 className={`${CONTENT_CLASS}__heading-text`}>{t('translation:pages.directory.title', 'Directory')}</h1>
            <span>
              <FlightButton
                label={t('translation:pages.directory.btn-invite', 'Invite users')}
                ariaLabel={t('translation:pages.directory.btn-invite', 'Invite users')}
                size="medium"
                theme="secondary"
                onClick={() => {
                  history.push(`/organization/${orgID}/invites`);
                }}
              />
              <FlightButton
                label={t('translation:pages.directory.btn-team', 'Create team')}
                ariaLabel={t('translation:pages.directory.btn-team', 'Create team')}
                size="medium"
                theme="secondary"
                onClick={() => {
                  history.push(`/organization/${orgID}/teams/new`);
                }}
              />
            </span>
          </div>
          <div className={`${CONTENT_CLASS}__tabs-header`} role="tablist" onKeyDown={handleKeyDownTabNav}>
            <span role="tab" aria-selected={selectedTab === 'users'}>
              <FlightRadioButton
                label={t('translation:pages.directory.users', 'Users')}
                checked={selectedTab === 'users'}
                className={`${CONTENT_CLASS}__tabs-header-item`}
                onSelect={() => {
                  setTab('users');
                }}
                value="users"
              />
            </span>
            <span role="tab" aria-selected={selectedTab === 'teams'}>
              <FlightRadioButton
                label={t('translation:pages.directory.teams', 'Teams')}
                checked={selectedTab === 'teams'}
                className={`${CONTENT_CLASS}__tabs-header-item`}
                onSelect={() => {
                  setTab('teams');
                }}
                value="teams"
              />
            </span>
          </div>
        </div>
        <div className={`${CONTENT_CLASS}__wrapper`} aria-atomic>
          <div className={`${CONTENT_CLASS}__filters`}>
            {selectedTab === 'users' || (selectedTab === 'teams' && organizationTeams.length) ? (
              <FlightTextInput
                placeholderText=""
                ariaLabel={`Filter ${selectedTab}`}
                label={`Filter ${selectedTab}`}
                isLabelAlwaysOn
                width="292px"
                aria-autocomplete="on"
                hasClearIcon
                trailingIcon="search"
                type="text"
                name="table-filter"
                value={searchTerm}
                onChange={editSearchTerm}
              />
            ) : null}
          </div>
          {selectedTab === 'users' && (
            <div
              className={`${CONTENT_CLASS}__tabs-content`}
              role="tabpanel"
              aria-hidden="false"
              aria-label={t('translation:pages.directory.users', 'Users')}
              ref={focusRef}
            >
              <FlightTable
                className={`${CONTENT_CLASS}__tabs-content-table`}
                allowRowSelect
                isLoading={usersLoading}
                hasError={false}
                emptyState={
                  <div className={`${CONTENT_CLASS}__tabs-content-table-no-results`}>
                    <p>
                      <strong>0</strong>{' '}
                      {t('translation:pages.directory.messages.no_user_found', 'users found in this organization')}{' '}
                      {searchTerm && (
                        <>
                          {t('translation:pages.directory.messages.with_filter', 'with the filter')} &ldquo;
                          <strong>{searchTerm}</strong>&rdquo;
                        </>
                      )}
                    </p>
                  </div>
                }
                errorState={<span role="alert">{error}</span>}
                tableData={usersFilteredData()}
                tableHeaders={tableHeaders}
                selectedDataKey={selectedUser?.key}
                handleDataSelect={(e) => {
                  setSelectedUser(e);
                  showSidebar(true);
                }}
                hasPaginationBeforeTable={false}
                isShowHeader={false}
              />
            </div>
          )}
          {selectedTab === 'teams' && (
            <div
              className={`${CONTENT_CLASS}__tabs-content`}
              role="tabpanel"
              aria-hidden="false"
              aria-label={t('translation:pages.directory.teams', 'Teams')}
              ref={focusRef}
            >
              {!teamsLoading && !organizationTeams.length ? (
                <div className={`${CONTENT_CLASS}__tabs-teams-empty`}>
                  <TeamIcon />
                  <div>{t('translation:pages.directory.messages.no_teams_created', 'No teams have been created')}</div>
                  <FlightButton
                    label={t('translation:pages.directory.btn-team', 'Create team')}
                    size="medium"
                    theme="primary"
                    onClick={() => {
                      history.push(`/organization/${orgID}/teams/new`);
                    }}
                  />
                </div>
              ) : (
                <div className={`${CONTENT_CLASS}__tabs-teams`}>
                  {teamsLoading ? (
                    <LoadingIcon width={40} height={40} visible={teamsLoading} />
                  ) : (
                    <CollapsibleMultiple
                      arrowPosition="left"
                      className={`${CONTENT_CLASS}__tabs-teams-list`}
                      items={teamsFilteredData()}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {selectedTab === 'users' && sidebar && (
        <SidePanel
          showPanel={sidebar}
          title={selectedUser?.name ? `Showing ${selectedUser.name}'s info panel` : ''}
          content={
            <PanelContent
              user={user}
              t={t}
              addNotificationError={addNotificationError}
              addNotification={addNotification}
              userSelected={selectedUser}
              toggleTeamModal={toggleModal}
              showTeamModal={showTeamModal}
              shouldUpdateTree={updateTree}
              showAlert={toggleAlert}
              organizationTeams={allOrganizationTeams}
              updateTableData={toggleUpdateTable}
              togglePanel={toggleSidebar}
            />
          }
          togglePanel={toggleSidebar}
        />
      )}
    </div>
  );
};

export default OrganizationDirectory;
