import { FlightTextInput, getIcon } from '@flybits/design-system';
import React, { CSSProperties, memo, useState } from 'react';
import { useDrop } from 'react-dnd';

const style: CSSProperties = {
  backgroundColor: 'white',
  cursor: 'move',
};

export interface ParamDestProps {
  accept: string[];
  data: any;
  mapping?: any;
  onDrop: (item: any) => void;
  onChange: (type: string, id: string, value: string) => void;
}

export const ParamDest: React.FunctionComponent<ParamDestProps> = memo(function PluginAttr(props: ParamDestProps) {
  const { accept, data, mapping, onDrop, onChange } = props;
  const { name, id, type, defaultValue, index } = data;
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  let backgroundColor = '#fff';
  let borderColor = '#cdd0d7';
  if (isActive) {
    backgroundColor = '#DEFCF5';
    borderColor = '#00882f';
  } else if (canDrop) {
    backgroundColor = '#DEFCF5';
    borderColor = '#ccc';
  }
  const [condition, setCondition] = useState(defaultValue || '');
  const editCondition = (e: any) => {
    setCondition(e.target.value);
    onChange('destination', id, e.target.value);
  };
  return (
    <details
      className={`param extra-field ${
        mapping && mapping[index]?.from === -1 && !mapping[index]?.default && !condition ? 'error' : ''
      }`}
      id={id}
      ref={drop}
      style={{
        ...style,
        backgroundColor,
        borderColor,
        opacity: canDrop ? 1 : 0.85,
      }}
      role="button"
    >
      <summary>
        <code className="code mono">{type}</code>
        <div>{name}</div>
        <span className="plugin-top-arrow">{getIcon('baselineKeyboardArrowDown', {})}</span>
      </summary>
      <div>
        <FlightTextInput
          placeholderText=""
          width="100%"
          label={`Default`}
          isLabelAlwaysOn
          type="text"
          name="table-filter"
          value={condition}
          onChange={editCondition}
        />
      </div>
    </details>
  );
});
