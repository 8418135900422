import localforage from 'localforage';
import React, { useState, useCallback } from 'react';
interface NotificationContextProps {
  message: string;
  addNotification: (message: string) => void | null;
  addNotificationError: (message: string) => void | null;
  addNotificationWarning: (message: string) => void | null;
  removeNotification: () => void | null;
}
type Props = {
  children: React.ReactNode;
};
export const NotificationContext = React.createContext<NotificationContextProps>({
  message: '',
  addNotification: () => null,
  addNotificationError: () => null,
  addNotificationWarning: () => null,
  removeNotification: () => null,
});

// TADA
const successAudio = new Audio(process.env.PUBLIC_URL + '/yeah.mp3');
const errorAudio = new Audio(process.env.PUBLIC_URL + '/error.mp3');

export default function NotificationProvider({ children }: Props) {
  const [message, setMessage] = useState('');

  const removeNotification = () => setMessage('');

  const addNotification = (message: string) => {
    // Check for Justin Mode
    localforage.getItem('JustinEnabled').then((v) => {
      if (v) successAudio.play();
    });
    setMessage(message);
  };
  const addNotificationWarning = (message: string) => {
    setMessage(`~~~${message}`);
  };
  const addNotificationError = (message: string) => {
    // Check for Justin Mode
    localforage.getItem('JustinEnabled').then((v) => {
      if (v) errorAudio.play();
    });
    setMessage(`!!!${message}`);
  };

  const contextValue = {
    message,
    addNotification: useCallback((message: string) => addNotification(message), []),
    addNotificationError: useCallback((message: string) => addNotificationError(message), []),
    addNotificationWarning: useCallback((message: string) => addNotificationWarning(message), []),
    removeNotification: useCallback(() => removeNotification(), []),
  };

  return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>;
}
