import React, { useCallback, useEffect, useState } from 'react';
import './UserDefinedAttributes.scss';
import { ConnectorV1 } from 'src/model/connectors/connectors';
import { ReactComponent as UserIcon } from 'src/assets/icons/person-icon.svg';
import { ReactComponent as TenantScopeIcon } from 'src/assets/icons/tenant-scope.svg';
import {
  FlightTable,
  FlightTextInput,
  FlightSelect,
  FlightOverflowMenu,
  FlightRadioButton,
  FlightButton,
  FlightTooltip,
} from '@flybits/design-system';
import { SidePanel } from 'src/components/Shared/SidePanel/SidePanel';
import PluginAttributesPanel from 'src/components/Shared/PluginAttributesPanel/PluginAttributesPanel';
import { getDataExpiryText } from 'src/helpers/timeConverters';
import { AdditionalAttribute, DataSourceV1, AttrSelectedProps } from 'src/model/datasources/datasources';
import PreviewFormEditor from './PreviewFormEditor/PreviewFormEditor';
interface Props {
  current: DataSourceV1 | undefined;
  connector: ConnectorV1 | undefined;
  t: any;
  onError: (tab: string, hasError: boolean) => void;
  onChangeValue: (e: any) => void;
}
const tableHeaders = [
  {
    key: 'scope',
    isVisible: false,
  },
  {
    key: 'scopeIcon',
    name: 'Scope',
    isVisible: true,
    hideTooltip: true,
  },
  {
    key: 'name',
    name: 'Name',
    isVisible: true,
    hideTooltip: true,
  },
  {
    key: 'nameValue',
    isVisible: false,
    hideTooltip: true,
  },
  {
    key: 'type',
    name: 'Input type',
    isVisible: true,
    hideTooltip: true,
  },
  {
    key: 'expiry',
    name: 'Data expiry',
    isVisible: true,
    hideTooltip: true,
  },
  {
    key: 'options',
    name: 'Options',
    isVisible: true,
    hideTooltip: true,
  },
];
const NameCol = (name: string) => {
  return <div className="name-col">{name}</div>;
};
const defaultAttribute = {
  name: '', //will be the attribute name in the plugin
  description: '', //display when creating the datasource
  dataType: 'string',
  scope: 'user', //user or tenant
  parameters: [],
  defaultExpDuration: -1,
  connectorConfig: {}, //must match json schema
};

const UserDefinedAttributes: React.FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'UserDefinedAttributes';
  const { current, connector, onChangeValue, t } = props;
  const { additionalAttributes } = current?.configs || {};
  const { configSchema: connectorConfigSchemaAdditional, uiSchema: connectorUISchemaAdditional } =
    connector?.configs?.additionalAttributes || {};
  const [selectedAttribute, setSelectedAttribute] = useState<AttrSelectedProps | null>(null);
  const [, setSelectedAttributeIndex] = useState<number | null>(null);
  const [newAttribute, setNewAttribute] = useState<boolean>(false);
  const [currentPanelTab, setCurrentPanelTab] = useState<string>('attributes');
  const [currentAttribute, setCurrentAttribute] = useState<AdditionalAttribute | null>(null);
  const [typeFilters, setTypeFilters] = useState<any[]>([
    {
      key: 'all',
      name:
        t('integrations:connectors.details.sections.pre-defined-attributes.filters.any-type', `Any input type`) ||
        'Any input type',
    },
  ]);
  const [selectedFilterType, setSelectedFilterType] = useState({
    key: 'all',
    name:
      t('integrations:connectors.details.sections.pre-defined-attributes.filters.any-type', `Any input type`) ||
      'Any input type',
  });
  const [selectedFilterScope, setSelectedFilterScope] = useState({
    key: 'all',
    name:
      t('integrations:connectors.details.sections.pre-defined-attributes.filters.any-scope', `Any scope`) ||
      'Any scope',
  });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // set current type filters
    const filters = [
      {
        key: 'all',
        name:
          t('integrations:connectors.details.sections.pre-defined-attributes.filters.any-type', `Any input type`) ||
          'Any input type',
      },
    ];
    additionalAttributes?.map((item) => {
      !filters.some((e) => e.key === item.dataType) &&
        filters.push({
          key: item.dataType,
          name: formatInputType(item.dataType) || item.dataType,
        });
    });
    setTypeFilters(filters);
    resetSelection();
  }, [additionalAttributes]);

  const editSearchTerm = (e: any) => {
    setSearchTerm(e.target.value);
  };
  const resetSelection = () => {
    setCurrentAttribute(null);
    setNewAttribute(false);
    setSelectedAttribute(null);
    setSelectedAttributeIndex(null);
    setCurrentPanelTab('attributes');
  };
  const selectPluginAttribute = (key: any) => {
    if (!key) return;
    const selected = filteredSearchData().find((item: any) => item.key === key);
    if (!selected) return;
    setSelectedAttribute(selected);
    setSelectedAttributeIndex(selected.key - 1);
    setCurrentAttribute(additionalAttributes?.find((item) => item.name === selected?.nameValue) || null);
    setNewAttribute(false);
  };
  const handleDelete = (attribute: any) => {
    if (!attribute) return;
    const updatedAttributes = additionalAttributes || [];
    updatedAttributes.splice(
      updatedAttributes.findIndex((item) => item.name === attribute.name),
      1,
    );
    onChangeValue({
      ...current,
      configs: {
        ...current?.configs,
        additionalAttributes: updatedAttributes,
      },
    });
  };
  const closePanel = () => {
    resetSelection();
  };
  const handleSaveAttribute = (attribute: any) => {
    if (!attribute) return;
    const updatedAttributes = additionalAttributes || [];

    if (!selectedAttribute?.key) updatedAttributes.push(attribute);
    else updatedAttributes[selectedAttribute?.key - 1 || 0] = attribute;
    onChangeValue({
      ...current,
      configs: {
        ...current?.configs,
        additionalAttributes: updatedAttributes,
      },
    });
    closePanel();
  };
  const handleChangeFormData = useCallback(
    (e: any) => {
      if (!current || !connector) return;
      const updatedAttributes = additionalAttributes || [];
      const selectedIndex = selectedAttribute?.key ? selectedAttribute.key - 1 : 0;
      updatedAttributes[selectedIndex].connectorConfig = e.formData;
      onChangeValue({
        ...current,
        configs: {
          ...current?.configs,
          additionalAttributes: updatedAttributes,
        },
      });
    },
    [selectedAttribute],
  );
  const ScopeIcon = (scope: string) => {
    return scope === 'user' ? <UserIcon title="User scope" /> : <TenantScopeIcon title="Tenant scope" />;
  };
  const formatInputType = (type: string) => {
    switch (type) {
      case 'string':
        return 'String';
      case 'int':
        return 'Integer';
      case 'float':
        return 'Float';
      case 'bool':
      case 'boolean':
        return 'Yes/No';
      default:
        return type;
    }
  };
  const filteredSearchData = () => {
    if (!current || !additionalAttributes) return [];
    const selectedResource = additionalAttributes?.map((item: AdditionalAttribute, idx: number) => ({
      key: idx + 1,
      data: item,
      scope: item.scope,
      scopeIcon: ScopeIcon(item.scope),
      name: NameCol(item.name),
      nameValue: item.name,
      type: formatInputType(item.dataType),
      expiry: `${getDataExpiryText(Number(item.defaultExpDuration)).value} ${
        getDataExpiryText(Number(item.defaultExpDuration)).unit
      }`,
      options: (
        <FlightOverflowMenu
          ariaLabel={'Open more options'}
          optionGroups={[
            [
              {
                key: 1,
                name: t('translation:general.buttons.edit', 'Edit'),
                disabled: false,
                onClick: () => selectPluginAttribute(idx + 1),
              },
              {
                key: 2,
                name: t('translation:general.buttons.delete', 'Delete'),
                disabled: false,
                onClick: () => handleDelete(item),
              },
            ],
          ]}
        />
      ),
    }));
    return selectedResource.filter((item) => {
      let matchScope = false;
      let matchType = false;
      // Scope

      switch (selectedFilterScope.key) {
        case 'all':
          matchScope = !searchTerm || item.nameValue.toLowerCase().includes(searchTerm.toLowerCase());
          break;
        case 'user':
          matchScope =
            item.scope === 'user' && (!searchTerm || item.nameValue.toLowerCase().includes(searchTerm.toLowerCase()));
          break;
        case 'tenant':
          matchScope =
            item.scope === 'tenant' && (!searchTerm || item.nameValue.toLowerCase().includes(searchTerm.toLowerCase()));
          break;
      }

      if (selectedFilterType.key !== 'all') {
        matchType =
          item.type === selectedFilterType.name &&
          (!searchTerm || item.nameValue.toLowerCase().includes(searchTerm.toLowerCase()));
      } else {
        matchType = !searchTerm || item.nameValue.toLowerCase().includes(searchTerm.toLowerCase());
      }

      return !!(matchType && matchScope);
    });
  };

  return (
    <div className={MAIN_CLASS}>
      <div className={`${MAIN_CLASS}__header`}>
        <h2 className="section__title">
          {t(
            'integrations:datasources.details.sections.user-defined-attributes.title',
            'User-defined Context Attributes',
          )}
        </h2>
        <FlightButton
          label={t(
            `integrations:datasources.details.sections.user-defined-attributes.create`,
            `Create context attribute`,
          )}
          size="medium"
          theme="secondary"
          onClick={() => {
            setCurrentAttribute(defaultAttribute);
            setNewAttribute(true);
          }}
        />
      </div>

      <div className={`${MAIN_CLASS}__filters`}>
        <FlightTextInput
          placeholderText=""
          width="222px"
          hasClearIcon
          trailingIcon="search"
          label={t('integrations:connectors.details.sections.pre-defined-attributes.filters.search', `Search`)}
          isLabelAlwaysOn
          type="text"
          name="table-filter"
          value={searchTerm}
          onChange={editSearchTerm}
        />
        <FlightSelect
          options={typeFilters}
          hasLabelAnimation
          label={`Filter by input type:`}
          selected={selectedFilterType}
          dropdownMaxHeight="150px"
          width="180px"
          handleOptionClick={setSelectedFilterType}
        />
        <FlightSelect
          options={[
            {
              key: 'all',
              name:
                t('integrations:connectors.details.sections.pre-defined-attributes.filters.any-scope', `Any scope`) ||
                'Any scope',
            },
            {
              key: 'user',
              name: t('integrations:connectors.details.sections.pre-defined-attributes.scopes.user', 'User') || 'User',
            },
            {
              key: 'tenant',
              name:
                t('integrations:connectors.details.sections.pre-defined-attributes.scopes.tenant', 'Tenant') ||
                'Tenant',
            },
          ]}
          hasLabelAnimation
          label={`Filter by scope:`}
          selected={selectedFilterScope}
          dropdownMaxHeight="150px"
          width="180px"
          handleOptionClick={setSelectedFilterScope}
        />
      </div>
      <div className={`${MAIN_CLASS}__table`}>
        <FlightTable
          className={`${MAIN_CLASS}__table-content`}
          allowRowSelect
          hasError={false}
          selectedDataKey={selectedAttribute?.key}
          emptyState={
            <div className={`${MAIN_CLASS}__table-content-table-no-results`}>
              <p>
                <strong>0</strong> attributes found{' '}
                {searchTerm && (
                  <>
                    with the term &ldquo;<strong>{searchTerm}</strong>&rdquo;
                  </>
                )}
              </p>
            </div>
          }
          tableData={filteredSearchData()}
          tableHeaders={tableHeaders}
          hasPaginationBeforeTable={false}
          handleDataSelect={(e) => selectPluginAttribute(e.key)}
          isShowHeader
        />
      </div>
      <SidePanel
        showPanel={!!currentAttribute}
        className={`${MAIN_CLASS}__panel`}
        title={
          selectedAttribute?.nameValue
            ? `Showing ${selectedAttribute.nameValue}'s attribute info panel`
            : `Create a new attribute info panel`
        }
        content={
          <div className="section__tabs">
            {connectorConfigSchemaAdditional && Object.keys(connectorConfigSchemaAdditional).length >= 0 && (
              <div className="section__tabs-header">
                <FlightRadioButton
                  label={'Create/View attribute'}
                  checked={currentPanelTab === 'attributes' || !currentPanelTab}
                  className={`section__tabs-header-item`}
                  onSelect={() => {
                    setCurrentPanelTab('attributes');
                  }}
                  value="attributes"
                />

                <FlightTooltip
                  direction="bottom"
                  isEnabled={newAttribute}
                  description={'You need to create the attribute first to be able to configure it'}
                >
                  <FlightRadioButton
                    label={'Configure attribute'}
                    checked={currentPanelTab === 'configure'}
                    className={`section__tabs-header-item`}
                    onSelect={() => {
                      setCurrentPanelTab('configure');
                    }}
                    disabled={newAttribute}
                    value="configure"
                  />
                </FlightTooltip>
              </div>
            )}
            <div className="section__tabs-content">
              {currentPanelTab === 'attributes' ? (
                <PluginAttributesPanel
                  t={t}
                  onSave={handleSaveAttribute}
                  onCancel={closePanel}
                  isNewAttribute={newAttribute}
                  pluginAttributes={additionalAttributes || []}
                  attributeValue={currentAttribute}
                  originalAttributes={currentAttribute}
                />
              ) : (
                <PreviewFormEditor
                  formData={currentAttribute?.connectorConfig || {}}
                  schema={connectorConfigSchemaAdditional || {}}
                  uiSchema={connectorUISchemaAdditional || {}}
                  onChange={handleChangeFormData}
                  connectorInfo={connector}
                  t={t}
                />
              )}
            </div>
          </div>
        }
        togglePanel={closePanel}
      />
    </div>
  );
};

export default UserDefinedAttributes;
