import React, { FunctionComponent } from 'react';
import ProjectListItem from './ProjectListItem';
import { Project } from '../../model/projects/projects';
import './ProjectList.scss';
import { setStoredProjectId } from 'src/helpers/auth.helper';
import { useHistory } from 'react-router-dom';
import useNotification from 'src/hooks/useNotification';
import { copyToClipboard } from 'src/helpers/general.helper';

interface Props {
  list: Array<Project>;
  t: any;
}

const ProjectList: FunctionComponent<Props> = (props: Props) => {
  const history = useHistory();
  const { list, t } = props;

  const { addNotification } = useNotification();

  const handleCopyToClipboard = (content: string) => {
    copyToClipboard(content);
    addNotification(t('translation:pages.projects.copied', 'Content copied successfuly!'));
  };

  const gotoProject = (project: string) => {
    setStoredProjectId(project);
    history.push(history.location.pathname + '/project/' + project);
  };
  return (
    <>
      {list.length &&
        list
          .sort((a) => (a.active ? -1 : 1)) // Show active projects first
          .map((item, idx) => (
            <ProjectListItem
              style={props}
              t={t}
              key={item.id + idx}
              id={item.id}
              name={item.name}
              region={item.region}
              tier={item.tier}
              deployment={item.deployment}
              organization={item.organization}
              endpoint={item.endpoint}
              url={item.subdomain}
              isActive={item.update?.active || item.active}
              copyToClipboard={handleCopyToClipboard}
              goToProject={() => {
                gotoProject(item.deployment + '_' + item.subdomain);
              }}
            />
          ))}
    </>
  );
};

export default ProjectList;
