import React, { useState } from 'react';
import { FlightModal, FlightCheckbox, FlightButton } from '@flybits/design-system';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import './ShareConnectorModal.scss';

interface ModalProps {
  className?: string;
  title?: string;
  showModal: boolean;
  connectorData: string[];
  handleShareConnector: (arg0: string[], arg1: any) => Promise<boolean>;
  toggleModal: () => void;
}
// Translation
import { useTranslation } from 'react-i18next';
const ShareConnectorModal = (props: ModalProps) => {
  const { className, showModal, connectorData, toggleModal, handleShareConnector } = props;
  const { t } = useTranslation(['translation', 'components']);
  const [loading, setLoading] = useState(false);
  const MAIN_CLASS = 'ShareConnectorModal';

  const handleClose = () => {
    toggleModal();
  };

  const initialValuesShareModal = {
    approve_3rd_party: false,
    approve_sharing: false,
  };
  const shareModalValidationSchema = Yup.object().shape({
    approve_sharing: Yup.boolean().required(
      t(
        'components:share_connector_modal.validations.approve_sharing.required',
        'You need to accept the terms before sharing the connector',
      ),
    ),
  });

  return (
    <FlightModal
      isVisible={showModal}
      toggleModalShown={handleClose}
      scrollable={false}
      size="small"
      warning={false}
      className={` ${MAIN_CLASS}__alert ${className || ''}`}
      header={
        <span>
          {t('components:share_connector_modal.heading', 'Sharing will permanently lock in connector configurations')}
        </span>
      }
      content={
        <Formik
          initialValues={initialValuesShareModal}
          validationSchema={shareModalValidationSchema}
          validateOnChange
          validateOnBlur
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            const result = await handleShareConnector(connectorData, values);
            setLoading(false);
            if (result) {
              resetForm();
              handleClose();
            }
          }}
        >
          {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue, setFieldTouched }) => (
            <form>
              <p>
                {t(
                  'components:share_connector_modal.body.disclaimer',
                  'Once the connector has been taken out of private state certain changes can no longer be made.',
                )}{' '}
                <strong>{t('components:share_connector_modal.body.cannot_be_undone', 'This cannot be undone.')}</strong>
              </p>

              <ul>
                <li>
                  {t(
                    'components:share_connector_modal.body.list.item1',
                    'The configuration template can no longer be edited',
                  )}
                </li>
                <li>
                  {t(
                    'components:share_connector_modal.body.list.item2',
                    'The deployment configurations can no longer be changed',
                  )}
                </li>
              </ul>
              <p>
                {t('components:share_connector_modal.body.learn_more', 'To learn more about connector sharing')}{' '}
                <Link
                  to={{
                    pathname: t(
                      'components:share_connector_modal.body.learn_more_url',
                      'https://app.gitbook.com/@flybits/s/product-guide/',
                    ),
                  }}
                  target="_blank"
                >
                  {t('components:share_connector_modal.body.click_here', 'click here')}
                </Link>
                .
              </p>
              <div className="checkboxes">
                <div className="checkbox">
                  <Field
                    as={FlightCheckbox}
                    name="approve_3rd_party"
                    label={t(
                      'components:share_connector_modal.labels.approve_3rd_party',
                      'I allow people creating datasources using this connector to share their datasources with 3rd parties',
                    )}
                    ariasLabel={t(
                      'components:share_connector_modal.labels.approve_3rd_party',
                      'I allow people creating datasources using this connector to share their datasources with 3rd parties',
                    )}
                    checkState={values.approve_3rd_party ? 'SELECTED' : 'UNSELECTED'}
                    value={values.approve_3rd_party}
                    onSelect={() => {
                      setFieldValue('approve_3rd_party', !values.approve_3rd_party, false);
                      setFieldTouched('approve_3rd_party', true, true);
                    }}
                  />
                </div>
                <div className="checkbox">
                  <Field
                    as={FlightCheckbox}
                    name="approve_sharing"
                    label={t(
                      'components:share_connector_modal.labels.approve_sharing',
                      'I understand the terms of sharing a connector',
                    )}
                    ariaLabel={t(
                      'components:share_connector_modal.labels.approve_sharing',
                      'I understand the terms of sharing a connector',
                    )}
                    checkState={values.approve_sharing ? 'SELECTED' : 'UNSELECTED'}
                    value={values.approve_sharing}
                    hasError={touched.approve_sharing && values.approve_sharing ? false : true}
                    errorMessage={
                      <span role="alert" aria-atomic>
                        <b>Error:</b> {errors.approve_sharing}
                      </span>
                    }
                    onSelect={() => {
                      setFieldValue('approve_sharing', !values.approve_sharing, false);
                      setFieldTouched('approve_sharing', true, true);
                    }}
                  />
                </div>
              </div>
              <div className={`${MAIN_CLASS}__alert_footer`}>
                <FlightButton
                  type="button"
                  theme="secondary"
                  label={t('translation:general.buttons.cancel', 'Cancel')}
                  ariaLabel={t('translation:general.buttons.cancel', 'Cancel')}
                  disabled={loading || isSubmitting}
                  onClick={handleClose}
                />
                <FlightButton
                  type="button"
                  label={t('translation:general.buttons.continue', 'Continue')}
                  ariaLabel={t('translation:general.buttons.continue', 'Continue')}
                  disabled={!values.approve_sharing || loading || isSubmitting}
                  loading={loading || isSubmitting}
                  onClick={handleSubmit}
                />
              </div>
            </form>
          )}
        </Formik>
      }
    />
  );
};

export default ShareConnectorModal;
