import { useEffect, useState } from 'react';
import { useInputEvent } from 'src/hooks/useInputEvent';

export const useSecretCode = (secretCode: string[]) => {
  const [count, setCount] = useState(0);
  const [success, setSuccess] = useState(false);
  const key = useInputEvent();

  useEffect(() => {
    if (key == null) return;
    if (key === 'Escape') {
      setCount(0);
      setSuccess(false);
      return;
    }
    if (key !== secretCode[count]) {
      setCount(0);
      return;
    }
    setCount((state) => state + 1);
    if (count + 1 === secretCode.length) {
      setSuccess(true);
    }
  }, [key]);
  return success;
};
