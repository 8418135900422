import React, { CSSProperties, memo } from 'react';
import { useDrag } from 'react-dnd';

const style: CSSProperties = {
  backgroundColor: 'white',
  cursor: 'move',
};

export interface OutputFieldProps {
  name: string;
  type: string;
  id: string;
  parameters?: any[];
  isDropped: boolean;
}

export const OutputField: React.FunctionComponent<OutputFieldProps> = memo(function Box({
  name,
  type,
  parameters,
  id,
}: OutputFieldProps) {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { name, type, id, parameters },
      options: {
        dropEffect: 'copy',
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [name, type],
  );

  return (
    <div ref={drag} style={{ ...style, opacity }} data-testid="box" className={`field`} role="button" tabIndex={0}>
      <span className="handle">:::</span>
      <span
        className={`name ${parameters?.length ? 'has-parameters' : ''}`}
        title={`${parameters?.length ? 'Attribute has parameters' : ''}`}
      >
        {' '}
        {name}
      </span>
      <code className="code mono">{type}</code>
    </div>
  );
});
