import React, { FunctionComponent, useEffect, useState } from 'react';
import { FlightButton, FlightAvatar, FlightDropdown } from '@flybits/design-system';
import './NavBarMenu.scss';
import { Link } from 'react-router-dom';
import { Organization } from '../../model/organizations/organizations';
import { User } from '../../model/users/users';
import useRoleAccess from 'src/hooks/useRoleAccess';
import { getUserRoles } from 'src/helpers/auth.helper';

// Translation
import { useTranslation } from 'react-i18next';
import { handleKeyDownHelper } from 'src/helpers/general.helper';
interface Props {
  currentOrganization: Organization | null;
  user: User | null;
  handleSwitchOrganizations: () => void;
  handleManageFlybitsAccount: () => void;
  initSignOut: () => void;
}
const NavBarMenu: FunctionComponent<Props> = (props: Props) => {
  const { currentOrganization, user, handleSwitchOrganizations, handleManageFlybitsAccount, initSignOut } = props;

  const { t } = useTranslation();

  const DEFAULT_CLASS = 'flight-nav';
  const HEADER_CLASS = `${DEFAULT_CLASS}__header`;

  const [isActiveDropdown, setActiveDropDown] = useState(false);

  const [currentRole, setCurrentRole] = useState(['member']);
  useEffect(() => {
    async function getRole() {
      const savedRoles = await getUserRoles();
      const roles = !currentOrganization || !currentOrganization.roles.length ? savedRoles : currentOrganization.roles;
      setCurrentRole(roles);
    }
    getRole();
  }, [isActiveDropdown]);
  const hasAccess = (required: string[]) => useRoleAccess(required, currentRole);
  const toggleDropdown = () => {
    setActiveDropDown(!isActiveDropdown);
  };
  const clickSwitchOrganizations = () => {
    setActiveDropDown(false);
    handleSwitchOrganizations();
  };
  const clickManageFlybitsAccount = () => {
    setActiveDropDown(false);
    handleManageFlybitsAccount();
  };
  const handleInitSignOut = () => {
    setActiveDropDown(false);
    initSignOut();
  };

  return (
    <div className="custom__navigation">
      <div className={`${HEADER_CLASS}__user ${currentOrganization?.name ? 'has-organization' : ''}`}>
        <FlightDropdown
          className={`${HEADER_CLASS}__user__dropdown`}
          isActive={isActiveDropdown}
          handleClickOutside={toggleDropdown}
          handleTriggerClick={toggleDropdown}
          isControlledByIsActive
          autoFocus
          maxWidth={'228px'}
          trigger={
            <div
              role={'button'}
              tabIndex={0}
              onClick={toggleDropdown}
              onKeyDown={handleKeyDownHelper(toggleDropdown, toggleDropdown)}
            >
              <FlightAvatar
                className={`${HEADER_CLASS}__user__avatar`}
                firstName={user?.givenName}
                lastName={user?.familyName}
                email={user?.username}
              />
              <p className="trigger-org-name" role="contentinfo">
                {currentOrganization?.name}
              </p>
            </div>
          }
        >
          <div className={`${HEADER_CLASS}__user__profile`}>
            <FlightAvatar
              className={`${HEADER_CLASS}__user__profile__avatar`}
              firstName={user?.givenName}
              lastName={user?.familyName}
              email={user?.username}
            />
            {user?.givenName && user?.familyName ? (
              <div
                aria-label={`User name: ${`${user?.givenName} ${user?.familyName}`}`}
                className={`${HEADER_CLASS}__user__profile__name`}
                tabIndex={0}
              >{`${user?.givenName} ${user?.familyName}`}</div>
            ) : null}
            {user?.username ? (
              <div
                aria-label={`User e-mail: ${`${user?.givenName} ${user?.familyName}`}`}
                className={`${HEADER_CLASS}__user__profile__email`}
              >
                {user?.username}
              </div>
            ) : null}

            {currentOrganization?.name ? (
              <div className={`${HEADER_CLASS}__user__profile__org`}>
                {currentOrganization?.imageUrl &&
                  currentOrganization?.imageUrl !== 'https://controltower.flybits.com/' && (
                    <img
                      src={currentOrganization?.imageUrl}
                      alt={`Image of: ${currentOrganization?.name}`}
                      role="presentation"
                      width={16}
                    />
                  )}
                {currentOrganization?.name}#{currentOrganization?.id}
              </div>
            ) : null}

            <div className={`${HEADER_CLASS}__user__profile__group`} role="navigation" aria-label="You grouping">
              {currentOrganization?.name && <h5>{t('general.menu.sections.you', 'You')}</h5>}
              <FlightButton
                className={`${HEADER_CLASS}__user__profile__links`}
                label={t('general.menu.labels.manage', 'Manage your Flybits account')}
                theme="link"
                onClick={clickManageFlybitsAccount}
                onKeyDown={handleKeyDownHelper(clickManageFlybitsAccount, toggleDropdown)}
              />
              {user?.confirmed === true && currentOrganization?.name && (
                <FlightButton
                  className={`${HEADER_CLASS}__user__profile__links`}
                  label={t('general.menu.labels.switch', 'Switch organizations')}
                  theme="link"
                  onClick={clickSwitchOrganizations}
                  onKeyDown={handleKeyDownHelper(clickSwitchOrganizations, toggleDropdown)}
                />
              )}
            </div>
            {currentOrganization?.name && hasAccess(['owner', 'projects', 'teams']) && (
              <div
                className={`${HEADER_CLASS}__user__profile__group`}
                role="navigation"
                aria-label="Organization grouping"
              >
                <h5>{t('general.menu.sections.organization', 'Organization')}</h5>
                {hasAccess(['owner', 'projects']) && (
                  <Link
                    to={`/organization/${currentOrganization?.id}`}
                    className={`${HEADER_CLASS}__user__profile__links`}
                    onClick={toggleDropdown}
                  >
                    {t('general.menu.labels.projects', 'Projects')}
                  </Link>
                )}
                {hasAccess(['owner', 'teams']) && (
                  <Link
                    to={`/organization/${currentOrganization?.id}/directory`}
                    className={`${HEADER_CLASS}__user__profile__links`}
                    onClick={toggleDropdown}
                  >
                    {t('general.menu.labels.directory', 'Directory')}
                  </Link>
                )}
                {hasAccess(['owner', 'connectors', 'datasources']) && (
                  <Link
                    to={`/organization/${currentOrganization?.id}/integrations/v1/`}
                    className={`${HEADER_CLASS}__user__profile__links`}
                    onClick={toggleDropdown}
                  >
                    {t('general.menu.labels.integrations', 'Integrations')} (v2)
                  </Link>
                )}
                {hasAccess(['owner']) && (
                  <Link
                    to={`/organization/${currentOrganization?.id}/settings`}
                    className={`${HEADER_CLASS}__user__profile__links`}
                    onClick={toggleDropdown}
                  >
                    {t('general.menu.labels.about-org', 'About this organization')}
                  </Link>
                )}
              </div>
            )}
            <FlightButton
              className={`${HEADER_CLASS}__user__profile__signout`}
              label={t('general.menu.labels.signout', 'Sign out')}
              theme="link"
              onClick={handleInitSignOut}
              onKeyDown={handleKeyDownHelper(handleInitSignOut, toggleDropdown)}
            />
          </div>
        </FlightDropdown>
      </div>
    </div>
  );
};
export default NavBarMenu;
