import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { GENERIC_INTERNAL_ERROR } from 'src/constants/errors';
import { FlightButton, FlightModal, FlightTextInput } from '@flybits/design-system';
import './DeleteDatasourceModal.scss';
import DataSourcesService from 'src/services/datasources.service';

interface ModalProps {
  className?: string;
  showModal: boolean;
  datasourceData: any[];
  organizationID: string;
  apiVersion?: string;
  t: any;
  handleDeleteDatasource: (deleted: boolean, message?: string) => void;
  toggleModal: () => void;
}

const datasourceServiceManager = new DataSourcesService();
const DeleteDatasourceModal = (props: ModalProps) => {
  const { className, showModal, datasourceData, organizationID, apiVersion, toggleModal, handleDeleteDatasource, t } =
    props;
  const MAIN_CLASS = 'DeleteDatasourceModal';

  const [title, setTitle] = useState(
    t('integrations:datasources.details.modals.delete.title', `Are you sure you want to delete this datasource?`),
  );
  const [message, setMessage] = useState(
    t(
      'integrations:datasources.details.modals.delete.message',
      `To delete this datasource enter the datasource name below.`,
    ),
  );

  const initialValuesDeleteModal = {
    datasourceNameDelete: '',
  };
  const deleteModalValidationSchema = Yup.object().shape({
    datasourceNameDelete: Yup.string()
      .required(t('integrations:datasources.details.modals.delete.label', 'Enter data source name'))
      .test(
        'name-match',
        t('integrations:datasources.details.modals.delete.error', 'Data source name does not match'),
        (value) => value?.trim() === datasourceData[1].trim(),
      ),
  });
  const handleClose = () => {
    toggleModal();
  };
  const handleDelete = async (data: string[]) => {
    if (!data.length) return;
    try {
      const datasourcesResponseObject = await datasourceServiceManager.updateDataSourceStatus(
        organizationID,
        data[0],
        'delete',
        apiVersion,
      );
      if (datasourcesResponseObject && [200, 201, 204].includes(datasourcesResponseObject.status)) {
        handleDeleteDatasource(true);
        return true;
      }
    } catch (error: any) {
      console.error(error);
      handleDeleteDatasource(
        false,
        error?.response?.data?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR),
      );
      return false;
    }
    return false;
  };

  useEffect(() => {
    const getSharedOrgs = async () => {
      try {
        const datasourcesResponseObject = await datasourceServiceManager.getDataSourceSharedOrganizations(
          organizationID,
          datasourceData[0],
        );
        if (datasourcesResponseObject?.data && datasourcesResponseObject?.data.length) {
          setTitle(
            t(
              'integrations:datasources.details.modals.delete.in-use',
              'This datasource is currently in use. Are you sure you want to delete?',
            ),
          );
          setMessage(
            t('integrations:datasources.details.modals.delete.in-use-message', {
              org_number: datasourcesResponseObject?.data.length,
            }),
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    showModal && getSharedOrgs();
  }, [showModal]);
  return (
    <FlightModal
      isVisible={showModal}
      toggleModalShown={handleClose}
      scrollable={false}
      size="small"
      warning={false}
      className={` ${MAIN_CLASS}__alert ${className || ''}`}
      header={<span>{title}</span>}
      content={
        <Formik
          initialValues={initialValuesDeleteModal}
          validationSchema={deleteModalValidationSchema}
          validateOnChange
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            const result = await handleDelete(datasourceData);
            if (result) {
              resetForm();
              handleClose();
            }
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <form>
              <span role="alert" aria-atomic>
                {message}
              </span>
              <div className="input_remove">
                <Field
                  type="text"
                  name="datasourceNameDelete"
                  width="100%"
                  as={FlightTextInput}
                  label={t('integrations:datasources.details.modals.delete.label', 'Enter datasource name')}
                  value={values.datasourceNameDelete}
                  hasError={errors.datasourceNameDelete ? true : false}
                  errorMessage={
                    <span role="alert" aria-atomic>
                      <b>Error:</b> {errors.datasourceNameDelete}
                    </span>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={`${MAIN_CLASS}__alert_footer modal_buttons`}>
                <FlightButton
                  type="button"
                  theme="secondary"
                  label={t('translation:general.buttons.cancel', 'Cancel')}
                  ariaLabel={t('translation:general.buttons.cancel', 'Cancel')}
                  onClick={handleClose}
                />
                <FlightButton
                  type="button"
                  theme="primary"
                  label={t('integrations:datasources.details.modals.delete.button', 'Delete datasource')}
                  ariaLabel={t('integrations:datasources.details.modals.delete.button', 'Delete datasource')}
                  disabled={values.datasourceNameDelete !== datasourceData[1]}
                  onClick={handleSubmit}
                />
              </div>
            </form>
          )}
        </Formik>
      }
    />
  );
};

export default DeleteDatasourceModal;
