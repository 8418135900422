import React, { useEffect, useState } from 'react';
import { getIcon } from '@flybits/design-system';
import { useTransition, animated } from 'react-spring';
import * as Animations from 'src/constants/spring-animations';
import './LoadingIcon.scss';

interface Props {
  width?: number;
  height?: number;
  className?: string;
  text?: string;
  type?: string;
  fullScreen?: boolean;
  visible?: boolean;
}

const LoadingIcon = (props: Props) => {
  const { type, width, height, className, fullScreen, visible, text } = props;
  const [show, setVisibility] = useState(false);
  const animationProps = {
    ...Animations.fadeIn,
    config: {
      duration: 250,
    },
  };
  const transitions = useTransition(show, null, animationProps);
  useEffect(() => {
    setVisibility(visible || false);
  }, [visible]);

  const loadingBlock = (height = 16) => (
    <div
      className="content-placeholder-text"
      role="progressbar"
      style={{ ['--block-height' as string]: `${height}px` }}
    >
      <span className="content-placeholder-background"></span>
    </div>
  );
  const loadingIcon =
    !type || type === 'circle' ? getIcon('loading', { width, height, className }) : loadingBlock(height);
  return (
    <>
      {' '}
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={props}
              className={`loading__icon_wrapper loading__icon_wrapper_${
                !type || type === 'circle' ? 'circle' : 'block'
              } ${fullScreen ? 'fullscreen' : ''}`}
            >
              {loadingIcon}
              {text && <h3>{text}</h3>}
            </animated.div>
          ),
      )}
    </>
  );
};

export default LoadingIcon;
