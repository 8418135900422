import React, { FunctionComponent } from 'react';
import ProjectUsersAndTeamsListItem from './ProjectUsersAndTeamsListItem';
import { ProjectUser, ProjectTeam } from 'src/model/projects/projects';
import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import './ProjectUsersAndTeamsList.scss';

interface Props {
  list: { users: Array<ProjectUser>; teams: Array<ProjectTeam> };
  t: any;
}

const ProjectUsersAndTeamsList: FunctionComponent<Props> = (props: Props) => {
  const { list, t } = props;

  const controlTowerContextData = useControlTowerContext();

  const { user } = controlTowerContextData;

  const MAIN_CLASS = 'project_users_list';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  return (
    <div className={FORM_CLASS}>
      <div className={`${CONTENT_CLASS}__infos-wrapper_list`}>
        {list.users.map((item: ProjectUser) => (
          <ProjectUsersAndTeamsListItem
            key={item.id}
            id={item.id}
            user={item}
            t={t}
            currentUserEmail={user?.username}
          />
        ))}
        {list.teams.map((item: ProjectTeam) => (
          <ProjectUsersAndTeamsListItem key={item.id} id={item.id} t={t} team={item} />
        ))}
      </div>
    </div>
  );
};

export default ProjectUsersAndTeamsList;
