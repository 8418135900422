import React from 'react';
import { register } from 'src/workers/serviceWorkerRegistration';
import './UpdatedVersionNotification.scss';
import { FlightButton } from '@flybits/design-system';
// Translation
import { useTranslation } from 'react-i18next';
interface ServiceWorkerEvent extends Event {
  target: (Partial<ServiceWorker> & EventTarget) | null;
}
const UpdatedVersionNotification: React.FC = () => {
  const { t } = useTranslation();
  const [showReload, setShowReload] = React.useState(false);
  const [installingWorker, setInstallingWorker] = React.useState<ServiceWorker | null>(null);
  const [waitingServiceWorker, setWaitingServiceWorker] = React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setInstallingWorker(registration.installing);
    setWaitingServiceWorker(registration.waiting);
  };

  React.useEffect(() => {
    register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    installingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event: ServiceWorkerEvent) => {
        if (event.target && event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload();
  };

  const handleCancel = () => {
    setShowReload(false);
  };

  return showReload ? (
    <div className={`UpdatedVersionNotification ${showReload ? 'show' : ''}`} role="alert">
      <div>{t('general.update.text', 'A new version of Control Tower is available')}</div>
      <div>
        <FlightButton
          type="button"
          label={t('general.buttons.update', 'Update')}
          ariaLabel={t('general.buttons.update', 'Update')}
          theme="primary"
          size="small"
          iconLeft="replay"
          onClick={reloadPage}
        />
        <FlightButton
          type="button"
          theme="link"
          size="small"
          label={t('general.buttons.dismiss', 'Dismiss')}
          ariaLabel={t('general.buttons.dismiss', 'Dismiss')}
          onClick={handleCancel}
        />
      </div>
    </div>
  ) : null;
};

export default UpdatedVersionNotification;
