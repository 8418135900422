import React, { useEffect, useState } from 'react';
import { FlightButton, FlightCheckbox, FlightModal, FlightSelect } from '@flybits/design-system';
import Treeview from 'src/components/Shared/Treeview/Treeview';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import OrganizationService from 'src/services/organization.service';
import { ORG_ROLES } from 'src/constants/levels';
import { Formik, Field } from 'formik';
import { User, UserSelectedProps } from 'src/model/users/users';
import { getFormattedRole } from 'src/helpers/teams.helper';
import { Team } from 'src/model/teams/teams';
interface Props {
  userSelected: UserSelectedProps | null;
  user: User | null;
  showTeamModal: boolean;
  shouldUpdateTree: boolean;
  organizationTeams: Team[];
  addNotification: any;
  addNotificationError: any;
  toggleTeamModal: () => void;
  updateTableData: () => void;
  showAlert: (arg0: any) => void;
  togglePanel: () => void;
  t: any;
}
interface SelectOptionProps {
  key: string;
  name: string;
}
interface RoleInfosProps {
  role: string;
  name: string;
  permissions: Array<string>;
  info: string;
}

const PanelContent = (props: Props) => {
  const roleInfoDefaultProps = {
    role: '',
    name: '',
    permissions: [],
    info: '',
  };
  const {
    user,
    userSelected,
    toggleTeamModal,
    showAlert,
    updateTableData,
    organizationTeams,
    shouldUpdateTree,
    togglePanel,
    addNotification,
    addNotificationError,
    t,
  } = props;
  const [tryRevokeAccess, setTryRevokeAccess] = useState(false);
  const [sendingInvite, setSendingInvite] = useState(false);
  // const [userPermissionsData, setUserPermissionsData] = useState<string[]>([]);
  const [userTeamsLoading, setUserTeamsLoading] = useState(true);
  const [shouldUpdatePanel, setShouldUpdatePanel] = useState(false);
  const [selectedRole, setSelectedRole] = useState<SelectOptionProps | null>(null);
  const [roleInfos, setRoleInfos] = useState<RoleInfosProps>(roleInfoDefaultProps);
  const [userTeamsInfo, setUserTeamsInfo] = useState<any[]>([]);

  const organizationServiceManager = new OrganizationService();

  const updateRoleInfos = (role?: string | string[], permissions: string[] | string = []) => {
    if (!role) return;
    setRoleInfos(
      ORG_ROLES.map((item: any) => {
        if (item.role.toLocaleLowerCase() === role.toString().toLocaleLowerCase()) {
          setSelectedRole({
            key: item.role,
            name: t(`translation:general.levels.${item.role}.single`, item.name),
          });
          return { ...item, permissions: permissions.toString().split('|') || [] };
        }
        return roleInfoDefaultProps;
      }).filter((selected: any) => selected.role === role.toString())[0],
    );
  };
  const getUpdatedOwnersInfo = async () => {
    try {
      if (userSelected?.id && userSelected?.organization) {
        const organizationOwnersResponseObject = await organizationServiceManager.getOrganizationOwners(
          userSelected.organization,
        );
        if (organizationOwnersResponseObject?.data) {
          return organizationOwnersResponseObject.data;
        }
        return [];
      }
    } catch (error) {
      console.error(error?.data?.message);
      return [];
    }
  };
  const handleRevokeAccessToOrganization = async () => {
    try {
      if (userSelected?.id && userSelected?.organization) {
        const organizationDeleteResponseObject = await organizationServiceManager.deleteUserFromOrganization(
          userSelected.organization,
          userSelected.id,
        );
        if (
          organizationDeleteResponseObject &&
          organizationDeleteResponseObject.status >= 200 &&
          organizationDeleteResponseObject.status < 300
        ) {
          updateTableData();
          togglePanel();
          return true;
        }
        return false;
      }
      return false;
    } catch (error) {
      console.error(error?.data?.message);
      return false;
    }
  };
  const handleUpdateUserRoles = async (newPermissions: string[]) => {
    try {
      if (userSelected?.id && userSelected?.organization) {
        const organizationResponseObject = await organizationServiceManager.updateOrganizationUserRoles(
          userSelected.organization,
          userSelected.id,
          { roles: newPermissions },
        );
        if (
          organizationResponseObject &&
          organizationResponseObject.status >= 200 &&
          organizationResponseObject.status < 300
        ) {
          updateTableData();
          return true;
        }
        return false;
      }
    } catch (error) {
      console.error(error?.data?.message);
      setShouldUpdatePanel(false);
      return false;
    }
  };

  const handleCheckboxClick = async (
    permissions: string[],
    newPermission: string,
    setFieldValue: (...args: any) => void,
  ) => {
    const newPermissions = !permissions.includes('owner') ? permissions : [];
    if (!permissions.includes(newPermission)) {
      newPermissions.push(newPermission);
    } else {
      const idx = permissions.indexOf(newPermission);
      newPermissions.splice(idx, 1);
    }
    // setUserPermissionsData(newPermissions);
    const success = await handleUpdateUserRoles(newPermissions);
    setFieldValue('permissions', success ? newPermissions : permissions, false);
    updateRoleInfos(getFormattedRole(newPermissions), newPermissions.join('|'));
  };
  const handleSelectOptionClick = async (value: SelectOptionProps, setFieldValue: (...args: any) => void) => {
    if (user?.username === userSelected?.email && userSelected?.role === 'owner' && value.key !== 'owner') {
      const owners = await getUpdatedOwnersInfo();
      if (owners.length < 1) {
        showAlert(
          t(
            'translation:pages.directory.sidebar.messages.only_owner_error',
            'You must assign another owner before removing yourself',
          ),
        );
        return false;
      }
    }
    setSelectedRole({
      key: value.key,
      name: t(`translation:general.levels.${value.key}.single`, value.name),
    });
    let newPermissions: string[] = [];
    if (value.key === 'owner') {
      newPermissions = [value.key];
    } else if (value.key === 'admin') {
      newPermissions = ['projects', 'teams'];
    } else {
      newPermissions = [];
    }
    // setUserPermissionsData(newPermissions);
    const success = await handleUpdateUserRoles(newPermissions);
    if (success) {
      addNotification(
        t('translation:pages.directory.messages.user_update_success', 'User permissions were updated successfully'),
      );
      setFieldValue('role', value.key);
      setFieldValue('permissions', newPermissions, false);
      updateRoleInfos(value.key, newPermissions.join('|'));
      setShouldUpdatePanel(true);
    }
  };
  const resendInvite = async (user: any) => {
    const { name, organization, teams } = user;
    const invitesRequestObject = {
      recipients: [
        {
          email: name,
          roles: undefined,
          teams: teams || [],
        },
      ],
    };
    try {
      const invitesResponseObject = await organizationServiceManager.inviteUsersToOrganization(
        organization,
        invitesRequestObject,
      );
      if (invitesResponseObject && invitesResponseObject.status >= 200 && invitesResponseObject.status < 400) {
        addNotification(t('translation:pages.directory.messages.invite_send_success', 'Invite sent!'));
        return true;
      }
    } catch (error) {
      console.error(error);
      addNotificationError(
        t(
          'translation:pages.directory.messages.invite_send_error',
          'There was an error while trying to invite this user',
        ),
      );
      return false;
    }
  };
  const cancelInvite = async (email: string, orgID: string) => {
    const invitesRequestObject = {
      recipients: [email],
    };
    try {
      const invitesResponseObject = await organizationServiceManager.cancelOrganizationInvites(
        orgID,
        invitesRequestObject,
      );
      if (invitesResponseObject && invitesResponseObject.status >= 200 && invitesResponseObject.status < 400) {
        addNotification(t('translation:pages.directory.messages.invite_cancel_success', 'Invite canceled!'));
        updateTableData();
        return true;
      }
    } catch (error) {
      console.error(error);
      addNotificationError(
        t(
          'translation:pages.directory.messages.invite_cancel_error',
          'There was an error while trying to cancel the invite for this user',
        ),
      );
      return false;
    }
  };
  useEffect(() => {
    const getUserDetails = async () => {
      const organizationServiceManager = new OrganizationService();
      try {
        if (userSelected?.id && userSelected?.organization) {
          setUserTeamsLoading(true);
          const organizationUserResponseObject = await organizationServiceManager.getOrganizationUserTeamsInfo(
            userSelected.organization,
            userSelected.id,
          );
          setUserTeamsInfo([]);
          if (organizationUserResponseObject?.data) {
            if (
              !organizationUserResponseObject.data.length &&
              typeof userSelected?.teams === 'object' &&
              userSelected?.teams.length
            ) {
              setUserTeamsInfo(userSelected.teams);
            } else {
              setUserTeamsInfo(organizationUserResponseObject?.data);
            }
            updateRoleInfos(userSelected?.role, userSelected?.permissions);
          }
          setUserTeamsLoading(false);
          setShouldUpdatePanel(false);
        }
      } catch (error) {
        console.error(error?.data?.message);
        setUserTeamsLoading(false);
        setShouldUpdatePanel(false);
      }
    };
    userSelected?.id && userSelected?.organization && getUserDetails();
    (shouldUpdateTree || shouldUpdatePanel) && getUserDetails();
    // eslint-disable-next-line
  }, [userSelected, shouldUpdateTree]);
  const MAIN_CLASS = 'OrganizationDirectory';
  return (
    <>
      <FlightModal
        isVisible={tryRevokeAccess}
        toggleModalShown={() => setTryRevokeAccess(false)}
        scrollable={false}
        size="small"
        warning={false}
        className={`${MAIN_CLASS}__alert`}
        header={
          <span>{t('translation:pages.directory.sidebar.revoke_modal.title', { name: userSelected?.name })}</span>
        }
        content={
          <small>
            <em>
              {t('translation:pages.directory.sidebar.revoke_modal.note', '* You can invite this user again later')}
            </em>
          </small>
        }
        footer={
          <div className={`${MAIN_CLASS}__alert_footer`}>
            <FlightButton
              type="button"
              theme="secondary"
              label={t('translation:general.buttons.cancel', 'Cancel')}
              onClick={() => {
                setTryRevokeAccess(false);
              }}
            />
            <FlightButton
              type="button"
              className={`${MAIN_CLASS}__alert__button_delete`}
              label={t('translation:pages.directory.sidebar.btn-revoke', 'Revoke access')}
              onClick={async (e) => {
                e.preventDefault();
                const resultDelete = await handleRevokeAccessToOrganization();
                if (resultDelete) updateTableData();
                setTryRevokeAccess(false);
              }}
            />
          </div>
        }
      />
      <Formik
        initialValues={{
          permissions: roleInfos.permissions,
        }}
        enableReinitialize
        onSubmit={() => {
          //
        }}
      >
        {({ values, setFieldValue, handleChange }) => (
          <form aria-live="assertive">
            <div aria-busy={userTeamsLoading}>
              <h2>
                {userTeamsLoading ? (
                  <LoadingIcon type="block" />
                ) : (
                  `${userSelected?.name} ${
                    user?.username === userSelected?.email ? t('translation:pages.projects.details.you', '(you)') : ''
                  }`
                )}
              </h2>
              <div className="info" role="alert" aria-atomic>
                {userTeamsLoading ? <LoadingIcon type="block" /> : userSelected?.email}
                {userSelected?.email === t('translation:pages.directory.invite_sent', 'Invite sent') && (
                  <>
                    {' '}
                    -{' '}
                    <FlightButton
                      type="button"
                      theme="link"
                      size="small"
                      label={t('translation:pages.directory.sidebar.btn-resend', 'Resend')}
                      loading={sendingInvite}
                      disabled={sendingInvite || !userSelected}
                      onClick={async () => {
                        if (!userSelected) return;
                        setSendingInvite(true);
                        const result = await resendInvite(userSelected);
                        if (result) {
                          setSendingInvite(false);
                        }
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            {user?.username !== userSelected?.email && (
              <div aria-live="polite">
                <h3 tabIndex={0} aria-label={t('translation:pages.directory.sidebar.role', 'Role')}>
                  {t('translation:pages.directory.sidebar.role', 'Role')}
                </h3>
                <Field
                  as={FlightSelect}
                  type="select"
                  name="role"
                  label={'Select role:'}
                  ariaLabel={t('translation:pages.directory.sidebar.role', 'Role')}
                  options={[
                    { key: 'member', name: t('translation:general.levels.member.single', 'Member') },
                    { key: 'admin', name: t('translation:general.levels.admin.single', 'Admin') },
                    { key: 'owner', name: t('translation:general.levels.owner.single', 'Owner') },
                  ]}
                  selected={selectedRole}
                  hasLabelAnimation
                  dropdownMaxHeight="150px"
                  handleOptionClick={async (value: SelectOptionProps) => {
                    await handleSelectOptionClick(value, setFieldValue);
                  }}
                  width="300px"
                />
                <div
                  className="info small"
                  role="description"
                  aria-label={roleInfos?.info}
                  tabIndex={0}
                  aria-live="assertive"
                >
                  {roleInfos?.info}
                </div>
                {selectedRole?.key === 'admin' && (
                  <div
                    className="roles"
                    role="group"
                    aria-live="polite"
                    aria-label={t('translation:pages.directory.sidebar.role_permissions', 'Role permissions')}
                  >
                    <Field
                      as={FlightCheckbox}
                      value="projects"
                      label={t('translation:general.levels.admin.permissions.projects', 'Projects')}
                      ariaLabel={t('translation:general.levels.admin.permissions.projects', 'Projects')}
                      name="roles"
                      checkState={values.permissions.includes('projects') ? 'SELECTED' : 'UNSELECTED'}
                      onChange={handleChange}
                      onSelect={async () => {
                        await handleCheckboxClick(values.permissions, 'projects', setFieldValue);
                      }}
                    />
                    <Field
                      as={FlightCheckbox}
                      value="teams"
                      label={t('translation:general.levels.admin.permissions.teams', 'Teams')}
                      ariaLabel={t('translation:general.levels.admin.permissions.teams', 'Teams')}
                      name="roles"
                      checkState={values.permissions.includes('teams') ? 'SELECTED' : 'UNSELECTED'}
                      onSelect={async () => {
                        await handleCheckboxClick(values.permissions, 'teams', setFieldValue);
                      }}
                    />
                    <Field
                      as={FlightCheckbox}
                      value="connectors"
                      label={t(`translation:general.levels.admin.permissions.connectors`, 'Connectors')}
                      ariaLabel={t(`translation:general.levels.admin.permissions.connectors`, 'Connectors')}
                      name="roles"
                      checkState={values.permissions.includes('connectors') ? 'SELECTED' : 'UNSELECTED'}
                      onSelect={async () => {
                        await handleCheckboxClick(values.permissions, 'connectors', setFieldValue);
                      }}
                    />
                    <Field
                      as={FlightCheckbox}
                      value="datasources"
                      label={t('translation:general.levels.admin.permissions.datasources', 'Data sources')}
                      ariaLabel={t('translation:general.levels.admin.permissions.datasources', 'Data sources')}
                      name="roles"
                      checkState={values.permissions.includes('datasources') ? 'SELECTED' : 'UNSELECTED'}
                      onSelect={async () => {
                        await handleCheckboxClick(values.permissions, 'datasources', setFieldValue);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {userTeamsInfo.length && !userTeamsLoading ? (
              <div>
                <h3
                  tabIndex={0}
                  aria-label={t('translation:pages.directory.sidebar.team_memberships', {
                    teams: userTeamsInfo.length,
                  })}
                >
                  {t('translation:pages.directory.sidebar.team_memberships', { teams: userTeamsInfo.length })}
                  {userSelected?.email !== t('translation:pages.directory.invite_sent', 'Invite sent') && (
                    <FlightButton
                      type="button"
                      theme="link"
                      size="small"
                      label={t('translation:pages.directory.sidebar.btn-modify', 'Modify')}
                      ariaLabel={t('translation:pages.directory.sidebar.btn-modify', 'Modify') + ', opens dialog'}
                      onClick={() => {
                        toggleTeamModal();
                      }}
                    />
                  )}
                </h3>
                <Treeview
                  title={t('translation:pages.directory.sidebar.team_memberships', { teams: userTeamsInfo.length })}
                  data={userTeamsInfo}
                  teams={organizationTeams}
                />
              </div>
            ) : (
              <div>
                {userSelected?.email !== t('translation:pages.directory.invite_sent', 'Invite sent') && (
                  <>
                    <h3>
                      {t('translation:pages.directory.sidebar.no_teams', 'This user does not belong to any teams.')}
                      <FlightButton
                        type="button"
                        theme="link"
                        size="small"
                        label={t('translation:pages.directory.sidebar.btn-modify', 'Modify')}
                        ariaLabel={t('translation:pages.directory.sidebar.btn-modify', 'Modify') + ', opens dialog'}
                        onClick={() => {
                          toggleTeamModal();
                        }}
                      />
                    </h3>
                  </>
                )}
              </div>
            )}
            <div>
              {userSelected?.email !== t('translation:pages.directory.invite_sent', 'Invite sent') ? (
                <FlightButton
                  type="secondary"
                  className={`button_delete`}
                  size="medium"
                  theme="secondary"
                  iconLeft="trashCan"
                  label={t('translation:pages.directory.sidebar.btn-revoke-org', 'Revoke access to organization')}
                  ariaLabel={
                    t('translation:pages.directory.sidebar.btn-revoke-org', 'Revoke access to organization') +
                    ', opens dialog'
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setTryRevokeAccess(true);
                    return false;
                  }}
                />
              ) : (
                <>
                  <FlightButton
                    type="secondary"
                    className={`button_delete`}
                    size="medium"
                    theme="secondary"
                    iconLeft="trashCan"
                    disabled={!userSelected}
                    label={t('translation:pages.directory.sidebar.btn-cancel-invite', 'Cancel invitation')}
                    onClick={async (e) => {
                      e.preventDefault();
                      if (!userSelected) return;
                      const result = await cancelInvite(userSelected.name, userSelected.organization);
                      if (result) {
                      }
                      return false;
                    }}
                  />
                </>
              )}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
export default PanelContent;
