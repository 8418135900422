import React, { useCallback, useEffect, useState } from 'react';
import '../SharingSettings.scss';
import { FlightTextInput, FlightToggleSwitch } from '@flybits/design-system';
import { Organization } from 'src/model/organizations/organizations';

import { GENERIC_INTERNAL_ERROR } from 'src/constants/errors';
import DataSourcesService from 'src/services/datasources.service';
import { getUserRoles } from 'src/helpers/auth.helper';
import { DataSourceV1 } from 'src/model/datasources/datasources';
import { Project } from 'src/model/projects/projects';
import Jdenticon from 'src/components/Jdenticon';
import { ReactComponent as EmptyDatasources } from 'src/assets/illustrations/no-datasources.svg';
import { capitalize } from 'src/helpers/general.helper';
interface Props {
  current: DataSourceV1 | undefined;
  sharedOrgs: any;
  projects: Project[];
  t: any;
  organization: Organization | null;
  onError: (tab: string, hasError: boolean) => void;
  onChangeValue: (e: any) => void;
  onChangeShareStatus: (e: any) => void;
  addNotification: (e: any) => void;
  addNotificationError: (e: any) => void;
}
interface SharedProjectProps {
  sharedProjects: string[];
  project: Project;
}
const datasourceServiceManager = new DataSourcesService();
const ProjectAccess: React.FunctionComponent<Props> = (props: Props) => {
  const { organization, current, projects, addNotification, addNotificationError, t } = props;
  const MAIN_CLASS = 'SharingSettings';
  const [isSharingProject, setSharingProject] = useState(false);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [sharedProjects, setSharedProjects] = useState<string[]>([]);
  const [searchTermProject, setSearchTermProject] = useState('');

  const editSearchTermProject = (e: any) => {
    setSearchTermProject(e.target.value);
  };

  useEffect(() => {
    const getStoredUserRoles = async () => {
      const roles = await getUserRoles();
      setUserRoles(roles);
    };
    if (current) getSharedProjects();
    getStoredUserRoles();
  }, [current, organization]);
  const getSharedProjects = async () => {
    try {
      if (!organization) return;
      setSharingProject(true);
      const shareResponseObject: any = await datasourceServiceManager.getDataSourceSharedProjects(
        organization?.id,
        current?.metadata.connectorId + '_' + current?.id,
        'v1/',
      );
      if ([200, 201, 204].includes(shareResponseObject.status)) {
        shareResponseObject.data &&
          setSharedProjects(
            shareResponseObject.data.map(({ deployment, subdomain }: any) => deployment + '_' + subdomain),
          );
        setSharingProject(false);
      }
    } catch (e: any) {
      console.error(e);
      addNotificationError(e?.response?.data?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
      setSharingProject(false);
    }
  };
  const projectsFilteredData = () => {
    return projects.filter((proj) => {
      if (!searchTermProject) return true;
      if (proj.name.toLowerCase().includes(searchTermProject.toLowerCase())) {
        return true;
      }
      return false;
    });
  };
  const updateProjectAccess = useCallback(async (projectId: any, shared: boolean) => {
    if (!current) return;
    if (shared) {
      try {
        await datasourceServiceManager.deleteProjectAccess(
          organization ? organization.id : '',
          current?.metadata.connectorId + '_' + current?.id,
          projectId,
        );
        addNotification(
          t('integrations:datasources.details.sections.sharing.messages.revoked', 'Access revoked successfully'),
        );
      } catch (e: any) {
        addNotificationError(e?.response?.data?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
      }
    } else {
      try {
        await datasourceServiceManager.addProjectAccess(
          organization ? organization.id : '',
          current?.metadata.connectorId + '_' + current?.id,
          projectId,
        );
        addNotification(
          t('integrations:datasources.details.sections.sharing.messages.granted', 'Access granted successfully'),
        );
      } catch (e: any) {
        addNotificationError(e?.response?.data?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
      }
    }
    getSharedProjects();
  }, []);
  const SharedProjectListItem = useCallback((props: SharedProjectProps) => {
    if (!props) return null;

    const { deployment, subdomain, name, active } = props.project;
    const projectId = deployment + '_' + subdomain;
    const shared = props.sharedProjects.includes(projectId);
    return (
      <li>
        <div className={`project__item ${shared ? 'shared' : ''}`}>
          <div className="project__item-toggle">
            <FlightToggleSwitch
              label=""
              checked={shared}
              disabled={isSharingProject}
              onChange={() => updateProjectAccess(projectId, shared)}
            />
          </div>
          <div className="project__item-logo">
            <Jdenticon size="40" value={subdomain} />
          </div>
          <div className="project__item-content">
            <h5>{name}</h5>
            <span className={`project__status ${active ? 'active' : 'inactive'}`}>
              {active
                ? t(`integrations:general.list.active`, 'Active')
                : t(`integrations:general.list.inactive`, 'Inactive')}
            </span>{' '}
            - <span>{capitalize(deployment)}</span>
          </div>
        </div>
      </li>
    );
  }, []);
  return (
    <div className={MAIN_CLASS}>
      <h2 className="section__title">
        {t('integrations:datasources.details.sections.project-access.title', 'Project access')}
      </h2>
      <div className="section__fields">
        {(userRoles.includes('owner') || userRoles.includes('datasources')) &&
        current &&
        !['draft', 'deleted'].includes(current?.metadata.status) ? (
          <div className="section__fields-field">
            <label>
              {t('integrations:datasources.details.sections.sharing.project_access', 'Internal project access')}
            </label>
            <div>
              <FlightTextInput
                placeholderText=""
                width="212px"
                hasClearIcon
                trailingIcon="search"
                type="text"
                name="table-filter"
                value={searchTermProject}
                onChange={editSearchTermProject}
                ariaLabel={`Filter projects`}
                label={`Filter projects`}
                isLabelAlwaysOn
              />
            </div>
            <ul className="section__fields-projects">
              {projectsFilteredData().map((project: Project, idx) => (
                <SharedProjectListItem key={project.id + idx} sharedProjects={sharedProjects} project={project} />
              ))}
            </ul>
          </div>
        ) : (
          <>
            {current && (
              <>
                <p>You need to activate the Data source to see the projects list</p>
                <EmptyDatasources />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectAccess;
