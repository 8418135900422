import React, { useState } from 'react';
import './CollapsibleMultiple.scss';
import { CHECKBOX_STATES, EVENT_KEYS } from 'src/constants/general';
import { getIcon } from '@flybits/design-system';
import ReactDOMServer from 'react-dom/server';

const MAIN_CLASS = 'CollapsibleMultiple';
const HEADER_CLASS = `${MAIN_CLASS}__header`;
const LABEL_CLASS = `${MAIN_CLASS}__label`;
const CONTENT_CLASS = `${MAIN_CLASS}__content`;

export interface CollapsibleItem {
  content?: JSX.Element | string;
  disabled?: boolean;
  key: string | number;
  name?: string;
  header: JSX.Element | string;
  depth?: number;
}

type CheckBoxStates = {
  [index: string]: string;
};
interface Props {
  className?: string;
  disabled?: boolean;
  arrowPosition?: string;
  onClick?: (e: any) => void;
  items: Array<CollapsibleItem>;
}

const CollapsibleMultiple = (props: Props) => {
  const { className, onClick, disabled, items, arrowPosition } = props;
  const stateObject: CheckBoxStates = {};
  items.forEach((item: CollapsibleItem) => {
    Object.assign(stateObject, {
      [`${item.key}`]: CHECKBOX_STATES.UNSELECTED,
    });
  });
  const [checkBoxState, setCheckBoxState] = useState(stateObject);

  const handleOnClick = (event: any, triggeredItem: CollapsibleItem) => {
    const nextStateObject = { ...checkBoxState };
    if (checkBoxState[triggeredItem.key] === CHECKBOX_STATES.UNSELECTED || !checkBoxState[triggeredItem.key]) {
      items.forEach((item: CollapsibleItem) => {
        if (item.key === triggeredItem.key) {
          nextStateObject[item.key] = CHECKBOX_STATES.SELECTED;
        } else {
          nextStateObject[item.key] = CHECKBOX_STATES.UNSELECTED;
        }
      });
    } else if (checkBoxState[triggeredItem.key] === CHECKBOX_STATES.SELECTED) {
      nextStateObject[triggeredItem.key] = CHECKBOX_STATES.UNSELECTED;
    }

    setCheckBoxState(nextStateObject);
    if (onClick) onClick(event);
  };
  const handleKeyDown = (func: any) => (e: any) => {
    switch (e.key) {
      case ' ':
      case EVENT_KEYS.ARROW_DOWN:
      case EVENT_KEYS.ARROW_UP:
      case EVENT_KEYS.ENTER:
      case EVENT_KEYS.SPACE:
        e.preventDefault();
        func();
        break;
      default:
        break;
    }
  };
  const jsxToString = (jsx: any) => {
    const optionWrapper = document.createElement('span');
    optionWrapper.innerHTML = ReactDOMServer.renderToStaticMarkup(jsx);
    return optionWrapper?.textContent?.toLocaleLowerCase() || '';
  };
  return (
    <div className={`${MAIN_CLASS}-wrapper ${className}`} role="tree">
      {items.map((item) => {
        return (
          <div className={`${MAIN_CLASS}${disabled || item.disabled ? ` ${MAIN_CLASS}--disabled` : ''}`} key={item.key}>
            <div
              className={`${LABEL_CLASS}${disabled || item.disabled ? ` ${LABEL_CLASS}--disabled` : ''}`}
              style={{ paddingLeft: (item.depth ? item.depth + 1 : 1) * 32 + 'px' }}
            >
              {item.content && arrowPosition === 'left' && (
                <div
                  className={`${LABEL_CLASS}__icon`}
                  onClick={(event) => handleOnClick(event, item)}
                  onKeyDown={(event) => handleKeyDown(() => handleOnClick(event, item))(event)}
                  role="button"
                  tabIndex={0}
                  aria-expanded={checkBoxState[item.key] === CHECKBOX_STATES.UNSELECTED ? false : true}
                  aria-label={item.name || jsxToString(item.header)}
                >
                  {getIcon(
                    checkBoxState[item.key] === CHECKBOX_STATES.UNSELECTED
                      ? 'baselineKeyboardArrowDown'
                      : 'baselineKeyboardArrowUp',
                    { className: `${LABEL_CLASS}__icon` },
                  )}
                </div>
              )}
              <div className={HEADER_CLASS}>{item.header}</div>
              {item.content && (!arrowPosition || arrowPosition === 'right') && (
                <div
                  className={`${LABEL_CLASS}__icon`}
                  onClick={(event) => handleOnClick(event, item)}
                  onKeyDown={(event) => handleKeyDown(() => handleOnClick(event, item))(event)}
                  role="button"
                  tabIndex={0}
                  aria-label={item.name || jsxToString(item.header)}
                  aria-expanded={checkBoxState[item.key] === CHECKBOX_STATES.UNSELECTED ? false : true}
                >
                  {getIcon(
                    checkBoxState[item.key] === CHECKBOX_STATES.UNSELECTED
                      ? 'baselineKeyboardArrowDown'
                      : 'baselineKeyboardArrowUp',
                    { className: `${LABEL_CLASS}__icon` },
                  )}
                </div>
              )}
            </div>
            {item.content && (
              <div
                className={`${CONTENT_CLASS}${
                  checkBoxState && checkBoxState[item.key] !== CHECKBOX_STATES.SELECTED
                    ? ''
                    : ` ${CONTENT_CLASS}--selected`
                }`}
              >
                {checkBoxState[item.key] === CHECKBOX_STATES.SELECTED ? item.content : null}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(CollapsibleMultiple);
