import React from 'react';

const IconVerified = (props?: any) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M17.69 9.99996L18.2083 8.39996C18.5958 7.20246 18.0566 5.89996 16.9358 5.32746L15.4383 4.56246L14.6733 3.06496C14.1008 1.94413 12.7983 1.40413 11.6008 1.79246L9.99996 2.30996L8.39996 1.79163C7.20246 1.40413 5.89996 1.9433 5.32746 3.06413L4.56163 4.56163L3.06413 5.32746C1.9433 5.89996 1.40413 7.20246 1.79163 8.39996L2.30996 9.99996L1.79163 11.6C1.40413 12.7975 1.9433 14.1 3.06413 14.6725L4.56163 15.4375L5.32663 16.935C5.89913 18.0558 7.20163 18.5958 8.39913 18.2075L9.99996 17.69L11.6 18.2083C12.7975 18.5958 14.1 18.0566 14.6725 16.9358L15.4375 15.4383L16.935 14.6733C18.0558 14.1008 18.5958 12.7983 18.2075 11.6008L17.69 9.99996ZM9.16663 13.6783L6.07746 10.5891L7.2558 9.4108L9.16663 11.3216L13.5775 6.9108L14.7558 8.08913L9.16663 13.6783Z"
      fill="#10B2FB"
    />
  </svg>
);

export default IconVerified;
