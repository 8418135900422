import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import {
  clearDB,
  deleteStoredEmail,
  deleteStoredOrganizationId,
  deleteStoredPassword,
  deleteStoredProjectId,
  deleteUserConfirmed,
  deleteUserRoles,
} from 'src/helpers/auth.helper';
import AuthService from 'src/services/authentication.service';

const authServiceManager = new AuthService();
function AppSignout() {
  const [isSignoutInProgress, setIsSignoutInProgress] = useState(true);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        await authServiceManager.signOutUser();
        await clearDB();
        await deleteStoredProjectId();
        await deleteStoredOrganizationId();
        await deleteStoredEmail();
        await deleteStoredPassword();
        await deleteUserConfirmed();
        await deleteUserRoles();
        history.replace('/');
      } catch (error) {
        setIsSignoutInProgress(false);
        console.error(error);
      }
    })();
  }, []);

  return <LoadingIcon width={80} height={80} fullScreen visible={isSignoutInProgress} />;
}

export default AppSignout;
