import React, { useState, useEffect } from 'react';
import { FlightRadioButton, FlightTextInput, FlightTooltip } from '@flybits/design-system';
import { Connector, DefinedConfigs, DynamicConfigs } from 'src/model/connectors/connectors';
import InputPreview from 'src/components/Shared/Preview/InputPreview/InputPreview';
import PasswordPreview from 'src/components/Shared/Preview/PasswordPreview/PasswordPreview';
import TextareaPreview from 'src/components/Shared/Preview/TextareaPreview/TextareaPreview';
import RadioPreview from 'src/components/Shared/Preview/RadioPreview/RadioPreview';
import CheckboxPreview from 'src/components/Shared/Preview/CheckboxPreview/CheckboxPreview';
import DropdownPreview from 'src/components/Shared/Preview/DropdownPreview/DropdownPreview';
import TableEdit from 'src/components/Shared/Preview/TableEdit/TableEdit';
import { Configs } from 'src/model/datasources/datasources';
import { ReactComponent as QuestionCircle } from 'src/assets/icons/question-circle.svg';
import './Preview.scss';
interface Props {
  connectorData: Connector | Record<string, never>;
  configs: Configs | Record<string, never>;
  canChange: boolean;
  onChangeValue: (e: any) => void;
  t: any;
}
const Preview: React.FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'ConfigPreview';
  const {
    connectorData: { configTemplate = {} },
    configs,
    canChange,
    onChangeValue,
    t,
  } = props;

  const [valueIndentityTagSelected, setValueIndentityTagSelected] = useState(configs.identityTag);

  const HandleChangeDefined = (event: any) => {
    if (!canChange) return;
    if (configs.definedConfigs[event.field[0]] && configs.definedConfigs[event.field[0]].mappings) {
      configs.definedConfigs[event.field[0]].mappings[event.field[1]] = event.data;
    }
    onChangeValue({ ...configs });
  };
  const HandleChangeDynamic = (event?: any) => {
    if (!canChange) return;
    if (event) {
      configs.dynamicConfigs[event.headerIndex].dynamicFields[event.itemIndex][event.name] =
        event.name === 'parameters'
          ? event.newValue === ''
            ? []
            : event.newValue.replace(/\[|\]/g, '').split(',') // : JSON.parse(String(event.newValue.replace(/\[|\]/g, '').split(',')))
          : event.newValue;
    }
    onChangeValue({ ...configs });
  };

  useEffect(() => {
    setValueIndentityTagSelected(configs.identityTag);
  }, []);

  return (
    <div className={MAIN_CLASS}>
      <>
        <section>
          <h3>{t('integrations:datasources.details.sections.configs.user_identity', 'User identity')}</h3>
          <label>
            {t('integrations:datasources.details.sections.configs.user_identifying_column_name', 'User Identity Tag')}
          </label>
          <div>
            <FlightRadioButton
              label={t('integrations:datasources.details.sections.configs.user_identifying_tag_name', 'Synthectic ID')}
              key={'user_identifying_tag_name'}
              checked={valueIndentityTagSelected === `syntheticId`}
              onSelect={() => {
                setValueIndentityTagSelected('syntheticId');
                onChangeValue({
                  ...configs,
                  identityTag: 'syntheticId',
                });
              }}
              disabled={!canChange}
              value={'syntheticId'}
            />
            <FlightRadioButton
              label={t('general.other', 'Other')}
              key={'other'}
              checked={valueIndentityTagSelected !== `syntheticId`}
              onSelect={() => {
                if (valueIndentityTagSelected !== 'syntheticId') return;
                setValueIndentityTagSelected('other');
              }}
              disabled={!canChange}
              value={'other'}
            />
          </div>
          {valueIndentityTagSelected !== `syntheticId` && (
            <div>
              <FlightTextInput
                placeholderText=""
                width="300px"
                type="text"
                name="identityTag"
                disabled={!canChange}
                value={configs.identityTag}
                onChange={(ev) => {
                  if (valueIndentityTagSelected === 'syntheticId') setValueIndentityTagSelected('other');
                  onChangeValue({
                    ...configs,
                    identityTag: ev.target.value,
                  });
                }}
                data-lpignore="true"
              />
            </div>
          )}
        </section>
        {Object.keys(configTemplate).length > 0 &&
          Object.entries(configTemplate).map(([configName, configData]) => {
            if (configName?.toString() === 'definedConfigs') {
              return configData?.map(({ heading, fields }: DefinedConfigs, configIndex: number) => (
                <section key={heading.name}>
                  <h3>
                    {heading.viewName}
                    {heading.description && (
                      <FlightTooltip
                        direction="right"
                        className="help"
                        isEnabled={true}
                        description={heading.description}
                      >
                        <QuestionCircle />
                      </FlightTooltip>
                    )}
                  </h3>
                  {fields &&
                    fields?.map((field: any) => {
                      if (!configs || !configs.definedConfigs || !configs.definedConfigs[configIndex].mappings)
                        return null;
                      const cfgField = [
                        configIndex,
                        field.name,
                        configs.definedConfigs[configIndex].mappings[field.name],
                        field,
                      ];
                      switch (field.inputType) {
                        case 'textbox':
                          return (
                            <InputPreview
                              key={field.name}
                              onChangeValue={HandleChangeDefined}
                              configField={cfgField}
                              {...field}
                            />
                          );
                        case 'textarea':
                          return (
                            <TextareaPreview
                              key={field.name}
                              onChangeValue={HandleChangeDefined}
                              configField={cfgField}
                              {...field}
                            />
                          );
                        case 'password':
                          return (
                            <PasswordPreview
                              key={field.name}
                              onChangeValue={HandleChangeDefined}
                              configField={cfgField}
                              {...field}
                            />
                          );
                        case 'dropdown':
                          return (
                            <DropdownPreview
                              key={field.name}
                              onChangeValue={HandleChangeDefined}
                              configField={cfgField}
                              {...field}
                            />
                          );
                        case 'checkbox':
                          return (
                            <CheckboxPreview
                              key={field.name}
                              onChangeValue={HandleChangeDefined}
                              configField={cfgField}
                              {...field}
                            />
                          );
                        case 'radio':
                          return (
                            <RadioPreview
                              key={field.name}
                              onChangeValue={HandleChangeDefined}
                              configField={cfgField}
                              {...field}
                            />
                          );
                        default:
                          console.warn('Invalid input type:', field.inputType);
                          return null;
                      }
                    })}
                </section>
              ));
            } else {
              return configData?.map(({ heading, fieldColumns }: DynamicConfigs) => (
                <section key={heading.name}>
                  <h3>
                    {heading.viewName}
                    {heading.description && (
                      <FlightTooltip
                        direction="right"
                        className="help"
                        isEnabled={true}
                        description={heading.description}
                      >
                        <QuestionCircle />
                      </FlightTooltip>
                    )}
                  </h3>
                  {fieldColumns && (
                    <TableEdit
                      onChangeValue={HandleChangeDynamic}
                      heading={heading}
                      columns={fieldColumns}
                      canChange={canChange}
                      t={t}
                      configs={configs}
                    />
                  )}
                </section>
              ));
            }
          })}
      </>
    </div>
  );
};

export default Preview;
