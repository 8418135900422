import React, { FunctionComponent, useEffect, useState } from 'react';
import Jdenticon from '../Jdenticon';
import { FlightButton, FlightCard, FlightOverflowMenu, FlightTag } from '@flybits/design-system';
import './ProjectListItem.scss';

import { EXP_STUDIO_1_ENABLED, EXP_STUDIO_2_ENABLED, EXP_STUDIO_2_OVERRIDES } from 'src/constants/featureFlags';
import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import { generateAuthCode, getValidAccessToken } from 'src/pkce-oidc/services';
interface Props {
  id: string;
  url: string;
  name: string;
  region: string;
  deployment: string;
  endpoint?: string;
  organization: string;
  tier: string;
  isActive: boolean;
  goToProject: () => void;
  copyToClipboard: (arg0: string) => void;
  style?: any;
  t: any;
}

const ProjectListItem: FunctionComponent<Props> = (props: Props) => {
  const { id, url, name, isActive, deployment, endpoint, copyToClipboard, goToProject, style, t } = props;
  const deploymentEnv = deployment === 'europe' ? 'v3-europe' : deployment;
  const hideESAndDP = false;
  const [isExpStudio1Enabled, setIsExpStudio1Enabled] = useState(true);
  const [isExpStudio2Enabled, setIsExpStudio2Enabled] = useState(false);
  const [isValidatingTokenForES1, setIsValidatingTokenForES1] = useState(false);
  const [isValidatingTokenForES2, setIsValidatingTokenForES2] = useState(false);

  const { featureFlags, organizationFlags } = useControlTowerContext();

  const DEFAULT_CLASS = 'projectListItem';
  const WRAPPER_CLASS = `${DEFAULT_CLASS}__wrapper`;
  const EXTRAS_CLASS = `${DEFAULT_CLASS}__extras`;
  const BORDER_CLASS = `${DEFAULT_CLASS}__border`;
  const ICON_CLASS = `${DEFAULT_CLASS}__project-icon`;
  const INACTIVE_CLASS = `${DEFAULT_CLASS}__inactive`;

  const goToExperienceStudio = async () => {
    if (!isActive) return;
    let host = `${url}.${deploymentEnv}.flybits.com`;
    if (deploymentEnv == 'td') {
      host = `${url}.flybits.com`;
    }
    setIsValidatingTokenForES1(true);
    const validAccessToken = await getValidAccessToken(id, deploymentEnv);
    setIsValidatingTokenForES1(false);

    if (validAccessToken) {
      let es1Url = `https://${host}/#/authenticate?sourceRouteName=experiences&token=${validAccessToken}`;
      if (url == 'bff-test') {
        es1Url = `https://${host}/es1/#/authenticate?sourceRouteName=experiences&token=${validAccessToken}`;
      }
      window.open(es1Url, '_blank');
    } else {
      let es1Url = `https://${host}/#/authenticate?sourceRouteName=experiences`;
      if (url == 'bff-test') {
        es1Url = `https://${host}/es1/#/authenticate?sourceRouteName=experiences`;
      }
      generateAuthCode(id, host, es1Url, deploymentEnv);
    }
  };

  const goToExperienceStudio2 = async () => {
    if (!isActive) return;

    setIsValidatingTokenForES2(true);
    const validAccessToken = await getValidAccessToken(id, deploymentEnv);
    setIsValidatingTokenForES2(false);

    let host = `${url}.${deploymentEnv}.flybits.com`;
    if (deploymentEnv == 'td') {
      host = `${url}.flybits.com`;
    }
    const es1Url = `https://${host}`;

    if (validAccessToken) {
      let es2Url = `https://experiences.${deploymentEnv}.flybits.com/redirect?path=project/${id}&origin=${encodeURIComponent(
        es1Url,
      )}&token=${validAccessToken}`;
      if (url == 'bff-test') {
        es2Url = `https://${url}.${deploymentEnv}.flybits.com/es2/project/redirect?path=project/${id}&origin=${encodeURIComponent(
          es1Url,
        )}&token=${validAccessToken}`;
      }
      window.open(es2Url, '_blank');
    } else {
      let es2Url = `https://experiences.${deploymentEnv}.flybits.com/redirect?path=project/${id}&origin=${encodeURIComponent(
        es1Url,
      )}`;
      if (url == 'bff-test') {
        es2Url = `https://${url}.${deploymentEnv}.flybits.com/es2/project/redirect?path=project/${id}&origin=${encodeURIComponent(
          es1Url,
        )}`;
      }

      generateAuthCode(id, host, es2Url, deploymentEnv);
    }
  };

  const goToDevPortal2 = () => {
    if (!isActive) return;

    let _url = `https://developer.${deploymentEnv}.flybits.com/project/${id}?fromControlTower=true`;
    if (url == 'bff-test') {
      _url = `https://bff-test.${deploymentEnv}.flybits.com/project/${id}?fromControlTower=true`;
    }
    window.open(_url, '_blank');
  };

  const CardBody = () => (
    <>
      <div className="project__region">{deploymentEnv}</div>
    </>
  );

  const CardFooter = () => (
    <>
      {isActive ? (
        <FlightTag label={t('translation:pages.projects.card.active', 'Active')} type="success" />
      ) : (
        <FlightTag label={t('translation:pages.projects.card.inactive', 'Inactive')} />
      )}
    </>
  );

  const CardExtras = () => (
    <div className={EXTRAS_CLASS} tabIndex={0}>
      {!hideESAndDP && (
        <div className={`${EXTRAS_CLASS}-content`}>
          {isExpStudio1Enabled && (
            <FlightButton
              type="primary"
              iconRight="openInNew"
              theme="link"
              label={t('translation:pages.projects.card.link_exp_studio', 'Experience Studio')}
              loading={isValidatingTokenForES1}
              onClick={goToExperienceStudio}
            />
          )}
          <div className={`${EXTRAS_CLASS}-content__break`} />
          {isExpStudio2Enabled && (
            <FlightButton
              type="primary"
              iconRight="openInNew"
              theme="link"
              label="Experience Studio 2.0"
              loading={isValidatingTokenForES2}
              onClick={goToExperienceStudio2}
            />
          )}
          <div className={`${EXTRAS_CLASS}-content__break`} />
          <FlightButton
            type="primary"
            iconRight="openInNew"
            theme="link"
            label={t('translation:pages.projects.card.project_settings', 'Project Settings')}
            onClick={goToDevPortal2}
          />
        </div>
      )}
    </div>
  );

  useEffect(() => {
    // Env/Deployment level

    if (deployment && (deployment === 'arbsvruzy063' || deployment === 'core-dev' || deployment === 'infra-gcp')) {
      setIsExpStudio1Enabled(false);
      setIsExpStudio2Enabled(true);
    } else if (deployment) {
      if (EXP_STUDIO_2_OVERRIDES.includes(deployment) || featureFlags[EXP_STUDIO_2_ENABLED]) {
        setIsExpStudio1Enabled(featureFlags[EXP_STUDIO_1_ENABLED]);
        setIsExpStudio2Enabled(true);
      } else {
        // Org level
        setIsExpStudio1Enabled(
          typeof organizationFlags['org_enable_es1.0'] !== 'undefined' ? organizationFlags['org_enable_es1.0'] : true,
        );
        setIsExpStudio2Enabled(
          typeof organizationFlags['org_enable_es2.0'] !== 'undefined' ? organizationFlags['org_enable_es2.0'] : false,
        );
        // Project level
        if (
          typeof organizationFlags[`proj|${deployment + '_' + url}|experience_studio_1.0`] !== 'undefined' ||
          typeof organizationFlags[`proj|${deployment + '_' + url}|experience_studio_2.0`] !== 'undefined'
        ) {
          setIsExpStudio1Enabled(organizationFlags[`proj|${deployment + '_' + url}|experience_studio_1.0`]);
          setIsExpStudio2Enabled(organizationFlags[`proj|${deployment + '_' + url}|experience_studio_2.0`]);
        }
        // User level
        !organizationFlags['org_enable_es1.0'] && featureFlags[EXP_STUDIO_1_ENABLED] && setIsExpStudio1Enabled(true);
        !organizationFlags['org_enable_es2.0'] && featureFlags[EXP_STUDIO_2_ENABLED] && setIsExpStudio2Enabled(true);
      }
    }
  }, [featureFlags, organizationFlags]);

  return (
    <div className={WRAPPER_CLASS} tabIndex={0} style={style}>
      <>
        <FlightCard
          className={`${BORDER_CLASS} ${!isActive ? INACTIVE_CLASS : ''}`}
          header={name}
          image={<Jdenticon size="60" value={url} className={ICON_CLASS} />}
          body={<CardBody />}
          footer={<CardFooter />}
          onClick={goToProject}
        />
        <FlightOverflowMenu
          ariaLabel={'Click to see more options'}
          optionGroups={[
            [
              {
                key: 1,
                name: t('translation:pages.projects.card.copy_api_url', 'Copy API URL'),
                disabled: false,
                onClick: () => copyToClipboard(`${endpoint}`),
              },
              {
                key: 2,
                name: t('translation:pages.projects.card.copy_id', 'Copy project ID'),
                disabled: false,
                onClick: () => copyToClipboard(id),
              },
              {
                key: 3,
                name: t('translation:pages.projects.card.about_project', 'About this project'),
                disabled: false,
                onClick: goToProject,
              },
            ],
          ]}
        />
        <CardExtras />
      </>
    </div>
  );
};

export default ProjectListItem;
