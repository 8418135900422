import React, { FunctionComponent, useEffect, useState } from 'react';
import SvgFlybitsLogo from 'src/assets/FlybitsLogo';
import { FlightButton } from '@flybits/design-system';
import { useHistory } from 'react-router-dom';
import 'src/styles/pages/SignIn.scss';
import { CODE_REQUIRED, CODE_NOT_MATCH, CODE_NOT_FOUND, GENERIC_INTERNAL_ERROR } from 'src/constants/errors';
import AuthService from 'src/services/authentication.service';
import { clearDB } from 'src/helpers/auth.helper';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import VerificationInput from 'react-verification-input';

// Translation
import { useTranslation } from 'react-i18next';
import { PublicPageChildProps } from 'src/model/general/public-pages';
import { capitalize } from 'src/helpers/general.helper';
interface FormValues {
  code: string;
}

const AutoSubmitToken = () => {
  // Grab values and submitForm from context
  const { values, submitForm } = useFormikContext<FormValues>();
  useEffect(() => {
    // Submit the form imperatively as an effect as soon as form values. token are 6 digits long
    if (values?.code?.length === 6) {
      submitForm();
    }
  }, [values, submitForm]);
  return null;
};

const authServiceManager = new AuthService();
const TwoFactorAuthentication: FunctionComponent<PublicPageChildProps> = (props: PublicPageChildProps) => {
  const { t } = useTranslation(['translation', 'public', 'errors']);

  const [initialValues] = useState<FormValues>({ code: '' });

  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const nextURL = atob(urlParams.get('next') || btoa('/organizations'));

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(t('errors:CODE_REQUIRED', CODE_REQUIRED)),
  });

  const history = useHistory();

  const MAIN_CLASS = 'signin';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const LINK_CLASS = `${ITEM_CLASS}__link`;

  const {
    setBannerVisibility,
    location: { state: { recipient = urlParams.get('email'), channel = 'email' } = {} } = {},
  } = props;

  const goToSignin = async () => {
    await clearDB();
    history.replace('/signin?skipCheck=true');
  };
  const initCheck = async (code: string) => {
    setError(false);
    setLoading(true);

    const checkRequestObject = {
      recipient: decodeURIComponent(recipient),
      channel: channel,
      code: code,
    };
    // prevents old tokens
    await clearDB();
    try {
      const response = await authServiceManager.checkVerificationCode(checkRequestObject);
      if (response && response.status === 200) {
        if (response.headers && response.headers['location']) {
          window.location.assign(response.headers['location']);
        }
        if (response.headers) {
          if (!urlParams.has('next')) {
            await setBannerVisibility(false);
          }
          setLoading(false);

          history.push(nextURL);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        if (error.response.data.message.indexOf('not match') >= 0) {
          setErrorMessage(t('errors:CODE_NOT_MATCH', CODE_NOT_MATCH));
        } else if (
          error.response.data.message.indexOf('not found') >= 0 ||
          error.response.data.message.indexOf('expired') >= 0
        ) {
          setErrorMessage(t('errors:CODE_NOT_FOUND', CODE_NOT_FOUND));
          handleResend();
        } else {
          setErrorMessage(capitalize(error.response.data.message));
        }
      } else {
        setErrorMessage(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
      }
      setLoading(false);
      setError(true);
      console.error(error);
    }
  };
  const handleResend = async () => {
    setError(false);
    setLoading(true);

    await clearDB();
    try {
      await authServiceManager.sendVerificationCode();
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        if (error.response.data.message.indexOf('not exist') >= 0) {
          setErrorMessage(t('errors:CODE_NOT_MATCH', CODE_NOT_MATCH));
        } else {
          setErrorMessage(error.response.data.message);
        }
      } else {
        setErrorMessage(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
      }
      setLoading(false);
      setError(true);
      console.error(error);
    }
  };
  //
  return (
    <div className={MAIN_CLASS}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          await initCheck(values.code);
          resetForm();
        }}
      >
        {({ values, errors, handleSubmit, setFieldValue }) => (
          <div>
            <form className={FORM_CLASS} action="/">
              <SvgFlybitsLogo width={104} height={30} viewBox="0 0 104 30" {...{ className: `${MAIN_CLASS}__logo` }} />
              <h1 className={`${MAIN_CLASS}__heading-text`}>{t('public:two-factor.heading', 'Verification')}</h1>
              <div className={`${MAIN_CLASS}__description center`}>
                <p>
                  {t('public:two-factor.description', {
                    email: recipient || urlParams.get('email'),
                  })}
                </p>
                <p>
                  {t('public:two-factor.didnt-receive', "Didn't receive it?")}
                  &nbsp;
                  <FlightButton
                    type="button"
                    theme="link"
                    loading={isLoading}
                    disabled={isLoading}
                    label={t('public:two-factor.labels.btn-resend', 'Resend the code.')}
                    onClick={handleResend}
                  />
                </p>
              </div>
              {error && (
                <div className="form__error" role="alert" aria-atomic>
                  <strong>Error:</strong>
                  <ul>
                    <li>{errorMessage}</li>
                  </ul>
                </div>
              )}
              <h4 className={`${MAIN_CLASS}__description`} id="code-label">
                {t('public:two-factor.labels.enter-code', 'Enter your code')}
              </h4>
              <div className={`${ITEM_CLASS}__segmented-input`}>
                <VerificationInput
                  validChars="0-9"
                  autoFocus
                  value={values.code}
                  inputProps={{ type: 'tel', autoComplete: 'off', 'aria-labelledby': 'code-label' }}
                  length={6}
                  placeholder=" "
                  onChange={(v) => {
                    setFieldValue('code', v);
                  }}
                  classNames={{
                    container: 'container',
                    character: 'character',
                  }}
                />
              </div>
              <FlightButton
                className={`${ITEM_CLASS}__button`}
                type="primary"
                size="large"
                label={t('public:two-factor.labels.btn-verify', 'Verify')}
                loading={isLoading}
                disabled={isLoading || !!errors.code || !values.code || values.code.length !== 6}
                onClick={handleSubmit}
              />
              <AutoSubmitToken />

              <div className={`${ITEM_CLASS}__row center`}>
                <div className={LINK_CLASS}>
                  <FlightButton
                    type="button"
                    theme="link"
                    label={t('public:two-factor.btn-signin', 'Back to sign in')}
                    onClick={goToSignin}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default TwoFactorAuthentication;
