import React, { useEffect, useState } from 'react';
import { FlightButton, FlightModal, FlightTextInput } from '@flybits/design-system';
import { useFormik } from 'formik';
import './TeamMembershipModal.scss';

import CollapsibleMultiple from 'src/components/Shared/CollapsibleMultiple/CollapsibleMultiple';
import { TeamProps, TeamModalProps, TeamNested, Team } from 'src/model/teams/teams';
import { filterNestedTeams } from 'src/helpers/teams.helper';
import TeamService from 'src/services/teams.service';
import useNotification from 'src/hooks/useNotification';
import { UserTeamObjectParams, UserTeamParams } from 'src/model/users/users';

const TeamMembershipModal: React.FunctionComponent<TeamModalProps> = (props: TeamModalProps) => {
  const { openModal, toggleModal, setUpdateTree, selectedUser, teams, t } = props;
  const [userTeams, setUserTeams] = useState<string[]>([]);
  const defaultSelection = new Set('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedTeams, setSelectedTeams] = useState(defaultSelection);
  const [firstSelection, setFirstSelection] = useState(defaultSelection);
  const formik: any = useFormik({
    initialValues: {
      teams: userTeams,
    },
    enableReinitialize: true,
    onSubmit: () => {
      //
    },
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [updating, setUpdating] = useState(false);
  const { addNotification, addNotificationError } = useNotification();

  const teamServiceManager = new TeamService();

  useEffect(() => {
    // get selected user's teams
    const getUserTeams = async () => {
      try {
        const userTeamsResponseObject = await teamServiceManager.getTeamsFromUser(
          selectedUser.organization,
          selectedUser.id,
        );
        if (userTeamsResponseObject?.data) {
          const selected = userTeamsResponseObject?.data.map((team: Team) => team.id);
          setUserTeams(selected);
          setSelectedTeams(new Set(selected.map((p: string) => p)));
          setFirstSelection(new Set(selected.map((p: string) => p)));
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          console.error(error.response.data.message);
        }
      }
    };
    if (updating) return;
    selectedUser?.id && selectedUser?.organization && openModal && getUserTeams();
    // eslint-disable-next-line
  }, [teams, openModal]);

  const TeamHeaderBlock = (team: TeamProps) => (
    <>
      <div className="checkbox__team">
        <label className="checkbox">
          <span className="checkbox__input">
            <input
              value={team.id}
              type="checkbox"
              checked={formik.values.teams.includes(team.id)}
              aria-checked={formik.values.teams.includes(team.id)}
              onChange={formik.handleChange}
              aria-labelledby={'team-m-' + team.id}
              onBlur={formik.handleBlur}
              name="teams"
              tabIndex={0}
            />
            <span className="checkbox__control" tabIndex={-1}>
              <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.8146 2.20718L4.1075 9.91429L0.400391 6.20718L1.8146 4.79297L4.1075 7.08586L10.4004 0.792969L11.8146 2.20718Z"
                />
              </svg>
            </span>
          </span>
        </label>
      </div>
      <div className="infos">
        <div className="title" id={'team-m-' + team.id}>
          {team.name}
        </div>
        {team.info && <span className="info">{team.info}</span>}
      </div>
      <div className={`teams_number ${team.subteams < 1 ? 'zero' : ''}`}>
        {team.subteams}{' '}
        {team.subteams > 1 || team.subteams === 0
          ? t('translation:pages.directory.teams', 'teams').toLocaleLowerCase()
          : t('translation:pages.directory.team', 'team').toLocaleLowerCase()}
      </div>
    </>
  );
  const generateTableData = (data: TeamNested[]) => {
    return data.map(function f(t, i) {
      const depth = t.id.split('_').length - 1;
      if (t.subteams && t.subteams.length) {
        return {
          header: <TeamHeaderBlock id={t.id} subteams={t.subteams.length} name={t.name} info={t.description} />,
          key: i,
          depth: depth,
          disable: updating,
          name: t.name,
          content: <CollapsibleMultiple key={t.id} items={t.subteams.map(f)} />,
        };
      } else {
        return {
          header: <TeamHeaderBlock id={t.id} subteams={0} name={t.name} info={t.description} />,
          key: i,
          disable: updating,
          name: t.name,
          depth: depth,
          content: '',
        };
      }
    });
  };

  const handleTeamsUpdate = async (values: any) => {
    setUpdating(true);
    const teamsToAdd: UserTeamParams[] = [];
    const teamsToRemove: UserTeamParams[] = [];

    values.teams.map((team: string) => {
      if (!firstSelection.has(team)) {
        teamsToAdd.push({ id: team, maintainer: false });
      }
    });
    userTeams.map((team: string) => {
      if (!values.teams.includes(team) && firstSelection.has(team)) {
        teamsToRemove.push({ id: team, maintainer: false });
      }
    });

    // Add
    if (teamsToAdd.length) {
      const submitData: UserTeamObjectParams = {
        teams: teamsToAdd,
      };
      const teamResponseObject = await teamServiceManager.addTeamsToUser(
        selectedUser.organization,
        selectedUser.id,
        submitData,
      );
      if (teamResponseObject && (teamResponseObject.status === 201 || teamResponseObject.status === 200)) {
        // Check for success/fails
        if (teamResponseObject?.data?.failed?.length > 0) {
          addNotificationError(
            t('translation:pages.teams.messages.add_team_user_error', {
              total: teamResponseObject?.data?.failed?.length,
            }),
          );
        } else {
          addNotification(
            t('translation:pages.teams.messages.add_team_user_success', 'User teams updated successfully!'),
          );
        }
      }
    }

    // Remove
    if (teamsToRemove.length) {
      await Promise.all(
        teamsToRemove.map(async (team: any) => {
          const teamRemoveResponseObject = await teamServiceManager.removeUserFromTeam(
            selectedUser.organization,
            team.id,
            selectedUser.id,
          );
          if (
            teamRemoveResponseObject &&
            teamRemoveResponseObject.status >= 200 &&
            teamRemoveResponseObject.status < 400
          ) {
            return true;
          }
          return false;
        }),
      );
    }
    setUpdating(false);
    setUpdateTree(true);
    setTimeout(() => toggleModal(), 1000);
  };
  const editSearchTerm = (e: any) => {
    setSearchTerm(e.target.value);
  };
  const teamsFilteredData = () => {
    return generateTableData(!searchTerm ? teams : filterNestedTeams(teams, searchTerm));
  };

  return (
    <>
      <FlightModal
        isVisible={openModal}
        toggleModalShown={toggleModal}
        className="TeamMembershipModal"
        scrollable={false}
        size="large"
        header={<span>{t('translation:pages.teams.modal_memberships.header', 'Modify team memberships')}</span>}
        content={
          <div>
            <div className="TeamMembershipModal__search_field">
              <FlightTextInput
                placeholderText=""
                width="292px"
                hasClearIcon
                trailingIcon="search"
                type="text"
                name="table-filter"
                value={searchTerm}
                onChange={editSearchTerm}
                ariaLabel={`Filter teams`}
                label={`Filter teams`}
                isLabelAlwaysOn
              />
            </div>

            <div
              className="TeamMembershipModal__teams_list"
              role="group"
              aria-label={t('translation:pages.teams.modal_memberships.teams_list', 'Select teams (optional)')}
            >
              <CollapsibleMultiple items={teamsFilteredData()} />
            </div>
          </div>
        }
        footer={
          <div>
            <FlightButton
              type="button"
              label={t('translation:general.buttons.update', 'Update')}
              disabled={updating}
              onClick={() => handleTeamsUpdate(formik.values)}
            />
            <FlightButton
              type="button"
              theme="secondary"
              label={t('translation:general.buttons.cancel', 'Cancel')}
              onClick={toggleModal}
            />
          </div>
        }
      />
    </>
  );
};
export default TeamMembershipModal;
