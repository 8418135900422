import React, { useEffect } from 'react';
import './ConfigTemplate.scss';
import Preview from './Preview/Preview';
import { DataSource } from 'src/model/datasources/datasources';
import { Connector } from 'src/model/connectors/connectors';
interface Props {
  current: DataSource | undefined;
  connectorData: Connector | undefined;
  t: any;
  onError: (tab: string, hasError: boolean) => void;
  onChangeValue: (e: any) => void;
}

const Configs: React.FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'ConfigTemplate';

  const { current, connectorData, onChangeValue, onError, t } = props;
  const { configs = {} } = current || {};

  const HandleChange = (cfgs: any) => {
    onChangeValue({
      ...current,
      configs: cfgs,
    });
  };
  useEffect(() => {
    if (!configs) return;
    if (Object.keys(configs).length > 0) {
      // Dynamic check for errors
      const hasError = configs?.dynamicConfigs?.some((dynamicConfig) => {
        return dynamicConfig.dynamicFields.some((field) =>
          Object.entries(field).some(
            (column: any) => column[0] !== 'parameters' && String(field[column[0]]).trim() === '',
          ),
        );
      });
      onError('configs', hasError);
    }
  }, [configs]);

  return (
    <div className={MAIN_CLASS}>
      <h2 className="section__title">
        {t('integrations:datasources.details.sections.configs.title', 'Configurations')}
      </h2>
      <div className="section__tabs">
        <div className="section__tabs-content">
          <div className="tab-inner">
            <Preview
              configs={configs}
              onChangeValue={HandleChange}
              canChange={current?.shareStatus === 'private'}
              connectorData={connectorData || {}}
              t={t}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configs;
