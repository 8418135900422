import { FlightCheckbox } from '@flybits/design-system';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'src/helpers/general.helper';
import { FieldsConfig } from 'src/model/connectors/connectors';
import ItemHeader from '../ItemHeader/ItemHeader';
import './CheckboxPreview.scss';

const CheckboxPreview = (props: FieldsConfig) => {
  const { name, inputOpts, onChangeValue, configField } = props;
  const { configVal } = configField ? configField[2] : false;
  const { t } = useTranslation(['integrations']);
  const [selected, setSelected] = useState<string[]>(
    configVal ? (typeof configVal === 'object' ? configVal : [configVal]) : [],
  );
  const toggleCheckbox = (option: string) => {
    setSelected(!selected.includes(option) ? [...selected, option] : selected.filter((sel) => sel !== option));
  };
  useEffect(() => {
    if (!configField) return;
    typeof onChangeValue === 'function' &&
      onChangeValue({
        field: [configField[0], configField[1]],
        data: { ...configField[2], configVal: selected },
      });
  }, [selected]);
  return (
    <>
      <ItemHeader {...props} />
      {inputOpts?.length && (
        <div className="checkbox">
          {inputOpts?.length ? (
            inputOpts.map((option, key) => (
              <FlightCheckbox
                label={capitalize(option)}
                key={name + key}
                checkState={selected.includes(option) ? 'SELECTED' : 'UNSELECTED'}
                onSelect={() => toggleCheckbox(option)}
              />
            ))
          ) : (
            <span>{t('integrations:datasources.details.sections.configs.no_options', 'No options found')}</span>
          )}
        </div>
      )}
    </>
  );
};
export default CheckboxPreview;
