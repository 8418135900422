import React, { FunctionComponent, useEffect, useState } from 'react';
import { FlightModal, FlightButton, FlightTextInput, getIcon, FlightCheckbox } from '@flybits/design-system';
import './OrganizationDetails.scss';
import OrganizationUserList from 'src/components/OrganizationUsersList/OrganizationUsersList';
import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import useNotification from 'src/hooks/useNotification';
// Services
import OrganizationService from 'src/services/organization.service';
// Icons
import IconOrganization from 'src/assets/icons/organization';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import IconVerified from 'src/assets/icons/verified';
import Thumb from 'src/components/UploadThumb/Thumb';
// Helpers
import {
  PASSWORD_VALIDATION,
  PASSWORD_REQUIRED,
  SAME_PASSWORD_VALIDATION,
  CONFIRMATION_PASSWORD_VALIDATION,
  GENERIC_INTERNAL_ERROR,
} from 'src/constants/errors';
import {
  passwordRegex,
  deleteStoredOrganizationId,
  setStoredOrganizationId,
  setUserRoles,
  getUserRoles,
} from 'src/helpers/auth.helper';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import useDebounce from 'src/hooks/useDebounce';
import { Organization } from 'src/model/organizations/organizations';
import { epochToUTC } from 'src/helpers/general.helper';
import { ReactComponent as IconError } from 'src/assets/icon-blocked.svg';
import { useTranslation } from 'react-i18next';
import useRoleAccess from 'src/hooks/useRoleAccess';

interface Props {
  location?: any;
  match?: any;
}

const OrganizationDetails: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation(['translation', 'errors']);
  const MAIN_CLASS = 'org_details';
  const FORM_CLASS = `${MAIN_CLASS}__form`;
  const ITEM_CLASS = `${FORM_CLASS}__item`;
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;
  const LOADING_ORGANIZATION_CLASS = `${MAIN_CLASS}__loading`;

  const { match } = props;
  const orgID = match.params.id;

  const { setControlTowerContextDataOrg, organization, user, organizationFlags, setControlTowerOrganizationFlags } =
    useControlTowerContext();
  const { addNotification, addNotificationError } = useNotification();

  const editIcon = getIcon('pencil', { width: 16, height: 16, fill: '#0851DF' });
  const checkIcon = getIcon('checkCircle', { width: 16, height: 16, fill: '#00ab50' });

  const history = useHistory();

  if (!orgID) {
    history.push('/organizations');
  }
  setStoredOrganizationId(orgID);
  interface FormValues {
    name: string;
    image?: any;
  }

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required(t('errors:PASSWORD_REQUIRED', PASSWORD_REQUIRED))
      .matches(passwordRegex, t('errors:PASSWORD_VALIDATION', PASSWORD_VALIDATION)),
    password: Yup.string()
      .required(t('errors:PASSWORD_REQUIRED', PASSWORD_REQUIRED))
      .matches(passwordRegex, t('errors:PASSWORD_VALIDATION', PASSWORD_VALIDATION))
      .test('match', t('errors:SAME_PASSWORD_VALIDATION', SAME_PASSWORD_VALIDATION), function (confirmPassword) {
        return confirmPassword !== this.parent.currentPassword;
      }),
    confirmPassword: Yup.string()
      .required(t('errors:PASSWORD_REQUIRED', PASSWORD_REQUIRED))
      .matches(passwordRegex, t('errors:PASSWORD_VALIDATION', PASSWORD_VALIDATION))
      .test(
        'match',
        t('errors:SAME_PASSWORD_VALIDATION', CONFIRMATION_PASSWORD_VALIDATION),
        function (confirmPassword) {
          return confirmPassword === this.parent.password;
        },
      ),
  });

  const validationSchemaDelete = Yup.object().shape({
    confirmId: Yup.string()
      .required(t('errors:ORG_EMPTY_ID', 'Organization ID should not be empty'))
      .matches(orgID, t('errors:INVALID_ORG_ID', 'Invalid Organization ID')),
  });

  const [valuesToSave, setValuesToSave] = useState({});
  const [isSaved, setIsSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);
  const [orgDetails, setOrgDetails] = useState({
    type: '',
    imageUrl: '',
    name: '',
    createdAt: 0,
    verified: false,
  });
  const [orgUsers, setOrgUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usersLoading, setUsersLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [deleting, setDeleting] = useState(false);
  const [leaving, setLeaving] = useState(false);
  const [tryDeleteOrg, setTryDeleteOrg] = useState(false);
  const [tryToLeaveOrg, setTryToLeaveOrg] = useState(false);
  const [errorMessageDel, setErrorMessageDel] = useState('');

  const initialValues = {
    name: orgDetails.name,
    image: orgDetails.imageUrl,
  };
  const initialValuesDelete = {
    confirmId: '',
  };

  // Defining the ref constant variable
  const inputImageRef = React.useRef<HTMLInputElement>(null);

  const triggerSelectImage = () => {
    (inputImageRef as any).current.click();
  };
  const [imageToUpload, setImageToUpload] = useState<any>();
  const autoSaveHandler = async (values: FormValues) => {
    resetErrors();
    // Check if the new value is valid
    if (!values.name || (!imageToUpload && values.name.trim().toLowerCase() === orgDetails.name.trim().toLowerCase()))
      return;
    const organizationRequestObject = {
      name: values.name,
      externalUrl: '',
      image: imageToUpload,
    };
    const organizationServiceManager = new OrganizationService();
    try {
      const organizationResponseObject = await organizationServiceManager.updateOrganization(
        orgID,
        organizationRequestObject,
      );
      if (organizationResponseObject && organizationResponseObject.status === 201) {
        setOrgDetails(organizationResponseObject.data);
        let roles = [];
        if (user && user.userId) {
          const organizationUserResponseObject = await organizationServiceManager.getOrganizationUserInfo(
            orgID,
            user.userId,
          );
          if (organizationUserResponseObject?.data) {
            roles = organizationUserResponseObject.data.roles;
            await setUserRoles(roles);
          }
        }
        setControlTowerContextDataOrg({ ...organizationResponseObject.data, roles: roles });
        return true;
      }
    } catch (error) {
      console.error(error);
      setError(true);
      addNotificationError(error?.response?.data?.message);
      return false;
    }
    return true;
  };
  const autoSave = useDebounce(valuesToSave, 500);
  useEffect(() => {
    // Make sure we have a value (user has entered something in input)
    if (autoSave) {
      // Set isSaved state
      setIsSaved(false);
      setIsSaving(true);
      // Fire off our API call
      autoSaveHandler(autoSave).then((res) => {
        // Set back to false since request finished
        setIsSaving(false);
        if (res) setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
        }, 3000);
      });
    }
    // eslint-disable-next-line
  }, [autoSave]);

  const resetErrors = () => {
    setError(false);
    setErrorMessage('');
    setErrorMessageDel('');
  };
  useEffect(() => {
    const getOrganizationDetails = async () => {
      resetErrors();
      setLoading(true);
      const organizationServiceManager = new OrganizationService();
      try {
        const organizationResponseObject = await organizationServiceManager.getOrganization(orgID);
        if (organizationResponseObject?.data) {
          setOrgDetails(organizationResponseObject?.data);
          //Success! Now get org users list
          const organizationUsersResponseObject = await organizationServiceManager.getOrganizationOwners(orgID);
          if (organizationUsersResponseObject?.data) {
            setOrgUsers(organizationUsersResponseObject?.data);
            setUsersLoading(false);
            setLoading(false);
          }
          const organizationDeployments = await organizationServiceManager.getOrganizationDeployments(orgID);
          const transformedResponse: Organization = organizationResponseObject?.data;
          transformedResponse.deployments = organizationDeployments?.data;
          let roles = [];
          if (user && user.userId) {
            const organizationUserResponseObject = await organizationServiceManager.getOrganizationUserInfo(
              orgID,
              user.userId,
            );
            if (organizationUserResponseObject?.data) {
              roles = organizationUserResponseObject.data.roles;
              await setUserRoles(roles);
            }
          }
          setControlTowerContextDataOrg({ ...transformedResponse, roles: roles });
        }
      } catch (error) {
        console.error(error?.response);
        if (error?.response?.data?.message) {
          setErrorMessage(error.response.data.message);
          setError(true);
        } else {
          setErrorMessage(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
          setError(true);
        }
        history.replace('/organizations');
        addNotificationError(error?.response?.data?.message);
      }
    };
    getOrganizationDetails();
    // eslint-disable-next-line
  }, [orgID, history, errorMessage, setControlTowerContextDataOrg]);
  const [currentRole, setCurrentRole] = useState(['member']);
  useEffect(() => {
    async function getRole() {
      const savedRoles = await getUserRoles();
      const roles = !organization || !organization.roles.length ? savedRoles : organization.roles;
      setCurrentRole(roles);
    }
    getRole();
  }, [orgID, organization]);
  const hasAccess = (required: string[]) => useRoleAccess(required, currentRole);

  const deleteOrganization = async (organizationId: string) => {
    resetErrors();
    const organizationServiceManager = new OrganizationService();
    setDeleting(true);
    try {
      const organizationResponseObject = await organizationServiceManager.deleteOrganization(organizationId);
      if (organizationResponseObject && [200, 201, 204].includes(organizationResponseObject.status)) {
        await deleteStoredOrganizationId();
        addNotification(
          t('translation:pages.organizations.details.messages.removed_success', 'Organization removed successfully.'),
        );
        setDeleting(false);
        setTryDeleteOrg(false);
      }
      return true;
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        setErrorMessageDel(error.response.data.message);
        setError(true);
      } else {
        setErrorMessageDel(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
        setError(true);
      }
      addNotificationError(errorMessageDel);
      setDeleting(false);
      return false;
    }
  };
  const leaveOrganization = async (organizationId: string) => {
    resetErrors();
    const organizationServiceManager = new OrganizationService();
    setLeaving(true);
    try {
      const organizationResponseObject = await organizationServiceManager.leaveOrganization(organizationId);
      if (organizationResponseObject && [200, 201, 204].includes(organizationResponseObject.status)) {
        await deleteStoredOrganizationId();
        addNotification(
          t('translation:pages.organizations.details.messages.leave_success', 'Successfully left organization.'),
        );
        setLeaving(false);
        setTryToLeaveOrg(false);
      }
      return true;
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        setErrorMessageDel(error.response.data.message);
        setError(true);
      } else {
        setErrorMessageDel(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
        setError(true);
      }
      addNotificationError(errorMessageDel);
      setLeaving(false);
      return false;
    }
  };

  return (
    <div className={MAIN_CLASS}>
      <FlightModal
        isVisible={tryDeleteOrg}
        toggleModalShown={() => {
          setTryDeleteOrg(false);
        }}
        scrollable={false}
        size="small"
        warning={true}
        header={
          <span>{t('translation:pages.organizations.details.modal_delete.header', { org: orgDetails.name })}</span>
        }
        content={
          <span>
            {deleting ? (
              <div className={LOADING_ORGANIZATION_CLASS}>
                <LoadingIcon width={80} height={80} className={LOADING_ORGANIZATION_CLASS} visible={deleting} />
                <h3>{t('translation:pages.organizations.details.modal_delete.please_wait', 'Please wait...')}</h3>
                <p>
                  {t('translation:pages.organizations.details.modal_delete.removing', 'Removing your organization.')}
                </p>
              </div>
            ) : (
              <>
                <p>
                  {t(
                    'translation:pages.organizations.details.modal_delete.text',
                    'To permanently delete your organization, enter your organization ID below. This action cannot be undone.',
                  )}
                </p>
                <Formik
                  initialValues={initialValuesDelete}
                  validationSchema={validationSchemaDelete}
                  validateOnChange
                  enableReinitialize
                  onSubmit={async (values) => {
                    const result = await deleteOrganization(values.confirmId);
                    setTimeout(() => {
                      if (result) {
                        history.push('/organizations');
                      }
                    }, 3000);
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form>
                      {error && (
                        <div className="form__error" role="alert" aria-atomic>
                          {errorMessageDel}
                        </div>
                      )}
                      <div className={CONTENT_CLASS}>
                        <Field
                          type="text"
                          name="confirmId"
                          className={`${CONTENT_CLASS}__input`}
                          width="100%"
                          as={FlightTextInput}
                          label={t(
                            'translation:pages.organizations.details.modal_delete.enter_org_id',
                            'Enter Organization ID',
                          )}
                          ariaLabel={t(
                            'translation:pages.organizations.details.modal_delete.enter_org_id',
                            'Enter Organization ID',
                          )}
                          value={values.confirmId}
                          errorMessage={
                            <span role="alert" aria-atomic>
                              <b>Error:</b> {errors.confirmId}
                            </span>
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <br />
                      <div className="modal_buttons">
                        <FlightButton
                          type="secondary"
                          size="medium"
                          theme="secondary"
                          label={t('translation:general.buttons.cancel', 'Cancel')}
                          ariaLabel={t('translation:general.buttons.cancel', 'Cancel')}
                          onClick={() => {
                            setTryDeleteOrg(false);
                          }}
                        />
                        <FlightButton
                          type="primary"
                          size="medium"
                          label={t('translation:pages.organizations.details.btn-delete', 'Delete Organization')}
                          ariaLabel={t('translation:pages.organizations.details.btn-delete', 'Delete Organization')}
                          className={`${CONTENT_CLASS}__button_delete`}
                          disabled={!touched.confirmId || !!errors.confirmId}
                          onClick={handleSubmit}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </>
            )}
          </span>
        }
      />
      <FlightModal
        isVisible={tryToLeaveOrg}
        toggleModalShown={() => {
          setTryToLeaveOrg(false);
        }}
        scrollable={false}
        size="small"
        header={
          <span>
            {t(
              'translation:pages.organizations.details.modal_leave.header',
              'Are you sure you want to leave this organization?',
            )}
          </span>
        }
        content={
          <span>
            {leaving ? (
              <div className={LOADING_ORGANIZATION_CLASS} role="alert">
                <LoadingIcon width={80} height={80} className={LOADING_ORGANIZATION_CLASS} visible={deleting} />
                <h3>{t('translation:pages.organizations.details.modal_delete.please_wait', 'Please wait...')}</h3>
              </div>
            ) : (
              <>
                <p>
                  {t(
                    'translation:pages.organizations.details.leave-text',
                    'Leaving an organization will prevent you from accessing projects and other functions.',
                  )}
                </p>
                <Formik
                  initialValues={initialValuesDelete}
                  enableReinitialize
                  onSubmit={async () => {
                    const result = await leaveOrganization(orgID);
                    setTimeout(() => {
                      if (result) {
                        history.push('/organizations');
                      }
                    }, 1000);
                  }}
                >
                  {({ handleSubmit }) => (
                    <form>
                      {error && (
                        <div className="form__error" role="alert" aria-atomic>
                          {errorMessageDel}
                        </div>
                      )}
                      <br />
                      <div className="modal_buttons">
                        <FlightButton
                          type="secondary"
                          size="medium"
                          theme="secondary"
                          disabled={leaving}
                          loading={leaving}
                          label={t('translation:general.buttons.cancel', 'Cancel')}
                          ariaLabel={t('translation:general.buttons.cancel', 'Cancel')}
                          onClick={() => {
                            setTryToLeaveOrg(false);
                          }}
                        />
                        <FlightButton
                          type="primary"
                          size="medium"
                          label={t('translation:pages.organizations.details.btn-leave', 'Leave Organization')}
                          ariaLabel={t('translation:pages.organizations.details.btn-leave', 'Leave Organization')}
                          className={`${CONTENT_CLASS}__button_delete`}
                          disabled={leaving}
                          loading={leaving}
                          onClick={handleSubmit}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </>
            )}
          </span>
        }
      />
      <div className={CONTENT_CLASS}>
        <div className={`${CONTENT_CLASS}__wrapper`}>
          <h1 className={`${CONTENT_CLASS}__heading-text`}>
            <FlightButton
              type="primary"
              className={`${CONTENT_CLASS}__button_link ${CONTENT_CLASS}__button_link-back`}
              theme="link"
              size="medium"
              label={`< ${t('translation:general.buttons.back', 'Back')}`}
              ariaLabel={`< ${t('translation:general.buttons.back', 'Back')}`}
              onClick={() => {
                history.push('/organization/' + orgID);
              }}
            />
            {t('translation:pages.organizations.details.title', 'About this Organization')}
          </h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange
            enableReinitialize
            onSubmit={async () => null}
          >
            {({ values, handleChange, handleBlur, setFieldValue, setFieldTouched }) => (
              <form className={FORM_CLASS} autoComplete="off">
                <input autoComplete="off" name="hidden" type="text" className={`${ITEM_CLASS}__hide`}></input>
                <div className={`${CONTENT_CLASS}__avatar-wrapper`}>
                  <div className={`${ITEM_CLASS}__group`}>
                    <div className={`${ITEM_CLASS}__image-field`}>
                      <div className="actions" role={'menu'}>
                        {hasAccess(['owner']) && (
                          <FlightButton
                            theme="link"
                            size="medium"
                            label={t('translation:general.buttons.change', 'Change')}
                            ariaLabel={'Change image'}
                            onClick={triggerSelectImage}
                          />
                        )}
                        {values.image && hasAccess(['owner']) && (
                          <FlightButton
                            theme="link"
                            size="medium"
                            className={`${ITEM_CLASS}__remove`}
                            label={t('translation:general.buttons.remove', 'Remove')}
                            ariaLabel={'Remove image'}
                            disabled={!values.image}
                            onClick={() => {
                              setFieldValue('image', '', false);
                              setFieldTouched('image', false, false);
                              addNotification('Image removed');
                            }}
                          />
                        )}
                      </div>
                      <Thumb file={values.image} />
                      <IconOrganization role="presentation" />
                      <div className="flight-text-input-wrapper">
                        <label className="flight-text-input organizations__form__item__file" htmlFor="image">
                          <input
                            className="flight-text-input__input flight-text-input__input--without-label"
                            id="image"
                            name="image"
                            type="file"
                            disabled={!hasAccess(['owner'])}
                            tabIndex={-1}
                            ref={inputImageRef}
                            onChange={(e) => {
                              if (!e.target.files) {
                                e.target.value = '';
                                e.target.files = null;
                                setImageToUpload(undefined);
                                return;
                              }
                              setFieldValue('image', e.target.files[0], true);
                              setImageToUpload(e.target.files[0]);
                              setValuesToSave(values);
                              e.target.files = null;
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={`${ITEM_CLASS}__edit`}>
                    {loading && <LoadingIcon width={32} height={32} type="block" visible={loading} />}
                    <label htmlFor="name" className={`${!loading ? 'loaded' : ''}`}>
                      {values.name}{' '}
                      {error ? (
                        <IconError />
                      ) : isSaved ? (
                        checkIcon
                      ) : isSaving ? (
                        <LoadingIcon width={20} height={20} visible={isSaving} />
                      ) : (
                        hasAccess(['owner']) && editIcon
                      )}
                    </label>
                    <Field
                      type="text"
                      name="name"
                      className={`${ITEM_CLASS}__edit-input ${!loading ? 'loaded' : ''}`}
                      width="100%"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={async (e: any) => {
                        handleBlur(e);
                        setValuesToSave(values);
                      }}
                      disabled={loading || !hasAccess(['owner'])}
                    />
                    {loading ? <LoadingIcon type="block" /> : <p>#{orgID}</p>}
                  </div>
                </div>
                <div className={`${CONTENT_CLASS}__infos-wrapper`}>
                  <div className={`${CONTENT_CLASS}__infos-item`}>
                    <div className={`${CONTENT_CLASS}__infos-item-left`}>
                      {t('translation:pages.organizations.details.labels.subscription', 'Subscription')}
                    </div>
                    <div className={`${CONTENT_CLASS}__infos-item-right`}>
                      {loading ? (
                        <LoadingIcon width={14.5} height={14.5} type="block" visible={loading} />
                      ) : (
                        orgDetails.type
                      )}
                    </div>
                  </div>
                  <div className={`${CONTENT_CLASS}__infos-item`}>
                    <div className={`${CONTENT_CLASS}__infos-item-left`}>
                      {t('translation:pages.organizations.details.labels.created', 'Date created')}
                    </div>
                    <div className={`${CONTENT_CLASS}__infos-item-right`}>
                      {loading ? (
                        <LoadingIcon width={14.5} height={14.5} type="block" visible={loading} />
                      ) : (
                        epochToUTC(orgDetails.createdAt)
                      )}
                    </div>
                  </div>
                  <div className={`${CONTENT_CLASS}__infos-item`}>
                    <div className={`${CONTENT_CLASS}__infos-item-left`}>
                      {t('translation:pages.organizations.details.labels.verified', 'Verified')}
                    </div>
                    <div className={`${CONTENT_CLASS}__infos-item-right`}>
                      {loading ? (
                        <LoadingIcon width={14.5} height={14.5} type="block" visible={loading} />
                      ) : orgDetails.verified ? (
                        <div className="verified">
                          <IconVerified /> {t('translation:pages.organizations.details.labels.verified_yes', 'Yes')}
                        </div>
                      ) : (
                        <>
                          {t('translation:pages.organizations.details.labels.verified_no', 'No')}
                          <div className={`${CONTENT_CLASS}__infos-item-right-small`}>
                            <a href={`mailto:support@flybits.com?Subject=Request to verify [Org: ${orgID}]`}>
                              {t('translation:pages.organizations.details.labels.verified_contact', 'Contact Flybits')}
                            </a>{' '}
                            {t(
                              'translation:pages.organizations.details.labels.verified_no_info',
                              'about getting your organization verified.',
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
          {usersLoading ? (
            <LoadingIcon width={64} height={64} className="loading__owners" visible={usersLoading} />
          ) : (
            <>
              {!usersLoading && !loading && orgUsers.length && (
                <>
                  <h2>
                    {t('translation:pages.organizations.details.owners', 'Owners')}{' '}
                    <FlightButton
                      type="primary"
                      className={`${CONTENT_CLASS}__button_manage`}
                      theme="link"
                      size="medium"
                      label={t('translation:pages.organizations.details.btn-manage', 'Manage users')}
                      ariaLabel={t('translation:pages.organizations.details.btn-manage', 'Manage users')}
                      onClick={() => {
                        history.push(`/organization/${orgID}/directory`);
                      }}
                    />
                  </h2>
                  <OrganizationUserList list={orgUsers} t={t} />
                </>
              )}
            </>
          )}

          <>
            {!loading && !usersLoading && hasAccess(['owner']) && (
              <>
                {/* Feature Flags */}
                <h2>{t('translation:pages.organizations.details.feature_flags', 'Feature flags')}</h2>
                <div className={`${CONTENT_CLASS}__infos-wrapper`}>
                  <div className={`${CONTENT_CLASS}__infos-item`}>
                    <div className={`${CONTENT_CLASS}__infos-item-left`}>
                      {t('translation:pages.organizations.details.labels.enable_integrations_v1', 'Integrations V1')}
                    </div>
                    <div className={`${CONTENT_CLASS}__infos-item-right`}>
                      <FlightCheckbox
                        key={'eintegrationsv1'}
                        label={organizationFlags['org_integrations_v1'] ? 'On' : 'Off'}
                        checkState={organizationFlags['org_integrations_v1'] ? 'SELECTED' : 'UNSELECTED'}
                        onSelect={() => {
                          setControlTowerOrganizationFlags({
                            ...organizationFlags,
                            org_integrations_v1: !organizationFlags['org_integrations_v1'],
                          });
                        }}
                        labelledby={`${t(
                          'translation:pages.organizations.details.labels.enable_integrations_v1',
                          'Integrations V1',
                        )}, checkbox ${organizationFlags['org_integrations_v1'] ? 'on' : 'off'}`}
                      />
                    </div>
                  </div>
                  <div className={`${CONTENT_CLASS}__infos-item`}>
                    <div className={`${CONTENT_CLASS}__infos-item-left`}>
                      {t('translation:pages.organizations.details.labels.enable_es1', 'Experience Studio 1.0')}
                    </div>
                    <div className={`${CONTENT_CLASS}__infos-item-right`}>
                      <FlightCheckbox
                        key={'es1'}
                        label={organizationFlags['org_enable_es1.0'] ? 'On' : 'Off'}
                        checkState={organizationFlags['org_enable_es1.0'] ? 'SELECTED' : 'UNSELECTED'}
                        onSelect={() => {
                          setControlTowerOrganizationFlags({
                            ...organizationFlags,
                            'org_enable_es1.0': !organizationFlags['org_enable_es1.0'],
                          });
                        }}
                        labelledby={`${t(
                          'translation:pages.organizations.details.labels.enable_es1',
                          'Experience Studio 1.0',
                        )}, checkbox ${organizationFlags['org_enable_es1.0'] ? 'on' : 'off'}`}
                      />
                    </div>
                  </div>
                  <div className={`${CONTENT_CLASS}__infos-item`}>
                    <div className={`${CONTENT_CLASS}__infos-item-left`}>
                      {t('translation:pages.organizations.details.labels.enable_es2', 'Experience Studio 2.0')}
                    </div>
                    <div className={`${CONTENT_CLASS}__infos-item-right`}>
                      <FlightCheckbox
                        label={organizationFlags['org_enable_es2.0'] ? 'On' : 'Off'}
                        key={'es2'}
                        checkState={organizationFlags['org_enable_es2.0'] ? 'SELECTED' : 'UNSELECTED'}
                        onSelect={() => {
                          setControlTowerOrganizationFlags({
                            ...organizationFlags,
                            'org_enable_es2.0': !organizationFlags['org_enable_es2.0'],
                          });
                        }}
                        labelledby={`${t(
                          'translation:pages.organizations.details.labels.enable_es2',
                          'Experience Studio 2.0',
                        )}, checkbox ${organizationFlags['org_enable_es2.0'] ? 'on' : 'off'}`}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
          {!loading && !usersLoading && (
            <div className={`${CONTENT_CLASS}__leave_org_wrapper`}>
              <h2>{t('translation:pages.organizations.details.leave-org', 'Leave this organization')}</h2>
              {(!hasAccess(['owner']) || (hasAccess(['owner']) && orgUsers.length > 1)) && (
                <>
                  <p>
                    {t(
                      'translation:pages.organizations.details.leave-text',
                      'Leaving an organization will prevent you from accessing projects and other functions.',
                    )}
                  </p>
                  <FlightButton
                    type="primary"
                    className={`${CONTENT_CLASS}__button_delete`}
                    size="medium"
                    loading={deleting}
                    iconLeft="nodeConnect"
                    label={t('translation:pages.organizations.details.btn-leave', 'Leave Organization')}
                    ariaLabel={t(
                      'translation:pages.organizations.details.btn-leave_aria',
                      'Leave Organization, opens a confirmation dialog',
                    )}
                    onClick={() => {
                      setTryToLeaveOrg(true);
                    }}
                  />
                </>
              )}
              {hasAccess(['owner']) && orgUsers.length === 1 && (
                <p>
                  {t(
                    'translation:pages.organizations.details.no-owners-text',
                    'You must assign another owner before leaving organization.',
                  )}
                </p>
              )}
            </div>
          )}
          {!loading && !usersLoading && hasAccess(['owner']) && (
            <div className={`${CONTENT_CLASS}__delete_org_wrapper`}>
              <h2>{t('translation:pages.organizations.details.delete-org', 'Delete this organization')}</h2>
              <p>
                {t(
                  'translation:pages.organizations.details.delete-text',
                  'Deleting an organization is permanent. This will remove all of the user accounts associated to the organization and delete all of the projects and datasources.',
                )}
              </p>
              <FlightButton
                type="primary"
                className={`${CONTENT_CLASS}__button_delete`}
                size="medium"
                iconLeft="trashCan"
                loading={deleting}
                label={t('translation:pages.organizations.details.btn-delete', 'Delete Organization')}
                ariaLabel={t(
                  'translation:pages.organizations.details.btn-delete_aria',
                  'Delete Organization, opens a confirmation dialog',
                )}
                onClick={() => {
                  setTryDeleteOrg(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganizationDetails;
