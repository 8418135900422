import LinesAndColumns from 'lines-and-columns';

interface CTException {
  name: string;
  message: string;
  line?: number;
  column?: number;
  position: number | null;
  fileName?: string;
}

export const JSONError = function (this: CTException, error?: CTException) {
  this.name = error?.name || 'JSONError';
  this.line = error?.line ? error.line + 1 : 1;
  this.column = error?.column || 0;
  this.position = error?.position || 0;
  this.fileName = error?.fileName || '';
  this.message = error?.message || '';
} as unknown as { new (error: CTException): CTException };

export const parseJson = (string: string, reviver: any = null, filename?: string) => {
  if (typeof reviver === 'string') {
    filename = reviver;
    reviver = null;
  }
  try {
    return JSON.parse(string, reviver);
  } catch (error) {
    const errorData: CTException = {
      name: 'JSONError',
      message: error.message,
      position: null,
    };
    error.message = error.message.replace(/\n/g, '');
    const indexMatch = error.message.match(/in JSON at position (\d+)/);

    if (filename) {
      errorData.fileName = filename;
    }

    if (indexMatch && indexMatch.length > 0) {
      const lines = new LinesAndColumns(string);
      const index = Number(indexMatch[1]);
      const location = lines.locationForIndex(index);

      errorData.line = location?.line;
      errorData.column = location?.column;
      errorData.position = lines.indexForLocation({ line: location?.line || 0, column: location?.column || 0 });
    }
    throw new JSONError(errorData);
  }
};

export const formatJSON = (jsonString: string) => {
  try {
    return JSON.stringify(parseJson(jsonString), undefined, 2);
  } catch (e) {
    console.error('[formatJSON]', e);
    return jsonString;
  }
};
