import React from 'react';
// import flagsmith from 'flagsmith';
import { User } from 'src/model/users/users';
import { Organization } from 'src/model/organizations/organizations';
import { CONTROL_TOWER_FLAGS } from 'src/constants/featureFlags';
import { FeatureFlags } from 'src/model/features/features.td';
// Translations
import { useTranslation } from 'react-i18next';

import { createFlagsmithInstance } from 'flagsmith';
import { isSame } from 'src/helpers/general.helper';
const flagsmith = createFlagsmithInstance();
const flagsmithOrg = createFlagsmithInstance();
//Create Control Tower Context

interface ControlTowerContextInterface {
  user: User | null;
  organization: Organization | null;
  featureFlags: FeatureFlags | Record<string, never>;
  organizationFlags: FeatureFlags | Record<string, never>;
  setControlTowerOrganizationFlags: (flagsData: FeatureFlags | Record<string, never>) => void | null;
  setControlTowerProjectFlags: (projectID: string, flagsData: FeatureFlags | Record<string, never>) => void | null;
  setControlTowerContextDataUser: (userData: User) => void | null;
  setControlTowerContextDataOrg: (orgData: Organization) => void | null;
  unsetControlTowerContextDataUser: () => void | null;
  unsetControlTowerContextDataOrg: () => void | null;
}

export const CT_DEFAULTS = {
  user: null,
  organization: null,
  featureFlags: {},
  organizationFlags: {
    'org_enable_es1.0': false,
    'org_enable_es2.0': false,
    org_integrations_v1: false,
  },
  setControlTowerOrganizationFlags: () => null,
  setControlTowerProjectFlags: () => null,
  setControlTowerContextDataUser: () => null,
  setControlTowerContextDataOrg: () => null,
  unsetControlTowerContextDataUser: () => null,
  unsetControlTowerContextDataOrg: () => null,
};
export const ControlTowerContext = React.createContext<ControlTowerContextInterface>(CT_DEFAULTS);

type Props = {
  children: React.ReactNode;
};

export const ControlTowerProvider = ({ children }: Props) => {
  // Sets current language's reading direction (LTR or RTL)
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const [organization, setOrganization] = React.useState<Organization | null>(CT_DEFAULTS.organization);
  const [user, setUser] = React.useState<User | null>(CT_DEFAULTS.user);
  const [featureFlags, setFeatureFlags] = React.useState<FeatureFlags>({});
  const [organizationFlags, setOrganizationFlags] = React.useState<FeatureFlags>(CT_DEFAULTS.organizationFlags);
  const minutesToMilliseconds = (min: number) => min * 60 * 1000;
  const setControlTowerOrganizationFlags = React.useCallback((flagsData: FeatureFlags | Record<string, never>) => {
    flagsData &&
      flagsmithOrg.setTraits({
        'org_enable_es1.0': flagsData['org_enable_es1.0'] === false ? 'no' : 'yes',
        'org_enable_es2.0': flagsData['org_enable_es2.0'] === false ? 'no' : 'yes',
        org_integrations_v1: flagsData['org_integrations_v1'] === false ? 'no' : 'yes',
      });
  }, []);
  const setControlTowerProjectFlags = React.useCallback(
    (projectID: string, flagsData: FeatureFlags | Record<string, never>) => {
      const traits: any = {};
      Object.keys(flagsData).length > 0 &&
        Object.entries(flagsData).map(([trait, enabled]) => {
          traits[trait] = enabled === false ? 'no' : 'yes';
        });
      flagsData && projectID && flagsmithOrg.setTraits(traits);
    },
    [],
  );
  const setControlTowerContextDataUser = React.useCallback((data: User | null) => {
    // check if its not the same
    if (data?.username) {
      flagsmith.identify(`${data?.username}`).then(() => {
        flagsmith.startListening(minutesToMilliseconds(5));
      });
    }
    setUser(data);
  }, []);
  const setControlTowerContextDataOrg = React.useCallback((data: Organization | null) => {
    if ((!organization && data?.id) || (organization?.id !== data?.id && !isSame(organization, data))) {
      flagsmithOrg.identify(`org_${data?.id}`).then(() => {
        flagsmithOrg.startListening(minutesToMilliseconds(5));
      });
    }
    setOrganization(data);
  }, []);
  const unsetControlTowerContextDataOrg = React.useCallback(() => {
    setOrganization(null);
  }, []);
  const unsetControlTowerContextDataUser = React.useCallback(() => {
    setUser(null);
  }, []);
  const yesNoToBool = (val: string) => val === 'yes';
  React.useEffect(() => {
    //Env/user features
    flagsmith.init({
      environmentID: `${process.env.REACT_APP_BULLET_TRAIN_ENVIRONMENT_ID}`,
      cacheFlags: true,
      onChange: (flags) => {
        // const { flags } = flagsmith.getState();
        if (!flags || (flags && !Object.keys(flags).length)) return;
        const featureFlags: FeatureFlags = {};

        flags &&
          CONTROL_TOWER_FLAGS.map((flag) => {
            if (typeof flag !== 'string') return;
            const controlTowerFeatureFlag = flags[flag];
            if (controlTowerFeatureFlag) {
              featureFlags[flag] = controlTowerFeatureFlag.enabled;
            }
          });

        setFeatureFlags(featureFlags);
      },
    });
    // Org level (traits)
    flagsmithOrg.init({
      environmentID: `${process.env.REACT_APP_BULLET_TRAIN_ENVIRONMENT_ID}`,
      cacheFlags: true,
      onChange: () => {
        const { traits } = flagsmithOrg.getState();
        if (!traits || (traits && !Object.keys(traits).length)) return;
        const orgFlags: any = {};
        Object.keys(traits).length > 0 &&
          Object.entries(traits).map(([trait, enabled]) => {
            orgFlags[trait] = yesNoToBool(enabled);
          });

        //Check for Org specific flags (traits)
        const updateTraits: any = {};
        if (orgFlags['org_enable_es1.0'] === 'undefined' || orgFlags['org_enable_es2.0'] === 'undefined') {
          orgFlags['org_enable_es1.0'] = true;
          orgFlags['org_enable_es2.0'] = false;
          updateTraits['org_enable_es1.0'] = 'yes';
          updateTraits['org_enable_es2.0'] = 'no';
        }
        //Check for Org Integrations trait
        if (orgFlags['org_integrations_v1'] === 'undefined') {
          orgFlags['org_integrations_v1'] = false;
          updateTraits['org_integrations_v1'] = 'no';
        }
        if (orgFlags['org_integrations_v1'] === 'undefined') {
          orgFlags['org_integrations_v1'] = false;
          updateTraits['org_integrations_v1'] = 'no';
        }
        if (Object.keys(updateTraits).length) {
          flagsmithOrg.setTraits(updateTraits);
        }
        setOrganizationFlags(orgFlags);
      },
    });
  }, []);

  return (
    <ControlTowerContext.Provider
      value={{
        user,
        organization,
        featureFlags,
        organizationFlags,
        setControlTowerOrganizationFlags,
        setControlTowerProjectFlags,
        setControlTowerContextDataUser,
        setControlTowerContextDataOrg,
        unsetControlTowerContextDataUser,
        unsetControlTowerContextDataOrg,
      }}
    >
      {children}
    </ControlTowerContext.Provider>
  );
};

export const useControlTowerContext = () => React.useContext(ControlTowerContext);
