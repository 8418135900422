import React, { FunctionComponent, useEffect, useState } from 'react';
import { FlightButton, FlightTextInput, FlightSelect, getIcon } from '@flybits/design-system';
import './DataSourceCreate.scss';
// Services
import DataSourcesService from 'src/services/datasources.service';
import ConnectorService from 'src/services/connectors.service';
// Helpers
import { GENERIC_INTERNAL_ERROR } from 'src/constants/errors';
import { setStoredOrganizationId } from 'src/helpers/auth.helper';
import { useHistory } from 'react-router-dom';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import useNotification from 'src/hooks/useNotification';

import { ReactComponent as IconGlobe } from 'src/assets/icon-globe.svg';
import { ReactComponent as ConnectorsImage } from 'src/assets/connectors-icon-color.svg';
interface Props {
  match?: any;
}
import { useTranslation } from 'react-i18next';
import { ConnectorListItem, ConnectorV1 } from 'src/model/connectors/connectors';
import ConnectorCardItem from 'src/components/ConnectorCardItem/ConnectorCardItem';
import { copyToClipboard } from 'src/helpers/general.helper';
const DataSourceCreate: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation(['translation', 'errors', 'integrations', 'components']);
  const connectorsServiceManager = new ConnectorService();
  const datasourcesServiceManager = new DataSourcesService();
  const MAIN_CLASS = 'DataSourceCreate';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  const selectConnector = (connector: ConnectorListItem) => {
    setStep('deployment');
    window.location.hash = 'deployment';
    setSelectedConnector(connector);
  };

  const { match } = props;
  if (match.params.id) setStoredOrganizationId(match.params.id);
  const orgID = match.params.id;
  const APIVersion = match.params.version ? match.params.version + '/' : '';
  const { addNotification, addNotificationError } = useNotification();

  const [loading, setLoading] = useState(false);
  const [selectedStep, setStep] = useState(window.location.hash.replace('#', '') || 'connector');
  const [selectedConnector, setSelectedConnector] = useState<ConnectorListItem | undefined>(undefined);
  const [connectors, setConnectors] = useState<ConnectorListItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState({
    key: 'all',
    name: t('integrations:general.filters.anyone', `Anyone`) || 'Anyone',
  });

  const history = useHistory();

  if (!orgID) {
    history.push('/organizations');
  }

  const editSearchTerm = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const getConnectorsList = async () => {
      setLoading(true);
      try {
        const connectorsResponseObject = await connectorsServiceManager.getConnectors(orgID, APIVersion);
        if (connectorsResponseObject?.data) {
          const _connectorData = !APIVersion
            ? connectorsResponseObject.data
            : (connectorsResponseObject.data as ConnectorV1[]).map((connector: any) => ({
                id: connector.id,
                createdAt: connector.metadata.createdAt,
                updatedAt: connector.metadata.updatedAt,
                updatedBy: connector.metadata.updatedBy,
                status: connector.metadata.status,
                deployments: connector.deployments,
                organization: connector.metadata.organization,
                ttl: connector.metadata.ttl,
                name: connector.display.name,
                imageUrl: connector.display.imageUrl,
                apiKey: connector.metadata.apiKey,
                description: connector.display.description,
              }));

          setConnectors(
            _connectorData?.filter(
              (connector: ConnectorListItem) =>
                connector.status === 'active' && Object.keys(connector.deployments).length > 0,
            ),
          );
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        console.error(error?.response);
        if (error?.response?.data?.message) {
          addNotificationError(error.response.data.message);
        } else {
          addNotificationError(t(`errors:GENERIC_INTERNAL_ERROR`, GENERIC_INTERNAL_ERROR));
        }
      }
    };
    getConnectorsList();
    if (selectedStep !== 'connector' && !selectedConnector) {
      setStep('connector');
      window.location.hash = 'connector';
    }
  }, [orgID]);

  const saveDatasource = async (deploymentId: string) => {
    if (!selectedConnector) return;
    setLoading(true);
    const datasourceRequestObject: any = !APIVersion
      ? {
          connectorId: selectedConnector.id,
          name: t('integrations:datasources.new.datasource-name', {
            random_number: Date.now().toString().substr(-4),
          }),
          deployment: {
            id: deploymentId,
          },
        }
      : {
          metadata: {
            connectorId: selectedConnector.id,
          },
          display: {
            name: t('integrations:datasources.new.datasource-name', {
              random_number: Date.now().toString().substr(-4),
            }),
          },
          deployment: {
            id: deploymentId,
          },
        };
    try {
      const datasourceResponseObject = await datasourcesServiceManager.createDataSource(
        orgID,
        datasourceRequestObject,
        APIVersion,
      );
      if (
        datasourceResponseObject &&
        (datasourceResponseObject.status === 201 || datasourceResponseObject.status === 200)
      ) {
        setLoading(false);
        //redirect to newly created datasource page
        setTimeout(() => {
          history.push(
            `/organization/${orgID}/integrations/v1/datasource/${selectedConnector.id}_${datasourceResponseObject.data.id}`,
          );
        }, 750);
      }
    } catch (e: any) {
      console.error(e);
      addNotificationError(e?.response?.data?.message);
      setLoading(false);
    }
  };

  const filteredSearchData = () => {
    return connectors.filter((connector) => {
      let match = false;
      switch (selectedFilter.key) {
        case 'all':
          match =
            !searchTerm ||
            connector.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            connector.description.toLowerCase().includes(searchTerm.toLowerCase());
          break;
        case 'owner':
          match =
            connector.organization.id === orgID &&
            (!searchTerm ||
              connector.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              connector.description.toLowerCase().includes(searchTerm.toLowerCase()));
          break;
        case 'shared':
          match =
            connector.organization.id !== orgID &&
            (!searchTerm ||
              connector.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              connector.description.toLowerCase().includes(searchTerm.toLowerCase()));
          break;
      }

      return match;
    });
  };
  const DeploymentCard = (props: any) => {
    const { id, data } = props;

    return (
      <div className="deploymentCard" role="button" onClick={() => saveDatasource(id)}>
        <IconGlobe className="deploymentCard__icon" />
        <div className="deploymentCard__name">{data.name || data.displayName}</div>
        {getIcon('baselineArrowRight', { className: 'deploymentCard__arrow' })}
      </div>
    );
  };

  return (
    <div className={MAIN_CLASS}>
      <LoadingIcon fullScreen={true} width={30} visible={loading} />
      <div className={CONTENT_CLASS}>
        <div className={`${CONTENT_CLASS}__wrapper`}>
          <h1 className={`${CONTENT_CLASS}__heading-text`}>
            <FlightButton
              type="primary"
              className={`${CONTENT_CLASS}__button_link ${CONTENT_CLASS}__button_link-back`}
              theme="link"
              size="medium"
              label={`< ${
                selectedStep === 'connector'
                  ? t('integrations:datasources.new.back-step-connector', 'Back to datasources')
                  : t('integrations:datasources.new.back-step-deployment', 'Back to connector selection')
              }`}
              onClick={() => {
                if (selectedStep === 'connector') {
                  history.push(`/organization/${orgID}/integrations/v1/`);
                } else {
                  setStep('connector');
                }
              }}
            />
            {selectedStep === 'connector'
              ? t('integrations:datasources.new.title-step-connector', 'Connect to your data')
              : t('integrations:datasources.new.title-step-deployment', 'Choose deployment')}
          </h1>
          <p className={`${CONTENT_CLASS}__heading-description`}>
            {selectedStep === 'connector'
              ? t(
                  'integrations:datasources.new.description-step-connector',
                  'Choose a connection method that matches the data set you want to access.',
                )
              : t(
                  'integrations:datasources.new.description-step-deployment',
                  'The region the connector is hosted in. You can always change this later.',
                )}
          </p>
          {selectedStep === 'connector' && (
            <div className={`${CONTENT_CLASS}__filters`}>
              <FlightTextInput
                placeholderText=""
                width="212px"
                hasClearIcon
                trailingIcon="search"
                type="text"
                name="table-filter"
                value={searchTerm}
                onChange={editSearchTerm}
                ariaLabel={`Search ${selectedStep}s`}
                label={`Search ${selectedStep}s`}
                isLabelAlwaysOn
              />
              <FlightSelect
                options={[
                  { key: 'all', name: t('integrations:general.filters.anyone', `Anyone`) || 'Anyone' },
                  {
                    key: 'owner',
                    name: t('integrations:general.filters.your-org', 'Your organization') || 'Your organization',
                  },
                  {
                    key: 'shared',
                    name: t('integrations:general.filters.shared-with-me', 'Shared with me') || 'Shared with me',
                  },
                ]}
                hasLabelAnimation
                label={`Filter ${selectedStep}s owned by:`}
                selected={selectedFilter}
                dropdownMaxHeight="150px"
                width="220px"
                handleOptionClick={setSelectedFilter}
              />
            </div>
          )}
          {selectedConnector && selectedStep !== 'connector' && (
            <div className="connectorDeployments">
              {Object.keys(selectedConnector.deployments).length > 0 &&
                Object.entries(selectedConnector.deployments).map(([id, data]) => (
                  <DeploymentCard key={id} id={id} data={data} />
                ))}
            </div>
          )}
          {selectedStep === 'connector' && (
            <div className="connectorCardList">
              {filteredSearchData().length > 0 ? (
                filteredSearchData().map((item) => (
                  <ConnectorCardItem
                    className={item.id === selectedConnector?.id ? 'is-selected' : ''}
                    key={item.id}
                    connectorData={item}
                    id={item.id}
                    t={t}
                    addNotification={addNotification}
                    copyToClipboard={copyToClipboard}
                    selectConnector={() => selectConnector(item)}
                  />
                ))
              ) : loading ? null : (
                <div className="IntegrationsList__content__tabs-content-empty" style={{ flex: 1 }}>
                  <p>
                    <ConnectorsImage />
                  </p>
                  <p>
                    {t(
                      'integrations:datasources.new.no-connectors',
                      "There are no 'Active' Connectors to create a Data source from. Please create and activate at least one.",
                    )}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataSourceCreate;
