import BaseService from './base.service';
import { CreateTeam, TeamProjectLevel, TeamProjects, TeamUsers } from 'src/model/teams/teams';
import { UserTeamObjectParams } from 'src/model/users/users';
export default class TeamService extends BaseService {
  CURRENT_PATH: string;
  constructor() {
    super();
    this.CURRENT_PATH = '/organizations';
  }

  async getTeams(organizationId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId + '/teams');
  }

  async getTeam(organizationId: string, teamId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId + '/teams/' + teamId);
  }

  async getTeamsFromUser(organizationId: string, userId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId + '/users/' + userId + '/teams');
  }

  async getTeamMembers(organizationId: string, teamId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId + '/teams/' + teamId + '/users');
  }

  async getTeamProjects(organizationId: string, teamId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId + '/teams/' + teamId + '/projects');
  }

  async getTeamAndSubteams(organizationId: string, teamId: string) {
    return this.request('GET', this.CURRENT_PATH + '/' + organizationId + '/teams/' + teamId);
  }

  async removeUserFromTeam(organizationId: string, teamId: string, userId: string) {
    return this.request('DELETE', this.CURRENT_PATH + '/' + organizationId + '/teams/' + teamId + '/users/' + userId);
  }
  async updateTeam(organizationId: string, teamId: string, data: CreateTeam) {
    return this.request('PUT', this.CURRENT_PATH + '/' + organizationId + '/teams/' + teamId, data);
  }
  async updateTeamProjectLevel(organizationId: string, teamId: string, projectId: string, data: TeamProjectLevel) {
    return this.request(
      'PATCH',
      this.CURRENT_PATH + '/' + organizationId + '/teams/' + teamId + '/projects/' + projectId,
      data,
    );
  }
  async deleteProjectFromTeam(organizationId: string, teamId: string, projectId: string) {
    return this.request(
      'DELETE',
      this.CURRENT_PATH + '/' + organizationId + '/teams/' + teamId + '/projects/' + projectId,
    );
  }
  async deleteTeam(organizationId: string, teamId: string) {
    return this.request('DELETE', this.CURRENT_PATH + '/' + organizationId + '/teams/' + teamId);
  }
  async createTeam(organizationId: string, data: CreateTeam) {
    return this.request('POST', this.CURRENT_PATH + '/' + organizationId + '/teams', data);
  }
  async addUserToTeam(organizationId: string, teamId: string, data: TeamUsers) {
    return this.request('POST', this.CURRENT_PATH + '/' + organizationId + '/teams/' + teamId + '/users', data);
  }
  async addTeamsToUser(organizationId: string, userId: string, data: UserTeamObjectParams) {
    return this.request('POST', this.CURRENT_PATH + '/' + organizationId + '/users/' + userId + '/teams', data);
  }
  async addProjectsToTeam(organizationId: string, teamId: string, data: TeamProjects) {
    return this.request('POST', this.CURRENT_PATH + '/' + organizationId + '/teams/' + teamId + '/projects', data);
  }
}
