import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { FlightButton, FlightRadioButton, FlightModal, getIcon } from '@flybits/design-system';
import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import { getStoredOrganizationId, setStoredOrganizationId } from 'src/helpers/auth.helper';
import useNotification from 'src/hooks/useNotification';
import SaveToolbar from 'src/components/SaveToolbar/SaveToolbar';
import { capitalize, timeFromNowTo, removeProperties, openInNewWindow } from 'src/helpers/general.helper';
import DeleteDatasourceModal from 'src/components/Shared/DeleteDatasourceModal/DeleteDatasourceModal';
import { GENERIC_INTERNAL_ERROR } from 'src/constants/errors';

// Icons
import { ReactComponent as DatasourceDefaultImage } from 'src/assets/datasources-icon.svg';
import imageErrorConnector from 'src/assets/datasources-icon.svg';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
// Translation
import { useTranslation } from 'react-i18next';
import useMessage from 'src/hooks/useMessage';
// Services
import DataSourcesService from 'src/services/datasources.service';
import ConnectorService from 'src/services/connectors.service';
import ProjectService from 'src/services/project.service';

// Models
import { AuthorizationRequest, DataSource, DataSourceV1 } from 'src/model/datasources/datasources';
import { Connector, ConnectorV1 } from 'src/model/connectors/connectors';
import { Project } from 'src/model/projects/projects';

// V0
import BasicInfo from 'src/pages/DataSources/BasicInfo';
import Contact from 'src/pages/DataSources/Contact';
import SharingSettings from 'src/pages/DataSources/SharingSettings';
import Authorization from 'src/pages/DataSources/Authorization';
import Configs from 'src/pages/DataSources/ConfigTemplate';

// V1 imports
import DisplayInformation from 'src/pages/DataSources/v1/DisplayInformation';
import Configurations from 'src/pages/DataSources/v1/Configurations';
import PreDefinedAttributes from 'src/pages/DataSources/v1/PreDefinedAttributes';
import UserDefinedAttributes from 'src/pages/DataSources/v1/UserDefinedAttributes';

// Messaging
let resolve: any;

interface Props {
  location?: any;
  match?: any;
}
// Styles
import './DataSource.scss';
import ProjectAccess from 'src/pages/DataSources/v1/ProjectAccess';

const DataSourcePage: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation(['translation', 'errors', 'integrations', 'components']);
  const { organization } = useControlTowerContext();

  const { match } = props;
  if (match.params.id) setStoredOrganizationId(match.params.id);
  const orgID = match.params.id || organization?.id || getStoredOrganizationId();
  const APIVersion = match.params.version ? match.params.version + '/' : '';
  const datasourceID = match.params.datasource;
  const history = useHistory();
  const { addNotification, addNotificationError } = useNotification();
  if (!orgID) {
    history.push('/organizations');
  }
  if (!datasourceID) {
    history.goBack();
  }

  const tabsArrayV0 = [
    { slug: 'basic', name: t('integrations:datasources.details.sections.basic.title', 'Basic info') },
    { slug: 'contact', name: t('integrations:datasources.details.sections.contact.title', 'Contact & documentation') },
    {
      slug: 'authorization',
      name: t('integrations:datasources.details.sections.authorization.title', 'Authorization'),
    },
    {
      slug: 'configs',
      name: t('integrations:datasources.details.sections.configs.title', 'Configurations'),
    },
    { slug: 'sharing', name: t('integrations:datasources.details.sections.sharing.title', 'Sharing settings') },
  ];
  const tabsArrayV1 = [
    {
      slug: 'basic',
      name: t('integrations:datasources.details.sections.display-information.title', 'Display information'),
    },
    {
      slug: 'configs',
      name: t('integrations:datasources.details.sections.configs.title', 'Configurations'),
    },
    {
      slug: 'pre-defined-attributes',
      name: t('integrations:datasources.details.sections.pre-defined-attributes.title', 'Pre-defined attributes'),
    },
    {
      slug: 'user-defined-attributes',
      name: t('integrations:datasources.details.sections.user-defined-attributes.title', 'User-defined attributes'),
    },
    { slug: 'sharing', name: t('integrations:datasources.details.sections.project-access.title', 'Project access') },
  ];
  const tabsArray = !APIVersion ? tabsArrayV0 : tabsArrayV1;
  const [selectedTab, setTab] = useState(window.location.hash.replace('#', '') || 'basic');
  const [loading, setLoading] = useState(false);
  const [isModified, setModified] = useState(false);
  const authRequestTimeout = useRef<any>(null);

  const [tryToDeleteDatasource, setTryToDeleteDatasource] = useState(false);
  const [tryToDiscardChanges, setTryToDiscardChanges] = useState(false);
  const [sharedOrgsList, setSharedOrgsList] = useState<any[]>([]);
  const [tabsWithError, setTabsWithError] = useState<any[]>([]);
  const [updatedImageFile, setUpdatedImageFile] = useState<any>(null);
  const [datasourceDataDefault, setDatasourceDataDefault] = useState<DataSource | DataSourceV1 | undefined>(undefined);
  const [datasourceData, setDatasourceData] = useState<DataSource | DataSourceV1 | undefined>(undefined);
  const [connectorData, setConnectorData] = useState<Connector | ConnectorV1 | undefined>(undefined);
  const [projectData, setProjectData] = useState<Project[] | undefined>(undefined);

  const datasourcesServiceManager = new DataSourcesService();
  const connectorsServiceManager = new ConnectorService();
  const projectServiceManager = new ProjectService();

  const getConnectorData = async (connectorID: string) => {
    try {
      const connectorsResponseObject = await connectorsServiceManager.getConnectorById(orgID, connectorID, APIVersion);
      if (connectorsResponseObject?.data) {
        return connectorsResponseObject?.data;
      }
    } catch (error: any) {
      console.error(error);
      addNotificationError(
        error?.response?.data?.message || error?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR),
      );
      return false;
    }
  };
  const HandleTabError = (tab: string, hasError: boolean) => {
    if (loading) return;
    if (hasError && !tabsWithError.includes(tab)) setTabsWithError([...tabsWithError, tab]);
    if (!hasError && tabsWithError.includes(tab)) setTabsWithError(tabsWithError.filter((item) => item !== tab));
  };
  const getProjectsData = async () => {
    try {
      const projectResponseObject = await projectServiceManager.getAllProjects(orgID);
      if (projectResponseObject) {
        return projectResponseObject?.data;
      }
    } catch (error: any) {
      console.error(error);
      addNotificationError(
        error?.response?.data?.message || error?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR),
      );
      return false;
    }
  };

  useEffect(() => {
    // Get current data
    const getDatasourceData = async () => {
      setLoading(true);
      try {
        const datasourceResponseObject = await datasourcesServiceManager.getDataSourceById(
          orgID,
          datasourceID,
          APIVersion,
        );
        if (datasourceResponseObject?.data) {
          const connectorResponse: Connector | ConnectorV1 = await getConnectorData(
            !APIVersion
              ? datasourceResponseObject?.data.connectorId
              : datasourceResponseObject?.data.metadata.connectorId,
          );
          if (connectorResponse) setConnectorData(connectorResponse);
          const projectResponse = await getProjectsData();
          if (projectResponse)
            setProjectData(
              // projectResponse
              // we're filtering to show only currently supported envs
              projectResponse.filter((project: Project) =>
                ['dev', 'development', 'staging', 'performance', 'demo'].includes(project.deployment),
              ),
            );
          // populate configs
          let _dataSourceData = datasourceResponseObject.data;
          // v0
          if (!_dataSourceData.configs || !Object.keys(_dataSourceData.configs).length) {
            const defaultConfigsV0 = {
              identityTag: (_dataSourceData as DataSource).configs.identityTag || 'syntheticId',
              definedConfigs:
                (connectorResponse as Connector).configTemplate.definedConfigs?.map((defs) => {
                  return {
                    headingName: defs.heading.name,
                    headingViewName: defs.heading.viewName,
                    description: defs.heading.description,
                    mappings: Object.fromEntries(
                      defs.fields.map((field) => {
                        return [
                          field.name,
                          {
                            configKey: field.name,
                            configVal: '',
                            dataType: field.dataType,
                            isOutput: field.isOutput,
                            parameters: field.parameters || [],
                          },
                        ];
                      }) || [],
                    ),
                  };
                }) || [],
              dynamicConfigs:
                (connectorResponse as Connector).configTemplate.dynamicConfigs?.map((dyn) => {
                  const currentColumns = dyn.fieldColumns.map((col) => [col.name, '']);
                  if (dyn.heading.isOutput) {
                    currentColumns.push(['outputFieldName', '']);
                    currentColumns.push(['dataType', '']);
                  }
                  return {
                    headingName: dyn.heading.name,
                    headingViewName: dyn.heading.viewName,
                    isOutput: dyn.heading.isOutput,
                    dynamicFields: [Object.fromEntries([...currentColumns, ['parameters', '']])] || [],
                  };
                }) || [],
            };
            const defaultConfigsV1 = {
              flybitsConfig: {
                identityType: (_dataSourceData as DataSourceV1)?.configs?.flybitsConfig?.identityType || 'syntheticId',
              },
              connectorConfig: {},
              pluginAttributes: [],
              additionalAttributes: [],
            };
            const defaultConfigs = !APIVersion ? defaultConfigsV0 : defaultConfigsV1;
            _dataSourceData = { ..._dataSourceData, configs: defaultConfigs };
          }
          setDatasourceData(_dataSourceData);
          setDatasourceDataDefault(_dataSourceData);
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        console.error(error);
        addNotificationError(
          error?.response?.data?.message ||
            error?.message ||
            t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR),
        );
        history.push({
          pathname: '/error',
          state: {
            title: t('integrations:datasources.not_found.title', 'Oops! You blew up the internet.'),
            message: t(
              'integrations:datasources.not_found.message',
              'The datasource you are looking for is no longer here, or never existed in the first place (bummer)',
            ),
            buttonText: t('integrations:datasources.not_found.buttonText', 'Return to a safe place'),
            buttonURL: `/organization/${orgID}/integrations`,
          },
        });
      }
    };

    datasourceID && getDatasourceData();
    closeAuthWindow();
    return () => closeAuthWindow();
  }, [datasourceID]);

  const updateSharedStatus = (data: DataSource) => {
    setDatasourceData(data);
    setDatasourceDataDefault(data);
  };
  const [isValidConfig, setIsValidConfig] = useState(false);
  useEffect(() => {
    if (!datasourceData) return;
    if (ValidateData('activate')) {
      setIsValidConfig(true);
    } else {
      setIsValidConfig(false);
    }
    return () => setIsValidConfig(false);
  }, [datasourceData]);
  const ValidateData = (action = 'save') => {
    const sectionErrors = [];

    const _status = !APIVersion
      ? (datasourceData as DataSource)?.status
      : (datasourceData as DataSourceV1).metadata.status;
    const _name = !APIVersion ? (datasourceData as DataSource)?.name : (datasourceData as DataSourceV1).display.name;
    if (_status === 'active' || (action !== 'save' && _status === 'draft')) {
      if (tabsWithError.length) return false;
      // - name
      // - configs
      let hasConfigs = !APIVersion ? true : false;
      let hasDeployment = false;
      const _datasourceData: any = !APIVersion ? (datasourceData as DataSource) : (datasourceData as DataSourceV1);

      if (datasourceData?.configs && Object.keys(datasourceData?.configs).length) {
        if (!APIVersion) {
          // v0
          if (_datasourceData.configs?.definedConfigs && _datasourceData.configs?.definedConfigs.length) {
            hasConfigs = true;
          }
          if (_datasourceData.configs?.dynamicConfigs && _datasourceData.configs?.dynamicConfigs.length) {
            hasConfigs = true;
          }
        } else {
          hasConfigs = true;
        }
      }

      if (datasourceData?.deployment) {
        if (Object.keys(datasourceData.deployment).length) {
          hasDeployment = true;
        }
      }
      if (_name && hasConfigs && hasDeployment) return true;
      if (!hasConfigs) sectionErrors.push('configs');
      if (!hasDeployment) sectionErrors.push('deployment');
      setTabsWithError(sectionErrors);
      return false;
    }
    return true;
  };

  const handleConnectorAction = async () => {
    if (!organization || !datasourceData) return;
    const _status = !APIVersion
      ? (datasourceData as DataSource)?.status
      : (datasourceData as DataSourceV1).metadata.status;
    if (_status !== 'active') {
      if (!ValidateData()) {
        toggleAlert(
          true,
          t('integrations:datasources.details.messages.activate_error_title', 'Unable to activate datasource'),
          t(
            'integrations:datasources.details.messages.activate_error_msg',
            'Your must have at least one deployment and a configuration to activate your datasource.',
          ),
        );
        return false;
      }
    }
    setLoading(true);
    try {
      const _status = !APIVersion
        ? (datasourceData as DataSource)?.status
        : (datasourceData as DataSourceV1).metadata.status;
      const datasourcesResponseObject = await datasourcesServiceManager.updateDataSourceStatus(
        organization.id,
        datasourceID,
        _status !== 'active' ? 'activate' : 'deactivate',
        APIVersion,
      );
      setLoading(false);
      if (datasourcesResponseObject && [200, 201, 204].includes(datasourcesResponseObject.status)) {
        if (datasourcesResponseObject?.data?.errorMessage) {
          addNotificationError(datasourcesResponseObject?.data?.errorMessage);
          return false;
        } else {
          addNotification(
            t('integrations:datasources.details.messages.update_status', 'Datasource status was updated successfully!'),
          );
          setDatasourceData(datasourcesResponseObject?.data);
          setDatasourceDataDefault(datasourcesResponseObject?.data);
          return true;
        }
      }
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      addNotificationError(
        error?.response?.data?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR),
      );
      return false;
    }
    return false;
  };
  const handleImageChange = (imgFile: any) => {
    if (!imgFile) return;
    setUpdatedImageFile(imgFile);
  };
  const handleViewConnectorDetails = () => {
    if (!datasourceData) return;
    const _connectorId = !APIVersion
      ? (datasourceData as DataSource)?.connectorId
      : (datasourceData as DataSourceV1).metadata.connectorId;
    history.push(`/organization/${orgID}/integrations/${APIVersion}connector/${_connectorId}#deployment`);
  };
  const handleCancelEdits = () => {
    addNotification(t('integrations:datasources.details.messages.changes_discarded', 'All changes were discarded'));
    setTryToDiscardChanges(false);
    setUpdatedImageFile(null);
    if (datasourceDataDefault) {
      setDatasourceData({
        ...datasourceDataDefault,
      });
      setDatasourceDataDefault({
        ...datasourceDataDefault,
      });
    }
  };
  const handleSaveEdits = async () => {
    if (!datasourceData || !datasourceData.id) return;
    if (!ValidateData()) return;
    setLoading(true);
    try {
      let connectorImage = !APIVersion
        ? (datasourceData as DataSource)?.imageUrl
        : (datasourceData as DataSourceV1)?.display.imageUrl;
      const _connectorConfig = (datasourceData as DataSourceV1)?.configs.connectorConfig || {};
      // handle Image upload
      if (updatedImageFile && connectorImage?.startsWith('data:')) {
        const ImgResponseObject = await datasourcesServiceManager.updateImage(orgID, updatedImageFile, APIVersion);

        const _imgResponse = ImgResponseObject?.data?.imageUrl;
        connectorImage = `${
          !_imgResponse.startsWith('http') ? 'https://controltower.flybits.com/' : ''
        }${_imgResponse}`;
      }
      const propsToRemove = [
        'id',
        'status',
        'createdAt',
        'shareStatus',
        'ttl',
        'owner',
        'shared',
        'organization',
        'authStatusMap',
        'supportEmailVerified',
        'connectorId',
        'updatedAt',
        'updatedBy',
        'metadata',
        'configsVersion',
      ];
      const propsToRemoveConfig = ['pluginAttributes'];
      if (APIVersion) propsToRemove.push('deployment');
      let saveResponseObject: any = undefined;
      const payload: any = !APIVersion
        ? {
            ...datasourceData,
            imageUrl: connectorImage,
          }
        : {
            ...datasourceData,
            configs: {
              ...removeProperties((datasourceData as DataSourceV1).configs, ...propsToRemoveConfig),
              connectorConfig: _connectorConfig,
            },
            display: {
              ...(datasourceData as DataSourceV1).display,
              imageUrl: connectorImage,
            },
          };
      // sanitize dynamic table parameters before updating
      if (!APIVersion) {
        (payload as DataSource).configs?.dynamicConfigs?.map((cfg) =>
          cfg?.dynamicFields.map((field) => field?.parameters?.filter((param) => param !== '')),
        );
      }

      // update
      saveResponseObject = await datasourcesServiceManager.updateDataSourceData(
        orgID,
        datasourceID,
        removeProperties(payload, ...propsToRemove),
        APIVersion,
      );
      if ([200, 201].includes(saveResponseObject.status)) {
        setLoading(false);
        setUpdatedImageFile(null);
        setDatasourceData(saveResponseObject.data);
        setDatasourceDataDefault(saveResponseObject.data);
        setModified(false);
        addNotification(t('integrations:datasources.details.messages.saved', 'All changes were saved'));
      }
    } catch (error: any) {
      setLoading(false);
      addNotificationError(
        error?.response?.data?.message || error?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR),
      );
      console.error(
        error?.response?.data?.message || error?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR),
      );
    }
  };
  const [showAlert, setShowAlert] = useState(false);
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [newWindowHandle, setNewWindowHandle] = useState<any>(null);
  // Messaging
  useMessage('authenticate', (send: any, payload: any) => {
    const { success } = payload;
    if (newWindowHandle) resolve(success);
  });
  const toggleAlert = (show: boolean, title = '', msg = '') => {
    setShowAlert(show);
    setAlertTitle(title);
    setAlertMessage(msg);
  };
  const changeTab = (tab = 'basic') => {
    if (tab !== selectedTab) window.location.hash = tab;
  };

  const { name, imageUrl, updatedAt, status } = (datasourceData as DataSource) || {};
  const { display, metadata } = (datasourceData as DataSourceV1) || {};

  const datasourceCommonInfo = {
    name: !APIVersion ? name : display?.name,
    imageUrl: !APIVersion ? imageUrl : display?.imageUrl,
    updatedAt: !APIVersion ? updatedAt : metadata?.updatedAt,
    status: !APIVersion ? status : metadata?.status,
  };
  useEffect(() => {
    setTab(window.location.hash.replace('#', '') || 'basic');
    // eslint-disable-next-line
  }, [window.location.hash]);

  const handleDeleteDatasource = (deleted: boolean, message?: string) => {
    if (deleted) {
      setTryToDeleteDatasource(false);
      addNotification(t('integrations:datasources.delete.success', 'Datasource deleted successfully'));
      history.replace(`/organization/${orgID}/integrations/${APIVersion}`);
    } else {
      addNotificationError(
        message || t('integrations:datasources.delete.error', 'Error while trying to delete this datasource.'),
      );
    }
  };

  useEffect(() => {
    if (!newWindowHandle || newWindowHandle.closed) {
      authRequestTimeout && clearInterval(authRequestTimeout.current);
      if (isAuthorizing) resolve(false);
      setIsAuthorizing(false);
      closeAuthWindow();
    } else {
      newWindowHandle?.addEventListener('beforeunload', () => closeAuthWindow);
      authRequestTimeout.current = setInterval(() => {
        if (newWindowHandle.closed) {
          if (isAuthorizing) resolve(false);
          setIsAuthorizing(false);
          closeAuthWindow();
        }
      }, 3000);
    }
  }, [newWindowHandle]);

  const waitForAuth = async () => {
    return new Promise((res) => {
      resolve = res;
    });
  };
  const closeAuthWindow = () => {
    newWindowHandle && newWindowHandle.close();
    setNewWindowHandle(null);
    authRequestTimeout && clearInterval(authRequestTimeout.current);
  };
  const handleConnect = async (event: React.MouseEvent, authData: AuthorizationRequest) => {
    setIsAuthorizing(true);
    try {
      const datasourceResponseObject = await datasourcesServiceManager.authorizeDataSource(
        orgID,
        datasourceID,
        authData,
      );

      if (datasourceResponseObject && [200, 201, 202, 204].includes(datasourceResponseObject.status)) {
        // Open window and await for connection
        const windowOptions = {
          url: datasourceResponseObject.data.authUrl,
          name: 'dataSourceAuthWin',
        };
        closeAuthWindow();
        const windowHandle = openInNewWindow(windowOptions);
        setNewWindowHandle(windowHandle);
        setIsAuthorizing(true);
        const result = await waitForAuth();
        setIsAuthorizing(false);
        if (result) {
          addNotification(
            t('integrations:datasources.details.sections.authorization.messages.success', 'Authorization successfull'),
          );
          return { status: 'connected', message: '' };
        } else {
          addNotificationError(
            t(
              'integrations:datasources.details.sections.authorization.messages.failed_auth_message',
              'Error authorizing your datasource. Please try again.',
            ),
          );
          return {
            status: 'failed',
            message: t(
              'integrations:datasources.details.sections.authorization.messages.failed',
              'Authorization error. Please try again later.',
            ),
          };
        }
      }
      closeAuthWindow();
      setIsAuthorizing(false);
      return {
        status: 'failed',
        message: t(
          'integrations:datasources.details.sections.authorization.messages.failed',
          'Authorization error. Please try again later.',
        ),
      };
    } catch (error: any) {
      console.error(error?.response?.data?.message || error);
      setIsAuthorizing(false);
      closeAuthWindow();
      return {
        status: 'failed',
        message: t(
          'integrations:datasources.details.sections.authorization.messages.failed',
          'Authorization error. Please try again later.',
        ),
      };
    }
  };
  const handleDisconnect = async (authData: AuthorizationRequest) => {
    try {
      const datasourceResponseObject = await datasourcesServiceManager.deleteDataSourceToken(
        orgID,
        datasourceID,
        authData,
      );
      if (datasourceResponseObject && [200, 201, 202, 204].includes(datasourceResponseObject.status)) {
        addNotification(
          t(
            'integrations:datasources.details.sections.authorization.messages.disconnected',
            'Datasource is now disconnected.',
          ),
        );
        return { status: '', message: '' };
      }
    } catch (error: any) {
      console.error(error);
      return {
        status: 'failed',
        message: error?.response?.data?.message || error || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR),
      };
    }
  };
  return (
    <>
      {loading && <LoadingIcon fullScreen={true} width={80} height={80} visible={true} />}
      <SaveToolbar
        current={datasourceData}
        defaultValues={datasourceDataDefault}
        onClickCancel={() => setTryToDiscardChanges(true)}
        onClickSave={handleSaveEdits}
        disabled={tabsWithError.includes('basic')}
        setModified={(res) => setModified(res)}
      />
      {/* DELETE DATA SOURCE MODAL */}
      <DeleteDatasourceModal
        handleDeleteDatasource={handleDeleteDatasource}
        t={t}
        apiVersion={APIVersion}
        showModal={tryToDeleteDatasource}
        toggleModal={() => setTryToDeleteDatasource(false)}
        datasourceData={[datasourceID, datasourceCommonInfo.name]}
        organizationID={orgID}
      />
      <FlightModal
        isVisible={tryToDiscardChanges}
        toggleModalShown={() => setTryToDiscardChanges(false)}
        scrollable={false}
        size="small"
        warning={false}
        className={`DataSource__alert`}
        header={
          <span>
            {t(
              'integrations:datasources.details.modals.discard.message',
              'Are you sure you want to discard all unsaved changes?',
            )}
          </span>
        }
        footer={
          <div className={`DataSource__alert_footer`}>
            <FlightButton
              type="button"
              theme="secondary"
              label={t('translation:general.buttons.cancel', 'Cancel')}
              onClick={() => {
                setTryToDiscardChanges(false);
              }}
            />
            <FlightButton
              type="button"
              theme="primary"
              className="delete-full"
              label={t('integrations:datasources.details.modals.discard.button', 'Discard changes')}
              onClick={handleCancelEdits}
            />
          </div>
        }
      />
      <FlightModal
        isVisible={showAlert}
        toggleModalShown={() => toggleAlert(false)}
        scrollable={false}
        size="small"
        warning={false}
        className={`DataSource__alert`}
        header={<span>{alertTitle}</span>}
        content={<span>{alertMessage}</span>}
        footer={
          <div className={`DataSource__alert_footer`}>
            <FlightButton
              type="button"
              theme="primary"
              label={t('integrations:datasources.details.messages.got_it', 'Got it')}
              onClick={() => {
                toggleAlert(false);
              }}
            />
          </div>
        }
      />
      <div className="DataSource">
        <div className={`DataSource__content full-width`}>
          <div className={`DataSource__content__wrapper with-separator`}>
            <div className={`DataSource__content__header`}>
              <h1>
                <div className="row-icon">
                  {datasourceCommonInfo.imageUrl ? (
                    <img
                      src={datasourceCommonInfo.imageUrl}
                      onError={(ev) => {
                        (ev.target as Element).setAttribute('src', imageErrorConnector);
                      }}
                      alt={name || 'Datasource image'}
                    />
                  ) : (
                    <DatasourceDefaultImage />
                  )}
                </div>{' '}
                {datasourceCommonInfo.name}{' '}
                <span>
                  (
                  {capitalize(
                    t(`integrations:general.list.${datasourceCommonInfo.status}`, datasourceCommonInfo.status),
                  )}
                  )
                </span>
              </h1>
              <div>
                {typeof datasourceCommonInfo.updatedAt !== 'undefined' && datasourceCommonInfo.updatedAt > 0 && (
                  <div className="updated-at">
                    {t('integrations:datasources.last-update', { time: timeFromNowTo(datasourceCommonInfo.updatedAt) })}
                  </div>
                )}
                <FlightButton
                  type="button"
                  theme="secondary"
                  size="medium"
                  label={capitalize(
                    datasourceCommonInfo.status !== 'active'
                      ? t(`integrations:general.list.activate`, 'Activate')
                      : t(`integrations:general.list.deactivate`, 'Deactivate'),
                  )}
                  disabled={
                    datasourceCommonInfo.status !== 'active' && (!isValidConfig || isModified || !!tabsWithError.length)
                  }
                  onClick={handleConnectorAction}
                />
              </div>
            </div>
          </div>
          <div className={`DataSource__content__wrapper with-tabs`}>
            <div className={`DataSource__content__tabs`}>
              <FlightButton
                type="primary"
                className={`button_link button_link-back`}
                theme="link"
                size="medium"
                label={`< ${t('intgrations:general.back-integrations', 'Back to integrations')}`}
                ariaLabel={`${t('intgrations:general.back-integrations', 'Back to integrations')}`}
                onClick={() => {
                  history.push(`/organization/${orgID}/integrations/${APIVersion}`);
                }}
              />
              {tabsArray.map(({ slug, name }, key) => (
                <div key={key + slug}>
                  {tabsWithError.includes(slug) && getIcon('error', { fill: '#DC3616' })}
                  <FlightRadioButton
                    key={slug}
                    label={name}
                    checked={selectedTab === slug}
                    disabled={selectedTab === slug}
                    className={`DataSource__content__tabs-header-item`}
                    onSelect={() => {
                      changeTab(slug);
                    }}
                    value={slug}
                  />
                </div>
              ))}
            </div>
            <div className={`DataSource__content__tabs-content`}>
              {/* v0 */}
              {selectedTab === 'basic' && !APIVersion && (
                <BasicInfo
                  current={datasourceData as DataSource}
                  connector={connectorData as Connector}
                  onError={HandleTabError}
                  onClickDetails={handleViewConnectorDetails}
                  onClickDelete={setTryToDeleteDatasource}
                  onChangeValue={setDatasourceData}
                  onChangeImage={handleImageChange}
                  t={t}
                />
              )}
              {selectedTab === 'contact' && (
                <Contact
                  current={datasourceData as DataSource}
                  onError={HandleTabError}
                  onChangeValue={setDatasourceData}
                  t={t}
                />
              )}
              {selectedTab === 'authorization' && (
                <Authorization
                  current={datasourceData as DataSource}
                  authWindow={newWindowHandle}
                  onError={HandleTabError}
                  onChangeValue={setDatasourceData}
                  onConnect={handleConnect}
                  onDisconnect={handleDisconnect}
                  t={t}
                />
              )}
              {selectedTab === 'configs' && !APIVersion && (
                <Configs
                  connectorData={connectorData as Connector}
                  current={datasourceData as DataSource}
                  onError={HandleTabError}
                  onChangeValue={setDatasourceData}
                  t={t}
                />
              )}
              {selectedTab === 'sharing' && !APIVersion && (
                <SharingSettings
                  current={datasourceData as DataSource}
                  sharedOrgs={sharedOrgsList}
                  onChangeValue={setSharedOrgsList}
                  onChangeShareStatus={updateSharedStatus}
                  addNotification={addNotification}
                  organization={organization}
                  projects={projectData || []}
                  onError={HandleTabError}
                  t={t}
                  addNotificationError={addNotificationError}
                />
              )}
              {/* v1 */}
              {selectedTab === 'basic' && APIVersion && connectorData && (
                <DisplayInformation
                  current={datasourceData as DataSourceV1}
                  connector={connectorData as ConnectorV1}
                  onError={HandleTabError}
                  onClickDetails={handleViewConnectorDetails}
                  onClickDelete={setTryToDeleteDatasource}
                  onChangeValue={setDatasourceData}
                  onChangeImage={handleImageChange}
                  t={t}
                />
              )}
              {selectedTab === 'configs' && APIVersion && connectorData && (
                <Configurations
                  current={datasourceData as DataSourceV1}
                  connector={connectorData as ConnectorV1}
                  onError={HandleTabError}
                  onChangeValue={setDatasourceData}
                  t={t}
                />
              )}
              {selectedTab === 'pre-defined-attributes' && APIVersion && connectorData && (
                <PreDefinedAttributes
                  current={connectorData as ConnectorV1}
                  onError={HandleTabError}
                  onChangeValue={setDatasourceData}
                  t={t}
                />
              )}
              {selectedTab === 'user-defined-attributes' &&
                APIVersion &&
                connectorData &&
                (connectorData as ConnectorV1).configs?.additionalAttributes?.allowAdditional && (
                  <UserDefinedAttributes
                    current={datasourceData as DataSourceV1}
                    connector={connectorData as ConnectorV1}
                    onError={HandleTabError}
                    onChangeValue={setDatasourceData}
                    t={t}
                  />
                )}
              {selectedTab === 'sharing' && APIVersion && datasourceData && projectData && (
                <ProjectAccess
                  current={datasourceData as DataSourceV1}
                  sharedOrgs={sharedOrgsList}
                  onChangeValue={setSharedOrgsList}
                  onChangeShareStatus={updateSharedStatus}
                  addNotification={addNotification}
                  organization={organization}
                  projects={projectData || []}
                  onError={HandleTabError}
                  t={t}
                  addNotificationError={addNotificationError}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataSourcePage;
