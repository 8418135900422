export const CHECKBOX_STATES = {
  UNSELECTED: 'UNSELECTED',
  INDETERMINATE: 'INDETERMINATE',
  SELECTED: 'SELECTED',
};

export const EVENT_KEYS = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  SPACE: 'SpaceBar',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  TAB: 'Tab',
};
export const KEYCODE_TAB = 9;
export const KEYCODE_ENTER = 13;
export const KEYCODE_ESCAPE = 27;

export const SIDEBAR_ITEM = 'sidebarItem';
export const ROW = 'S';
export const COLUMN = 'C';
export const COMPONENT = 'F';
