import { FlightRadioButton } from '@flybits/design-system';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'src/helpers/general.helper';
import { FieldsConfig } from 'src/model/connectors/connectors';
import ItemHeader from '../ItemHeader/ItemHeader';
import './RadioPreview.scss';

const RadioPreview = (props: FieldsConfig) => {
  const { inputOpts, onChangeValue, configField } = props;
  const { t } = useTranslation(['integrations']);
  const [value, setValue] = useState(configField ? configField[2]?.configVal : '');
  useEffect(() => {
    typeof onChangeValue === 'function' &&
      onChangeValue({
        field: [configField[0], configField[1]],
        data: { ...configField[2], configVal: value },
      });
  }, [value]);
  return (
    <>
      <ItemHeader {...props} />
      {inputOpts?.length && (
        <div className="radio">
          {inputOpts.length ? (
            inputOpts.map((option, key) => (
              <FlightRadioButton
                label={capitalize(option)}
                key={key}
                checked={value === String(key)}
                onSelect={() => setValue(String(key))}
                value={key}
              />
            ))
          ) : (
            <span>{t('integrations:datasources.details.sections.configs.no_options', 'No options found')}</span>
          )}
        </div>
      )}
    </>
  );
};
export default RadioPreview;
