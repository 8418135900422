import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import {
  FlightButton,
  FlightRadioButton,
  FlightTable,
  FlightModal,
  FlightTextInput,
  FlightSelect,
  FlightTextArea,
} from '@flybits/design-system';
import { GENERIC_INTERNAL_ERROR } from 'src/constants/errors';
import { PROJECT_LEVELS } from 'src/constants/levels';
import { ReactComponent as UsersIcon } from 'src/assets/icons/add-users.svg';
import { ReactComponent as ProjectIcon } from 'src/assets/icons/projects.svg';
import { ReactComponent as SubteamsIcon } from 'src/assets/icons/subteams.svg';
import { ReactComponent as IconError } from 'src/assets/icon-blocked.svg';
import { ReactComponent as IconValid } from 'src/assets/icons/green-check.svg';

import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import './TeamDetails.scss';
import CollapsibleMultiple from 'src/components/Shared/CollapsibleMultiple/CollapsibleMultiple';
import { TeamProjectsModal } from 'src/components/Shared/TeamProjectsModal/TeamProjectsModal';
import { TeamMembersModal } from 'src/components/Shared/TeamMembersModal/TeamMembersModal';
import { getStoredOrganizationId, setStoredOrganizationId } from 'src/helpers/auth.helper';
import { capitalize } from 'src/helpers/general.helper';
import { filterNestedTeams, getFormattedRole, getProjectLevel, getSubTeams } from 'src/helpers/teams.helper';
import { useHistory } from 'react-router';
import { OrganizationUser } from 'src/model/organizations/organizations';
import { UserSelectedProps, UserColValue } from 'src/model/users/users';
import { ProjectSelectedProps, ProjectLevelValue, ProjectColValue } from 'src/model/projects/projects';
import { CreateTeam, Team, TeamProps } from 'src/model/teams/teams';
import TeamService from 'src/services/teams.service';
import { Link } from 'react-router-dom';
import Jdenticon from 'src/components/Jdenticon';
import useNotification from 'src/hooks/useNotification';
import { useTranslation } from 'react-i18next';
interface Props {
  location?: any;
  match?: any;
}

const TeamDetails: React.FunctionComponent<Props> = (props: Props) => {
  const controlTowerContextData = useControlTowerContext();
  const { t } = useTranslation(['translation', 'errors']);
  const { user, organization } = controlTowerContextData;
  const { match } = props;
  if (match.params.id) setStoredOrganizationId(match.params.id);
  const orgID = match.params.id || organization?.id || getStoredOrganizationId();
  const teamID = match.params.team;
  const history = useHistory();
  const { addNotification, addNotificationError } = useNotification();
  if (!orgID) {
    history.push('/organizations');
  }
  if (!teamID) {
    history.goBack();
  }
  const htmlElRef = useRef<HTMLTextAreaElement>(null);
  const [error, setError] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [selectedTab, setTab] = useState(
    window.location.hash === '#teams' ? 'teams' : window.location.hash === '#projects' ? 'projects' : 'members',
  );
  // eslint-disable-next-line
  const [selectedUser, setSelectedUser] = useState<UserSelectedProps | null>(null);
  const [alert, showAlert] = useState('');
  const [updateTable, setUpdateTableData] = useState(true);
  const [tryToDeleteTeam, setTryToDeleteTeam] = useState(false);
  const [updateDescriptionButtonShow, setUpdateDescriptionButtonShow] = useState(false);
  const [teamData, setTeamData] = useState<Team | null>(null);
  const [allTeams, setAllTeamsData] = useState<Team[]>([]);
  const [teamUsers, setTeamUsers] = useState<UserSelectedProps[]>([]);
  const [teamProjects, setProjects] = useState<ProjectSelectedProps[]>([]);
  const [subTeams, setSubTeams] = useState<Team[]>([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [teamNameData, setTeamNameData] = useState('');
  const [teamDescriptionData, setTeamDescriptionData] = useState('');

  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showProjectsModal, setShowProjectsModal] = useState(false);
  const [showMembersModal, setShowMembersModal] = useState(false);
  // eslint-disable-next-line
  const toggleModal = () => {
    setShowTeamModal(!showTeamModal);
  };
  const toggleModalProjects = () => {
    if (showProjectsModal) {
      toggleUpdateTable();
    }
    setShowProjectsModal(!showProjectsModal);
  };
  const cancelModalProjects = () => {
    setShowProjectsModal(false);
  };
  const toggleModalMembers = () => {
    if (showMembersModal) {
      toggleUpdateTable();
    }
    setShowMembersModal(!showMembersModal);
  };
  const cancelModalMembers = () => {
    setShowMembersModal(false);
  };

  const editSearchTerm = (e: any) => {
    e.target.value !== searchTerm && setSearchTerm(e.target.value);
  };

  const toggleAlert = (message = '') => {
    showAlert(message);
  };
  // eslint-disable-next-line
  const toggleUpdateTable = () => {
    setUpdateTableData(true);
  };

  const MAIN_CLASS = 'TeamDetails';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  const UserTableCol = (data: UserColValue) => (
    <div>
      <p>
        <strong aria-label={'Highlighted user: ' + data.name + '.'}>{data.name}</strong>
      </p>
      <p>
        <small aria-label={'E-mail address: ' + data.email + ' .'}>{data.email}</small>
      </p>
    </div>
  );
  const ProjectTableCol = (data: ProjectColValue) => (
    <div className="projectHeader">
      <div className="projectHeader-icon">
        <Jdenticon size="48" value={data.url} />
      </div>
      <div className="projectHeader-content">
        <p>
          <strong aria-label={'Project name: ' + data.name + '.'}>{data.name}</strong>
        </p>
        <p>
          <span className={data.active ? 'active' : 'inactive'}>
            {data.active ? t('translation:general.active', 'Active') : t('translation:general.inactive', 'Inactive')}
          </span>{' '}
          - <span>{data.region}</span>
        </p>
      </div>
    </div>
  );
  const UserRoleCol = (role: Array<string>) => (
    <span
      aria-label={
        'User role:' +
        capitalize(
          t(
            `translation:general.levels.${getFormattedRole(role) || 'member'}.single`,
            getFormattedRole(role) || 'member',
          ),
        ) +
        ' .'
      }
    >
      {capitalize(
        t(
          `translation:general.levels.${getFormattedRole(role) || 'member'}.single`,
          getFormattedRole(role) || 'member',
        ),
      )}
      {getFormattedRole(role) === 'admin' && (
        <small>
          <br />
          {role.length > 1
            ? role
                .map((r) =>
                  capitalize(
                    t(`translation:general.levels.admin.permissions.${r.toLocaleLowerCase()}`, r.toLocaleLowerCase()),
                  ),
                )
                .join(', ')
            : capitalize(
                t(
                  `translation:general.levels.admin.permissions.${role.toString().toLocaleLowerCase()}`,
                  role.toString().toLocaleLowerCase(),
                ),
              )}
        </small>
      )}
    </span>
  );
  const handleLevelSelect = async (item: ProjectLevelValue, id: string) => {
    try {
      const changeLevelResponseObject = await teamsServiceManager.updateTeamProjectLevel(orgID, teamID, id, {
        level: item.key,
      });
      if (
        changeLevelResponseObject &&
        changeLevelResponseObject.status >= 200 &&
        changeLevelResponseObject.status < 400
      ) {
        toggleUpdateTable();
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        console.error(error.response.data.message);
      }
    }
  };
  const LevelCol = (level: number, id: string) => (
    <>
      <FlightSelect
        options={PROJECT_LEVELS.map((item) => {
          return { key: item.level, name: item.name };
        })}
        className="level__select"
        selected={{ key: level, name: getProjectLevel(level) }}
        dropdownMaxHeight="80px"
        width="170px"
        label={'Select a level:'}
        hasLabelAnimation
        handleOptionClick={(e) => handleLevelSelect(e, id)}
      />
    </>
  );
  const userTableHeaders = [
    {
      key: 'userFormatted',
      isVisible: true,
    },
    {
      key: 'name',
      isVisible: false,
    },
    {
      key: 'email',
      isVisible: false,
    },
    {
      key: 'id',
      isVisible: false,
    },
    {
      key: 'organization',
      isVisible: false,
    },
    {
      key: 'roleFormatted',
      isVisible: false,
    },
    {
      key: 'role',
      isVisible: false,
    },
    {
      key: 'permissions',
      isVisible: false,
    },
  ];
  const projectTableHeaders = [
    {
      key: 'projectFormatted',
      isVisible: true,
    },
    {
      key: 'name',
      isVisible: false,
    },
    {
      key: 'deployment',
      isVisible: false,
    },
    {
      key: 'url',
      isVisible: false,
    },
    {
      key: 'id',
      isVisible: false,
    },
    {
      key: 'organization',
      isVisible: false,
    },
    {
      key: 'active',
      isVisible: false,
    },
    {
      key: 'region',
      isVisible: false,
    },
    {
      key: 'team',
      isVisible: false,
    },
    {
      key: 'levelFormatted',
      isVisible: true,
    },
    {
      key: 'removeButton',
      isVisible: true,
    },
    {
      key: 'level',
      isVisible: false,
    },
  ];
  const handleClickTeam = (id: string) => {
    history.push(`/organization/${orgID}/team/${id}`);
    history.go();
  };
  const HeaderBlock = (team: TeamProps) => (
    <>
      <div className="infos" role="button" onClick={() => handleClickTeam(team.id)}>
        <h4>{team.name}</h4>
        <span className="info">{team.info}</span>
      </div>
      <div className={`teams_number ${team.subteams < 1 ? 'zero' : ''}`}>
        {team.subteams}{' '}
        {team.subteams > 1 || team.subteams === 0
          ? t('translation:pages.directory.teams', 'teams').toLocaleLowerCase()
          : t('translation:pages.directory.team', 'team').toLocaleLowerCase()}
      </div>
    </>
  );
  const usersFilteredData = () => {
    return teamUsers.filter(
      (user) =>
        !searchTerm ||
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  };
  const projectsFilteredData = () => {
    return teamProjects.filter((proj) => !searchTerm || proj.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };
  const buildTableItems = (data: any[]) => {
    let depth = -1;
    let row: any = {};
    return data.map(function f(t, i) {
      if (t.subteams && t.subteams.length) {
        depth++;
        row = {
          header: <HeaderBlock id={t.id} subteams={t.subteams.length} name={t.name} info={t.description} />,
          key: i,
          depth: depth,
          name: t.name,
          content: <CollapsibleMultiple arrowPosition="left" items={t.subteams.map(f)} />,
        };
        return row;
      } else {
        row = {
          header: <HeaderBlock id={t.id} subteams={0} name={t.name} info={t.description} />,
          key: i,
          depth: depth,
          name: t.name,
          content: '',
        };
        depth = depth !== -1 ? -1 : depth;
        return row;
      }
    });
  };
  const generateTableData = useCallback((data: any[]) => buildTableItems(data), []);
  const teamsFilteredData = () => {
    return generateTableData(searchTerm ? filterNestedTeams(subTeams, searchTerm) : subTeams);
  };

  const BreadCrumb = () => {
    const teamIds = teamData?.id.split('_') || [];
    teamIds.reduce((acc, item, index) => {
      teamIds[index] = `${acc}${acc ? '_' : ''}${item}`;
      return teamIds[index];
    }, '');
    return (
      <>
        {!!teamIds.length &&
          allTeams.map((item) => {
            if (teamIds.includes(item.id) && item.id !== teamData?.id) {
              return (
                <React.Fragment key={item.id}>
                  <span>/</span>
                  <span
                    role="button"
                    tabIndex={0}
                    className="breadcrumb__link"
                    onClick={() => handleClickTeam(item.id)}
                  >
                    {item.name}
                  </span>
                </React.Fragment>
              );
            }
            return null;
          })}
      </>
    );
  };

  const [usersLoading, setUsersLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [teamsLoading, setTeamsLoading] = useState(true);
  const teamsServiceManager = new TeamService();

  useEffect(() => {
    setTab(window.location.hash === '#teams' ? 'teams' : window.location.hash === '#projects' ? 'projects' : 'members');
    // eslint-disable-next-line
  }, [window.location.hash]);
  useEffect(() => {
    const getTeamDetails = async () => {
      setUsersLoading(true);
      setProjectsLoading(true);
      setTeamsLoading(true);

      try {
        const allTeamsResponseObject = await teamsServiceManager.getTeams(orgID);
        if (allTeamsResponseObject?.data) {
          setAllTeamsData(allTeamsResponseObject?.data);
        }
        const teamSubTeamsResponseObject = await teamsServiceManager.getTeamAndSubteams(orgID, teamID);
        if (teamSubTeamsResponseObject?.data) {
          const teamDataValues = teamSubTeamsResponseObject?.data.filter((selected: Team) => selected.id === teamID)[0];
          setTeamNameData(teamDataValues.name);
          setTeamDescriptionData(teamDataValues.description);
          !teamDataValues.description && setUpdateDescriptionButtonShow(true);
          setTeamData(teamDataValues);
          setSubTeams(getSubTeams(teamSubTeamsResponseObject?.data, teamID));
          setTeamsLoading(false);
        }
        const teamUsersResponseObject = await teamsServiceManager.getTeamMembers(orgID, teamID);
        if (teamUsersResponseObject?.data) {
          setTeamUsers(
            teamUsersResponseObject.data.map((orgUser: OrganizationUser, idx: number) => ({
              key: idx + 1,
              userFormatted: UserTableCol({
                name:
                  orgUser.givenName +
                  ' ' +
                  orgUser.familyName +
                  (user?.username === orgUser.email ? t('translation:pages.projects.details.you', '(you)') : ''),
                email: orgUser.email,
              }),
              roleFormatted: UserRoleCol(orgUser.roles),
              name: orgUser.givenName + ' ' + orgUser.familyName,
              email: orgUser.email,
              id: orgUser.id,
              organization: orgUser.organization,
              role: '',
              permissions: '',
            })),
          );

          setUsersLoading(false);
        }
        const teamProjectsResponseObject = await teamsServiceManager.getTeamProjects(orgID, teamID);
        if (teamProjectsResponseObject?.data) {
          setProjects(
            teamProjectsResponseObject?.data.map(
              ({ level, deployment, subdomain, name, active }: any, idx: number) => ({
                key: idx + 1,
                projectFormatted: ProjectTableCol({
                  url: subdomain,
                  name: name,
                  id: `${deployment}_${subdomain}`,
                  active: active,
                  region: capitalize(deployment),
                }),
                levelFormatted: LevelCol(level, `${deployment}_${subdomain}`),
                name: name,
                active: active,
                id: `${deployment}_${subdomain}`,
                organization: orgID,
                team: teamID,
                region: deployment,
                deployment: deployment,
                url: subdomain,
                level: level || 5,
                removeButton: null,
              }),
            ),
          );

          setProjectsLoading(false);
        }

        setUpdateTableData(false);
      } catch (error) {
        console.error(error?.response);
        if (error?.response?.data?.message) {
          setError(error.response.data.message);
        } else {
          setError(t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR));
        }
        setUpdateTableData(false);
        history.replace(`/organization/${orgID}/directory#teams`);
      }
    };
    updateTable && teamID && getTeamDetails();
    // eslint-disable-next-line
  }, [orgID, teamID, history, updateTable]);

  const handleTeamUpdate = async (teamRequestObject: CreateTeam) => {
    setError('');
    setIsSaved(false);
    try {
      const teamResponseObject = await teamsServiceManager.updateTeam(orgID, teamID, teamRequestObject);
      if (teamResponseObject && teamResponseObject.status === 200) {
        setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
        }, 3000);
        return true;
      }
    } catch (e) {
      console.error(e);
      let errMsg = e?.response?.data?.message || t('errors:GENERIC_INTERNAL_ERROR', GENERIC_INTERNAL_ERROR);
      if (errMsg.indexOf('Invalid') > -1) {
        errMsg = t('errors:TEAM_INVALID_NAME_DESC', 'Invalid team name or description');
      }
      setError(errMsg);
      addNotificationError(errMsg);
      return false;
    }
  };
  const handleTeamNameChange = (e: any) => {
    e.target.value && setTeamNameData(e.target.value);
  };
  const handleTeamDescriptionChange = (e: any) => {
    e.target.value && setTeamDescriptionData(e.target.value);
  };
  const handleTeamNameSave = async () => {
    setLoading(true);
    await handleTeamUpdate({
      name: teamNameData,
      description: teamDescriptionData,
    });
    setLoading(false);
  };
  const handleTeamDescriptionSave = async () => {
    setLoading(true);
    await handleTeamUpdate({
      name: teamNameData,
      description: teamDescriptionData,
    });
    setLoading(false);
  };
  const handleDeleteTeam = async (team: string) => {
    setLoading(true);
    try {
      const teamResponseObject = await teamsServiceManager.deleteTeam(orgID, team);
      if (teamResponseObject && teamResponseObject.status === 204) {
        setTryToDeleteTeam(false);
        addNotification(t('translation:pages.teams.messages.remove_success', 'Team removed successfully.'));
        setLoading(false);
        return true;
      }
    } catch (error) {
      console.error(error);
      addNotificationError(
        t('translation:pages.teams.messages.remove_error', 'Team could not be removed. Please try again later.'),
      );
      setLoading(false);
      return false;
    }
  };
  return (
    <>
      <TeamProjectsModal
        openModal={showProjectsModal}
        toggleModal={toggleModalProjects}
        cancelModal={cancelModalProjects}
        team={teamID}
        organization={orgID}
        projects={teamProjects}
        t={t}
      />
      <TeamMembersModal
        openModal={showMembersModal}
        toggleModal={toggleModalMembers}
        cancelModal={cancelModalMembers}
        team={teamID}
        organization={orgID}
        users={teamUsers}
        t={t}
      />
      <FlightModal
        isVisible={tryToDeleteTeam}
        toggleModalShown={() => setTryToDeleteTeam(false)}
        scrollable={false}
        size="small"
        warning={false}
        className={`${MAIN_CLASS}__alert`}
        header={
          <span>{t('translation:pages.teams.modal_delete.header', 'Are you sure you want to delete this team?')}</span>
        }
        footer={
          <div className={`${MAIN_CLASS}__alert_footer`}>
            <FlightButton
              type="button"
              theme="secondary"
              label={t('translation:general.buttons.cancel', 'Cancel')}
              onClick={() => {
                setTryToDeleteTeam(false);
              }}
            />
            <FlightButton
              type="button"
              label={t('translation:pages.teams.btn-delete', 'Delete team')}
              onClick={async () => {
                const res = await handleDeleteTeam(teamID);
                if (res) {
                  history.replace(`/organization/${orgID}/directory#teams`);
                }
              }}
            />
          </div>
        }
      />
      <div className={`${MAIN_CLASS}__breadcrumbs`}>
        <Link replace to={`/organization/${orgID}/directory#teams`}>
          {t('translation:pages.teams.title', 'Teams')}
        </Link>
        <BreadCrumb /> <span>/</span> <span>{teamData?.name}</span>
      </div>
      <div className={MAIN_CLASS}>
        <FlightModal
          isVisible={!!alert}
          toggleModalShown={() => toggleAlert('')}
          scrollable={false}
          size="small"
          warning={false}
          className={`${MAIN_CLASS}__alert`}
          header={<span>{alert}</span>}
          footer={
            <div className={`${MAIN_CLASS}__alert_footer`}>
              <FlightButton
                type="primary"
                label={t('translation:pages.directory.got_it', 'Got It!')}
                onClick={() => toggleAlert('')}
              />
            </div>
          }
        />
        <aside className={`${MAIN_CLASS}__left-panel`} tabIndex={0}>
          <div>
            {!teamData ? (
              <LoadingIcon type="block" />
            ) : (
              <>
                {!loading ? (
                  error ? (
                    <IconError />
                  ) : isSaved ? (
                    <IconValid />
                  ) : (
                    ''
                  )
                ) : (
                  <LoadingIcon width={20} height={20} visible={loading} className="icon-floating" />
                )}
                <FlightTextInput
                  value={teamNameData}
                  width="100%"
                  type="text"
                  name="team-name"
                  className={`edit-input`}
                  onChange={handleTeamNameChange}
                  onBlur={handleTeamNameSave}
                />
              </>
            )}
            <div>
              {updateDescriptionButtonShow && (
                <FlightButton
                  type="button"
                  theme="link"
                  size="small"
                  label={t('translation:pages.teams.btn-add-desc', '+ Add description')}
                  onClick={() => {
                    setUpdateDescriptionButtonShow(false);
                    htmlElRef.current && htmlElRef.current.focus();
                  }}
                />
              )}
              {!updateDescriptionButtonShow && (
                <>
                  <FlightTextArea
                    hideCharCounter={true}
                    label=""
                    width="100%"
                    name="team-description"
                    value={teamDescriptionData}
                    className={`edit-textarea`}
                    onChange={handleTeamDescriptionChange}
                    onBlur={handleTeamDescriptionSave}
                  />
                </>
              )}
            </div>
          </div>
          <div className="button_wrapper">
            <FlightButton
              type="secondary"
              className={`button_delete`}
              size="medium"
              theme="secondary"
              iconLeft="trashCan"
              label={t('translation:pages.teams.btn-delete', 'Delete team')}
              onClick={(e) => {
                e.preventDefault();
                setTryToDeleteTeam(true);
              }}
            />
          </div>
        </aside>

        <div className={`${CONTENT_CLASS} full-width`}>
          <div className={`${CONTENT_CLASS}__wrapper with-separator`}>
            <div className={`${CONTENT_CLASS}__tabs-header`} role="tablist">
              <span role="tab" aria-selected={selectedTab === 'members'}>
                <FlightRadioButton
                  label={t('translation:pages.teams.tab_members', { num: teamUsers.length || 0 })}
                  checked={selectedTab === 'members'}
                  className={`${CONTENT_CLASS}__tabs-header-item`}
                  onSelect={() => {
                    setTab('members');
                  }}
                  value="members"
                />
              </span>
              <span role="tab" aria-selected={selectedTab === 'users'}>
                <FlightRadioButton
                  label={t('translation:pages.teams.tab_projects', { num: teamProjects.length || 0 })}
                  checked={selectedTab === 'projects'}
                  className={`${CONTENT_CLASS}__tabs-header-item`}
                  onSelect={() => {
                    setTab('projects');
                  }}
                  value="projects"
                />
              </span>
              <span role="tab" aria-selected={selectedTab === 'teams'}>
                {' '}
                <FlightRadioButton
                  label={t('translation:pages.teams.tab_teams', { num: subTeams.length || 0 })}
                  checked={selectedTab === 'teams'}
                  className={`${CONTENT_CLASS}__tabs-header-item`}
                  onSelect={() => {
                    setTab('teams');
                  }}
                  value="teams"
                />
              </span>
            </div>
          </div>
          <div className={`${CONTENT_CLASS}__wrapper`} aria-atomic>
            <div className={`${CONTENT_CLASS}__filters`}>
              {(selectedTab === 'members' && teamUsers.length) ||
              (selectedTab === 'teams' && subTeams.length) ||
              (selectedTab === 'projects' && teamProjects.length) ? (
                <>
                  <FlightTextInput
                    placeholderText=""
                    width="292px"
                    hasClearIcon
                    trailingIcon="search"
                    type="text"
                    name="table-filter"
                    value={searchTerm}
                    onChange={editSearchTerm}
                    ariaLabel={`Filter ${selectedTab}`}
                    label={`Filter ${selectedTab}`}
                    isLabelAlwaysOn
                  />
                  <div className="button_right">
                    {selectedTab === 'members' && (
                      <FlightButton
                        label={t('translation:pages.teams.btn-manage-members', 'Manage members')}
                        ariaLabel={t(
                          'translation:pages.teams.btn-manage-members_aria',
                          'Manage members, opens a dialog',
                        )}
                        size="medium"
                        theme="secondary"
                        onClick={() => {
                          setShowMembersModal(true);
                        }}
                      />
                    )}
                    {selectedTab === 'projects' && (
                      <FlightButton
                        label={t('translation:pages.teams.btn-manage-projects', 'Manage projects')}
                        ariaLabel={t(
                          'translation:pages.teams.btn-manage-projects_aria',
                          'Manage projects, opens a dialog',
                        )}
                        size="medium"
                        theme="secondary"
                        onClick={() => {
                          setShowProjectsModal(true);
                        }}
                      />
                    )}
                    {selectedTab === 'teams' && (
                      <FlightButton
                        label={t('translation:pages.teams.btn-create', 'Create team')}
                        size="medium"
                        theme="secondary"
                        onClick={() => {
                          history.push(`/organization/${orgID}/teams/new?parent=${teamID}`);
                        }}
                      />
                    )}
                  </div>
                </>
              ) : null}
            </div>
            {selectedTab === 'members' && (
              <div
                className={`${CONTENT_CLASS}__tabs-content`}
                role="tabpanel"
                aria-hidden="false"
                aria-label={'Members tab'}
                tabIndex={0}
              >
                {!usersLoading && !teamUsers.length ? (
                  <div className={`${CONTENT_CLASS}__tabs-empty`}>
                    <UsersIcon />
                    <div>
                      {t('translation:pages.teams.messages.no_members', 'No members have been added to this team yet')}
                    </div>
                    <FlightButton
                      label={t('translation:pages.teams.btn-add-members', 'Add members')}
                      ariaLabel={t('translation:pages.teams.btn-add-members_aria', 'Add members, opens a dialog')}
                      size="medium"
                      theme="primary"
                      onClick={() => {
                        setShowMembersModal(true);
                      }}
                    />
                  </div>
                ) : (
                  <FlightTable
                    className={`${CONTENT_CLASS}__tabs-content-table`}
                    allowRowSelect
                    isLoading={usersLoading}
                    hasError={false}
                    emptyState={
                      <div className={`${CONTENT_CLASS}__tabs-content-table-no-results`}>
                        <p>
                          <strong>0</strong>{' '}
                          {t('translation:pages.teams.messages.no_users_found', 'users found in this team')}{' '}
                          {searchTerm && (
                            <>
                              {t('translation:pages.teams.messages.with_filter', 'with the filter')} &ldquo;
                              <strong>{searchTerm}</strong>&rdquo;
                            </>
                          )}
                        </p>
                      </div>
                    }
                    errorState={<span>{error}</span>}
                    tableData={usersFilteredData()}
                    tableHeaders={userTableHeaders}
                    handleDataSelect={(e) => {
                      setSelectedUser(e);
                    }}
                    hasPaginationBeforeTable={false}
                    isShowHeader={false}
                  />
                )}
              </div>
            )}
            {selectedTab === 'projects' && (
              <div
                className={`${CONTENT_CLASS}__tabs-content`}
                role="tabpanel"
                aria-hidden="false"
                aria-label={'Projects tab'}
                tabIndex={0}
              >
                {!projectsLoading && !teamProjects.length ? (
                  <div className={`${CONTENT_CLASS}__tabs-empty`}>
                    <ProjectIcon />
                    <div>
                      {t(
                        'translation:pages.teams.messages.no_projects',
                        'No projects have been added to this team yet',
                      )}
                    </div>
                    <FlightButton
                      label={t('translation:pages.teams.btn-add-projects', 'Add projects')}
                      ariaLabel={t('translation:pages.teams.btn-add-projects_aria', 'Add projects, opens a dialog')}
                      size="medium"
                      theme="primary"
                      onClick={() => {
                        setShowProjectsModal(true);
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <div className="info-text">
                      {t('translation:pages.teams.learn_more_projects', 'To learn more about project access levels')}{' '}
                      <Link
                        to={{
                          pathname: t(
                            'translation:pages.teams.learn_more_projects_url',
                            'https://flybits.gitbook.io/product-guide/-MCJQnb9xKBWvcUyw_9V/for-owners/managing-teams#access-levels',
                          ),
                        }}
                        target="_blank"
                      >
                        {t('translation:pages.teams.learn_more_projects_label', 'click here')}
                      </Link>
                    </div>
                    <FlightTable
                      className={`${CONTENT_CLASS}__tabs-content-table`}
                      isLoading={projectsLoading}
                      hasError={false}
                      emptyState={
                        <div className={`${CONTENT_CLASS}__tabs-content-table-no-results`}>
                          <p>
                            <strong>0</strong>{' '}
                            {t('translation:pages.teams.messages.no_projects_found', 'projects found in this team')}{' '}
                            {searchTerm && (
                              <>
                                {t('translation:pages.teams.messages.with_filter', 'with the filter')} &ldquo;
                                <strong>{searchTerm}</strong>&rdquo;
                              </>
                            )}
                          </p>
                        </div>
                      }
                      errorState={<span>{error}</span>}
                      tableData={projectsFilteredData()}
                      tableHeaders={projectTableHeaders}
                      hasPaginationBeforeTable={false}
                      isShowHeader={false}
                    />
                  </>
                )}
              </div>
            )}
            {selectedTab === 'teams' && (
              <div
                className={`${CONTENT_CLASS}__tabs-content`}
                role="tabpanel"
                aria-hidden="false"
                aria-label={'Teams tab'}
                tabIndex={0}
              >
                {!teamsLoading && !subTeams.length ? (
                  <div className={`${CONTENT_CLASS}__tabs-empty`}>
                    <SubteamsIcon />
                    <div>
                      {t('translation:pages.teams.messages.no_teams', 'No sub teams have been added to this team yet')}
                    </div>
                    <FlightButton
                      label={t('translation:pages.teams.btn-create', 'Create team')}
                      size="medium"
                      theme="primary"
                      onClick={() => {
                        history.push(`/organization/${orgID}/teams/new?parent=${teamID}`);
                      }}
                    />
                  </div>
                ) : (
                  <div className={`${CONTENT_CLASS}__tabs-teams`}>
                    {teamsLoading ? (
                      <LoadingIcon width={40} height={40} visible={teamsLoading} />
                    ) : (
                      <>
                        <CollapsibleMultiple
                          arrowPosition="left"
                          className={`${CONTENT_CLASS}__tabs-teams-list`}
                          items={teamsFilteredData()}
                        />
                        {searchTerm && !teamsFilteredData().length && (
                          <div className={`${CONTENT_CLASS}__tabs-content-table-no-results`}>
                            <p>
                              <strong>0</strong>{' '}
                              {t('translation:pages.teams.messages.no_teams_found', 'sub teams found in this team')}{' '}
                              {searchTerm && (
                                <>
                                  {t('translation:pages.teams.messages.with_filter', 'with the filter')} &ldquo;
                                  <strong>{searchTerm}</strong>&rdquo;
                                </>
                              )}
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamDetails;
