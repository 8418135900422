import React, { useEffect } from 'react';
import { Team } from 'src/model/teams/teams';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import './Treeview.scss';
import { getSubTeams } from 'src/helpers/teams.helper';

interface TreeviewProps {
  className?: string;
  title?: string;
  data: any;
  teams: Team[];
}

const Treeview = (props: TreeviewProps) => {
  const { data, teams, title } = props;
  const buildSubTreesArray = () => {
    const treeData = data?.map((item: any, index: number, arr: any) => {
      if (index < 1) return item;
      const hasParent = !(arr[index - 1].id && item.id.indexOf(arr[index - 1].id) > -1) ? false : true;
      if (hasParent) {
        item.parent = arr[index - 1].id;
      }
      return item;
    });
    return getSubTeams(treeData);
  };

  const ListItem = (node: any) => {
    let children = null;
    if (node.item.subteams) {
      children = (
        <ul role="group">
          {node.item.subteams.map((i: any) => (
            <ListItem item={i} key={i.id} />
          ))}
        </ul>
      );
    }
    const team = teams.find(({ id }) => id === node.item.id) || { id: node.item.id, name: '' };
    return (
      <li role="treeitem" aria-expanded="true">
        <span tabIndex={0}>{node.item.name ? node.item.name : team.name}</span>
        {children}
      </li>
    );
  };
  const generateTreeData = () => {
    const tree = buildSubTreesArray();
    return (
      <ul role="tree">
        {tree.map((i) => (
          <ListItem item={i} key={i.id} />
        ))}
      </ul>
    );
  };

  useEffect(() => {
    if (!data) return;
    generateTreeData();
    // eslint-disable-next-line
  }, [data]);
  return data && data.length ? (
    <section className="Treeview" tabIndex={0} title={title || 'treeview'} aria-label={title || 'treeview'}>
      {generateTreeData()}
    </section>
  ) : (
    <LoadingIcon width={40} height={40} visible={!data} />
  );
};
export default Treeview;
