import localforage from 'localforage';
import { RankingItem } from 'src/model/ranking';

const store = localforage.createInstance({ name: 'ranking' });

/**
 * An implementation of elo ranking system
 * Explanation about the system: https://en.wikipedia.org/wiki/Elo_rating_system
 */
const STARTING_SCORE = 1000;
const K_FACTOR = 16;

/**
 * Creates new ranking item with initial values.
 *
 * @param id Ranking item identifier
 * @returns The newly created ranking item
 */
const createNewRankingItem = (id: string): RankingItem => {
  return {
    id,
    score: STARTING_SCORE,
  };
};

/**
 *
 * @param winner The winner ranking item
 * @param others An array with all ranking items, but the winner
 * @returns An array with all ranking items with theis scores updated.
 */
const updateRankingList = (winner: RankingItem, others: RankingItem[]): RankingItem[] => {
  const winnerScore = winner?.score || STARTING_SCORE;

  const updatedWinner = { ...winner };
  const updatedOthers = [...others].map((other) => {
    const expectedScore = 1 / (1 + Math.pow(10, (other.score - winnerScore) / 400));
    const delta = K_FACTOR * (1 - expectedScore);
    updatedWinner.score += delta;
    other.score -= delta;
    return other;
  });

  return [...updatedOthers, updatedWinner];
};

/**
 * Updates the ranking, increasing the winner
 * item score and decreasing the others.
 *
 * @param rankingName The ranking name
 * @param winnerId Rank item identifier
 * @returns The updated ranking
 */
export async function updateRanking(rankingName: string, winnerId: string): Promise<RankingItem[]> {
  let ranking = await store.getItem<RankingItem[]>(rankingName);
  if (!ranking) {
    ranking = [createNewRankingItem(winnerId)];
    return await store.setItem(rankingName, ranking);
  }

  const winner = ranking.find((item) => item.id === winnerId) || createNewRankingItem(winnerId);
  const others = ranking.filter((item) => item.id !== winnerId);

  const updatedRankingList = updateRankingList(winner, others);
  return await store.setItem(rankingName, updatedRankingList);
}

/**
 * Returns the complete ranking by ranking name.
 *
 * @param rankingName The ranking name
 * @returns Promise<RankingItem[] | null>
 */
export async function getRankingByName(rankingName: string): Promise<RankingItem[] | null> {
  return await store.getItem<RankingItem[]>(rankingName);
}

/**
 * Returns an ASC sorted array of IDs by ranking score.
 *
 * @param rankingName The ranking name
 * @returns Promise<string[]>
 */
export async function getSortedRankArrayByName(rankingName: string): Promise<string[]> {
  const ranking = await store.getItem<RankingItem[]>(rankingName);
  return (ranking || []).sort((itemA, itemB) => itemA.score - itemB.score).map((item) => item.id);
}
