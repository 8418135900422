import { useState, useEffect, useCallback, useRef } from 'react';

// Based on: https://github.com/rottitime/react-hook-window-message-event
/*
The hook useMessage takes two arguments.
The first is the name of the event you want to listen to.
The second is a optional callback which can be fired once a message has been recieved

The callback provides two arguments. As per the example below

send can be used to ping a message back to the source
payload gives you the data that was sent by the source
const ExampleComponent = () => {
  //Listen for the message 'authenticate' and then fire a callback
  useMessage('authenticate', (send, payload) => {
    send({ type: 'authenticate', payload: { success: true } })
  })

  return <div>Hellow world</div>
}
Send a message to parent window/iframe
The hook also takes usage of the Window.postMessage() to allow you to post messages to the parent window/tab/iframe.

In the example below, we are sending the message 'Hellow world' to the parent window

const ExampleComponent = () => {
  //Listen for the message 'authenticate' and then fire a callback
  const { sendToParent } = useMessage()
  sendToParent('authenticate')
  return <div>Hellow world</div>
}
*/

type CallbackProps = {
  origin: any;
  source: any;
  data: any;
};

const postMessage = (data: any, target: any, origin = '*') => target.postMessage(data, origin);
const useMessage = (watch: any, eventHandler?: any) => {
  const [historyData, setHistoryData] = useState<any>([]);
  const [origin, setOrigin] = useState();
  const [source, setSource] = useState();

  const originRef = useRef();
  const sourceRef = useRef();

  originRef.current = origin;
  sourceRef.current = source;

  const sendToSender = (data: any) => postMessage(data, sourceRef.current, originRef.current);

  const sendToParent = (data: any) => {
    const { opener } = window;
    if (!opener) throw new Error('Parent window has closed');
    postMessage(data, opener);
  };

  const onWatchEventHandler = useCallback(
    ({ origin, source, data }: CallbackProps) => {
      const { type, payload } = data;
      if (type === watch) {
        setSource(source);
        setOrigin(origin);
        setHistoryData((old: any) => [...old, payload]);
        eventHandler(sendToSender, payload);
      }
    },
    [watch, eventHandler, setSource, setOrigin],
  );

  useEffect(() => {
    window.addEventListener('message', onWatchEventHandler);
    return () => window.removeEventListener('message', onWatchEventHandler);
  }, [watch, source, origin, onWatchEventHandler]);

  return { historyData, sendToParent };
};

export default useMessage;
