import { PROJECT_LEVELS } from 'src/constants/levels';

export const getFormattedRole = (roles: Array<string> = []): string => {
  if (typeof roles === 'object') {
    if (roles.length > 0) {
      if (
        roles.includes('teams') ||
        roles.includes('projects') ||
        roles.includes('connectors') ||
        roles.includes('datasources')
      ) {
        return 'admin';
      }
      return roles.includes('owner') ? 'owner' : 'error';
    }
    return 'member';
  }
  return 'member';
};

export const getSubTeams = (arr: any[], parent = '') => {
  const subteams: any[] = [];
  for (let i = 0; i < arr.length; ++i) {
    if (arr[i].parent === parent || (!arr[i].parent && !parent)) {
      const subTeam = getSubTeams(arr, arr[i].id);
      if (subTeam.length) {
        arr[i].subteams = subTeam;
      }
      subteams.push(arr[i]);
    }
  }
  return subteams;
};
export const getProjectLevel = (lvl: number) => {
  return (
    PROJECT_LEVELS.map((item) => {
      if (item.level === lvl) {
        return item.name;
      }
      return false;
    }) || 'Member'
  );
};
export const filterNestedTeams = (object: any[], search: string) => {
  const teams = object;
  return teams.filter(function f(o) {
    if (o.name.includes(search)) return true;
    if (o.subteams) {
      return (o.subteams = o.subteams.filter(f)).length;
    }
    return false;
  });
};

export const tableHeaders = [
  {
    key: 'userFormatted',
    isVisible: true,
  },
  {
    key: 'name',
    isVisible: false,
  },
  {
    key: 'email',
    isVisible: false,
  },
  {
    key: 'id',
    isVisible: false,
  },
  {
    key: 'organization',
    isVisible: false,
  },
  {
    key: 'roleFormatted',
    isVisible: true,
  },
  {
    key: 'role',
    isVisible: false,
  },
  {
    key: 'permissions',
    isVisible: false,
  },
];
