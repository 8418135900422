import React, { FunctionComponent, useEffect } from 'react';
import { FlightButton } from '@flybits/design-system';
import './TermsOfService.scss';
import { useHistory, Link } from 'react-router-dom';

interface Props {
  location: any;
}
// Translation
import { useTranslation } from 'react-i18next';
import SvgFlybitsLogo from 'src/assets/FlybitsLogo';

const TermsOfService: FunctionComponent<Props> = () => {
  const MAIN_CLASS = 'static';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  const { t } = useTranslation(['translation']);
  const history = useHistory();
  useEffect(() => {
    // Set dynamic document title
    document.title = 'Terms of service - Control Tower';
    history.listen(() => {
      // check for sw updates on page change
      navigator.serviceWorker.getRegistrations().then((regs) => regs.forEach((reg) => reg.update()));
    });
  }, []);

  return (
    <div className={MAIN_CLASS}>
      <div className={CONTENT_CLASS}>
        <div className={`${CONTENT_CLASS}__wrapper`}>
          <h1 className={`${CONTENT_CLASS}__heading-text`}>
            <FlightButton
              type="primary"
              className={`${CONTENT_CLASS}__button_link ${CONTENT_CLASS}__button_link-back`}
              theme="link"
              size="medium"
              label={`< ${t('translation:general.buttons.back', 'Back')}`}
              onClick={() => {
                history.goBack();
              }}
            />
            Terms of service
          </h1>
          <article className={`${CONTENT_CLASS}__infos-wrapper`}>
            <p>
              <SvgFlybitsLogo tabIndex={-1} role="logo" viewBox="0 0 104 30" {...{ className: '' }} />
            </p>
            <p>
              Effective Date: 25 May 2018
              <br />
              Version: 2.0
            </p>
            <p>
              Please read these “Terms of Service” fully and carefully before using{' '}
              <a
                href={'https://www.flybits.com'}
                target="_blank"
                rel="noopener noreferrer"
                title="This link opens in a new tab"
              >
                https://www.flybits.com
              </a>{' '}
              (the “Flybits Platform”) and any of the services, features, or applications made available by Flybits
              (“Flybits”, “we”, “us”) or any other Flybits services that links to these Terms of Service (collectively,
              the “Services”).
            </p>
            <p>
              These Terms of Service incorporate Flybits Inc.’s <Link to={'/privacy-policy'}>Privacy Policy</Link>,
              which is hereby made a part of these Terms of Service.
            </p>
            <p>
              These Terms of Service set forth the legally binding terms and conditions for your use of the Services.
            </p>
            <p>
              By “signing up”, you or the company or entity that you represent (“you,” “your,” “yours” or “customer”)
              are consenting to be bound by and are becoming a party to these Terms of Service. You are also
              representing and warranting that the individual signing up is authorized to enter into this agreement and
              bind such entity. Your continued use of any portion of the services shall also constitute assent to the
              terms of these Terms of Service. If you are using the services on behalf of a company or other entity,
              then all references to “you” or “your” herein shall refer to both the individual and the entity.
            </p>
            <p>
              If you do not unconditionally agree to all of the terms of these Terms of Service, you will have no right
              to use the Services (and you should immediately cease all such use).
            </p>
            <h2>Acceptance of Terms of Service</h2>
            <p>
              By registering for and/or using any of the Services in any manner, including visiting or using the Flybits
              Platform and/or Services, you agree to these Terms of Service, and all other operating rules, policies,
              and procedures that we may publish from time to time through the Flybits Platform and/or Services, each of
              which is incorporated by reference, and each of which may be updated from time to time without notice to
              you.
            </p>
            <p>
              Certain use of the Services may be subject to additional terms and conditions specified by us from time to
              time; your use of such Services is subject to those additional terms and conditions, which are
              incorporated into these Terms of Service by this reference.
            </p>
            <p>
              These Terms of Service apply to all users of the Services who use the Flybits Platform and/or Services in
              any capacity. Please note that if you have executed a separate written agreement with Flybits regarding
              your use of our Services, then that agreement applies to those Services and supersedes the Terms of
              Service.
            </p>
            <p>
              Arbitration notice and class action waiver: except for certain types of disputes described in the
              arbitration section below, you agree that disputes between you and us will be resolved by binding,
              individual arbitration and you waive your right to participate in a class action lawsuit or class-wide
              arbitration.
            </p>
            <h2>Eligibility</h2>
            <p>
              You represent and warrant that you are at least 13 years of age. If you are under age 13, you may not,
              under any circumstances or for any reason, register for an Account (defined below) or use the Services.
              Furthermore, you must be at least 18 years of age to use the Flybits Platform and/or Services in any
              capacity. We may, in our sole discretion, refuse to offer the Services to any person or entity and change
              its eligibility criteria at any time. You are solely responsible for ensuring that these Terms of Service
              and your use of the Services (a) are in compliance with all laws, rules and regulations applicable to you
              and (b) do not violate any other agreement to which you are a party. The right to access the Services is
              revoked where these Terms of Service or use of the Services is prohibited or to the extent offering, sale
              or provision of the Services conflicts with any applicable law, rule or regulation. Further, the Services
              are offered only for your use, and not for the use or benefit of any third party.
            </p>
            <h2>Registration</h2>
            <p>
              To sign up for the Services, you must register for an account on the Services (an “Account”). You must
              provide accurate and complete information for your Account. You may never use another person’s user
              Account without permission from Flybits, or share your Account or access credentials with any other
              individual or entity. “User” refers to an individual whom you authorize to use the Services. Users may
              include, for example, your employees, business affiliates, or any user of the Flybits Platform. You should
              never publish, distribute, or post login information for your Account. A User’s access credentials may not
              be shared with any other individual or entity for purposes of using the Services. You are responsible for
              maintaining the security of your Account, passwords (including, but not limited to, administrative and
              user passwords). You shall be responsible for acts, omissions, or breaches hereunder by any of your Users
              or any other individuals using your Account or credentials for the Services. You must keep your Account
              information updated, and you must notify us immediately of any change in your eligibility to use the
              Services or breach of security or unauthorized use of your Account. Users may have the ability to invite
              other designated users to their Flybits project, unless the applicable Account settings restrict this
              functionality. Please note that if you are a User of an Account purchased by your employer or another
              entity, then these Terms of Service allow such person or entity to provision additional services, or to
              change or limit your access to the Services at any time. It is up to that person or entity to inform you
              of any particular rules, requirements or limitations regarding your use beyond those set forth in these
              Terms of Service. You agree to look solely to such person or entity regarding any dispute relating to such
              additional rules, requirements, or limitations.
            </p>
            <h2>Service</h2>
            <p>
              Subject to these Terms of Service, Flybits (i) grants to Customer a worldwide, non-exclusive,
              non-sublicensable, and non-transferable license to use the Flybits Platform and/or Services solely for
              purposes of using the Flybits Platform or Services for Customer’s internal use, unless otherwise stated in
              a written agreement with Flybits and Customer.
            </p>
            <h2>Restrictions on Use the Services</h2>
            <p>
              Customer shall not directly or indirectly (i) sell, license, sublicense, distribute, copy, rent or lease
              the Flybits Platform and/or Services, or otherwise make the Flybits Platform and/or Services available to,
              or use the Flybits Platform and/or Services for the benefit of, any third party, or transfer any of the
              rights received hereunder; (ii) interfere with or disrupt the integrity or performance of the Flybits
              Platform and/or Services or any third party data contained therein or attempt to gain unauthorized access
              to the Flybits Platform and/or Services or its related systems or networks; (iii) copy, modify, translate
              or create derivative works based on the Flybits Platform and/or Services or any underlying software or any
              part, feature, function or user interface thereof, including by framing or mirroring any part of any of
              the Flybits Platform and/or Services; (iv) access or use the Flybits Platform and/or Services for
              benchmarking or similar competitive analysis purposes or in order to build a competitive product or
              service; (v) decompile, disassemble, decipher or reverse engineer the Flybits Platform and/or Services, or
              otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Flybits
              Platform and/or Services, (except to the extent such restriction is prohibited by applicable statutory
              law); (vi) remove any copyright notices, information and restrictions contained in the Flybits Platform
              and/or Services; (vii) bypass, circumvent or attempt to bypass or circumvent any measures Flybits may use
              to prevent or restrict access to the Flybits Platform and/or Services (or other accounts, computer systems
              or networks connected to the Flybits Platform and/or Services) or take any action that imposes or may
              impose (as determined by us in Flybits sole discretion) an unreasonable or disproportionately large load
              on Flybits (or Flybits third party providers’) infrastructure; (viii) use manual or automated software,
              devices, or other processes to “crawl” or “spider” any page of the Flybits Platform and/or Services; or
              (ix) otherwise take any action in violation of Flybits guidelines and policies, including these Flybits
              Platform and/or Services.
            </p>
            <h2>Availability</h2>
            <p>
              Flybits is not and will not be responsible or liable for any failure in the Flybits Platform and/or
              Services resulting from or attributable to (i) failures in any telecommunications, network or other
              service or equipment outside of Flybits facilities; or (iii) any force majeure or other cause beyond
              Flybits reasonable control. Flybits reserves the right, but does not have any obligation, to remove, edit,
              modify or block access to the Flybits Platform and/or Services in its reasonable discretion, at any time,
              without notice to Customer.
            </p>
            <h2>Proprietary Rights</h2>
            <p>
              As between the parties, all right, title and interest in and to the Flybits Platform and/or Services, and
              all copies, modifications and derivative works thereof is and will remain the sole and exclusive property
              of Flybits. Except as expressly set forth herein, no license or other right, title or interest in the
              Flybits Platform and/or Services is granted by Flybits to Customer.
            </p>
            <h2>Aggregate Data</h2>
            <p>
              Notwithstanding anything to the contrary set forth herein, Flybits may collect and use data regarding the
              use and performance of the Service in anonymized and aggregated form, to analyze and improve the Service
              and for distribution in general benchmarking data and industry reports, provided that any reported user
              data is aggregated and anonymized such that no personally identifying information of Customer or its users
              is revealed.
            </p>
            <h2>Representations and Warranties</h2>
            <p>
              Customer represents, warrants and covenants that (i) all account information, log data, session activity
              and other electronic information (“Customer Data”) has been collected and provided in accordance, and is
              compliant with all applicable laws, rules and regulations; (ii) Customer owns all rights, title and
              interest in and to the Customer Data, or has otherwise secured all necessary rights in the Customer Data,
              as may be necessary to permit the access, use and distribution thereof as contemplated by these Terms of
              Service; (iii) Customer shall only use the Flybits Platform and/or Services in accordance with all
              applicable laws, rules and regulations, these Terms of Service and any relevant documentation provided by
              Flybits; and (iv) Customer will not, and will not permit any third party, to upload, download, post,
              submit, provide, transmit, distribute or otherwise make available to or through the Flybits Platform
              and/or Services any Customer Data that (A) is unlawful, infringing, deceptive, fraudulent, invasive of
              another’s privacy, tortious, obscene, or that otherwise violates any other right of any third party,
              including any intellectual property, proprietary or privacy rights, or that is otherwise inappropriate, as
              determined by Flybits in its sole discretion; (B) contains any viruses, code, files or programs designed
              or intended to disrupt, damage, limit or interfere with the proper function of any software, hardware, or
              telecommunications equipment or that is or can be otherwise malicious or disruptive; (C) constitutes
              unauthorized or unsolicited advertising, junk or bulk e-mail (“spamming”) or otherwise violates federal
              CAN-SPAM regulation; or (D) contains any personally identifying information subject to specialized
              security regimes including without limitation the Health Insurance Portability and Accountability Act
              (“HIPAA”), and the standards promulgated by the PCI Security Standards Council (“PCI”). Customer
              acknowledges and agrees that Flybits is not a “Business Associate” under HIPAA, and Customer will not
              provide any protected health information to Flybits. Customer will not provide Flybits with any personally
              identifying information except for Authorized User information as necessary to allow Authorized Users to
              access the Flybits Platform and/or Services as set forth in these Terms of Service. Customer is
              responsible for complying with CAN-SPAM, including without limitation for supplying an “Unsubscribe” link
              in any emails required to include such link.
            </p>
            <h2>Third Party Services</h2>
            <p>
              Customer acknowledges and agrees that any third party services accessed through the Flybits Platform
              and/or Services, including without limitation any third party vendors providing advertising services on or
              related to the Flybits Platform and/or Services, are accessed at Customer’s own risk and may use
              Customer’s data in accordance with such third party’s privacy policy. Flybits is not responsible or liable
              for the content, functions, accuracy, legality, appropriateness or any other aspect of any third party
              services or any damage or loss caused or alleged to be caused by or in connection with the use of or
              reliance on any such content, goods or services available on or through any third party services.
            </p>
            <h2>Termination</h2>
            <p>
              Termination or cancellation of Customer’s Account by either party may result in the forfeiture and
              destruction of all information and data, including Customer Data, associated with Customer’s Account. Upon
              Customer’s request, upon termination, Flybits shall promptly delete or destroy all copies of Customer Data
              in Flybits systems or otherwise in Flybits possession or control, unless not reasonably feasible due to
              technical limitations e.g. feature incompatibility), unless legally prohibited. Flybits reserves the
              right, including after termination, to access, read, preserve, and disclose any information, including
              without limitation Customer Data, as Flybits reasonably believes is necessary to (i) satisfy any
              applicable law, regulation, legal process or governmental request; (ii) enforce these Terms of Service,
              including investigation of potential violations hereof; (iii) detect, prevent, or otherwise address fraud,
              security or technical issues; (iv) respond to user support requests; or (v) protect the rights, property
              or safety of Flybits, Flybits users and the public.
            </p>
            <h2>Definition of Confidential Information</h2>
            <p>
              “Confidential Information” means all information disclosed by a party (“Disclosing Party”) to the other
              party (“Receiving Party”), whether orally or in writing, that is designated as confidential or that
              reasonably should be understood to be confidential given the nature of the information and the
              circumstances of disclosure. Customer’s Confidential Information includes Customer Data, Flybits’s
              Confidential Information includes the Flybits Platform and/or Services, and Confidential Information of
              each party includes the terms and conditions of all Order Forms (including pricing), as well as business
              and marketing plans, technology and technical information, product plans and designs, source code and
              business processes disclosed by such party. However, Confidential Information of a Disclosing Party does
              not include any information that (i) is or becomes generally known to the public without breach of any
              obligation owed to the Disclosing Party; (ii) was rightfully known to the Receiving Party prior to its
              disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party; (iii) is
              rightfully received from a third party without breach of any obligation owed to the Disclosing Party; or
              (iv) was independently developed by the Receiving Party without use of or reference to the Confidential
              Information of the Disclosing Party.
            </p>
            <h2>Protection of Confidential Information</h2>
            <p>
              The Receiving Party will use the same degree of care that it uses to protect the Confidential Information
              of the Disclosing Party as it uses to protect the confidentiality of its own confidential information of
              like kind (but not less than reasonable care). The Receiving Party will (i) not use any Confidential
              Information of the Disclosing Party for any purpose outside the scope of these Terms of Service, and (ii)
              except as otherwise authorized by the Disclosing Party in writing, only disclose Confidential Information
              of the Disclosing Party to those of its and its affiliates’ employees and contractors who (A) need that
              access for purposes consistent with these Terms of Service and (B) have confidentiality obligations with
              respect to the Disclosing Party’s Confidential Information consistent with those contained herein. Neither
              party will disclose the terms of Order Form to any third party other than (1) its affiliates, legal
              counsel and accountants, or (2) in connection with a bona fide due diligence inquiry for a financing,
              acquisition or similar transaction, in each case subject to confidentiality obligations consistent with
              these Terms of Service, without the other party’s prior written consent. A Receiving Party shall be
              responsible for any breaches of confidentiality obligations hereunder by any third party to whom it
              discloses the Disclosing Party’s Confidential Information. Notwithstanding anything to the contrary set
              forth herein, Flybits is expressly authorized to use data regarding Customer’s use of the Flybits Platform
              and/or Services in anonymized and aggregated form, to analyze and improve the Flybits Platform and/or
              Services and for distribution in general benchmarking data and industry reports, provided that any
              reported user data is aggregated and anonymized such that no personally identifying information of
              Customer or its users is revealed.
            </p>
            <h2>Compelled Disclosure</h2>
            <p>
              The Receiving Party may disclose Confidential Information of the Disclosing Party to the extent compelled
              by law, provided the Receiving Party gives the Disclosing Party prior notice of the compelled disclosure
              (to the extent legally permitted) and reasonable assistance, at the Disclosing Party’s cost, if the
              Disclosing Party wishes to contest the disclosure.
            </p>
            <h2>Data Security</h2>
            <p>
              Flybits shall maintain appropriate administrative, physical, and technical safeguards designed to protect
              the security of the Flybits Platform and/or Services and Customer Data. If Customer’s use of the Flybits
              Platform and/or Services involves processing personal data pursuant to Regulation 2016/679 (the “GDPR”)
              and/or transferring personal data outside the European Economic Area to any country not deemed by the
              European Commission as providing an adequate level of protection for personal data, the terms of the data
              processing addendum shall apply to such personal data and be incorporated into the Terms of Service upon
              the Effective Date. The data processing addendum may be accessed here.
            </p>
            <h2>Warranty Disclaimer</h2>
            <p>
              Flybits makes no representations concerning any Content contained in or accessed through the Flybits
              Platform and/or Services, and Flybits will not be responsible or liable for the accuracy, copyright
              compliance, or legality of material or Content contained in or accessed through the Flybits Platform
              and/or Services.
            </p>
            <p>
              THE FLYBITS PLATFORM AND/OR SERVICES ARE PROVIDED “AS IS”, “AS AVAILABLE” AND WITHOUT WARRANTY OF ANY
              KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE,
              NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY
              COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. Flybits, AND ITS
              DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS AND CONTENT PROVIDERS DO NOT WARRANT THAT: (I) THE
              FLYBITS PLATFORM AND/OR SERVICES WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (II) ANY
              DEFECTS OR ERRORS WILL BE CORRECTED; (III) THE FLYBITS PLATFORM AND/OR SERVICES IS FREE OF VIRUSES OR
              OTHER HARMFUL COMPONENTS AT ALL TIMES; CUSTOMER’S USE OF THE FLYBITS PLATFORM AND/OR SERVICES IS SOLELY AT
              CUSTOMER’S OWN RISK.
            </p>
            <p>
              Indemnification Customer is solely responsible for Customer’s conduct and Customer’s data related to the
              Flybits Platform and/or Services. Customer shall defend, indemnify, and hold harmless Flybits, its
              affiliates and each of Flybits and its affiliates’ respective employees, contractors, directors, suppliers
              and representatives from all liabilities, claims, and expenses, including reasonable attorneys’ fees, that
              arise from or relate to Customer’s use, misuse or provision of, or access to, the Flybits Platform and/or
              Services, violation of these Terms of Service, or infringement by Customer, or any third party using
              Customer’s Account or identity in the Flybits Platform and/or Services, of any intellectual property or
              other right of any person or entity.
            </p>
            <p>
              Flybits will defend Customer against any claim, demand, suit or proceeding (“Claim”) made or brought
              against Customer by a third party alleging that Customer’s use of the Flybits Platform and/or Services
              provided hereunder in accordance with these Terms of Service infringes or misappropriates such third
              party’s intellectual property rights, and will indemnify and hold harmless Customer from any damages,
              attorney fees and costs finally awarded to such third parties as a result of, or for any amounts paid by
              Flybits under a settlement of, such Claim made in accordance with the terms of this Section. The foregoing
              obligations do not apply with respect to any Claim based on or arising from (i) the Flybits Platform
              and/or Services or Flybits Platform and/or Services or portions or components thereof (A) used not
              strictly in accordance with these Terms of Service or in an environment or on a platform or with devices
              for which it was not designed or contemplated; (B) made in whole or in part in accordance with Customer
              specifications; (C) modified, altered or enhanced by a party other than Flybits or (D) combined with other
              products, services, processes, content or materials not supplied by Flybits where the alleged infringement
              would not have occurred without such combination. The foregoing obligations also do not apply to (E) any
              continuing allegedly infringing activity after being notified thereof or provided modifications that would
              have avoided the alleged infringement, or (F) any Claims based on or arising out of Customer’s, Customer’s
              Affiliates, or Customer’s Users breach of these Terms of Service or an applicable Order Form. Any claim
              for indemnification under this Section 10.b. requires that Customer (i) promptly give the Flybits written
              notice of the Claim; (ii) give Flybits sole control of the defense and settlement of the Claim, and (iii)
              give Flybits all reasonable assistance. In the event that the Content is held by a court of competent
              jurisdiction or reasonably believed by Flybits to constitute an infringement of a third party’s
              intellectual property rights, Flybits may at its option (a) modify the infringing portion of the Content
              so that it is non-infringing; (b) procure for Customer sufficient rights to continue to exercise its
              rights under these Terms of Service; or (c) terminate these Terms of Services, and/or any Order Forms and
              Customer’s access to the Flybits Platform and/or Services. This Section represents the sole right and
              remedy available to Customer if the Flybits Platform and/or Services or Content infringe the intellectual
              property rights of a third party.
            </p>
            <h2>Limitation of Liability</h2>
            <p>
              EXCEPT FOR CUSTOMER’S INDEMNIFICATION OBLIGATIONS HEREUNDER, IN NO EVENT SHALL EITHER PARTY, NOR SUCH
              PARTY’S DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT,
              TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE FLYBITS
              PLATFORM AND/OR SERVICES (A) FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR
              SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, COMPENSATORY OR CONSEQUENTIAL DAMAGES OF ANY KIND
              WHATSOEVER (HOWEVER ARISING); (B) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE
              SOURCE OF ORIGINATION); OR (C) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) OF THE FEES PAID OR
              PAYABLE BY SUCH PARTY DURING THE IMMEDIATELY PREVIOUS TWELVE (12) MONTH PERIOD.
            </p>
            <h2>Miscellaneous</h2>
            <p>
              Entire Agreement and Severability. These Terms of Service constitute the entire agreement, and supersede
              all prior negotiations, understandings or agreements (oral or written), between the parties regarding the
              subject matter of these Terms of Service (and all past dealing or industry custom). Any inconsistent or
              additional terms, even if signed by the parties hereafter, shall have no effect under these Terms of
              Service. No change, consent or waiver under these Terms of Service will be effective unless in writing and
              signed by the party against which enforcement is sought. The failure of either party to enforce its rights
              under these Terms of Service at any time for any period will not be construed as a waiver of such rights,
              and the exercise of one right or remedy will not be deemed a waiver of any other right or remedy. No
              waiver by any party of any breach or default of any provision of these Terms of Service by the other party
              shall be effective as to any other breach or default, whether of the same or any other provision and
              whether occurring prior to, concurrent with, or subsequent to the date of such waiver. If any provision of
              these Terms of Service is determined to be illegal or unenforceable, that provision will be limited or
              eliminated to the minimum extent necessary so that these Terms of Service will otherwise remain in full
              force and effect and enforceable. These Terms of Service are in English only, which language shall be
              controlling in all respects. No version of these Terms of Service in another language shall be binding or
              of any effect.
            </p>
            <h2>Force Majeure</h2>
            <p>
              Neither party shall be liable for any failure to perform its obligations hereunder where such failure
              results from any cause beyond such party’s reasonable control, including, without limitation, mechanical,
              electronic or communications failure or degradation.
            </p>
            <h2>Subcontractors</h2>
            <p>
              We use subcontractors to provide certain aspects of the Services and in some cases permit them to access
              Customer Data subject to appropriate obligations of security, confidentiality and compliance with
              applicable laws. We remain responsible for our subcontractors’ compliance with these Terms of Service.
            </p>
            <h2>Assignment</h2>
            <p>
              These Terms of Service and the rights and obligations hereunder may not be assigned, in whole or in part,
              by either party without the other party’s written consent, not to be unreasonably withheld. However,
              without consent, either party may assign these Terms of Service to any successor to all or substantially
              all of its business or assets which concerns these Terms of Service (whether by sale of assets or equity,
              merger, consolidation or otherwise). These Terms of Service shall be binding upon, and inure to the
              benefit of, the successors, representatives and permitted assigns of the parties hereto.
            </p>
            <h2>Agency</h2>
            <p>
              No agency, partnership, joint venture, or employment relationship is created as a result of these Terms of
              Service and neither party has any authority of any kind to bind the other in any respect.
            </p>
            <h2>Notices</h2>
            <p>
              Unless otherwise specified in these Terms of Service, all notices under these Terms of Service will be in
              writing and will be deemed to have been duly given when received, if personally delivered or sent by
              certified or registered mail, return receipt requested; when receipt is electronically confirmed, if
              transmitted by facsimile or e-mail; or the day after it is sent, if sent for next day delivery by
              recognized overnight delivery service. Electronic notices should be sent to{' '}
              <a
                href={'mailto:support@flybits.com'}
                target="_blank"
                rel="noopener noreferrer"
                title="This link opens your email software"
              >
                support@flybits.com
              </a>
              . Customer acknowledges and agrees that Flybits may occasionally send notices to Customer regarding
              Customer’s Account or the Flybits Platform and/or Services via email or via the Flybits Platform and/or
              Services.
            </p>
            <h2>Headings; Interpretation</h2>
            <p>
              The section and paragraph headings in these Terms of Service are for convenience only and shall not affect
              their interpretation. Any use of “including” “for example” or “such as” in these Terms of Service shall be
              read as being followed by “without limitation” where appropriate.
            </p>
            <h2>Governing Law</h2>
            <p>
              These Terms of Service and the transactions contemplated hereby shall be governed by and construed under
              the law of the Province of Ontario, without regard to conflicts of laws provisions thereof and without
              regard to the United Nations Convention on Contracts for the International Sale of Goods or any
              implementation of the Uniform Computer Information Transactions Act.
            </p>
            <h2>Venue</h2>
            <p>
              Except that either party may seek equitable or similar relief from any court of competent jurisdiction,
              any dispute, controversy or claim arising out of or in relation to these Terms of Service, or at law, or
              the breach, termination or invalidity of these Terms of Service shall be finally settled in accordance
              with the arbitration rules of JAMS then in force, by one or more arbitrators appointed in accordance with
              said rules. Any dispute arising from or related to the subject matter of these Terms of Service shall be
              resolved in Toronto, Canada.
            </p>
            <h2>Proceedings; Judgment</h2>
            <p>
              The award rendered shall be final and binding on both parties. Judgment on the award may be entered in any
              court of competent jurisdiction. In any action or proceeding to enforce rights under these Terms of
              Service, the prevailing party shall be entitled to recover costs and attorneys’ fees. Flybits commits to
              being GDPR compliant! If you have users in the EU, please follow this link where you can execute our Data
              Processing Addendum, which further sets forth the details of our GDPR compliance.
            </p>
            <h2>Miscellaneous</h2>
            <p>
              Flybits commits to being GDPR compliant. If you have any further questions regarding the data Flybits
              collects, or how we use it, then please feel free to contact us by email at:{' '}
              <a
                href={'mailto:legal@flybits.com'}
                target="_blank"
                rel="noopener noreferrer"
                title="This link opens your email software"
              >
                legal@flybits.com
              </a>
              .
            </p>
            <p>
              Flybits has a “Data Protection Officer” who is responsible for matters relating to privacy and data
              protection. This Data Protection Officer can be reached at the following address:
              <a
                href={'mailto:dataprotectionofficer@flybits.com'}
                target="_blank"
                rel="noopener noreferrer"
                title="This link opens your email software"
              >
                dataprotectionofficer@flybits.com
              </a>
            </p>
            <h2>General Contact Information</h2>
            <p>You may contact us at the following address:</p>
            <address>
              Flybits Inc
              <br /> Attn: Data Protection Officer
              <br /> 200 Wellington Street, Floor 8<br /> Toronto, ON M5V 3C7
              <p>
                Or, email us at{' '}
                <a
                  href={'mailto:support@flybits.com'}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="This link opens your email software"
                >
                  support@flybits.com
                </a>
                .
              </p>
            </address>
          </article>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
