import React, { useEffect, useState } from 'react';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import './ConfigTemplate.scss';
import { FlightRadioButton } from '@flybits/design-system';
import { ConnectorV1 } from 'src/model/connectors/connectors';
import { Link } from 'react-router-dom';
import JSONFormEditor from 'src/components/Shared/JSONFormEditor/JSONFormEditor';
import PreviewFormEditor from './PreviewFormEditor/PreviewFormEditor';
interface Props {
  current: ConnectorV1 | undefined;
  APIVersion?: string;
  t: any;
  onError: (tab: string, hasError: boolean) => void;
  onChangeValue: (e: any) => void;
  setCodeModified: (e: any) => void;
}
const Configurations: React.FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'ConfigTemplate';

  const { current, onChangeValue, setCodeModified, onError, t } = props;
  const { configSchema = {}, uiSchema = {} } = current?.configs || {};
  const [selectedTab, setTab] = useState('editor');
  const [previewError, setPreviewError] = useState(false);
  const { status } = current?.metadata || {};
  const [currentUiSchema, setUiSchema] = useState<UiSchema>(uiSchema);
  const [schema, setSchema] = useState<RJSFSchema>(configSchema as RJSFSchema);
  useEffect(() => {
    onError('configuration', previewError);
  }, [previewError]);

  const handleChange = (e: any, type?: string) => {
    const updatedValue = e.target ? e.target.value : e;
    const configs = current?.configs;

    if (type === 'configSchema') {
      onChangeValue({
        ...current,
        configs: {
          ...configs,
          configSchema: updatedValue,
          uiSchema: configs?.uiSchema,
        },
      });
    }
    if (type === 'uiSchema') {
      onChangeValue({
        ...current,
        configs: {
          ...configs,
          uiSchema: updatedValue,
          configSchema: configs?.configSchema,
        },
      });
    }
  };

  return (
    <div className={MAIN_CLASS}>
      <h2 className="section__title">
        {t('integrations:connectors.details.sections.config-template.title', 'Configuration template')}
      </h2>
      <div className="section__description">
        {t(
          'integrations:connectors.details.sections.config-template.description',
          'The configuration template allows people using this connector to create data source to fill in the necessary data to establish a connection to their data source.',
        )}
      </div>
      <div className="section__tabs">
        <div className="section__tabs-header">
          <FlightRadioButton
            label={t('integrations:connectors.details.sections.config-template.editor.title', 'JSON')}
            checked={selectedTab === 'editor'}
            className={`section__tabs-header-item`}
            onSelect={() => {
              setTab('editor');
            }}
            value="editor"
          />

          <FlightRadioButton
            label={t('integrations:connectors.details.sections.config-template.preview.title', 'Preview')}
            checked={selectedTab === 'preview'}
            className={`section__tabs-header-item`}
            onSelect={() => {
              setTab('preview');
            }}
            value="preview"
            disabled={previewError}
          />
          <Link
            to={{
              pathname: t(
                'integrations:connectors.details.sections.configurations.got_questions_url',
                'https://rjsf-team.github.io/react-jsonschema-form/docs/quickstart/#form-schema',
              ),
            }}
            target="_blank"
          >
            {t('integrations:connectors.details.sections.config-template.got_questions', 'Got schema questions?')}
          </Link>
        </div>
        <div className="section__tabs-content">
          {selectedTab === 'editor' && (
            <div className="tab-inner">
              <div className="json-editor">
                <JSONFormEditor
                  schema={schema}
                  setSchema={setSchema}
                  uiSchema={currentUiSchema}
                  setUiSchema={setUiSchema}
                  handleChange={handleChange}
                  setPreviewError={setPreviewError}
                  setModified={setCodeModified}
                  selectedTab={selectedTab}
                  disabled={status === 'active'}
                  t={t}
                />
              </div>
            </div>
          )}
          {selectedTab === 'preview' && (
            <div className="tab-inner">
              <PreviewFormEditor
                setTab={setTab}
                schema={schema}
                uiSchema={uiSchema}
                previewError={previewError}
                t={t}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Configurations;
