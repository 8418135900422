import { FlightTooltip } from '@flybits/design-system';
import React from 'react';
import { ReactComponent as QuestionCircle } from 'src/assets/icons/question-circle.svg';
import { HeadingConfig } from 'src/model/connectors/connectors';
import './ItemHeader.scss';

const ItemHeader = (props: HeadingConfig) => {
  const { viewName, description } = props;

  return (
    <div className="ItemHeader">
      {viewName}
      {description && (
        <FlightTooltip direction="right" className="help" isEnabled={true} description={description}>
          <QuestionCircle />
        </FlightTooltip>
      )}
    </div>
  );
};
export default ItemHeader;
