// Animation Defaults

//Fades
export const fadeIn = {
  from: { opacity: 0 },
  to: { opacity: 1 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
  config: { duration: 250 },
};
export const fadeRight = {
  from: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
  to: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  leave: { opacity: 0, transform: 'translate3d(100%,0,0)' },
};
export const fadeLeft = {
  from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
  to: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  leave: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
};
export const fadeTop = {
  from: { opacity: 0, transform: 'translate3d(0,-10%,0)' },
  to: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  leave: { opacity: 0, transform: 'translate3d(0,-10%,0)' },
};

//Slides
export const slideRight = {
  from: { transform: 'translate3d(-100%,0,0)' },
  to: { transform: 'translate3d(0%,0,0)' },
  enter: { transform: 'translate3d(0%,0,0)' },
  leave: { transform: 'translate3d(100%,0,0)' },
};

export const slideLeft = {
  from: { transform: 'translate3d(100%,0,0)' },
  enter: { transform: 'translate3d(0%,0,0)' },
  to: { transform: 'translate3d(0%,0,0)' },
  leave: { transform: 'translate3d(-100%,0,0)' },
};

export const slideDown = {
  from: { transform: 'translate3d(0,-100%,0)' },
  enter: { transform: 'translate3d(0%,0,0)' },
  to: { transform: 'translate3d(0%,0,0)' },
  leave: { transform: 'translate3d(0,-100%,0)' },
};

// export const doTransition = (animationProps: {}) => {
//   const location = useLocation();
//   const transitions = useTransition(location, (location) => location.pathname, animationProps);
//   transitions.map(({ item, props, key }) => {
//     // return (<animated.div key={key} style={props}>{props.children}</animated.div>);
//   });
// };
