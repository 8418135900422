import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FlightButton,
  FlightTable,
  FlightModal,
  FlightTextInput,
  FlightSelect,
  FlightRadioButton,
  FlightOverflowMenu,
} from '@flybits/design-system';
import { GENERIC_INTERNAL_ERROR } from 'src/constants/errors';
import './IntegrationsList.scss';
import { setStoredOrganizationId, unicodeEmailRegex } from 'src/helpers/auth.helper';
import { connectorsTableHeaders, datasourcesTableHeaders } from 'src/helpers/integrations.helper';
import { useHistory } from 'react-router';
import { ConnectorListItem, ConnectorSelectedProps, ConnectorV1 } from 'src/model/connectors/connectors';
import { Link } from 'react-router-dom';
import { ReactComponent as DatasourcesImage } from 'src/assets/datasources-icon-color.svg';
import { ReactComponent as DatasourcesWhatImage } from 'src/assets/illustrations/what-datasources.svg';
import { ReactComponent as DatasourcesDefaultImage } from 'src/assets/datasources-icon.svg';
import { ReactComponent as ConnectorsImage } from 'src/assets/connectors-icon-color.svg';
import { ReactComponent as ConnectorsWhatImage } from 'src/assets/illustrations/what-connectors.svg';
import { ReactComponent as ConnectorDefaultImage } from 'src/assets/connectors-icon.svg';
import imageErrorConnector from 'src/assets/connectors-icon.svg';
import imageErrorDatasources from 'src/assets/datasources-icon.svg';
import {
  capitalize,
  copyToClipboard,
  isValidJson,
  isValidSchema,
  removeProperties,
  timeFromNowTo,
} from 'src/helpers/general.helper';
import useNotification from 'src/hooks/useNotification';
import ConnectorService from 'src/services/connectors.service';
import ShareConnectorModal from 'src/components/Shared/ShareConnectorModal/ShareConnectorModal';
import DeleteConnectorModal from 'src/components/Shared/DeleteConnectorModal/DeleteConnectorModal';
interface Props {
  location?: any;
  match?: any;
}
// Import Connector
import connectorSchemaImport from 'src/constants/connector-import-schema.json';
import connectorSchemaImportV1 from 'src/constants/connector-import-schema-v1.json';

// Translation
import { useTranslation } from 'react-i18next';
import DataSourcesService from 'src/services/datasources.service';
import DeleteDatasourceModal from 'src/components/Shared/DeleteDatasourceModal/DeleteDatasourceModal';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import { IMPORT_INTEGRATIONS_ENABLED } from 'src/constants/featureFlags';
import { DataSourceV1 } from 'src/model/datasources/datasources';
import Remapping from 'src/pages/Remapping/Remapping';

const IntegrationsList: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation(['translation', 'errors', 'integrations', 'components']);
  const { match } = props;
  const orgID = match.params.id;
  const APIVersion = match.params.version ? match.params.version + '/' : '';
  const prevAPIVersion = useRef(APIVersion);
  const { featureFlags, organizationFlags } = useControlTowerContext();
  const [areIntegrationsImportEnabled, setAreIntegrationsImportEnabled] = useState(false);
  const history = useHistory();
  useEffect(() => {
    featureFlags[IMPORT_INTEGRATIONS_ENABLED] && setAreIntegrationsImportEnabled(true);
  }, [featureFlags, organizationFlags]);

  if (!orgID) {
    history.push('/organizations');
  }
  setStoredOrganizationId(orgID);
  const connectorsServiceManager = new ConnectorService();
  const datasourcesServiceManager = new DataSourcesService();
  const [error, setError] = useState('');
  const [selectedTab, setTab] = useState(window.location.hash === '#connectors' ? 'connector' : 'datasource');
  const [selectedFilter, setSelectedFilter] = useState({
    key: 'all',
    name: t('integrations:general.filters.anyone', `Anyone`) || 'Anyone',
  });
  const [alert, showAlert] = useState(false);
  const [shareCode, setShareCode] = useState('');
  const [updateTable, setUpdateTableData] = useState(true);
  const [connectors, setConnectors] = useState<ConnectorSelectedProps[]>([]);
  const [datasources, setDatasources] = useState<ConnectorSelectedProps[]>([]);

  const [tryToRemoveConnector, setTryToRemoveConnector] = useState<string[]>([]);
  const [tryToDeleteConnector, setTryToDeleteConnector] = useState<string[]>([]);
  const [tryToShareConnector, setTryToShareConnector] = useState<string[]>([]);
  const [tryToDeleteDatasource, setTryToDeleteDatasource] = useState<string[]>([]);

  const { addNotification, addNotificationError } = useNotification();

  const [searchTerm, setSearchTerm] = useState('');

  const editSearchTerm = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const shareCodeToggle = (show: boolean) => {
    showAlert(show);
  };

  const MAIN_CLASS = 'IntegrationsList';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  const handleDeleteIntegration = (deleted: boolean, message?: string) => {
    if (deleted) {
      addNotification(t(`integrations:${selectedTab}s.delete.success`));
      setUpdateTableData(true);
    } else {
      addNotificationError(
        message || t(`integrations:${selectedTab}s.delete.error`, `Error while trying to delete this ${selectedTab}.`),
      );
    }
  };
  const addConnector = async (connectorInsertData: any, message?: string, errorMessage?: string) => {
    if (connectorInsertData) {
      const propsToRemove = [
        'id',
        'status',
        'createdAt',
        'shareStatus',
        'shared',
        'ttl',
        'owner',
        'organization',
        'supportEmailVerified',
        'updatedAt',
        'updatedBy',
        'metadata',
      ];
      const connectorPayload = {
        ...connectorInsertData,
      };

      // create
      try {
        const saveResponseObject: any = await connectorsServiceManager.createConnector(
          orgID,
          removeProperties(connectorPayload, ...propsToRemove),
          APIVersion,
        );
        if ([200, 201, 204].includes(saveResponseObject.status)) {
          setConnectorsLoading(false);
          //redirect to newly created connector
          message && addNotification(message);
          history.push(`/organization/${orgID}/integrations/${APIVersion}connector/${saveResponseObject.data.id}`);
        }
      } catch (error: any) {
        setConnectorsLoading(false);
        if (errorMessage) {
          addNotificationError(errorMessage);
          return;
        }
        if (error?.response?.data?.message) {
          addNotificationError(error.response.data.message);
        } else {
          addNotificationError(t(`errors:GENERIC_INTERNAL_ERROR`, GENERIC_INTERNAL_ERROR));
        }
      }
    }
  };
  const handleExportConnector = async (data: string[]) => {
    if (!data.length) return;
    setConnectorsLoading(true);
    try {
      const connectorsResponseObject = await connectorsServiceManager.getConnectorById(orgID, data[0], APIVersion);
      if (connectorsResponseObject && [200, 201, 204].includes(connectorsResponseObject.status)) {
        if (connectorsResponseObject?.data) {
          const propsToRemove = [
            'id',
            'status',
            'createdAt',
            'shareStatus',
            'shared',
            'ttl',
            'owner',
            'organization',
            'supportEmailVerified',
            'updatedAt',
            'updatedBy',
            'metadata',
          ];
          const dataStr = JSON.stringify(
            removeProperties(connectorsResponseObject.data, ...propsToRemove),
            undefined,
            2,
          );
          const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

          const exportFileDefaultName = `connector-${data[0]}.json`;

          const linkElement = document.createElement('a');
          linkElement.setAttribute('href', dataUri);
          linkElement.setAttribute('download', exportFileDefaultName);
          linkElement.click();
          setConnectorsLoading(false);

          addNotification(t('integrations:connectors.details.messages.exported', 'Connector exported successfully!'));
        }
        setConnectorsLoading(false);
      }
      setConnectorsLoading(false);
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.message) {
        addNotificationError(error.response.data.message);
      } else {
        addNotificationError(t(`errors:GENERIC_INTERNAL_ERROR`, GENERIC_INTERNAL_ERROR));
      }
    }
  };
  const handleDuplicateConnector = async (data: string[]) => {
    if (!data.length) return;
    setConnectorsLoading(true);
    try {
      const connectorsResponseObject = await connectorsServiceManager.getConnectorById(orgID, data[0], APIVersion);
      if (connectorsResponseObject && [200, 201, 204].includes(connectorsResponseObject.status)) {
        if (connectorsResponseObject?.data) {
          // add
          await addConnector(
            {
              ...connectorsResponseObject.data,
              name: `Copy of ${data[1]}`,
            },
            t('integrations:connectors.details.messages.duplicated', 'Connector duplicated successfully!'),
            t('integrations:connectors.details.messages.duplicate-error', 'Error duplicating connector.'),
          );
        }
        setConnectorsLoading(false);
      }
      setConnectorsLoading(false);
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.message) {
        addNotificationError(error.response.data.message);
      } else {
        addNotificationError(t(`errors:GENERIC_INTERNAL_ERROR`, GENERIC_INTERNAL_ERROR));
      }
    }
  };
  const handleRemoveConnector = async (data: string[]) => {
    if (!data.length) return;
    try {
      const connectorsResponseObject = await connectorsServiceManager.updateConnectorStatus(
        orgID,
        data[0],
        'delete',
        APIVersion,
      );
      if (connectorsResponseObject && [200, 201, 204].includes(connectorsResponseObject.status)) {
        addNotification(t('integrations:connectors.remove.success', 'Connector removed'));
        setUpdateTableData(true);
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.message) {
        addNotificationError(error.response.data.message);
      } else {
        addNotificationError(t(`errors:GENERIC_INTERNAL_ERROR`, GENERIC_INTERNAL_ERROR));
      }
    }
  };
  const handleRestoreDataSource = async (data: string[]) => {
    if (!data.length) return;
    try {
      const datasourcesResponseObject = await datasourcesServiceManager.updateDataSourceStatus(
        orgID,
        data[0],
        'cancelDelete',
        APIVersion,
      );
      if (datasourcesResponseObject && [200, 201, 204].includes(datasourcesResponseObject.status)) {
        setUpdateTableData(true);
        addNotification(t('integrations:datasources.restore.success', 'Datasource restored successfully'));
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.message) {
        addNotificationError(error.response.data.message);
      } else {
        addNotificationError(t(`errors:GENERIC_INTERNAL_ERROR`, GENERIC_INTERNAL_ERROR));
      }
    }
  };
  const handleRestoreConnector = async (data: string[]) => {
    if (!data.length) return;
    try {
      const connectorsResponseObject = await connectorsServiceManager.updateConnectorStatus(
        orgID,
        data[0],
        'cancelDelete',
        APIVersion,
      );
      if (connectorsResponseObject && [200, 201, 204].includes(connectorsResponseObject.status)) {
        setUpdateTableData(true);
        addNotification(t('integrations:connectors.restore.success', 'Connector restored successfully'));
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.message) {
        addNotificationError(error.response.data.message);
      } else {
        addNotificationError(t(`errors:GENERIC_INTERNAL_ERROR`, GENERIC_INTERNAL_ERROR));
      }
    }
  };
  const handleShareConnector = useCallback(async (data: string[], values: any) => {
    if (!data.length || !values.approve_sharing) return false;
    try {
      const connectorsResponseObject = await connectorsServiceManager.updateConnectorStatus(
        orgID,
        data[0],
        values.approve_3rd_party && values.approve_sharing ? 'approve_3rd_party' : 'approve_sharing',
        APIVersion,
      );
      if (connectorsResponseObject && [200, 201, 204].includes(connectorsResponseObject.status)) {
        if (connectorsResponseObject?.data?.errorMessage) {
          addNotificationError(connectorsResponseObject?.data?.errorMessage);
          return false;
        } else {
          addNotification(t('integrations:connectors.share.success', 'Connector shared successfully!'));
          setUpdateTableData(true);
          return true;
        }
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.message) {
        addNotificationError(error.response.data.message);
      } else {
        addNotificationError(t(`errors:GENERIC_INTERNAL_ERROR`, GENERIC_INTERNAL_ERROR));
      }
      return false;
    }
    return false;
  }, []);
  const getCorrectID = (id: string, connectorId: string, dataType = 'connector') =>
    `${dataType === 'connector' ? '' : connectorId + '_'}${id}`;

  const LeftTableCol = (data: any, isFromShared: boolean, dataType = 'connector') => {
    const dataNormalized: any = {
      id: data.id,
      connectorId: !APIVersion ? data.connectorId : data.metadata.connectorId,
      status: !APIVersion ? data.status : data.metadata.status,
      imageUrl: !APIVersion ? data.imageUrl : data.display.imageUrl,
      name: !APIVersion ? data.name : data.display.name,
      organization: !APIVersion
        ? data.organization
        : dataType === 'connector'
        ? data.metadata.organization
        : { name: '' },
    };
    const { id, connectorId, status, imageUrl, name, organization } = dataNormalized;
    const isDeleted = status === 'deleted';
    return (
      <div className={`left-items ${isFromShared ? 'is-shared' : ''} ${isDeleted ? 'is-deleted' : ''}`}>
        <div className="row-icon">
          {imageUrl ? (
            <img
              src={imageUrl}
              onError={(ev) => {
                (ev.target as Element).setAttribute(
                  'src',
                  dataType === 'connector' ? imageErrorConnector : imageErrorDatasources,
                );
              }}
              alt={name}
            />
          ) : dataType === 'connector' ? (
            <ConnectorDefaultImage />
          ) : (
            <DatasourcesDefaultImage />
          )}
        </div>
        <div>
          <p>
            <strong>
              {!isFromShared && !isDeleted ? (
                <Link
                  to={`/organization/${orgID}/integrations/${APIVersion}${dataType}/${
                    connectorId ? connectorId + '_' + id : id
                  }`}
                >
                  {name}
                </Link>
              ) : (
                name
              )}
            </strong>{' '}
            {!isFromShared && <em>({capitalize(t(`integrations:general.list.${status}`, status))})</em>}
          </p>
          {organization.name && (
            <p>
              <small>{t('integrations:general.list.by-org', { org: organization.name })}</small>
            </p>
          )}
        </div>
      </div>
    );
  };
  const RightTableCol = (data: any, isFromShared: boolean, isOwned: boolean, dataType = 'connector') => {
    const dataNormalized: any = {
      id: data.id,
      status: !APIVersion ? data.status : data.metadata.status,
      ttl: !APIVersion ? data.ttl : data.metadata.ttl,
      connectorId: !APIVersion
        ? data.connectorId
        : dataType === 'connector'
        ? data.connectorId
        : data.metadata.connectorId,
      shareStatus: !APIVersion ? data.shareStatus : 'private',
      shared: !APIVersion ? data.shared : false,
      imageUrl: !APIVersion ? data.imgUrl : data.display.imageUrl,
      name: !APIVersion ? data.name : data.display.name,
      supportEmail: !APIVersion ? data.supportEmail : data.display.supportEmail,
      documentationUrl: !APIVersion ? data.documentationUrl : data.display.documentationUrl,
      updatedAt: !APIVersion ? data.updatedAt : data.metadata.updatedAt,
    };
    const { id, status, ttl, connectorId, shareStatus, shared, name, updatedAt, supportEmail, documentationUrl } =
      dataNormalized;
    const topContextOptions: any[] = [];
    const commonContextOptions: any[] = [];
    const footerContextOptions: any[] = [];
    const optionGroups: any = [];
    // Top options
    topContextOptions.push({
      key: getCorrectID(id, connectorId, dataType) + '-0',
      name: t(`integrations:general.list.menus.view-details`, 'View details'),
      disabled: false,
      onClick: () =>
        history.push(
          `/organization/${orgID}/integrations/${APIVersion}${dataType}/${getCorrectID(id, connectorId, dataType)}`,
        ),
    });
    // Common options
    if (supportEmail)
      commonContextOptions.push({
        key: getCorrectID(id, connectorId, dataType) + '-1',
        name: t(`integrations:general.list.menus.contact-support`, 'Contact support'),
        disabled: false,
        onClick: () => {
          if (unicodeEmailRegex.test(String(supportEmail).toLowerCase())) {
            copyToClipboard(supportEmail);
            window.open('mailto:' + supportEmail);
          }
        },
      });
    if (documentationUrl)
      commonContextOptions.push({
        key: getCorrectID(id, connectorId, dataType) + '-2',
        name: t(`integrations:general.list.menus.documentation`, 'Documentation'),
        disabled: false,
        onClick: () => window.open(documentationUrl, '_blank'),
      });
    if (dataType === 'connector') {
      // Duplicate
      commonContextOptions.push({
        key: getCorrectID(id, connectorId, dataType) + '-3',
        name: t(`integrations:general.list.menus.duplicate`, 'Duplicate'),
        disabled: false,
        onClick: () => handleDuplicateConnector([id, name]),
      });
      // Export
      commonContextOptions.push({
        key: getCorrectID(id, connectorId, dataType) + '-4',
        name: t(`integrations:general.list.menus.export`, 'export'),
        disabled: false,
        onClick: () => handleExportConnector([id, name]),
      });
    }
    // Shared options
    if (shareStatus !== 'private') {
      // its NOT shared yet
      if (isOwned) {
        //I'm the owner
        if (dataType === 'connector') {
          // It's a connector
          topContextOptions.push({
            key: getCorrectID(id, connectorId, dataType) + '-3',
            name: t(`integrations:general.list.menus.approve-sharing`, 'Approve for sharing'),
            disabled: status !== 'active',
            onClick: () => setTryToShareConnector([id, name]),
          });
        }
      }
    }
    if (isFromShared) {
      if (dataType === 'connector')
        footerContextOptions.push({
          key: getCorrectID(id, connectorId, dataType) + '-5',
          name: t(`integrations:general.list.menus.remove`, 'Remove'),
          disabled: false,
          onClick: () => setTryToRemoveConnector([id, name]),
        });
    } else {
      if (isOwned)
        footerContextOptions.push({
          key: getCorrectID(id, connectorId, dataType) + '-4',
          name: t(`integrations:general.list.menus.delete`, 'Delete'),
          disabled: false,
          onClick: () =>
            dataType === 'connector'
              ? setTryToDeleteConnector([id, name])
              : setTryToDeleteDatasource([connectorId + '_' + id, name]),
        });
    }
    if (topContextOptions.length) optionGroups.push(topContextOptions);
    if (commonContextOptions.length) optionGroups.push(commonContextOptions);
    if (footerContextOptions.length) optionGroups.push(footerContextOptions);
    return (
      <div className="right-items">
        {status === 'deleted' ? (
          <div className="to-delete">
            <div>{t('integrations:general.list.scheduled-for-deletion', { time: timeFromNowTo(ttl) })}</div>
            <FlightButton
              type="button"
              theme="link"
              size="small"
              label={t(`integrations:general.list.restore`, 'Restore')}
              onClick={() => {
                dataType === 'connector'
                  ? handleRestoreConnector([id, name])
                  : handleRestoreDataSource([connectorId + '_' + id, name]);
              }}
            />
          </div>
        ) : (
          <>
            {!isFromShared && !APIVersion && (
              <>
                {shared ? (
                  <strong>{t('integrations:general.list.shared', 'Shared')}</strong>
                ) : (
                  <strong>{t('integrations:general.list.private', 'Private')}</strong>
                )}
                <br />
              </>
            )}
            {t('integrations:connectors.last-update', { time: timeFromNowTo(updatedAt) })}{' '}
            <FlightOverflowMenu ariaLabel={'Open more options'} optionGroups={optionGroups} />
          </>
        )}
      </div>
    );
  };
  const filteredSearchData = () => {
    const selectedResource = selectedTab === 'datasource' ? datasources : connectors;
    // Sort by date updatedAt (time)
    selectedResource.sort((a, b) => (a.time < b.time ? 1 : -1));
    return selectedResource.filter((item) => {
      let match = false;
      switch (selectedFilter.key) {
        case 'all':
          match =
            !searchTerm ||
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.email.toLowerCase().includes(searchTerm.toLowerCase());
          break;
        case 'owner':
          match =
            item.organization === orgID &&
            (!searchTerm ||
              item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              item.email.toLowerCase().includes(searchTerm.toLowerCase()));
          break;
        case 'shared':
          match =
            item.organization !== orgID &&
            (!searchTerm ||
              item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              item.email.toLowerCase().includes(searchTerm.toLowerCase()));
          break;
      }

      return match;
    });
  };

  const generateShareCode = async () => {
    setGeneratingCode(true);
    setShareCode('');
    const connectorsAddObject = await connectorsServiceManager.generateShareCode(orgID);
    setShareCode(connectorsAddObject?.data.pseudonym);
    setGeneratingCode(false);
  };

  // IMPORT CONNECTOR
  const [connectorsImporting, setConnectorsImporting] = useState(false);
  const importInputRef = useRef<any>(null);
  const processFile = async (event: ProgressEvent<FileReader>) => {
    setConnectorsImporting(true);
    const str = event?.target?.result;
    if (typeof str !== 'string') {
      addNotificationError(`Invalid file type "${typeof str}". Expected "string"`);
      setConnectorsImporting(false);
      return;
    }
    if (!isValidJson(str)) {
      addNotificationError(`Invalid JSON file format`);
      setConnectorsImporting(false);
      return;
    }

    const JSONtoImport = JSON.parse(str);
    if (!isValidSchema(JSONtoImport, connectorSchemaImport) && !isValidSchema(JSONtoImport, connectorSchemaImportV1)) {
      addNotificationError('Invalid Connector data, please check your file.');
      setConnectorsImporting(false);
      return;
    }
    // check for version compatibility
    if (JSONtoImport.hasOwnProperty('configTemplate') && APIVersion) {
      // V0 to V1 = error
      addNotificationError('V0 Connector is not compatible with V1');
      setConnectorsImporting(false);
      return;
    }
    if (JSONtoImport.hasOwnProperty('configs') && !APIVersion) {
      // V1 to V0 = error
      addNotificationError('V1 Connector is not compatible with V0');
      setConnectorsImporting(false);
      return;
    }
    setTimeout(() => setConnectorsImporting(false), 1150);
    setTimeout(() => {
      history.push({
        pathname: `/organization/${orgID}/integrations/${APIVersion}connector/new`,
        hash: '#basic',
        state: {
          importFile: JSONtoImport,
          isImport: true,
        },
      });
    }, 1200);
  };
  // triggers the input when the button is clicked
  const onButtonClick = () => {
    // 👇️ reset file input
    importInputRef.current.value = null;
    importInputRef.current.click();
  };
  const handleFile = (file: any) => {
    // If there's no file, do nothing
    if (file.type !== 'application/json') {
      addNotificationError('Invalid JSON file');
      return;
    }

    // Create a new FileReader() object
    const reader = new FileReader();
    // Setup the callback event to run when the file is read
    reader.onload = processFile;

    // Read the file
    reader.readAsText(file);
  };
  const handleImportConnector = (e: any) => {
    e.preventDefault();
    const fileObj = e.target.files && e.target.files[0];
    if (!fileObj) {
      return;
    }
    // 👇️ reset file input
    e.target.value = null;

    handleFile(fileObj);
  };

  const [generatingCode, setGeneratingCode] = useState(false);
  const [connectorsLoading, setConnectorsLoading] = useState(true);
  const [datasourcesLoading, setDatasourcesLoading] = useState(true);
  const areIntegrationsLoading = () => {
    return !(!datasourcesLoading && !connectorsLoading && !connectorsImporting);
  };
  useEffect(() => {
    const getConnectorsList = async () => {
      setConnectorsLoading(true);

      try {
        const connectorsResponseObject = await connectorsServiceManager.getConnectors(orgID, APIVersion);
        if (connectorsResponseObject?.data) {
          const connectorsList: any = [];
          if (!APIVersion) {
            connectorsResponseObject?.data.map((connectorItem: ConnectorListItem, idx: number) => {
              connectorsList.push({
                key: idx + 1,
                connectorFormatted: LeftTableCol(connectorItem, connectorItem.organization.id !== orgID),
                timeFormatted: RightTableCol(
                  connectorItem,
                  connectorItem.organization.id !== orgID,
                  connectorItem.owner.id === orgID,
                ),
                name: connectorItem.name,
                status: connectorItem.status,
                ttl: connectorItem.ttl,
                email: connectorItem.organization.name,
                id: connectorItem.id,
                organization: connectorItem.organization.id,
                time: connectorItem.updatedAt,
              });
            });
          } else {
            connectorsResponseObject?.data.map((connectorItem: ConnectorV1, idx: number) => {
              connectorsList.push({
                key: idx + 1,
                connectorFormatted: LeftTableCol(
                  connectorItem,
                  connectorItem.metadata.organization?.id !== orgID || false,
                ),
                timeFormatted: RightTableCol(
                  connectorItem,
                  connectorItem.metadata.organization?.id !== orgID || false,
                  connectorItem.metadata.organization?.id !== orgID || false,
                ),
                name: connectorItem.display.name,
                status: connectorItem.metadata.status,
                ttl: connectorItem.metadata.ttl,
                email: connectorItem.metadata.organization?.name,
                id: connectorItem.id,
                organization: connectorItem.metadata.organization?.id,
                time: connectorItem.metadata.updatedAt,
              });
            });
          }
          setConnectors(connectorsList);

          setConnectorsLoading(false);
        }
        setUpdateTableData(false);
      } catch (error: any) {
        console.error(error?.response);
        setConnectorsLoading(false);
        setError(
          error?.response?.data?.message || error.message || t(`errors:GENERIC_INTERNAL_ERROR`, GENERIC_INTERNAL_ERROR),
        );
        setUpdateTableData(false);
      }
    };

    const getDataSourcesList = async () => {
      setDatasourcesLoading(true);

      try {
        const datasourceResponseObject = await datasourcesServiceManager.getDataSources(orgID, APIVersion);
        if (datasourceResponseObject?.data) {
          const datasourceList: any = [];
          if (!APIVersion) {
            datasourceResponseObject?.data.map((datasourceItem: any, idx: number) => {
              datasourceList.push({
                key: idx + 1,
                datasourceFormatted: LeftTableCol(
                  datasourceItem,
                  datasourceItem.organization.id !== orgID,
                  'datasource',
                ),
                timeFormatted: RightTableCol(
                  datasourceItem,
                  datasourceItem.organization.id !== orgID,
                  datasourceItem.owner.id === orgID,
                  'datasource',
                ),
                name: datasourceItem.name,
                status: datasourceItem.status,
                ttl: datasourceItem.ttl,
                email: datasourceItem.organization.name,
                id: datasourceItem.connectorId + '_' + datasourceItem.id,
                organization: datasourceItem.organization.id,
                time: datasourceItem.updatedAt,
              });
            });
          } else {
            datasourceResponseObject?.data.map((datasourceItem: DataSourceV1, idx: number) => {
              datasourceList.push({
                key: idx + 1,
                datasourceFormatted: LeftTableCol(datasourceItem, false, 'datasource'),
                timeFormatted: RightTableCol(datasourceItem, false, true, 'datasource'),
                name: datasourceItem.display.name,
                status: datasourceItem.metadata.status,
                ttl: datasourceItem.metadata.ttl,
                email: 'some@email',
                id: datasourceItem.id,
                organization: 0,
                time: datasourceItem.metadata.updatedAt,
              });
            });
          }
          setDatasources(datasourceList);

          setDatasourcesLoading(false);
        }
        setUpdateTableData(false);
      } catch (error: any) {
        console.error(error?.response);
        setError(
          error?.response?.data?.message || error.message || t(`errors:GENERIC_INTERNAL_ERROR`, GENERIC_INTERNAL_ERROR),
        );
        setDatasourcesLoading(false);
        setUpdateTableData(false);
      }
    };
    const getShareCode = async () => {
      try {
        const connectorsResponseObject = await connectorsServiceManager.getShareCode(orgID);
        if (connectorsResponseObject?.data) {
          setShareCode(connectorsResponseObject?.data.pseudonym);
        } else {
          const connectorsAddObject = await connectorsServiceManager.generateShareCode(orgID);
          setShareCode(connectorsAddObject?.data.pseudonym);
        }
      } catch (error: any) {
        console.error(error);
        if (error?.response?.data?.message) {
          setError(error.response.data.message);
        } else {
          setError(t(`errors:GENERIC_INTERNAL_ERROR`, GENERIC_INTERNAL_ERROR));
        }
      }
    };
    if (orgID) {
      (updateTable || APIVersion !== prevAPIVersion.current) && getDataSourcesList();
      (updateTable || APIVersion !== prevAPIVersion.current) && getConnectorsList();
      (updateTable || APIVersion !== prevAPIVersion.current) && getShareCode();
      prevAPIVersion.current = APIVersion;
    }
    return () => {
      prevAPIVersion.current = APIVersion;
    };
    // eslint-disable-next-line
  }, [orgID, history, updateTable, APIVersion]);
  useEffect(() => {
    setTab(window.location.hash === '#connectors' ? 'connector' : 'datasource');
    // eslint-disable-next-line
  }, [window.location.hash]);
  return (
    <div className={MAIN_CLASS}>
      {/* SHARE CODE MODAL */}
      <FlightModal
        isVisible={alert}
        toggleModalShown={() => shareCodeToggle(false)}
        scrollable={false}
        size="small"
        warning={false}
        className={`${MAIN_CLASS}__alert`}
        header={<span>{t('integrations:general.organization-share-code.modal-title', 'Share code')}</span>}
        content={
          <div className={`${MAIN_CLASS}__alert_content`}>
            {t(
              'integrations:general.organization-share-code.modal-description',
              'Send this code to somebody at the other orgnaization that wants to share connectors &amp; datasources with you.',
            )}
            <div className={`${MAIN_CLASS}__alert_content_form`}>
              <div className="share-code">{shareCode}</div>
              <FlightButton
                type="primary"
                label={t('integrations:general.organization-share-code.copy', 'Copy')}
                disabled={generatingCode}
                loading={generatingCode}
                onClick={() => {
                  copyToClipboard(shareCode);
                  addNotification(
                    t('integrations:general.organization-share-code.success', 'Share code copied successfuly!'),
                  );
                }}
              />
            </div>
          </div>
        }
        footer={
          <div className={`${MAIN_CLASS}__alert_footer`}>
            <FlightButton
              type="button"
              theme="link"
              label={t(
                'integrations:general.organization-share-code.generate-new-share-code',
                'Generate a new share code',
              )}
              onClick={() => generateShareCode()}
            />
          </div>
        }
      />
      {/* DELETE DATA SOURCE MODAL */}
      <DeleteDatasourceModal
        handleDeleteDatasource={handleDeleteIntegration}
        t={t}
        showModal={tryToDeleteDatasource.length > 0}
        toggleModal={() => setTryToDeleteDatasource([])}
        datasourceData={tryToDeleteDatasource}
        organizationID={orgID}
        apiVersion={APIVersion}
      />
      {/* REMOVE CONNECTOR MODAL */}
      <FlightModal
        isVisible={tryToRemoveConnector.length > 0}
        toggleModalShown={() => setTryToRemoveConnector([])}
        scrollable={false}
        size="small"
        warning={false}
        className={`${MAIN_CLASS}__alert`}
        header={
          <span>
            {t('integrations:connectors.remove.header', {
              connector_name: tryToRemoveConnector[1] || '',
            })}
          </span>
        }
        footer={
          <div className={`${MAIN_CLASS}__alert_footer`}>
            <FlightButton
              type="button"
              theme="secondary"
              label={t('translation:general.buttons.cancel', 'Cancel')}
              onClick={() => {
                setTryToRemoveConnector([]);
              }}
            />
            <FlightButton
              type="button"
              label={t('integrations:connectors.remove.link-text', 'Remove connector')}
              onClick={async () => {
                handleRemoveConnector(tryToRemoveConnector);
                setTryToRemoveConnector([]);
              }}
            />
          </div>
        }
      />
      {/* DELETE CONNECTOR MODAL */}
      <DeleteConnectorModal
        handleDeleteConnector={handleDeleteIntegration}
        showModal={tryToDeleteConnector.length > 0}
        toggleModal={() => setTryToDeleteConnector([])}
        t={t}
        connectorData={tryToDeleteConnector}
        organizationID={orgID}
        apiVersion={APIVersion}
      />
      {/* SHARE CONNECTOR MODAL */}
      <ShareConnectorModal
        handleShareConnector={handleShareConnector}
        showModal={tryToShareConnector.length > 0}
        toggleModal={() => setTryToShareConnector([])}
        connectorData={tryToShareConnector}
      />

      <div className={`${CONTENT_CLASS} full-width`}>
        <div className={`${CONTENT_CLASS}__wrapper with-separator`}>
          <h1 className={`${CONTENT_CLASS}__heading-text`}>
            {t('integrations:general.heading', 'Integrations')}
            {!APIVersion && (
              <span>
                <FlightButton
                  label={t('integrations:general.organization-share-code.link-label', 'Organization share code')}
                  ariaLabel={t(
                    'integrations:general.organization-share-code.link-label-aria',
                    'Organization share code, opens dialog',
                  )}
                  className={`${CONTENT_CLASS}__share_button`}
                  size="medium"
                  theme="link"
                  onClick={() => {
                    shareCodeToggle(true);
                  }}
                />
              </span>
            )}
          </h1>
          <div className={`${CONTENT_CLASS}__tabs-header`} role="tablist">
            <span role="tab" aria-selected={selectedTab === 'datasource'}>
              <FlightRadioButton
                label={t('integrations:datasources.title-plural', 'Datasources')}
                checked={selectedTab === 'datasource'}
                className={`${CONTENT_CLASS}__tabs-header-item`}
                onSelect={() => {
                  setTab('datasource');
                }}
                value="datasource"
              />
            </span>
            <span role="tab" aria-selected={selectedTab === 'connector'}>
              <FlightRadioButton
                label={t('integrations:connectors.title-plural', 'Connectors')}
                checked={selectedTab === 'connector'}
                className={`${CONTENT_CLASS}__tabs-header-item`}
                onSelect={() => {
                  setTab('connector');
                }}
                value="connector"
              />
            </span>
            {APIVersion && (
              <span role="tab" aria-selected={selectedTab === 'remapping'}>
                <FlightRadioButton
                  label={t('integrations:remapping.title', 'Remapping')}
                  checked={selectedTab === 'remapping'}
                  className={`${CONTENT_CLASS}__tabs-header-item`}
                  onSelect={() => {
                    setTab('remapping');
                  }}
                  value="remapping"
                />
              </span>
            )}
          </div>
        </div>
        <div className={`${CONTENT_CLASS}__wrapper`}>
          {areIntegrationsLoading() && (
            <span className={`${CONTENT_CLASS}__loading`}>
              <LoadingIcon
                width={80}
                height={80}
                visible={areIntegrationsLoading() || connectorsImporting}
                fullScreen={connectorsImporting}
                text={connectorsImporting ? t('integrations:connectors.importing-message', 'Importing connector') : ''}
              />
            </span>
          )}
          {((selectedTab === 'datasource' && !areIntegrationsLoading() && datasources.length > 0) ||
            (selectedTab === 'connector' && !areIntegrationsLoading() && connectors.length > 0)) && (
            <div className={`${CONTENT_CLASS}__filters`}>
              <FlightTextInput
                placeholderText=""
                width="222px"
                hasClearIcon
                trailingIcon="search"
                ariaLabel={`Search ${selectedTab}s`}
                label={`Search ${selectedTab}s`}
                isLabelAlwaysOn
                type="text"
                name="table-filter"
                value={searchTerm}
                onChange={editSearchTerm}
              />
              <FlightSelect
                options={[
                  { key: 'all', name: t('integrations:general.filters.anyone', `Anyone`) || 'Anyone' },
                  {
                    key: 'owner',
                    name: t('integrations:general.filters.your-org', 'Your organization') || 'Your organization',
                  },
                  {
                    key: 'shared',
                    name: t('integrations:general.filters.shared-with-me', 'Shared with me') || 'Shared with me',
                  },
                ]}
                hasLabelAnimation
                label={`Filter ${selectedTab}s by`}
                selected={selectedFilter}
                dropdownMaxHeight="150px"
                width="180px"
                handleOptionClick={setSelectedFilter}
              />
              <div className="add-gap-20">
                <FlightButton
                  label={t(`integrations:${selectedTab}s.create`, `Create ${selectedTab}`)}
                  size="medium"
                  theme="secondary"
                  className={`${CONTENT_CLASS}__filters_button`}
                  onClick={() => {
                    history.push(`/organization/${orgID}/integrations/${APIVersion}${selectedTab}/new/#basic`);
                  }}
                />
                {selectedTab === 'connector' && areIntegrationsImportEnabled && (
                  <label id="label-file-upload" htmlFor="input-file-upload">
                    <div className="upload-template-drop-area-content">
                      <input
                        ref={importInputRef}
                        type="file"
                        id="input-file-upload"
                        accept=".json"
                        multiple={false}
                        onChange={handleImportConnector}
                      />
                      <FlightButton
                        ariaLabel={t(`integrations:${selectedTab}s.import`, `Import ${selectedTab}`)}
                        label={t(`integrations:${selectedTab}s.import`, `Import ${selectedTab}`)}
                        size="medium"
                        theme="secondary"
                        iconLeft={'download'}
                        onClick={onButtonClick}
                      />
                    </div>
                  </label>
                )}
              </div>
            </div>
          )}

          <div
            className={`${CONTENT_CLASS}__tabs-content`}
            role="tabpanel"
            aria-hidden="false"
            aria-label={`${selectedTab}s tab`}
          >
            {((selectedTab === 'datasource' && !areIntegrationsLoading() && datasources.length === 0) ||
              (selectedTab === 'connector' && !areIntegrationsLoading() && connectors.length === 0)) && (
              <div className={`${CONTENT_CLASS}__tabs-content-empty`}>
                <p>{selectedTab === 'connector' ? <ConnectorsImage /> : <DatasourcesImage />}</p>
                <p>
                  {t(
                    `integrations:${selectedTab}s.no-content`,
                    `No ${selectedTab}s have been added to this organization.`,
                  )}
                </p>
                <p>
                  <Link
                    to={{
                      pathname: t(
                        `integrations:${selectedTab}s.what-are-link`,
                        `https://app.gitbook.com/@flybits/s/product-guide/for-owners/managing-${selectedTab}s`,
                      ),
                    }}
                    target="_blank"
                  >
                    {t(`integrations:${selectedTab}s.what-are`, `What are ${selectedTab}s?`)}
                  </Link>
                </p>
                <div className="add-gap-20">
                  <FlightButton
                    label={t(`integrations:${selectedTab}s.create`, `Create ${selectedTab}`)}
                    size="medium"
                    theme="primary"
                    onClick={() => {
                      history.push(`/organization/${orgID}/integrations/${APIVersion}${selectedTab}/new/#basic`);
                    }}
                  />
                  {selectedTab === 'connector' && areIntegrationsImportEnabled && (
                    <label id="label-file-upload" htmlFor="input-file-upload">
                      <div className="upload-template-drop-area-content">
                        <input
                          ref={importInputRef}
                          type="file"
                          id="input-file-upload"
                          accept=".json"
                          multiple={false}
                          onChange={handleImportConnector}
                        />
                        <FlightButton
                          ariaLabel={t(`integrations:${selectedTab}s.import`, `Import ${selectedTab}`)}
                          label={t(`integrations:${selectedTab}s.import`, `Import ${selectedTab}`)}
                          size="medium"
                          theme="primary"
                          iconLeft={'download'}
                          onClick={onButtonClick}
                        />
                      </div>
                    </label>
                  )}
                </div>
              </div>
            )}
            {((selectedTab === 'datasource' && !areIntegrationsLoading() && datasources.length > 0) ||
              (selectedTab === 'connector' && !areIntegrationsLoading() && connectors.length > 0)) && (
              <div className={`${CONTENT_CLASS}__tabs-content-columns`}>
                <FlightTable
                  className={`${CONTENT_CLASS}__tabs-content-table`}
                  allowRowSelect
                  isLoading={areIntegrationsLoading()}
                  hasError={false}
                  emptyState={
                    <div className={`${CONTENT_CLASS}__tabs-content-table-no-results`}>
                      <p>
                        <strong>0</strong> {selectedTab}s found{' '}
                        {searchTerm && (
                          <>
                            with the filter &ldquo;<strong>{searchTerm}</strong>&rdquo;
                          </>
                        )}
                      </p>
                    </div>
                  }
                  errorState={<span>{error}</span>}
                  tableData={filteredSearchData()}
                  tableHeaders={selectedTab === 'connector' ? connectorsTableHeaders : datasourcesTableHeaders}
                  hasPaginationBeforeTable={false}
                  isShowHeader={false}
                />
                <div className={`${CONTENT_CLASS}__tabs-content-side`}>
                  <div className="item">
                    <div className="group">
                      <h2>{t(`integrations:${selectedTab}s.what-are`, `What are ${selectedTab}s?`)}</h2>
                      <p>
                        {t(`integrations:${selectedTab}s.what-are-text`)}
                        <br />
                        <Link
                          to={{
                            pathname: t(
                              `integrations:${selectedTab}s.what-are-link`,
                              `https://flybits.gitbook.io/product-guide/-MCJQnb9xKBWvcUyw_9V/for-owners/managing-${selectedTab}s`,
                            ),
                          }}
                          target="_blank"
                        >
                          {t(`integrations:${selectedTab}s.what-are-learn-more`, `Learn more`)}
                        </Link>
                      </p>
                      <div className="image">
                        {selectedTab === 'connector' ? <ConnectorsWhatImage /> : <DatasourcesWhatImage />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === 'remapping' && !areIntegrationsLoading() && APIVersion && (
              <div className={`${CONTENT_CLASS}__tabs-content-remapping`}>
                <Remapping t={t} orgID={orgID} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationsList;
