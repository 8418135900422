import React, { useState, useEffect } from 'react';
import './Authorization.scss';
import { FlightButton, FlightSelect, FlightTextInput, FlightTooltip } from '@flybits/design-system';
import { ReactComponent as QuestionCircle } from 'src/assets/icons/question-circle.svg';
import { ReactComponent as IconError } from 'src/assets/icon-blocked.svg';
import { ReactComponent as IconValid } from 'src/assets/icons/green-check.svg';
import { AuthorizationRequest, DataSource } from 'src/model/datasources/datasources';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
interface Props {
  current: DataSource | undefined;
  t: any;
  authWindow: any;
  onError: (tab: string, hasError: boolean) => void;
  onChangeValue: (e: any) => void;
  onConnect: (event: React.MouseEvent, data: AuthorizationRequest) => Promise<any>;
  onDisconnect: (data: AuthorizationRequest) => Promise<any>;
}
const Authorization: React.FunctionComponent<Props> = (props: Props) => {
  const MAIN_CLASS = 'Authorization';
  const { current, authWindow, onConnect, onDisconnect, onError, t } = props;
  const [authorizing, setAuthorizing] = useState(false);
  const [error, setError] = useState('');
  const [lastConnection, setLastConnection] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [authTagsList, setAuthTagsList] = useState<any[]>([]);
  const [selectedAuth, setSelectedAuth] = useState({
    key: 'none',
    name: t('integrations:connectors.details.sections.deployment.none', 'None') || 'None',
  });

  const [touched, setTouched] = useState<string[]>([]);
  // Auth test data
  //   {
  //     "authType": "oauth",
  //     "authTag": "test",
  //     "issuerUrl": "https://flybits.okta.com",
  //     "defaultOauthScopes": "openid",
  //     "clientId": "0oac1124lyQ2N5Gg4357",
  //     "clientSecret": "AvvjZSdrWfIorISZg5o8x8zw60WKfJicXUeQUJ-s"
  // }
  const [authData, setAuthData] = useState<AuthorizationRequest>({
    redirect: `https://${window.location.host}/datasource-auth-callback`,
    authTag: '',
    clientId: '',
    clientSecret: '',
  });
  useEffect(() => {
    !authWindow && setAuthorizing(false);
  }, [authWindow]);

  useEffect(() => {
    // get auth status
    const { authStatusMap = {} } = current || {};
    let list = [];
    let connectedIndex = 0;
    list = Object.entries(authStatusMap).map(([item, value], idx: number) => {
      if (value === 'connected') {
        connectedIndex = idx;
        setIsConnected(true);
      }
      return { key: item, name: item };
    });
    !list.length &&
      list.push({
        key: 'none',
        name: t('integrations:connectors.details.sections.deployment.none', 'None') || 'None',
      });
    setAuthTagsList(list);
    HandleSelectAuth(list[connectedIndex]);
  }, [current]);
  const preRequest = () => {
    setAuthorizing(true);
    setError('');
    setIsConnected(false);
  };
  const postRequest = (status: string, message = '', disconnect = false) => {
    setIsConnected(disconnect ? false : status === 'connected');
    if (['failed', 'revoked'].includes(status)) {
      setError(message);
      onError('authorization', true);
    } else {
      setError('');
      onError('authorization', false);
    }
    if (disconnect) {
      setAuthData({ ...authData, clientId: '', clientSecret: '', authTag: '' });
    }
    setTouched([]);
    setAuthorizing(false);
  };

  const getAuthTagsList = () => authTagsList;

  const HandleAuthorize = async (event: React.MouseEvent) => {
    preRequest();
    const { status, message } = await onConnect(event, authData);
    if (status === 'connected') {
      const dateNow = new Date();
      setLastConnection(
        `${dateNow.toLocaleTimeString('en', {
          timeStyle: 'short',
          hour12: true,
        })} ${dateNow.toLocaleDateString('en', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
        })}`,
      );
    }
    postRequest(status, message);
  };
  const HandleDisconnect = async () => {
    preRequest();
    const { status, message } = await onDisconnect(authData);
    postRequest(status, message, true);
  };
  const HandleChange = (e: any) => {
    if (!touched.includes(e.target.name)) {
      setTouched([...touched, e.target.name]);
    }
    setAuthData({
      ...authData,
      [e.target.name]: e.target.value,
    });
  };
  const HandleSelectAuth = (e: any) => {
    setSelectedAuth(e);
    if (e.key === 'none') onError('authorization', false);
    if (current?.authStatusMap[e.key] === 'failed' || current?.authStatusMap[e.key] === 'revoked')
      onError('authorization', true);
    setAuthData({ ...authData, authTag: e.key });
  };

  return (
    <div className={MAIN_CLASS}>
      <LoadingIcon
        width={80}
        height={80}
        visible={authorizing}
        fullScreen
        text={t('integrations:datasources.details.sections.authorization.messages.loading', 'Please wait...')}
      />
      <h2 className="section__title">
        {t('integrations:datasources.details.sections.authorization.title', 'Authorization')}
      </h2>
      <div className={`section__fields ${isConnected ? 'authorized' : ''}`}>
        <div className="section__fields-field status">
          {!!error && (
            <div className="error">
              <IconError /> {error}
            </div>
          )}
          {isConnected && (
            <div className="success">
              <IconValid />{' '}
              <strong>{t('integrations:datasources.details.sections.authorization.authorized', 'Authorized')}</strong>{' '}
              <span>{lastConnection || ''}</span>
            </div>
          )}
        </div>
        {!isConnected ? (
          <>
            <div className="section__fields-field">
              <label htmlFor="dropdown-select-auth-tag">
                {t('integrations:datasources.details.sections.authorization.labels.auth_tag', 'Auth Tag')}{' '}
                <FlightTooltip
                  direction="right"
                  isEnabled={true}
                  description={t(
                    'integrations:datasources.details.sections.authorization.labels.auth_tag_tooltip',
                    'Select an Auth Tag to identify your connection',
                  )}
                >
                  <QuestionCircle />
                </FlightTooltip>
              </label>
              <FlightSelect
                options={getAuthTagsList()}
                selected={selectedAuth}
                dropdownMaxHeight="200px"
                ariaControlsId="auth-tag"
                width="600px"
                handleOptionClick={HandleSelectAuth}
              />
            </div>
            {selectedAuth.key !== 'none' && (
              <>
                <div className="section__fields-field">
                  <h3>
                    {t('integrations:datasources.details.sections.authorization.labels.client_id', 'Client ID')}{' '}
                    <FlightTooltip
                      direction="right"
                      isEnabled={true}
                      description={t(
                        'integrations:datasources.details.sections.authorization.labels.client_id_tooltip',
                        'Client ID from your auth provider',
                      )}
                    >
                      <QuestionCircle />
                    </FlightTooltip>
                  </h3>
                  <FlightTextInput
                    placeholderText=""
                    width="600px"
                    type="text"
                    name="clientId"
                    disabled={authorizing}
                    value={authData.clientId}
                    hasError={touched.includes('clientId') && !authData.clientId}
                    errorMessage={
                      <span role="alert" aria-atomic>
                        <b>Error:</b>
                        {t(
                          'integrations:datasources.details.sections.authorization.labels.client_id_error',
                          'Please add a Client ID',
                        )}
                      </span>
                    }
                    onChange={HandleChange}
                  />
                </div>
                <div className="section__fields-field">
                  <h3>
                    {t('integrations:datasources.details.sections.authorization.labels.client_secret', 'Client Secret')}{' '}
                    <FlightTooltip
                      direction="right"
                      isEnabled={true}
                      description={t(
                        'integrations:datasources.details.sections.authorization.labels.client_secret_tooltip',
                        'Client Secret from your auth provider',
                      )}
                    >
                      <QuestionCircle />
                    </FlightTooltip>
                  </h3>
                  <FlightTextInput
                    placeholderText=""
                    width="600px"
                    type="password"
                    disabled={authorizing}
                    name="clientSecret"
                    value={authData.clientSecret}
                    hasError={touched.includes('clientSecret') && !authData.clientSecret}
                    errorMessage={
                      <span role="alert" aria-atomic>
                        <b>Error:</b>
                        {t(
                          'integrations:datasources.details.sections.authorization.labels.client_secret_error',
                          'Please add a Client Secret',
                        )}
                      </span>
                    }
                    onChange={HandleChange}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="section__fields-field readonly">
              <strong>
                {t('integrations:datasources.details.sections.authorization.labels.client_id', 'Client ID')}
              </strong>{' '}
              {`laskfh;lskdJF;LAKSDJG;LKADSJG;LKADH;KJFHUIOWUROIWQ`}
            </div>
          </>
        )}

        {selectedAuth.key !== 'none' && (
          <div className="section__fields-field">
            {!isConnected ? (
              <FlightButton
                type="button"
                theme="primary"
                disabled={authorizing || !authData.clientId || !authData.clientSecret || !authData.authTag}
                loading={authorizing}
                label={t('integrations:datasources.details.sections.authorization.labels.btn', 'Authorize')}
                onClick={HandleAuthorize}
              />
            ) : (
              <FlightButton
                type="button"
                theme="secondary"
                disabled={authorizing}
                loading={authorizing}
                label={t('integrations:datasources.details.sections.authorization.labels.disconnect', 'Disconnect')}
                onClick={HandleDisconnect}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Authorization;
