import React, { useEffect, useState } from 'react';
import { FlightButton, FlightModal, FlightTable, FlightTextInput } from '@flybits/design-system';
import './TeamMembersModal.scss';

import { TeamModalMembersProps, TeamUsers, TeamUser } from 'src/model/teams/teams';
import TeamService from 'src/services/teams.service';
import useNotification from 'src/hooks/useNotification';
import { getFormattedRole } from 'src/helpers/teams.helper';
import { UserColValue, UserSelectedProps } from 'src/model/users/users';
import { capitalize } from 'src/helpers/general.helper';
import OrganizationService from 'src/services/organization.service';
import { OrganizationUser } from 'src/model/organizations/organizations';

export function TeamMembersModal(props: TeamModalMembersProps) {
  const { openModal, toggleModal, cancelModal, users, team, organization, t } = props;
  const defaultSelection = new Set('');
  const [selectedMembers, setSelectedMembers] = useState(defaultSelection);
  const [firstSelection, setFirstSelection] = useState(defaultSelection);
  const [membersList, setUsers] = useState<UserSelectedProps[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [updating, setUpdating] = useState(false);
  const { addNotification, addNotificationError } = useNotification();

  const teamServiceManager = new TeamService();
  const organizationServiceManager = new OrganizationService();
  const membersTableHeaders = [
    {
      key: 'userFormatted',
      isVisible: true,
    },
    {
      key: 'name',
      isVisible: false,
    },
    {
      key: 'email',
      isVisible: false,
    },
    {
      key: 'id',
      isVisible: false,
    },
    {
      key: 'organization',
      isVisible: false,
    },
    {
      key: 'roleFormatted',
      isVisible: false,
    },
    {
      key: 'role',
      isVisible: false,
    },
    {
      key: 'permissions',
      isVisible: false,
    },
  ];
  const MAIN_CLASS = 'TeamDetails';
  const CONTENT_CLASS = `${MAIN_CLASS}__content`;

  const UserTableCol = (data: UserColValue) => (
    <div className="memberHeader">
      <p>
        <strong aria-label={'Highlighted user: ' + data.name + '.'}>{data.name}</strong>
      </p>
      <p>
        <small aria-label={'E-mail address: ' + data.email + ' .'}>{data.email}</small>
      </p>
    </div>
  );
  const UserRoleCol = (role: Array<string>) => (
    <span
      aria-label={
        'User role:' +
        capitalize(
          t(
            `translation:general.levels.${getFormattedRole(role) || 'member'}.single`,
            getFormattedRole(role) || 'member',
          ),
        ) +
        ' .'
      }
    >
      {capitalize(
        t(
          `translation:general.levels.${getFormattedRole(role) || 'member'}.single`,
          getFormattedRole(role) || 'member',
        ),
      )}
      {getFormattedRole(role) === 'admin' && (
        <small>
          <br />
          {role.length > 1
            ? role
                .map((r) =>
                  capitalize(
                    t(`translation:general.levels.admin.permissions.${r.toLocaleLowerCase()}`, r.toLocaleLowerCase()),
                  ),
                )
                .join(', ')
            : capitalize(
                t(
                  `translation:general.levels.admin.permissions.${role.toString().toLocaleLowerCase()}`,
                  role.toString().toLocaleLowerCase(),
                ),
              )}
        </small>
      )}
    </span>
  );

  useEffect(() => {
    const getOrgMembers = async () => {
      setSearchTerm('');
      const orgMembersResponseObject = await organizationServiceManager.getOrganizationUsers(organization);
      if (orgMembersResponseObject?.data) {
        setUsers(
          orgMembersResponseObject.data.map((orgUser: OrganizationUser) => ({
            key: orgUser.id,
            userFormatted: UserTableCol({
              name: orgUser.givenName + ' ' + orgUser.familyName,
              email: orgUser.email,
            }),
            roleFormatted: UserRoleCol(orgUser.roles),
            name: orgUser.givenName + ' ' + orgUser.familyName,
            email: orgUser.email,
            id: orgUser.id,
            organization: orgUser.organization,
            role: orgUser.roles,
            permissions: orgUser.roles,
          })),
        );
        setSelectedMembers(new Set(users.map((member: any) => member.id)));
        setFirstSelection(new Set(users.map((member: any) => member.id)));
      }
    };

    if (updating) return;
    team && organization && openModal && getOrgMembers();
    // eslint-disable-next-line
  }, [users, openModal]);

  const handleMembersUpdate = async () => {
    setUpdating(true);
    const membersToAdd: TeamUser[] = [];
    const membersToRemove: TeamUser[] = [];

    membersList.map((member) => {
      if (selectedMembers.has(member.id) && !firstSelection.has(member.id)) {
        membersToAdd.push({ id: member.id, maintainer: false });
      }
      if (!selectedMembers.has(member.id) && firstSelection.has(member.id)) {
        membersToRemove.push({ id: member.id, maintainer: false });
      }
    });
    try {
      // Add
      if (membersToAdd.length) {
        const submitData: TeamUsers = {
          users: membersToAdd,
        };
        const teamResponseObject = await teamServiceManager.addUserToTeam(organization, team, submitData);
        if (teamResponseObject && (teamResponseObject.status === 201 || teamResponseObject.status === 200)) {
          // Check for success/fails
          if (teamResponseObject?.data?.failed?.length > 0) {
            addNotificationError(
              t('translation:pages.teams.messages.update_team_members_error', {
                total: teamResponseObject?.data?.failed?.length,
              }),
            );
          } else {
            addNotification(
              t('translation:pages.teams.messages.update_team_members_success', 'Team members updated successfully!'),
            );
          }
        }
      }

      // Remove
      if (membersToRemove.length) {
        await Promise.all(
          membersToRemove.map(async (member: any) => {
            const teamRemoveResponseObject = await teamServiceManager.removeUserFromTeam(organization, team, member.id);
            if (!teamRemoveResponseObject) return false;
            if (teamRemoveResponseObject.status >= 200 && teamRemoveResponseObject.status < 400) {
              return true;
            }
            return false;
          }),
        );
      }
      setUpdating(false);
      toggleModal();
      return true;
    } catch (error) {
      if (error?.response?.data?.message) {
        addNotificationError(
          t(
            'translation:pages.teams.messages.update_team_members_error_generic',
            'Some members were unable to be added to team',
          ),
        );
        console.error(error.response.data.message);
      }
      setUpdating(false);
      return false;
    }
  };

  const editSearchTerm = (e: any) => {
    setSearchTerm(e.target.value || '');
  };
  const membersFilteredData = () => {
    return membersList.filter(
      (member) =>
        !searchTerm ||
        member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.email.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  };
  const handleTableDataSelect = (item: UserSelectedProps) => {
    const selectedData = selectedMembers;
    if (selectedData.has(item.id)) selectedData.delete(item.id);
    else selectedData.add(item.id);
    setSelectedMembers(new Set(selectedData));
  };
  return (
    <>
      <FlightModal
        isVisible={openModal}
        toggleModalShown={toggleModal}
        className="TeamMembersModal"
        scrollable={false}
        size="large"
        header={<span>{t('translation:pages.teams.modal_members.header', 'Add team members')}</span>}
        content={
          <div>
            <div className="TeamMembersModal__search_field">
              <FlightTextInput
                placeholderText=""
                width="292px"
                hasClearIcon
                trailingIcon="search"
                type="text"
                name="table-filter"
                value={searchTerm}
                onChange={editSearchTerm}
                ariaLabel={`Filter members`}
                label={`Filter members`}
                isLabelAlwaysOn
              />
            </div>

            <div className="TeamMembersModal__members_list" role="group" aria-label="Members list">
              <FlightTable
                className={`${CONTENT_CLASS}__tabs-content-table`}
                allowRowSelect
                allowMultiSelect
                selectedDataKey={selectedMembers}
                handleDataSelect={handleTableDataSelect}
                hasError={false}
                emptyState={
                  <div className={`${CONTENT_CLASS}__tabs-content-table-no-results`}>
                    <p>
                      <strong>0</strong>{' '}
                      {t('translation:pages.teams.messages.no_members_found', 'members found in this team')}{' '}
                      {searchTerm && (
                        <>
                          {t('translation:pages.teams.messages.with_filter', 'with the filter')} &ldquo;
                          <strong>{searchTerm}</strong>&rdquo;
                        </>
                      )}
                    </p>
                  </div>
                }
                tableData={membersFilteredData()}
                tableHeaders={membersTableHeaders}
                hasPaginationBeforeTable={false}
                isShowHeader={false}
              />
            </div>
          </div>
        }
        footer={
          <div>
            <FlightButton
              type="button"
              label={t('translation:pages.teams.modal_members.btn-add', 'Add selected')}
              onClick={async () => await handleMembersUpdate()}
            />
            <FlightButton
              type="button"
              theme="secondary"
              label={t('translation:general.buttons.cancel', 'Cancel')}
              onClick={cancelModal}
            />
          </div>
        }
      />
    </>
  );
}
// export default TeamMembersModal;
