import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom';
import {
  clearDB,
  deleteStoredEmail,
  deleteStoredOrganizationId,
  deleteStoredPassword,
  deleteStoredProjectId,
  deleteUserConfirmed,
  deleteUserRoles,
  getStoredOrganizationId,
  setStoredOrganizationId,
  setUserRoles,
} from 'src/helpers/auth.helper';
import { Organization } from 'src/model/organizations/organizations';
import OrganizationService from 'src/services/organization.service';
import { useControlTowerContext } from 'src/contexts/ControlTowerContext';
import AuthService from 'src/services/authentication.service';
import { PRIVATE_PAGE_TITLES } from 'src/constants/titles';
import useNotification from 'src/hooks/useNotification';

const authServiceManager = new AuthService();

const PrivateRoute = (routeProps: RouteProps) => {
  const { setControlTowerContextDataOrg, organization, user } = useControlTowerContext();
  const history = useHistory();
  const locationCheck = useLocation();
  const { addNotificationError } = useNotification();
  useEffect(() => {
    // change page title based on route
    const pageTitle =
      routeProps && routeProps.path === 'string' && PRIVATE_PAGE_TITLES[routeProps.path]
        ? PRIVATE_PAGE_TITLES[routeProps.path]
        : 'Organization projects';
    document.title = pageTitle + ' - Control Tower';
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [locationCheck]);
  // Service worker update check
  useEffect(() => {
    history.listen(() => {
      // check for sw updates on page change
      navigator.serviceWorker.getRegistrations().then((regs) => regs.forEach((reg) => reg.update()));
    });
  }, []);

  const initSignOut = async () => {
    try {
      if (user?.userId) {
        await authServiceManager.signOutUser();
      }
      await clearDB();
      await deleteStoredProjectId();
      await deleteStoredOrganizationId();
      await deleteStoredEmail();
      await deleteStoredPassword();
      await deleteUserConfirmed();
      await deleteUserRoles();

      // set redirect url if needed
      const redirectURL = `${window.location.pathname}${window.location.search}${window.location.hash}`;
      history.replace(`/signin?loggedOut=true&next=${btoa(redirectURL)}`);
    } catch (error) {
      console.error(error);
    }
  };
  // organization id is not set/found
  useEffect(() => {
    const checkSignedInStatus = async () => {
      const _isAuthenticated = await authServiceManager.getSignedInUser();

      if (_isAuthenticated?.status != 200) {
        initSignOut();
        return null;
      } else {
        const getOrganizationDetails = async () => {
          if (organization?.id) await setStoredOrganizationId(organization.id);
          const fallbackOrgId = await getStoredOrganizationId();
          const orgID = organization?.id || fallbackOrgId;
          if (!orgID) return;
          const organizationServiceManager = new OrganizationService();
          try {
            const organizationResponseObject = await organizationServiceManager.getOrganization(orgID);
            if (organizationResponseObject?.data) {
              const transformedResponse: Organization = organizationResponseObject?.data;
              let roles = [];
              if (user && user.userId) {
                const organizationUserResponseObject = await organizationServiceManager.getOrganizationUserInfo(
                  orgID,
                  user.userId,
                );
                if (organizationUserResponseObject?.data) {
                  roles = organizationUserResponseObject.data.roles;
                  await setUserRoles(roles);
                }
              }
              setControlTowerContextDataOrg({ ...transformedResponse, roles: roles });
            }
          } catch (error) {
            console.error(error?.response);
            if ([400, 401, 404].includes(error.response.status)) {
              addNotificationError('Your token has expired, please log in again.');
              const redirectURL = `${window.location.pathname}${window.location.search}${window.location.hash}`;
              history.replace(`/signin?next=${window.btoa(redirectURL)}`);
            } else {
              history.replace('/organizations');
            }
          }
        };

        getOrganizationDetails();
      }
    };
    user?.userId && checkSignedInStatus();
    // eslint-disable-next-line
  }, [routeProps.path]);

  return <Route {...routeProps} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};

export default PrivateRoute;
