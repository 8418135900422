import React, { useEffect } from 'react';
import LoadingIcon from 'src/components/Shared/LoadingIcon/LoadingIcon';
import { useHistory } from 'react-router';
import AuthService from 'src/services/authentication.service';

const authServiceManager = new AuthService();
function ApiAuthRequest() {
  const history = useHistory();
  useEffect(() => {
    (async () => {
      try {
        const res = await authServiceManager.getApiAuthRequest(
          `${window.location.origin}/api/auth/request2/${window.location.search}`,
        );
        if (res && res.headers.location) window.location.href = res.headers.location;
      } catch (error) {
        history.push('/not-found');
      }
    })();
  }, []);

  return (
    <>
      <LoadingIcon width={80} height={80} fullScreen visible />
    </>
  );
}

export default ApiAuthRequest;
