import React, { useEffect, useState } from 'react';
import './SaveToolbar.scss';
import { FlightButton } from '@flybits/design-system';
import { diffObject } from 'src/helpers/general.helper';
import { Connector, ConnectorV1 } from 'src/model/connectors/connectors';
import useExitPrompt from 'src/hooks/useExitPrompt';
import { DataSource, DataSourceV1 } from 'src/model/datasources/datasources';

interface Props {
  className?: string;
  current: Connector | ConnectorV1 | DataSource | DataSourceV1 | undefined;
  defaultValues: Connector | ConnectorV1 | DataSource | DataSourceV1 | undefined;
  disabled?: boolean;
  isCodeModified?: boolean;
  setModified: (e: any) => void;
  onClickSave: (e: any) => void;
  onClickCancel: (e: any) => void;
}

const SaveToolbar = React.memo((props: Props) => {
  const { className, isCodeModified, disabled, current, defaultValues, setModified, onClickSave, onClickCancel } =
    props;
  const [unsaved, setUnsaved] = useState(0);
  const [, setShowExitPrompt] = useExitPrompt(false);
  const checkChanges = (
    currentData: Connector | ConnectorV1 | DataSource | DataSourceV1 | undefined,
    defaultData: Connector | ConnectorV1 | DataSource | DataSourceV1 | undefined,
  ) => {
    // if (!isCodeModified && JSON.stringify(defaultData) === JSON.stringify(currentData)) return 0;
    const diffs = diffObject(defaultData, currentData, true);
    return Object.keys(diffs.updated).length + Object.keys(diffs.added).length + Object.keys(diffs.removed).length;
  };
  useEffect(() => {
    if (!current || !defaultValues) {
      setUnsaved(0);
      setModified(false);
      return;
    }
    setUnsaved(checkChanges(defaultValues, current));
  }, [current, defaultValues]);
  useEffect(() => {
    setUnsaved(isCodeModified ? checkChanges(defaultValues, current) || 1 : checkChanges(defaultValues, current));
  }, [isCodeModified]);
  useEffect(() => {
    setModified(unsaved > 0);
    setShowExitPrompt(unsaved > 0);
  }, [unsaved]);
  useEffect(() => {
    return () => {
      setShowExitPrompt(false);
    };
  }, []);
  return (
    <div className={`SaveToolbar ${className} ${isCodeModified || unsaved > 0 ? 'show' : ''}`}>
      <div>You have {unsaved} unsaved changes</div>
      <div>
        <FlightButton type="button" label="Save changes" onClick={onClickSave} disabled={disabled} />
        <FlightButton type="button" theme="secondary" label="Discard" onClick={onClickCancel} />
      </div>
    </div>
  );
});
SaveToolbar.displayName = 'SaveToolbar';
export default SaveToolbar;
