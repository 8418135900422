const EXP_STUDIO_1_ENABLED = 'ct_enable_experience_studio_1.0';
const EXP_STUDIO_2_ENABLED = 'ct_enable_experience_studio_2.0';
const EXP_STUDIO_2_OVERRIDES = ['staging', 'dev', 'development'];
const INTEGRATIONS_ENABLED = 'ct_enable_integrations';
const TRANSLATIONS_ENABLED = 'ct_enable_translations';
const REMAPPING_ENABLED = 'ct_enable_remapping';
const INTEGRATIONS_V1_ENABLED = 'ct_enable_integrations_v1';
const IMPORT_INTEGRATIONS_ENABLED = 'ct_enable_import_integrations';

export {
  EXP_STUDIO_1_ENABLED,
  EXP_STUDIO_2_ENABLED,
  EXP_STUDIO_2_OVERRIDES,
  INTEGRATIONS_ENABLED,
  TRANSLATIONS_ENABLED,
  REMAPPING_ENABLED,
  INTEGRATIONS_V1_ENABLED,
  IMPORT_INTEGRATIONS_ENABLED,
};

export const CONTROL_TOWER_FLAGS = [
  EXP_STUDIO_1_ENABLED,
  EXP_STUDIO_2_ENABLED,
  EXP_STUDIO_2_OVERRIDES,
  INTEGRATIONS_ENABLED,
  TRANSLATIONS_ENABLED,
  REMAPPING_ENABLED,
  INTEGRATIONS_V1_ENABLED,
  IMPORT_INTEGRATIONS_ENABLED,
];
